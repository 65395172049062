import { createAsyncThunk } from "@reduxjs/toolkit";

import { ISolutionCatalogData, IGetSolutionListThunkData } from "../../../../../types/redux/thunkTypes";
import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";
import { gettingSolutionPaginationEndpoint } from "../../../../api/resources/apiResourcesConstants";

const baseURL = getCorrectBaseUrl("ps");

export const getSolutionListData = createAsyncThunk(
	"solution/getSolutions",
	async (params: IGetSolutionListThunkData, thunkAPI) => {
		try {
			const response = await fetch(
				`${baseURL}/${gettingSolutionPaginationEndpoint}?offset=${params.pageNumber - 1}&limit=${params.limit}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(params.filterData),
					credentials: "include",
				},
			);
			return (await response.json()) as ISolutionCatalogData;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);
