import { IContextModalPositionState } from "../types/ui/ContextMenuModal";

export enum MenuContent {
	EDIT = "Редактировать",
	PREVIEW = "Предпросмотр",
	PUBLISH = "Опубликовать",
	UNPUBLISH = "Снять с публикации",
	RECOVER = "Восстановить",
	DELETE = "Удалить",
}

export const defaultContextMenuPosition: IContextModalPositionState = {
	top: 0,
	left: 0,
};
