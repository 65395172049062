import { SubmitHandler, useForm } from "react-hook-form";
import { BaseSyntheticEvent } from "react";

import { ICreateAdminProfileForm } from "../../../types/common/ComponentProps";
import { ICreateProfileData } from "../../../types/components/CreateProfileForm";
import { IEmployeeProfileData } from "../../../types/api/AdminApiTypes";
import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { ServerError } from "../../../types/api/AuthApiTypes";
import { Form } from "../../../ui/Form/Form";
import { AuthInput } from "../../AuthLayoutComponents/AuthInput/AuthInput";
import { createNewEmployeeProfile } from "../../../services/api/admin/staffPageActions";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";
import {
	emailPattern,
	errorServerLoginEmail,
	firstnamePattern,
	lastnamePattern,
	passwordPattern,
	serverLoginErrors,
} from "../../../helpers/AuthConstants";

import styles from "./CreateProfileForm.module.css";

function CreateAdminProfileForm({ userRole, toggleModal }: ICreateAdminProfileForm) {
	const {
		handleSubmit,
		control,
		setError,
		formState: { isValid, errors },
		reset,
	} = useForm<ICreateProfileData>({
		defaultValues: {
			firstname: "",
			lastname: "",
			email: "",
			password: "",
		},
		mode: "all",
		delayError: 200,
	});

	const minimalEmailLength: number = 8;
	const maximalEmailLength: number = 30;

	const getMessageType = (message: string) => (message === errorServerLoginEmail ? "email" : "password");

	const onSubmit: SubmitHandler<ICreateProfileData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		const employeeProfileData: IEmployeeProfileData = {
			firstname: data.firstname.trim(),
			lastname: data.lastname.trim() ?? "",
			email: data.email.trim(),
			password: data.password.trim(),
			role: userRole as string,
		};
		createNewEmployeeProfile(employeeProfileData)
			.then(() => {
				reset();
				return toggleModal();
			})
			.catch((e: ServerErrorResponse) => {
				const message = (e.response?.data as ServerError).message as keyof typeof serverLoginErrors;
				message &&
					setError(
						getMessageType(message),
						getDefaultServerErrorMessage(serverLoginErrors[message]),
						shouldBeFocusedField,
					);
			});
	};

	return (
		<div className={styles.createAdminProfileFormWrap}>
			<Form
				noValidate={true}
				buttonName="Добавить"
				isValid={isValid}
				styleName="authForm"
				onSubmit={handleSubmit(onSubmit)}>
				<AuthInput
					control={control}
					id={"firstname"}
					name={"firstname"}
					label={"Имя"}
					type={"text"}
					rules={{
						required: true,
						minLength: 2,
						maxLength: 25,
						pattern: firstnamePattern,
					}}
					error={formHelpers.getNameError(errors)}
				/>
				<AuthInput
					control={control}
					id={"lastname"}
					name={"lastname"}
					label={"Фамилия"}
					type={"lastname"}
					rules={{
						required: true,
						pattern: lastnamePattern,
					}}
					error={formHelpers.getLastNameError(errors)}
				/>
				<AuthInput
					control={control}
					id={"email"}
					name={"email"}
					label={"Email"}
					type={"email"}
					autoComplete={"username"}
					rules={{
						required: true,
						pattern: emailPattern,
						minLength: minimalEmailLength,
						maxLength: maximalEmailLength,
					}}
					error={formHelpers.getEmailError(errors)}
				/>
				<AuthInput
					control={control}
					id={"current-password"}
					name={"password"}
					label={"Пароль"}
					type={"password"}
					autoComplete={"current-password"}
					rules={{
						required: true,
						pattern: passwordPattern,
					}}
					error={formHelpers.getCommonPasswordError(errors, control._formValues.password)}
				/>
			</Form>
		</div>
	);
}

export default CreateAdminProfileForm;
