/* eslint-disable react/no-unescaped-entities */
import { BaseSyntheticEvent } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";

import { ServerError, ResetPasswordData } from "../../../types/api/AuthApiTypes";
import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { Title } from "../../../ui/Title/Title";
import { Form } from "../../../ui/Form/Form";
import ReturnBackArrow from "../../../components/PersonalAreaLayoutComponents/ReturnBackArrow/ReturnBackArrow";
import { AuthInput } from "../../../components/AuthLayoutComponents/AuthInput/AuthInput";
import { resetUserPassword } from "../../../services/api/auth/resetUserPassword";
import { Path } from "../../../services/router/routes";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";
import { emailPattern, errorServerLoginEmail, serverLoginErrors } from "../../../helpers/AuthConstants";

import logo from "../../../assets/layouts/Main/logo.webp";

import styles from "./ResetPassword.module.css";

const ResetPassword = () => {
	const navigator = useNavigate();
	const {
		handleSubmit,
		control,
		setError,
		formState: { isValid, errors },
	} = useForm<ResetPasswordData>({
		defaultValues: {
			email: "",
		},
		mode: "all",
		delayError: 200,
	});

	const onSubmit: SubmitHandler<ResetPasswordData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		resetUserPassword(data)
			.then(() => navigator(-1))
			.catch((error: ServerErrorResponse) => {
				const message = (error.response?.data as ServerError).message;

				if (message === errorServerLoginEmail) {
					setError(
						"email",
						getDefaultServerErrorMessage(serverLoginErrors[errorServerLoginEmail]),
						shouldBeFocusedField,
					);
				}
			});
	};

	return (
		<>
			<div className={styles["logo-container"]}>
				<NavLink to={Path.Main}>
					<img src={logo} alt="Townsend logo" />
				</NavLink>
			</div>

			<div className={styles.resetPasswordWrap}>
				<ReturnBackArrow />
				<div className={styles.resetPasswordContainer}>
					<Title title={"Восстановить пароль"} styleName={"title _left"} />
					<Form
						styleName={styles.resetPasswordForm}
						buttonName="Отправить письмо"
						onSubmit={handleSubmit(onSubmit)}
						isValid={isValid}
						noValidate>
						<AuthInput
							control={control}
							id={"user-email"}
							name={"email"}
							label={"Email"}
							type={"email"}
							autoComplete={"username"}
							rules={{
								required: true,
								pattern: emailPattern,
							}}
							error={formHelpers.getEmailError(errors)}
						/>
					</Form>
					<div className={styles.warningInfoWrap}>
						<p className={styles.warningInfoWrap__title}>
							На Вашу электронную почту мы пришлём ссылку для восстановления пароля, если письмо не приходит,
							пожалуйста, проверьте папку "спам."
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResetPassword;
