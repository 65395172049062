import { IPlatformChange } from "../../../types/pages/Platform";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

export async function changeEditPlatform(inputData: IPlatformChange, id: string | undefined) {
	const baseURL = getCorrectBaseUrl("ps");

	return fetch(`${baseURL}/platforms/${id}`, {
		method: "PUT",
		body: JSON.stringify(inputData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
