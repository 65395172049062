import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IPlatformCatalogDataState } from "../../../../../types/redux/storeTypes";
import { IPlatformCatalogData, IPlatformCatalogContent } from "../../../../../types/redux/thunkTypes";

import { getPlatformListData } from "./PlatformCatalogThunk";

const platformCatalogDataState: IPlatformCatalogDataState = {
	catalogPlatforms: [],
	pageNumber: 0,
	loading: false,
	error: null,
};

const PlatformCatalogSlice = createSlice({
	name: "platforms",
	initialState: platformCatalogDataState,
	reducers: {
		setPlatformsData: (state: IPlatformCatalogDataState, action: PayloadAction<IPlatformCatalogContent[]>) => {
			state.catalogPlatforms = action.payload;
			state.pageNumber = 1;
		},
		clearData: (state: IPlatformCatalogDataState) => {
			state.catalogPlatforms = platformCatalogDataState.catalogPlatforms;
		},
	},
	extraReducers(builder) {
		builder.addCase(
			getPlatformListData.fulfilled,
			(state: IPlatformCatalogDataState, action: PayloadAction<IPlatformCatalogData>) => {
				state.catalogPlatforms = [...action.payload.content];
				state.pageNumber = action.payload.totalPages;
				state.loading = false;
			},
		);
		builder.addCase(
			getPlatformListData.rejected,
			(state: IPlatformCatalogDataState, action: PayloadAction<string | unknown>) => {
				state.error = action.payload;
				state.loading = false;
			},
		);
		builder.addCase(getPlatformListData.pending, (state: IPlatformCatalogDataState) => {
			state.loading = true;
		});
	},
});

export const { setPlatformsData, clearData } = PlatformCatalogSlice.actions;
export default PlatformCatalogSlice.reducer;
