import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import className from "classnames";

import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

import { Title } from "../../../ui/Title/Title";
import { ContactsLink } from "../../../components/MainLayoutComponents/ContactsLink/ContactsLink";
import { ContactsForm } from "../../../components/MainLayoutComponents/ContactsForm/ContactsForm";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { solutionFavoritesSelector } from "../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSelector";
import { platformFavoritesSelector } from "../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesSelector";
import { setActiveSolution } from "../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSlice";
import { setActivePlatform } from "../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesSlice";
import { Path } from "../../../services/router/routes";
import { processingPersonalDataLink } from "../../../helpers/contacts";

import styles from "./Contacts.module.css";

export const Contacts = () => {
	const userData = useAppSelector(userDataSelector).userData;
	const solutionFavoriteState = useAppSelector(solutionFavoritesSelector);
	const platformFavoritesState = useAppSelector(platformFavoritesSelector);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigator = useNavigate();

	useEffect(() => {
		if (location.pathname.includes(Path.PersonalAreaContacts) && !userData?.email) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		window.scrollTo({ top: 0 });
		return () => {
			dispatch(setActiveSolution(""));
			dispatch(setActivePlatform(""));
		};
	}, [dispatch]);

	const renderFormTitle = () => {
		const categoryName = solutionFavoriteState.activeSolutionTitle ? "Решение" : "Платформа";

		return solutionFavoriteState.activeSolutionTitle || platformFavoritesState.activePlatformTitle ? (
			<p>
				{categoryName}
				{userData?.id ? (
					<span className={styles.productName}>
						{" - "}
						{solutionFavoriteState.activeSolutionTitle || platformFavoritesState.activePlatformTitle}
					</span>
				) : (
					<></>
				)}
			</p>
		) : (
			<p>Давайте обсудим вашу задачу</p>
		);
	};

	return (
		<div className={styles.contactsContainer}>
			<div className={styles.contactsTitle}>
				<Title title="Связь с нами" styleName="titlePage" />
			</div>
			<div className={className(styles.contactsWrapper, { [styles.contactsWrapper__auth]: userData?.id })}>
				<div className={styles.formTitle}>{renderFormTitle()}</div>
				<div className={styles.formWrapper}>
					<address className={styles.formAddress}>
						<ContactsLink type="phone" content="+7 (911) 954-7183" />
						<ContactsLink type="email" content="loremdorsim@ipsum.ru" />
						<p className={styles.formInstruction}>Или просто заполните форму</p>
						<p className={styles.formAgreement}>
							Нажимая на кнопку «Отправить», я соглашаюсь с&nbsp;
							<a className={styles.processingLink} href={processingPersonalDataLink}>
								условиями обработки персональных данных.
							</a>
						</p>
					</address>
					<ContactsForm />
				</div>
			</div>
		</div>
	);
};
