import { UseControllerProps } from "react-hook-form";

import { IAuthInput } from "../../../types/common/ComponentProps";
import { Input } from "../../../ui/Input/Input";

import styles from "./AuthInput.module.css";

export const AuthInput = ({
	label,
	id,
	name,
	type,
	placeholder,
	autoComplete,
	error,
	...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IAuthInput & UseControllerProps<any>) => {
	return (
		<div className={styles["input-container"]}>
			<div className={styles["label-container"]}>
				<label htmlFor={id} className={styles.label}>
					{label}
				</label>
			</div>
			<Input
				id={id}
				name={name}
				placeholder={placeholder}
				autoComplete={autoComplete}
				type={type}
				control={props.control}
				rules={props.rules}
			/>
			{error && <p className={styles.error}>{error}</p>}
		</div>
	);
};
