import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useModal from "../../../hooks/useModal";

import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { ServerError, UpdateUserInfo } from "../../../types/api/AuthApiTypes";
import { IUserData } from "../../../types/redux/thunkTypes";
import { Form } from "../../../ui/Form/Form";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";
import { AuthInput } from "../../../components/AuthLayoutComponents/AuthInput/AuthInput";
import { Title } from "../../../ui/Title/Title";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { edit } from "../../../services/redux/features/userPart/userData/UserDataSlice";
import {
	emailPattern,
	errorServerLoginEmail,
	firstnamePattern,
	lastnamePattern,
	serverLoginErrors,
} from "../../../helpers/AuthConstants";
import { updateUserInfo } from "../../../services/api/auth/updateUserInfo";

import ModalHandelClose from "../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import ReturnBackArrow from "../../../components/PersonalAreaLayoutComponents/ReturnBackArrow/ReturnBackArrow";
import { Button } from "../../../ui/Button/Button";

import ModalAutoHidden from "../../../components/PersonalAreaLayoutComponents/ModalAutoHidden/ModalAutoHidden";
import { DELAY_IN_SECONDS } from "../../../helpers/delayConstants";
import { Path } from "../../../services/router/routes";

import style from "./EditProfile.module.css";

export const EditProfile = () => {
	const defaultFormValues = {
		firstname: "",
		lastname: "",
		email: "",
	};

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors, isValid },
		reset,
		setError,
	} = useForm<IUserData>({
		defaultValues: defaultFormValues,
		mode: "all",
		delayError: 1000,
	});

	const [isEditProfileError, setIsEditProfileError] = useState<boolean>(false);
	const userData = useAppSelector(userDataSelector).userData;
	const dispatch = useAppDispatch();
	const navigator = useNavigate();
	const { isOpen, toggleModal } = useModal();
	const { isOpen: isUpdateModalOpen, toggleModal: toggleUpdateModal, setDelayModal, delayModal } = useModal();

	useEffect(() => {
		if (!userData?.email) {
			navigator(Path.Login);
		}
		setDelayModal(delayModal + DELAY_IN_SECONDS);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setValue("firstname", userData?.firstname);
		setValue("lastname", userData?.lastname);
		setValue("email", userData?.email);
	}, [userData, setValue]);

	const timer = 4000;

	useEffect(() => {
		if (isUpdateModalOpen) {
			setTimeout(() => {
				toggleUpdateModal();
			}, timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUpdateModalOpen]);

	const getErrorEditProfileStatus = async (editProfileStatus: boolean) =>
		await setIsEditProfileError(editProfileStatus);

	const changeUserData = () => {
		const userData: UpdateUserInfo = {
			firstname: control._formValues.firstname || "",
			lastname: control._formValues.lastname || "",
			email: control._formValues.email || "",
		};

		updateUserInfo(userData)
			.then((response: Response) => {
				if (response.ok) {
					getErrorEditProfileStatus(false).then(() => {
						dispatch(edit(control._formValues));
						toggleUpdateModal();
					});
				} else {
					getErrorEditProfileStatus(true).then(() => {
						toggleUpdateModal();
					});
				}
			})
			.catch((error: ServerErrorResponse) => {
				const message = (error.response?.data as ServerError).message;
				getErrorEditProfileStatus(true).then(() => {
					toggleUpdateModal();
				});
				if (message === errorServerLoginEmail) {
					setError(
						"email",
						getDefaultServerErrorMessage(serverLoginErrors[errorServerLoginEmail]),
						shouldBeFocusedField,
					);
				}
			});
	};

	const getModalTitle = () => {
		return isEditProfileError ? "Что-то пошло не так :(" : "Отлично!";
	};

	const getModalDescription = () => {
		return isEditProfileError ? "Проверьте правильность введённых данных" : "Данные успешно сохранены";
	};

	const handlePrevState = () => {
		reset({ firstname: userData?.firstname, lastname: userData?.lastname, email: userData?.email });
		toggleModal();
	};

	return (
		<div className={style.edit__container}>
			<div className={style.titleContentWrap}>
				<ReturnBackArrow />
				<Title title="Редактировать профиль" styleName="titlePage" />
				<div></div>
			</div>
			<Form
				noValidate={true}
				styleName={"editForm"}
				onSubmit={handleSubmit(toggleModal)}
				buttonName="Сохранить"
				isValid={isValid}>
				<AuthInput
					label="Имя"
					id="firstname"
					name="firstname"
					control={control}
					rules={{ required: true, minLength: 2, maxLength: 25, pattern: firstnamePattern }}
					error={formHelpers.getNameError(errors)}
				/>
				<AuthInput
					label="Фамилия"
					name="lastname"
					control={control}
					rules={{ maxLength: 25, pattern: lastnamePattern }}
					error={formHelpers.getLastNameError(errors)}
				/>
				<AuthInput
					label="Email"
					name="email"
					control={control}
					rules={{ required: true, pattern: emailPattern }}
					error={formHelpers.getEmailError(errors)}
				/>
			</Form>
			<ModalHandelClose open={isOpen} toggle={toggleModal} title="Вы уверены, что хотите сохранить?">
				<div className={style.buttonsWrapper}>
					<Button
						onClick={() => {
							changeUserData();
							toggleModal();
						}}
						content="Сохранить"
						styleName="commonMaroonButton saveDataEditProfileModal"
					/>
					<Button
						content="Отмена"
						onClick={handlePrevState}
						styleName="commonWhiteMaroonButton cancelModalHandleClose"
					/>
				</div>
			</ModalHandelClose>
			<ModalAutoHidden
				title={getModalTitle()}
				message={getModalDescription()}
				open={isUpdateModalOpen}
				toggle={toggleUpdateModal}
			/>
		</div>
	);
};
