import { IDownloadImg } from "../../../../types/common/ComponentProps";

import styles from "./DownloadImgComponent.module.css";

const DownloadImgComponent = ({ name, onImageSelected, imageUrl, text }: IDownloadImg) => {
	return (
		<div className={styles.downloadImgWrapper} style={{ backgroundImage: `url(${imageUrl})` }}>
			<div>
				<label htmlFor="imageUpload" className={styles.downloadImgButton}>
					{text}
				</label>
				<input
					type="file"
					id="imageUpload"
					onChange={onImageSelected}
					className={styles.downloadImgButton}
					style={{ display: "none" }}
					name={name}
				/>
				<p className={styles.downloadImgText}>Минимальный размер — 808 x 632 пикселя.</p>
			</div>
		</div>
	);
};

export default DownloadImgComponent;
