import { IForm } from "../../types/common/UiKitProps";
import { MainButton } from "../../components/AuthLayoutComponents/MainButton/MainButton";
import { formClassesFromStyleName } from "../../utils/formClassesFromStyleName";

import style from "./Form.module.css";

export const Form = ({ children, buttonName, isValid, onSubmit, styleName, noValidate }: IForm) => {
	return (
		<form noValidate={noValidate} onSubmit={onSubmit} className={formClassesFromStyleName(styleName, style)}>
			{children}
			<MainButton disabled={!isValid} content={buttonName} type={"submit"} styleName={"button"} testId="" size="100%" />
		</form>
	);
};
