import { SignupData } from "../../../types/api/AuthApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { signupEndpoint } from "./apiConstants";

export async function signupUser(signupData: SignupData) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${signupEndpoint}`, {
		method: "POST",
		body: JSON.stringify(signupData),
		headers: {
			"Content-Type": "application/json",
		},
	});
}
