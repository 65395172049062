import { createSlice } from "@reduxjs/toolkit";

import { IHistorySolutionsState } from "../../../../../types/redux/storeTypes";

import { getSolutionHistory } from "./SolutionHistoryThunk";

const historySolutionInitialState: IHistorySolutionsState = {
	historySolutions: [],
	isLoading: false,
	error: null,
};

const SolutionHistorySlice = createSlice({
	name: "history/solutions",
	initialState: historySolutionInitialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(getSolutionHistory.pending, (state) => {
			state.error = null;
			state.isLoading = true;
		});
		builder.addCase(getSolutionHistory.rejected, (state) => {
			state.isLoading = false;
			state.error = null;
		});
		builder.addCase(getSolutionHistory.fulfilled, (state, action) => {
			state.error = null;
			state.isLoading = false;
			state.historySolutions = action.payload;
		});
	},
});

export default SolutionHistorySlice.reducer;
