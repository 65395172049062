import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import cn from "classnames";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useDebounce from "../../../../hooks/useDebounce";

import {
	IFilterRequestData,
	IGetSolutionListThunkData,
	ISolutionCatalogContent,
} from "../../../../types/redux/thunkTypes";
import { ISolutionFilterData, ISolutionFilterListData } from "../../../../types/components/SolutionCatalog";
import { SelectedFilterVariant } from "../../SelectedFilterVariant/SelectedFilterVariant";
import { SearchInput } from "../../../../ui/SearchInput/SearchInput";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { Button } from "../../../../ui/Button/Button";
import { SolutionCard } from "../SolutionCard/SolutionCard";
import { Filter } from "../../Filter/Filter";
import { Path } from "../../../../services/router/routes";
import { getSolutionFilterList } from "../../../../services/api/resources/getSolutionFilterList";
import { searchSolutions } from "../../../../services/api/resources/searchSolutions";
import { getSolutionListData } from "../../../../services/redux/features/userPart/solutionCatalog/SolutionCatalogThunk";
import { solutionCatalogSelector } from "../../../../services/redux/features/userPart/solutionCatalog/SolutionCatalogSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { setSolutionsData } from "../../../../services/redux/features/userPart/solutionCatalog/SolutionCatalogSlice";
import { searchDelay } from "../../../../helpers/delayConstants";
import { solutionListErrorContent } from "../../../../helpers/catalogHelpers";
import { Roles } from "../../../../helpers/roles";

import allFiltersArrow from "../../../../assets/components/PlatformCatalog/allFiltersArrow.svg";
import cross from "../../../../assets/pages/SolutionCatalog/cross.svg";

import style from "./CatalogSolutions.module.css";

function CatalogSolutions() {
	const [filterList, setFilterList] = useState<ISolutionFilterData[]>([]);
	const [filterGroups, setFilterGroups] = useState<IFilterRequestData[]>([]);
	const [selectedFilterGroupTitles, setSelectedFilterGroupTitles] = useState<string[]>([]);
	const [searchValue, setSearchValue] = useState("");
	const [isOpenTooltip, setIsOpenTooltip] = useState(true);
	const [isVisibleAllFilters, setIsVisibleAllFilters] = useState(false);
	const [isFiltersError, setIsFiltersError] = useState<boolean>(false);

	const debouncedSearch = useDebounce(handleSearchSolutions, searchDelay);
	const currentPage = Number(useParams().page);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { catalogSolutions, pageNumber, loading, error } = useAppSelector(solutionCatalogSelector);
	const userData = useAppSelector(userDataSelector).userData;

	const defaultMaxFilterListLength = 10;
	const solutionsPerPage = 6;
	const filterListErrorContent: string = "Ошибка загрузки списка фильтров!";

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
		window.localStorage.setItem("previousLoginPath", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		(async () => {
			if (filterGroups.length) {
				return null;
			}
			window.scrollTo({ top: 0 });
			getCorrectSolutionData(solutionsPerPage, currentPage ?? 1, []);
			await getSolutionFilterList().then(async (response: Response) => {
				if (response.ok) {
					const filters: Promise<ISolutionFilterListData> = await response.json();
					setFilterList((await filters).filters);
					setIsFiltersError(false);
				} else {
					setIsFiltersError(true);
				}
			});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, filterGroups, currentPage]);

	useEffect(() => {
		if (searchValue.length) {
			debouncedSearch();
		} else {
			setTimeout(() => {
				getCorrectSolutionData(solutionsPerPage, 1, filterGroups);
			}, searchDelay);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	useEffect(() => {
		const updatedFilterGroupTitles: string[] = [];

		filterGroups.forEach((filterGroup) => {
			updatedFilterGroupTitles.push(filterGroup.name);
		});

		setSelectedFilterGroupTitles(updatedFilterGroupTitles);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterGroups]);

	async function handleSearchSolutions() {
		if (!searchValue.length) {
			return null;
		}

		await searchSolutions({ value: searchValue }).then(async (res: Response) => {
			if (res.ok) {
				const searchedSolutionsData: Promise<ISolutionCatalogContent[]> = await res.json();
				dispatch(setSolutionsData(await searchedSolutionsData));
			}
		});
	}

	const getCorrectSolutionData = (limit: number, pageNumber: number, filterGroups: IFilterRequestData[]) => {
		const solutionData: IGetSolutionListThunkData = {
			limit: limit,
			pageNumber: pageNumber,
			filterData: {
				filterList: filterGroups,
			},
		};
		return dispatch(getSolutionListData(solutionData));
	};

	const addIncomingFilter = (filterGroupTitle: string, incomingFilterTitle: string) => {
		const incomingFilterGroup: IFilterRequestData = {
			name: filterGroupTitle,
			values: [incomingFilterTitle],
		};

		const filterData = filterList.find((currFilter) => currFilter.name === filterGroupTitle);

		if (!filterGroups.length) {
			return [incomingFilterGroup];
		}

		const isFilterGroupExists = filterGroups.find((filterGroup) => filterGroup.name === filterGroupTitle);

		if (isFilterGroupExists) {
			return filterGroups.map((filterGroupData) => {
				if (filterGroupData.name === filterGroupTitle) {
					if (filterData && !filterData.multipleChoice) {
						filterGroupData.values = incomingFilterGroup.values;
					} else {
						filterGroupData.values = [...filterGroupData.values, incomingFilterTitle];
					}
				}
				return filterGroupData;
			});
		} else {
			return [...filterGroups, incomingFilterGroup];
		}
	};

	const removeIncomingFilter = (filterGroupTitle: string, incomingFilterTitle: string) => {
		const updatedFilterGroups: IFilterRequestData[] = filterGroups.map((filterGroup) => {
			if (filterGroupTitle === filterGroup.name && filterGroup.values.includes(incomingFilterTitle)) {
				return {
					name: filterGroup.name,
					values: filterGroup.values.filter((filterValue) => filterValue !== incomingFilterTitle),
				};
			}
			return filterGroup;
		});
		return updatedFilterGroups.filter((filterGroup) => filterGroup.values.length);
	};

	const deleteFromCurrentFilter = (filterTitle: string, filterValue: string) => {
		setFilterGroups(removeIncomingFilter(filterTitle, filterValue));
	};

	const fillCurrentFilter = (checked: boolean, value: string, title: string) => {
		if (checked) {
			setFilterGroups(addIncomingFilter(title, value));
		} else {
			setFilterGroups(removeIncomingFilter(title, value));
		}
	};

	const clearAllSelectedFilters = () => {
		return setFilterGroups([]);
	};

	function getCorrectPaginationSearchLine(): string {
		return userData?.email ? Path.SolutionCatalogSignin : Path.SolutionCatalogUnSignin;
	}

	const renderFilterContent = () => {
		if (!filterList.length) {
			return (
				<div className={style.emptyListWrap}>
					<p className={style.emptyListMessage}>{isFiltersError ? filterListErrorContent : ""}</p>
				</div>
			);
		} else
			return (
				<>
					<ul>
						{filterList.map((item, index) => {
							if (!isVisibleAllFilters && index > defaultMaxFilterListLength - 1) {
								return null;
							}
							return (
								<Filter
									id={item.id}
									key={item.id}
									title={item.name}
									content={item.values}
									onChange={fillCurrentFilter}
									isSingleChoice={!item.multipleChoice}
									selectedFiltersData={filterGroups}
									selectedFilterGroupTitles={selectedFilterGroupTitles}
									tooltipText={item.description ?? ""}
								/>
							);
						})}
					</ul>
					<div className={style.btnGroup}>
						<Button
							content="Применить фильтры"
							styleName="commonWhiteMaroonButton platformCatalogFilters"
							onClick={() =>
								filterGroups.length !== 0 ? getCorrectSolutionData(solutionsPerPage, 1, filterGroups) : false
							}
						/>
						<button className={style.clearAllSelectedFiltersAction} onClick={clearAllSelectedFilters}>
							Сбросить фильтры
						</button>
						<button className={style.allFiltersActionWrap} onClick={() => setIsVisibleAllFilters(!isVisibleAllFilters)}>
							<span>Ещё фильтры</span>
							<img
								src={allFiltersArrow}
								alt="filter arrow"
								className={cn(style.allFiltersActionWrap__arrow, {
									[style.allFiltersActionWrap__arrow__show]: isVisibleAllFilters,
								})}
							/>
						</button>
					</div>
				</>
			);
	};

	const renderSolutionList = () => {
		return !catalogSolutions.length || loading ? (
			<p className={style.emptyListMessage}>{error ? solutionListErrorContent : ""}</p>
		) : (
			<ul className={style.solutionCatalogList}>
				{catalogSolutions.map((item) => (
					<SolutionCard
						key={item.id}
						id={item.id}
						title={item.name}
						shortDescription={item.shortDescription}
						logo={item.logo}
						role={Roles.USER}
						isFavorite={item.favorite}
					/>
				))}
			</ul>
		);
	};

	const renderSelectedFilterValues = () => {
		return (
			<ul className={style.selectedFiltersWrap}>
				{filterGroups.map((filterGroup) => {
					return filterGroup.values.map((filterGroupValue) => (
						<SelectedFilterVariant
							onClick={() => deleteFromCurrentFilter(filterGroup.name, filterGroupValue)}
							key={crypto.randomUUID()}
							value={filterGroupValue}
						/>
					));
				})}
			</ul>
		);
	};

	const renderSearchLineAndTooltip = () => {
		return (
			<>
				<SearchInput
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="Введите название решения"
				/>
				<div className={cn(style.tooltipWrap, { [style.tooltipWrap__opened]: isOpenTooltip })}>
					<img className={style.cross} src={cross} alt="close" onClick={() => setIsOpenTooltip(!isOpenTooltip)} />
					<p>
						Каталог решений содержит более 100 готовых решений для вашего бизнеса и подходит для самых разных задач. С
						помощью нашего каталога вы можете подобрать и приобрести успешно работающее решение. Просто выберите нужные
						вам критерии из списка слева и вы увидите решения подходящие именно вам
					</p>
				</div>
			</>
		);
	};

	return (
		<main className={style.solutionCatalogContainer}>
			<aside>{renderFilterContent()}</aside>
			<div className={style.solutionCatalogContent}>
				{renderSearchLineAndTooltip()}
				{renderSelectedFilterValues()}
				{renderSolutionList()}
				{catalogSolutions.length !== 0 && !loading && (
					<PaginationElement
						currPage={currentPage ?? 1}
						pageCount={pageNumber}
						searchLine={getCorrectPaginationSearchLine()}
					/>
				)}
			</div>
		</main>
	);
}

export default CatalogSolutions;
