export const VkIcon = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_890_1118)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M31.4414 7.86923C31.664 7.1346 31.4414 6.59473 30.3829 6.59473H26.8821C25.9918 6.59473
          25.5815 7.061 25.3593 7.57552C25.3593 7.57552 23.5787 11.8734 21.0567 14.6654C20.241
          15.4737 19.8702 15.7311 19.4247 15.7311C19.2025 15.7311 18.8803 15.4737 18.8803
          14.7394V7.86923C18.8803 6.98775 18.6218 6.59473 17.8801 6.59473H12.3783C11.8222 6.59473
          11.4872 7.00395 11.4872 7.39204C11.4872 8.22774 12.7484 8.42073 12.8785
          10.7718V15.8779C12.8785 16.9975 12.6744 17.2011 12.2293 17.2011C11.0428 17.2011
          8.15566 12.8834 6.4433 7.94319C6.10765 6.98282 5.77129 6.59508 4.87671
          6.59508H1.3759C0.37536 6.59473 0.175537 7.061 0.175537 7.57517C0.175537 8.49363 1.36203
          13.049 5.7016 19.0735C8.59441 23.1876 12.6702 25.4183 16.3793 25.4183C18.6044 25.4183
          18.88 24.9228 18.88 24.0694V20.9601C18.88 19.9691 19.0908 19.7715 19.7955 19.7715C20.3146
          19.7715 21.2046 20.0283 23.2818 22.0121C25.6555 24.3632 26.0466 25.4179 27.382
          25.4179H30.8829C31.8827 25.4179 32.383 24.9224 32.0946 23.9451C31.7785 22.9707 30.6453
          21.5571 29.1417 19.8818C28.3257 18.9267 27.1015 17.898 26.7307 17.3835C26.2112 16.7225
          26.3598 16.4287 26.7307 15.8406C26.7307 15.8406 30.9966 9.88964 31.4414 7.86923Z"
					fill="white"
					transform="scale(0.7) translate(12, 12)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_890_1118">
					<rect width="40" height="40" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
