import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { ChangePasswordData } from "../../../types/api/AuthApiTypes";

import { changePasswordEndpoint } from "./apiConstants";

export async function changeUserPassword(passwordData: ChangePasswordData) {
	const baseURL = getCorrectBaseUrl("as");

	return fetch(`${baseURL}/${changePasswordEndpoint}`, {
		method: "PUT",
		body: JSON.stringify(passwordData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
