import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IUserDataInitialState } from "../../../../../types/redux/storeTypes";
import { IUserData } from "../../../../../types/redux/thunkTypes";

import { getUserData } from "./UserDataThunk";

const initialStateUserData: IUserDataInitialState = {
	userData: {
		id: NaN,
		firstname: "",
		lastname: "",
		roles: [],
		statuses: [],
		email: "",
		avatar: [],
	},
	error: null,
};

const UserDataSlice = createSlice({
	name: "userData",
	initialState: initialStateUserData,
	reducers: {
		edit: (state: IUserDataInitialState, action: PayloadAction<Partial<IUserData>>) => {
			state.userData.firstname = action.payload.firstname || state.userData.firstname;
			state.userData.lastname = action.payload.lastname || state.userData.lastname;
			state.userData.email = action.payload.email || state.userData.email;
		},
		userLogout: (state: IUserDataInitialState) => {
			state.userData = initialStateUserData.userData;
		},
	},
	extraReducers(builder) {
		builder.addCase(getUserData.fulfilled, (state: IUserDataInitialState, action: PayloadAction<IUserData>) => {
			state.userData = action.payload;
		});
		builder.addCase(getUserData.rejected, (state: IUserDataInitialState, action: PayloadAction<string | unknown>) => {
			state.error = action.payload;
		});
	},
});

export const { userLogout, edit } = UserDataSlice.actions;
export default UserDataSlice.reducer;
