import DescriptionPlatform from "../../../components/MainLayoutComponents/ResourceComponents/DescriptionPlatform/DescriptionPlatform";
import { DescriptionPlatformTypes } from "../../../helpers/DescriptionPlatform";

import styles from "./CurrentPlatformUnSignIn.module.css";

function CurrentPlatformUnSignIn() {
	return (
		<div className={styles.fullPlatformDescriptionWrap}>
			<DescriptionPlatform pageType={DescriptionPlatformTypes.Common} isDisabledSolutionCard={false} />
		</div>
	);
}

export default CurrentPlatformUnSignIn;
