import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import cn from "classnames/dedupe";

import { Header } from "../../components/MainLayoutComponents/Header/Header";
import { Footer } from "../../components/MainLayoutComponents/Footer/Footer";
import { Path } from "../../services/router/routes";

import widgetBotLogo from "../../assets/layouts/Main/widgetBotTabledMessage.svg";

import styles from "./MainLayout.module.css";

export const MainLayout = () => {
	const [isCorrectPage, setIsCorrectPage] = useState<boolean>();
	const location = useLocation();

	useEffect(() => {
		setIsCorrectPage(location.pathname.includes(Path.CurrentPlatformUnSignin && Path.CurrentSolutionUnSignin));
	}, [location.pathname]);

	return (
		<div className={styles.layout}>
			<Header />
			<div className={cn(styles.outletWrap, { [styles.outletWrap__gray]: isCorrectPage })}>
				<Outlet />
				<button className={styles.widgetBotWrap}>
					<img src={widgetBotLogo} alt="widgetBot" />
				</button>
			</div>
			<Footer />
		</div>
	);
};
