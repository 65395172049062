import { AdminPath } from "../services/router/routes";

import dashboard from "../assets/layouts/PersonalArea/NavIcon/dashboard.svg";
import dashboardColored from "../assets/layouts/AdminLayout/NavIcon/DashboardColored.svg";
import lamp from "../assets/layouts/PersonalArea/NavIcon/lamp.svg";
import lampColored from "../assets/layouts/AdminLayout/NavIcon/SolutionsColored.svg";
import carbon from "../assets/layouts/PersonalArea/NavIcon/carbon.svg";
import carbonColored from "../assets/layouts/AdminLayout/NavIcon/platformsColored.svg";
import filters from "../assets/layouts/AdminLayout/NavIcon/FiltersAdmin.svg";
import filtersColored from "../assets/layouts/AdminLayout/NavIcon/FiltersColored.svg";
import staff from "../assets/components/NavbarAdmin/staff.svg";
import staffColored from "../assets/components/NavbarAdmin/staffColored.svg";
import createNew from "../assets/components/NavbarAdmin/plusIcon.svg";

import { AdminCatalogRoutes } from "./catalogHelpers";

export const adminNavIconArr = [
	{ icon: dashboard, coloredIcon: dashboardColored, title: "Дашборд", id: 0, src: AdminPath.Drafts },
	{
		icon: lamp,
		coloredIcon: lampColored,
		title: "Решения",
		id: 1,
		src: `${AdminPath.Solutions}/${AdminCatalogRoutes.Published}/1`,
	},
	{
		icon: carbon,
		coloredIcon: carbonColored,
		title: "Платформы",
		id: 2,
		src: `${AdminPath.Platforms}/${AdminCatalogRoutes.Published}/1`,
	},
	{ icon: filters, coloredIcon: filtersColored, title: "Фильтры", id: 3, src: AdminPath.Filters },
	{ icon: staff, coloredIcon: staffColored, title: "Персонал", id: 4, src: AdminPath.Staff },
	{ icon: createNew, title: "Создать заготовку", id: 5, src: AdminPath.Creation },
];
