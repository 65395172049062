import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import { IPrice } from "../../../types/common/ComponentProps";
import { Button } from "../../../ui/Button/Button";
import { Path } from "../../../services/router/routes";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";

import style from "./Price.module.css";

export const Price = ({ price, priceName, description }: IPrice) => {
	const userData = useAppSelector(userDataSelector).userData;
	const navigate = useNavigate();

	const handleNavigateContacts = () => {
		navigate(userData?.id ? Path.PersonalAreaContacts : Path.Contacts);
	};

	return (
		<div className={style.price__frame}>
			<h3 className={style.price__name}>{priceName}</h3>
			<div className={style.price}>{price}&#8381;</div>
			<p className={style.price__period}>в месяц</p>
			<ul className={style.description__container}>
				{description.map((item) => {
					return (
						<li key={crypto.randomUUID()} className={style.price__description}>
							{item}
						</li>
					);
				})}
			</ul>
			<Button
				content="Купить"
				styleName="commonWhiteMaroonButton priceServiceAction"
				onClick={handleNavigateContacts}
			/>
		</div>
	);
};
