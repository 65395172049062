import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../../hooks/useAppSelector";
import useAppDispatch from "../../../../hooks/useAppDispatch";

import { IDescriptionPlatform } from "../../../../types/common/ComponentProps";
import { ICurrentPlatformData, IUnsavedCurrentPlatformData } from "../../../../types/components/DescriptionPlatform";
import { IPlatformHistoryData } from "../../../../types/api/ResourcesApiTypes";
import { HandleImageErrorType } from "../../../../types/components/PlatformCatalog";
import { Button } from "../../../../ui/Button/Button";
import ReturnBackArrow from "../../../../ui/ReturnBackArrow/ReturnBackArrow";
import SampleSlider from "../../SampleSlider/SampleSlider";
import ImageViewElement from "../../../AdminLayoutComponents/ImageViewElement/ImageViewElement";
import { getCurrentPlatformData } from "../../../../services/api/resources/getCurrentPlatformData";
import { setPlatformFavorite } from "../../../../services/api/resources/setPlatformFavorite";
import { setPlatformHistory } from "../../../../services/api/resources/setPlatformHistory";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { setActivePlatform } from "../../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesSlice";
import ResourceDataEditSelector from "../../../../services/redux/features/adminPart/resourceForEditPages/ResourceDataEditSelector";
import { ReturnBackArrowTypes } from "../../../../helpers/ReturnBack";
import { mockChatBotSiteLink, mockPlatformLogo } from "../../../../mocks/linksArr";
import { getCorrectResourceLogo } from "../../../../utils/getCorrectResourceLogo";
import { DescriptionPlatformTypes } from "../../../../helpers/DescriptionPlatform";
import { Path } from "../../../../services/router/routes";

import styles from "./DescriptionPlatform.module.css";

function DescriptionPlatform({ pageType, isDisabledSolutionCard }: IDescriptionPlatform) {
	const [platformData, setPlatformData] = useState<ICurrentPlatformData | IUnsavedCurrentPlatformData>();
	const [isFavorite, setIsFavorite] = useState<boolean>(false);
	const [imageError, setImageError] = useState<boolean>(false);

	const isLogin = useAppSelector(userDataSelector).userData?.email;
	const unsavedPlatformData = useAppSelector(ResourceDataSelector).currentPlatformData;
	const unsavedEditPlatformData = useAppSelector(ResourceDataEditSelector).currentEditPlatformData;
	const params = useParams();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigator = useNavigate();

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		(async () => {
			if (pageType === DescriptionPlatformTypes.UnsavedData) {
				if (location.search.includes("from=platformCreation")) {
					return setPlatformData(unsavedPlatformData);
				} else {
					return setPlatformData(unsavedEditPlatformData);
				}
			} else {
				const response: Response = await getCurrentPlatformData(Number(params.platformId));
				const data: ICurrentPlatformData = await response.json();
				setPlatformData(data as ICurrentPlatformData);
				return setIsFavorite(isLogin ? data.favorite : Boolean(isLogin));
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigator, params.platformId, location.pathname]);

	useEffect(() => {
		(async () => {
			if (!platformData?.name) {
				return null;
			}
			const isCorrectLocation: boolean = location.pathname.includes(Path.CurrentPlatformSignin);
			const platformHistoryData: IPlatformHistoryData = {
				name: platformData?.name ?? "",
			};
			if (isLogin && isCorrectLocation) {
				return await setPlatformHistory(platformHistoryData);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [platformData?.name]);

	const handleImageError: HandleImageErrorType = () => {
		return () => setImageError(true);
	};

	const handleFavoriteAction = async () => {
		if (pageType === DescriptionPlatformTypes.AdminPanel || pageType === DescriptionPlatformTypes.UnsavedData) {
			return null;
		}
		if (!isLogin) {
			navigator(Path.Login);
		}
		await setPlatformFavorite(platformData?.id ?? 0, isFavorite);
		return setIsFavorite(!isFavorite);
	};

	const handleMakeProposal = () => {
		if (pageType === DescriptionPlatformTypes.AdminPanel || pageType === DescriptionPlatformTypes.UnsavedData) {
			return null;
		}
		if (isLogin && platformData) dispatch(setActivePlatform(platformData?.name));
		return navigator(isLogin ? Path.PersonalAreaContacts : Path.Contacts);
	};

	const getCorrectPlatformImage = () => {
		if (pageType === DescriptionPlatformTypes.UnsavedData) {
			return unsavedPlatformData.logoUrl ? (
				<ImageViewElement imageTextLine={unsavedPlatformData.logoUrl} />
			) : (
				<img
					src={getCorrectResourceLogo(platformData?.logoUrl, mockPlatformLogo, imageError)}
					alt="platform logo"
					className={styles.platformLogoWrap__picture}
					onError={handleImageError()}
				/>
			);
		}

		return (
			<img
				src={getCorrectResourceLogo(platformData?.logoUrl, mockPlatformLogo, imageError)}
				alt="platform logo"
				className={styles.platformLogoWrap__picture}
				onError={handleImageError()}
			/>
		);
	};

	const getCorrectPlatformCost = () => {
		return Math.ceil(platformData?.cost ?? 0);
	};

	return (
		<main className={styles.currentPlatformWrap}>
			<div className={styles.currentPlatformContainer}>
				<ReturnBackArrow type={ReturnBackArrowTypes.FullResourceDescriptionType} />
				<div className={styles.platformContent}>
					<div className={styles.shortDescriptionWrap}>
						<div className={styles.platformLogoWrap}>{getCorrectPlatformImage()}</div>
						<div className={styles.platformTextDataWrap}>
							<h3 className={styles.platformTextDataWrap__title}>{platformData?.name}</h3>
							<p className={styles.platformTextDataWrap__subtitle}>{platformData?.shortDescription}</p>
							<p className={styles.platformTextDataWrap__subtitle}>
								Количество решений, входящих в платформу: {platformData?.totalSolution}
							</p>
							<p className={styles.platformTextDataWrap__titleCost}>От {getCorrectPlatformCost()} ₽</p>
							<div className={styles.platformTextDataWrap__toolkitWrap}>
								<Button
									content={!isFavorite ? "В избранное" : "Убрать из избранного"}
									styleName="commonWhiteMaroonButton fullPlatformDescriptionSetFavorite"
									onClick={handleFavoriteAction}
								/>
								<Button
									content="Оставить заявку"
									styleName="commonMaroonButton fullPlatformDescriptionContactsLink"
									onClick={handleMakeProposal}
								/>
							</div>
						</div>
					</div>
					<div className={styles.fullDescriptionWrap}>
						<h3 className={styles.fullDescriptionWrap__title}>О платформе</h3>
						<p className={styles.fullDescriptionWrap__subtitle}>{platformData?.description}</p>
						<a
							href={platformData?.website ?? mockChatBotSiteLink}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.platformLink}>
							<Button
								content="Перейти на сайт платформы"
								styleName="commonWhiteMaroonButton fullPlatformDescriptionLinkToWebsite"
							/>
						</a>
					</div>
				</div>
				<div className={styles.linkedSolutionsWrap}>
					<p className={styles.linkedSolutionsWrap__title}>Решения, разработанные на этой платформе:</p>
					{!platformData?.solutionList || !platformData?.solutionList.length ? (
						<p className={styles.linkedSolutionsWrap__subtitle}>
							Список решений, разработанных на этой платформе, пуст!
						</p>
					) : (
						<SampleSlider
							cardList={platformData?.solutionList ?? []}
							interval={34}
							solutionsPerSlide={3}
							role="role"
							isDisabledCard={isDisabledSolutionCard}
						/>
					)}
				</div>
			</div>
		</main>
	);
}

export default DescriptionPlatform;
