export const ArrowBackIcon = () => {
	return (
		<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.1666 0.833336C14.4721 1.13889 14.6321 1.52778 14.6466 2C14.6599 2.47222
               14.5138 2.86111 14.2083 3.16667L6.04159 11.3333L24.6666 11.3333C25.1388 11.3333
               25.5349 11.4933 25.8549 11.8133C26.1738 12.1322 26.3333 12.5278 26.3333 13C26.3333
               13.4722 26.1738 13.8683 25.8549 14.1883C25.5349 14.5072 25.1388 14.6667 24.6666
               14.6667L6.04159 14.6667L14.2083 22.8333C14.5138 23.1389 14.6599 23.5278 14.6466
               24C14.6321 24.4722 14.4721 24.8611 14.1666 25.1667C13.861 25.4722 13.4721 25.625
               12.9999 25.625C12.5277 25.625 12.1388 25.4722 11.8333 25.1667L0.833254
               14.1667C0.666588 14.0278 0.548252 13.8544 0.478252 13.6467C0.409363
               13.4378 0.374918 13.2222 0.374918 13C0.374918 12.7778 0.409363 12.5694
               0.478252 12.375C0.548252 12.1806 0.666588 12 0.833254 11.8333L11.8333
               0.833336C12.1388 0.527781 12.5277 0.375 12.9999 0.375C13.4721 0.375
               13.861 0.527781 14.1666 0.833336Z"
				fill="#B3B3B3"
			/>
		</svg>
	);
};
