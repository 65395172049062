import { CrudMethods, EmailDataType, IActionStaffModal, IEmployeeProfileData } from "../../../types/api/AdminApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import {
	staffLockProfileEndpoint,
	staffPageEndpoint,
	staffUnLockProfileEndpoint,
	staffChangeUserRoleEndpoint,
} from "./apiAdminConstants";

async function actionStaffModal({
	endpoint,
	method,
	emailData,
	employeeProfileData,
}: IActionStaffModal): Promise<Response> {
	const baseURL = getCorrectBaseUrl("as");
	if (method === CrudMethods.get) {
		return await fetch(`${baseURL}/${endpoint}?offset=0&limit=20`, {
			method: method,
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
	}
	return await fetch(`${baseURL}/${endpoint}`, {
		method: method,
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeProfileData ? employeeProfileData : emailData),
		credentials: "include",
	});
}

export async function getStaffList() {
	return await actionStaffModal({
		endpoint: staffPageEndpoint,
		method: CrudMethods.get,
	} as IActionStaffModal);
}

export async function removeAdminProfile(emailData: EmailDataType) {
	return await actionStaffModal({
		endpoint: staffPageEndpoint,
		method: CrudMethods.delete,
		emailData: emailData,
	} as IActionStaffModal);
}

export async function blockProfile(emailData: EmailDataType) {
	return await actionStaffModal({
		endpoint: staffLockProfileEndpoint,
		method: CrudMethods.put,
		emailData: emailData,
	} as IActionStaffModal);
}

export async function unBlockProfile(emailData: EmailDataType) {
	return await actionStaffModal({
		endpoint: staffUnLockProfileEndpoint,
		method: CrudMethods.put,
		emailData: emailData,
	} as IActionStaffModal);
}

export async function changeUserRoleAction(emailData: EmailDataType) {
	return await actionStaffModal({
		endpoint: staffChangeUserRoleEndpoint,
		method: CrudMethods.put,
		emailData: emailData,
	} as IActionStaffModal);
}

export async function createNewEmployeeProfile(employeeProfileData: IEmployeeProfileData) {
	return await actionStaffModal({
		endpoint: staffPageEndpoint,
		method: CrudMethods.post,
		employeeProfileData: employeeProfileData,
	} as IActionStaffModal);
}
