import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { SendProposal } from "../../../types/api/AuthApiTypes";

import { sendProposalEndpoint } from "./apiConstants";

export async function sendProposalData(proposalData: SendProposal) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${sendProposalEndpoint}`, {
		method: "POST",
		body: JSON.stringify(proposalData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
