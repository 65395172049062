import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { Service } from "../../pages/MainLayoutPages/Service/Service";
import SolutionCatalogUnSignin from "../../pages/MainLayoutPages/CatalogPages/SolutionCatalogUnSignin/SolutionCatalogUnSignin";
import PlatformCatalogUnSignin from "../../pages/MainLayoutPages/CatalogPages/PlatformCatalogUnSignin/PlatformCatalogUnSignin";
import { Rates } from "../../pages/MainLayoutPages/Rates/Rates";
import { Contacts } from "../../pages/MainLayoutPages/Contacts/Contacts";
import { Blog } from "../../pages/MainLayoutPages/Blog/Blog";
import { NotFoundPage } from "../../pages/MainLayoutPages/NotFoundPage/NotFoundPage";
import { AuthLayout } from "../../layouts/AuthLayout/AuthLayout";
import { LoginPage } from "../../pages/AuthLayoutPages/LoginPage/LoginPage";
import { AcceptedPage } from "../../pages/MainLayoutPages/AcceptedPage/AcceptedPage";
import NewPassword from "../../pages/AuthLayoutPages/NewPassword/NewPassword";
import CurrentPlatformUnSignIn from "../../pages/MainLayoutPages/CurrentPlatformUnSignIn/CurrentPlatformUnSignIn";
import { CurrentSolutionUnSignIn } from "../../pages/MainLayoutPages/CurrentSolutionUnsignIn/CurrentSolutionUnsignIn";

import { SignupPage } from "../../pages/AuthLayoutPages/SignupPage/SignupPage";

import { LayoutPersonalArea } from "../../layouts/LayoutPersonalArea/LayoutPersonalArea";
import PersonalArea from "../../pages/PersonalAreaLayoutPages/PersonalArea/PersonalArea";
import PersonalAreaFavorites from "../../pages/PersonalAreaLayoutPages/PersonalAreaFavorites/PersonalAreaFavorites";
import PersonalAreaFAQ from "../../pages/PersonalAreaLayoutPages/PersonalAreaFAQ/PersonalAreaFAQ";
import PlatformCatalogSignin from "../../pages/PersonalAreaLayoutPages/CatalogPages/PlatformCatalogSignin/PlatformCatalogSignin";
import SolutionCatalogSignin from "../../pages/PersonalAreaLayoutPages/CatalogPages/SolutionCatalogSignin/SolutionCatalogSignin";
import ResetPassword from "../../pages/AuthLayoutPages/ResetPassword/ResetPassword";
import ChangePassword from "../../pages/PersonalAreaLayoutPages/ChangePassword/ChangePassword";
import { EditProfile } from "../../pages/PersonalAreaLayoutPages/EditProfile/EditProfile";
import CurrentPlatformSignIn from "../../pages/PersonalAreaLayoutPages/CurrentPlatformSignIn/CurrentPlatformSignIn";
import { CurrentSolutionSignIn } from "../../pages/PersonalAreaLayoutPages/CurrentSolutionSignIn/CurrentSolutionSignIn";

import { AdminLayout } from "../../layouts/AdminLayout/AdminLayout";
import Drafts from "../../pages/AdminLayoutPages1/Drafts/Drafts";
import Staff from "../../pages/AdminLayoutPages1/Staff/Staff";
import Filters from "../../pages/AdminLayoutPages1/Filters/Filters";
import Creation from "../../pages/AdminLayoutPages1/Create/Creation/Creation";
import PlatformsAdmin from "../../pages/AdminLayoutPages1/Platforms/PlatformsAdmin";
import EditSolution from "../../pages/AdminLayoutPages1/EditSolution/EditSolution";

import { SolutionAdmin } from "../../pages/AdminLayoutPages1/Solutions/SolutionsAdmin";
import PlatformPreview from "../../pages/AdminLayoutPages1/PlatformPreview/PlatformPreview";
import { SolutionPreview } from "../../pages/AdminLayoutPages1/SolutionPreview/SolutionPreview";
import EditPlatform from "../../pages/AdminLayoutPages1/EditPlatform/EditPlatform";

import {
	catalogPageRoutePart,
	platformIdRoutePart,
	resourcePageRouteParts,
	solutionIdRoutePart,
} from "../../helpers/dynamicRoutes";

import { AdminPath, Path } from "./routes";

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route element={<AuthLayout />}>
				<Route path={Path.Login} element={<LoginPage />} />
				<Route path={Path.Signup} element={<SignupPage />} />
				<Route path={Path.ResetPassword} element={<ResetPassword />} />
				<Route path={Path.NewPassword} element={<NewPassword />} />
			</Route>
			<Route element={<LayoutPersonalArea />}>
				<Route path={Path.PersonalArea} element={<PersonalArea />} />
				<Route path={Path.PersonalAreaFavorites + resourcePageRouteParts} element={<PersonalAreaFavorites />} />
				<Route path={Path.PersonalAreaFAQ} element={<PersonalAreaFAQ />} />
				<Route path={Path.PlatformCatalogSignin + catalogPageRoutePart} element={<PlatformCatalogSignin />} />
				<Route path={Path.SolutionCatalogSignin + catalogPageRoutePart} element={<SolutionCatalogSignin />} />
				<Route path={Path.CurrentPlatformSignin + platformIdRoutePart} element={<CurrentPlatformSignIn />} />
				<Route path={Path.CurrentSolutionSignin + solutionIdRoutePart} element={<CurrentSolutionSignIn />} />
				<Route path={Path.PersonalAreaContacts} element={<Contacts />} />
				<Route path={Path.PersonalAreaAcceptedPage} element={<AcceptedPage />} />
				<Route path={Path.EditProfile} element={<EditProfile />} />
				<Route path={Path.ChangeUserPassword} element={<ChangePassword />} />
			</Route>
			<Route path={Path.Main} element={<MainLayout />}>
				<Route index element={<Service />} />
				<Route path={Path.PlatformCatalogUnSignin + catalogPageRoutePart} element={<PlatformCatalogUnSignin />} />
				<Route path={Path.SolutionCatalogUnSignin + catalogPageRoutePart} element={<SolutionCatalogUnSignin />} />
				<Route path={Path.CurrentPlatformUnSignin + platformIdRoutePart} element={<CurrentPlatformUnSignIn />} />
				<Route path={Path.CurrentSolutionUnSignin + solutionIdRoutePart} element={<CurrentSolutionUnSignIn />} />
				<Route path={Path.Rates} element={<Rates />} />
				<Route path={Path.Contacts} element={<Contacts />} />
				<Route path={Path.Blog} element={<Blog />} />
				<Route path={Path.AcceptedPage} element={<AcceptedPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Route>
			<Route element={<AdminLayout />}>
				<Route path={AdminPath.Drafts} element={<Drafts />} />
				<Route path={AdminPath.Staff} element={<Staff />} />
				<Route path={AdminPath.Filters} element={<Filters />} />
				<Route path={AdminPath.Creation} element={<Creation />} />
				<Route path={AdminPath.SolutionsEdit + "/:id"} element={<EditSolution />} />
				<Route path={AdminPath.PlatformEdit + "/:id"} element={<EditPlatform />} />
				<Route path={AdminPath.Platforms + "/:pagepart/:page"} element={<PlatformsAdmin />} />
				<Route path={AdminPath.Solutions + "/:pagepart/:page"} element={<SolutionAdmin />} />
				<Route path={AdminPath.PlatformPreview + platformIdRoutePart} element={<PlatformPreview />} />
				<Route path={AdminPath.SolutionPreview + solutionIdRoutePart} element={<SolutionPreview />} />
			</Route>
		</>,
	),
);

export default router;
