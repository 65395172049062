import { PostCard } from "../PostCard/PostCard";

import { posts } from "../../../mocks/postList";

import style from "./PostList.module.css";

export const PostList = () => {
	const menuBarArr = [
		"Онлайн-образование",
		"Обучающий материал",
		"Истории наших клиентов",
		"B2B/B2C - товары и производство",
	];

	return (
		<>
			<div className={style.menu}>
				{menuBarArr.map((item, index) => {
					return (
						<div key={index} className={style.menu__item}>
							{item}
						</div>
					);
				})}
			</div>
			<div className={style.posts_container}>
				{posts.map((post, index) => {
					return (
						<div key={index}>
							<PostCard id={post.id} text={post.text} title={post.title} />
						</div>
					);
				})}
			</div>
		</>
	);
};
