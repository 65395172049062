import { BaseSyntheticEvent, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import useModal from "../../../hooks/useModal";

import { ChangePasswordData, ServerError } from "../../../types/api/AuthApiTypes";
import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { Title } from "../../../ui/Title/Title";
import { Form } from "../../../ui/Form/Form";
import ReturnBackArrow from "../../../components/PersonalAreaLayoutComponents/ReturnBackArrow/ReturnBackArrow";
import { AuthInput } from "../../../components/AuthLayoutComponents/AuthInput/AuthInput";
import ModalAutoHidden from "../../../components/PersonalAreaLayoutComponents/ModalAutoHidden/ModalAutoHidden";
import { changeUserPassword } from "../../../services/api/auth/changeUserPassword";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";
import { Path } from "../../../services/router/routes";
import { DELAY_IN_SECONDS } from "../../../helpers/delayConstants";
import {
	errorServerChangeOldPassword,
	errorServerLoginPassword,
	passwordPattern,
	serverLoginErrors,
} from "../../../helpers/AuthConstants";

import styles from "./ChangePassword.module.css";

function ChangePassword() {
	const {
		handleSubmit,
		control,
		setError,
		formState: { isValid, errors },
	} = useForm<ChangePasswordData>({
		defaultValues: {
			oldPassword: "",
			newPassword: "",
		},
		mode: "all",
		delayError: 200,
	});

	const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
	const isLogin = useAppSelector(userDataSelector).userData?.email;
	const { isOpen, toggleModal, delayModal, setDelayModal } = useModal();
	const navigator = useNavigate();

	useEffect(() => {
		if (!isLogin) {
			navigator(Path.Login);
		}
		setDelayModal(delayModal + DELAY_IN_SECONDS);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMessageType = (message: string) =>
		message === errorServerChangeOldPassword ? "oldPassword" : "newPassword";

	const getErrorPasswordStatus = async (passwordStatus: boolean) => await setIsPasswordError(passwordStatus);

	const getModalTitle = () => {
		return isPasswordError ? "Что-то пошло не так :(" : "Отлично!";
	};

	const getModalDescription = () => {
		return isPasswordError ? "Проверьте правильность введённых данных" : "Ваш пароль успешно изменен";
	};

	const onSubmit: SubmitHandler<ChangePasswordData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		changeUserPassword(data)
			.then((response: Response) => {
				if (response.ok) {
					getErrorPasswordStatus(false).then(() => {
						toggleModal();
					});
				} else {
					getErrorPasswordStatus(true).then(() => {
						toggleModal();
						toggleModal();
					});
				}
			})
			.catch((error: ServerErrorResponse) => {
				const message = (error.response?.data as ServerError).message as keyof typeof serverLoginErrors;

				getErrorPasswordStatus(true).then(() => {
					toggleModal();
					toggleModal();
				});

				message &&
					setError(
						getMessageType(message),
						getDefaultServerErrorMessage(serverLoginErrors[errorServerLoginPassword]),
						shouldBeFocusedField,
					);
			});
	};

	return (
		<>
			<ModalAutoHidden open={isOpen} toggle={toggleModal} title={getModalTitle()} message={getModalDescription()} />

			<div className={styles.changePasswordWrap}>
				<div className={styles.passwordContainer}>
					<ReturnBackArrow />
					<div className={styles.setNewPasswordContainer}>
						<Title title={"Изменить пароль"} size={"60px"} styleName="titlePage" />
						<Form
							noValidate={true}
							styleName={"changePasswordForm"}
							buttonName="Изменить пароль"
							isValid={isValid}
							onSubmit={handleSubmit(onSubmit)}>
							<AuthInput
								control={control}
								id={"old-password"}
								name={"oldPassword"}
								label={"Старый пароль"}
								type={"password"}
								autoComplete={"current-password"}
								rules={{
									required: true,
									pattern: passwordPattern,
								}}
								error={formHelpers.getOldPasswordError(errors, control._formValues.oldPassword)}
							/>

							<AuthInput
								control={control}
								id={"new-password"}
								name={"newPassword"}
								label={"Новый пароль"}
								type={"password"}
								autoComplete={"new-password"}
								rules={{
									required: true,
									pattern: passwordPattern,
								}}
								error={formHelpers.getNewPasswordError(errors, control._formValues.newPassword)}
							/>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default ChangePassword;
