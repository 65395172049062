import { useEffect, useState } from "react";
import className from "classnames";

import { IFilter } from "../../../types/common/ComponentProps";
import { IFilterTooltipOffset, IPlatformFilterValueList } from "../../../types/components/PlatformCatalog";

import filterArrow from "../../../assets/components/Filter/filterArrow.svg";

import style from "./Filter.module.css";

export const Filter = ({
	id,
	title,
	content,
	onChange,
	isSingleChoice,
	selectedFiltersData,
	selectedFilterGroupTitles,
	tooltipText,
}: IFilter) => {
	const [activeFilterId, setActiveFilterId] = useState<number | null>(null);
	const [checkedValues, setCheckedValues] = useState<string[]>([]);
	const [isFilterHovered, setIsFilterHovered] = useState<boolean>(false);
	const [tooltipOffset, setTooltipOffset] = useState<IFilterTooltipOffset>();

	const topLineFilerHeaderGap: number = 16;
	const topLineFilerHeaderHorizontalOffset: number = 10;

	useEffect(() => {
		if (selectedFilterGroupTitles?.includes(title)) {
			selectedFiltersData?.forEach((selectedFilterData) => {
				if (selectedFilterData.name === title) setCheckedValues(selectedFilterData.values);
				return selectedFilterData;
			});
		} else if (checkedValues.length) setCheckedValues([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFilterGroupTitles]);

	useEffect(() => {
		if (tooltipText) {
			const updatedTooltipOffset: IFilterTooltipOffset = {
				verticalOffset:
					Number(document.getElementById(`tooltipBlock${id}`)?.offsetHeight ?? 0) / 2 -
					topLineFilerHeaderHorizontalOffset,
				horizontalOffset: (document.getElementById(`filterHeaderTitle${id}`)?.offsetWidth ?? 0) + topLineFilerHeaderGap,
			};
			setTooltipOffset(updatedTooltipOffset);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const toggle = (id: number) => {
		setActiveFilterId(activeFilterId === id ? null : id);
	};

	const getCorrectFilterValue = (filter: string | IPlatformFilterValueList) => {
		return typeof filter === "string" ? filter : filter.value;
	};

	const getCorrectFilterValueId = (filter: string | IPlatformFilterValueList) => {
		return `${title}${(filter as IPlatformFilterValueList).value}`;
	};

	const setIncomingFilterValue = ({ value }: IPlatformFilterValueList) => {
		if (isSingleChoice) {
			return setCheckedValues([value]);
		}
		if (checkedValues.includes(value)) {
			const updatedCheckedValues = checkedValues.filter((checkedValue) => checkedValue !== value);
			return setCheckedValues(updatedCheckedValues);
		}
		return setCheckedValues([...checkedValues, value]);
	};

	function handleMouseEnterOnFilter() {
		if (!tooltipText) {
			return null;
		}

		return setIsFilterHovered(true);
	}

	function handleMouseLeaveOnFilter() {
		return setIsFilterHovered(false);
	}

	function renderTooltipWrap() {
		return tooltipText ? (
			<div
				className={className(style.tooltipWrap, { [style.tooltipWrap__hidden]: !isFilterHovered })}
				id={`tooltipBlock${id}`}
				style={{
					right: `-${tooltipOffset?.horizontalOffset}px`,
					left: `${tooltipOffset?.horizontalOffset}px`,
					top: `-${tooltipOffset?.verticalOffset}px`,
				}}>
				{tooltipText}
			</div>
		) : (
			<></>
		);
	}

	return (
		<li onMouseEnter={handleMouseEnterOnFilter} onMouseLeave={handleMouseLeaveOnFilter}>
			<div className={style.filterHeaderWrap} onClick={() => toggle(id + 1)}>
				<div
					className={className(style.filterHeaderWrap__arrowWrap, {
						[style.filterHeaderWrap__arrowWrap__active]: activeFilterId,
					})}>
					<img src={filterArrow} alt="arrow" />
				</div>
				<div className={style.topLineWrap}>
					<span className={style.topLineWrap__title} id={`filterHeaderTitle${id}`}>
						{title}
					</span>
					{renderTooltipWrap()}
				</div>
			</div>
			<ul className={className(style.filter__variants, { [style.filter__variants_show]: activeFilterId })}>
				{content?.map((filter, index) => {
					return (
						<li key={index}>
							<span className={style.filterVariantWrap}>
								<input
									onClick={() => setIncomingFilterValue(filter as IPlatformFilterValueList)}
									checked={checkedValues.includes((filter as IPlatformFilterValueList).value)}
									onChange={(e) => onChange(e.target.checked, e.target.value, title)}
									value={getCorrectFilterValue(filter)}
									name={title}
									id={getCorrectFilterValueId(filter)}
									type={isSingleChoice ? "radio" : "checkbox"}
								/>
								<label className={style.filterVariantWrap__title}>{getCorrectFilterValue(filter)}</label>
							</span>
						</li>
					);
				})}
			</ul>
		</li>
	);
};
