export const emailPattern =
	/^\s*(?=.{8,30}$)(?!.*\.{2})(?![-.])[\w.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)?\.[a-zA-Z]{2,6}\s*$/i;
export const passwordPattern =
	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[^a-zA-Z\d!#/='<>{}@,.$%[\]^&*()~\-:;+?])[^\s]{6,50}$/;
export const firstnamePattern = /^\s*(?:(?:[A-Za-zЁёА-я]{2,25})|(?:[A-Za-zЁёА-я]{2,12}-[A-Za-zЁёА-я]{2,12}))\s*$/;
export const lastnamePattern = /^\s*(?:(?:[A-Za-zЁёА-я]{2,25})|(?:[A-Za-zЁёА-я]{2,12}-[A-Za-zЁёА-я]{2,12}))\s*$/;
export const resourceName = /^[a-zA-Zа-яА-Я0-9\s!@#$%^&*()_+=[\]{};':".,<>/?-]+$/u;
export const domainPattern =
	/^(https?:\/\/)(?![0-9.]+$)(?!(?:.*[.]){5,})(?![^a-zA-Z.]+$)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9_@&?=.-]+)*$/;

export const pricePattern = /^[1-9]\d*$/;
export const filterTitlePattern = /^[\u0400-\u04FFA-Za-z\w\s!@#&()[\]{}:;',-.?/*~$^+=<>]{0,50}$/u;
export const filterHintPattern = /^[\u0400-\u04FFA-Za-z\w\s!@#&()[\]{}:;',-.?/*~$^+=<>]{0,120}$/u;

export const phonePattern = /^\+[0-9]+$/;
export const phonePatternForLib = /^7(6|7|9)\d{9}/;

export const errorDefault = "";
export const errorRequiredField = "Обязательное поле";
export const errorDomainField = "Некорректная ссылка";
export const errorEmailIncorrect = "Некорректный email";
export const errorPasswordIncorrect = "Некорректный пароль";
export const errorFirstnameIncorrect = "Некорректное имя";
export const errorLastnameIncorrect = "Некорректная фамилия";
export const errorPhoneIncorrect = "Некорректный телефон";

export const errorServerLoginEmail = "Email is not valid";
export const errorServerLoginPassword = "Password is not valid";

export const errorServerChangeOldPassword = "Old password is not valid";
export const errorServerChangeNewPassword = "New password is not valid";

export const serverLoginErrors = {
	[errorServerLoginEmail]: "Пользователь с таким email не существует",
	[errorServerLoginPassword]: "Неверный пароль",
};
