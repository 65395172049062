import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IResourceEditDataState } from "../../../../../types/redux/storeTypes";
import { IUnsavedCurrentPlatformData } from "../../../../../types/components/DescriptionPlatform";
import { IUnsavedSolution } from "../../../../../types/components/DescriptionSolution";
import { IFilterChecked } from "../../../../../types/pages/SolutionCatalog";

const resourceDataEditState: IResourceEditDataState = {
	currentEditPlatformData: {
		name: "",
	},
	currentEditSolutionData: {
		name: "",
	},
	selectedFilters: [],
	selectedSolutions: [],
};

const ResourceEditDataSlice = createSlice({
	name: "resourceDataEdit",
	initialState: resourceDataEditState,
	reducers: {
		setEditPlatformData: (state: IResourceEditDataState, action: PayloadAction<IUnsavedCurrentPlatformData>) => {
			state.currentEditPlatformData = action.payload;
		},
		setEditSolutionData: (state: IResourceEditDataState, action: PayloadAction<IUnsavedSolution>) => {
			state.currentEditSolutionData = action.payload;
		},
		setSelectedFilterForEdit: (state, action: PayloadAction<IFilterChecked[]>) => {
			state.selectedFilters = action.payload;
		},
		setSelectedSolutionForEdit: (state, action: PayloadAction<number[]>) => {
			state.selectedSolutions = action.payload;
		},
	},
});

export const { setEditPlatformData, setEditSolutionData, setSelectedFilterForEdit, setSelectedSolutionForEdit } =
	ResourceEditDataSlice.actions;
export default ResourceEditDataSlice.reducer;
