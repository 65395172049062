import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IResourceDataState } from "../../../../../types/redux/storeTypes";
import { IUnsavedCurrentPlatformData } from "../../../../../types/components/DescriptionPlatform";
import { IPlatformFilterData } from "../../../../../types/components/PlatformCatalog";
import { CatalogRoutes } from "../../../../../helpers/catalogHelpers";
import { IUnsavedSolution } from "../../../../../types/components/DescriptionSolution";

const resourceDataState: IResourceDataState = {
	currentResourceId: 0,
	currentResourceType: null,
	currentPlatformData: {
		name: "",
	},
	currentFilterData: null,
	currentSolutionData: {
		name: "",
	},
};

const ResourceDataSlice = createSlice({
	name: "resourceData",
	initialState: resourceDataState,
	reducers: {
		setResourceId: (state: IResourceDataState, action: PayloadAction<number>) => {
			state.currentResourceId = action.payload;
		},
		setResourceType: (state: IResourceDataState, action: PayloadAction<CatalogRoutes | null>) => {
			state.currentResourceType = action.payload;
		},
		setPlatformData: (state: IResourceDataState, action: PayloadAction<IUnsavedCurrentPlatformData>) => {
			state.currentPlatformData = action.payload;
		},
		setFilterData: (state: IResourceDataState, action: PayloadAction<IPlatformFilterData>) => {
			state.currentFilterData = action.payload;
		},
		setSolutionData: (state: IResourceDataState, action: PayloadAction<IUnsavedSolution>) => {
			state.currentSolutionData = action.payload;
		},
	},
});

export const { setResourceId, setPlatformData, setResourceType, setFilterData, setSolutionData } =
	ResourceDataSlice.actions;
export default ResourceDataSlice.reducer;
