import { IPlatformChange } from "../../../types/pages/Platform";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { createPlatform } from "./apiAdminConstants";

export async function createPlatforms(inputData: IPlatformChange) {
	const baseURL = getCorrectBaseUrl("ps");
	const data = {
		name: inputData.name,
		logo: inputData.logo,
		description: inputData.description,
		shortDescription: inputData.shortDescription,
		cost: inputData.cost,
		filters: inputData.filters,
		website: inputData.website,
		solutionIds: inputData.solutionIds,
	};

	return await fetch(`${baseURL}/${createPlatform}`, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
		},
	});
}
