import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";
import { settingSolutionHistoryEndpoint } from "../../../../api/resources/apiResourcesConstants";
import { ISolutionCatalogContent } from "../../../../../types/redux/thunkTypes";

const baseUrl = getCorrectBaseUrl("ps");

export const getSolutionHistory = createAsyncThunk("history/solutions", async (params, thunkAPI) => {
	try {
		const response = await fetch(`${baseUrl}/${settingSolutionHistoryEndpoint}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
		return (await response.json()) as Omit<ISolutionCatalogContent[], "favorites">;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
