import { ITargetAudience } from "../../../types/common/ComponentProps";

import blueArrow from "../../../assets/pages/Service/arrow-right-blue.svg";

import style from "./TargetAudience.module.css";

export const TargetAudience = ({ description }: ITargetAudience) => {
	return (
		<div className={style.targetAudienceWrap}>
			<img src={blueArrow} alt="arrow" className={style.targetAudienceWrap__arrow} />
			<p className={style.targetAudienceWrap__title}>{description}</p>
		</div>
	);
};
