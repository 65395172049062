import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import { Title } from "../../../ui/Title/Title";
import AdminCatalogSwitch from "../../../components/AdminLayoutComponents/AdminCatalogSwitch/AdminCatalogSwitch";
import PublishedPlatforms from "../../../components/AdminLayoutComponents/Platforms/PublishedPlatforms/PublishedPlatforms";
import DraftedPlatforms from "../../../components/AdminLayoutComponents/Platforms/DraftedPlatforms/DraftedPlatforms";
import DeletedPlatforms from "../../../components/AdminLayoutComponents/Platforms/DeletedPlatforms/DeletedPlatforms";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminCatalogRoutes } from "../../../helpers/catalogHelpers";
import { AdminPath, Path } from "../../../services/router/routes";
import { Roles } from "../../../helpers/roles";

import style from "./PlatformsAdmin.module.css";

function PlatformsAdmin() {
	const [isModerator, setIsModerator] = useState<boolean>(false);
	const params = useParams();
	const userData = useAppSelector(userDataSelector).userData;
	const location = useLocation();
	const navigator = useNavigate();

	const activeSwitchElement: AdminCatalogRoutes = params?.pagepart as AdminCatalogRoutes;

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (
			location.pathname.includes(AdminPath.Platforms) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (userData?.roles) {
			setIsModerator(userData?.roles[0]?.name === Roles.MODERATOR);
		}
	}, [userData?.roles]);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	function getCurrentPlatformList(): JSX.Element {
		if (activeSwitchElement === AdminCatalogRoutes.Published) {
			return <PublishedPlatforms isModerator={isModerator} />;
		} else {
			if (activeSwitchElement === AdminCatalogRoutes.Drafted) {
				return <DraftedPlatforms isModerator={isModerator} />;
			} else {
				return <DeletedPlatforms isModerator={isModerator} />;
			}
		}
	}

	return (
		<div className={style.platformsAdminWrap}>
			<Title title="Платформы" styleName="titlePage" />
			<AdminCatalogSwitch currentResource={AdminPath.Platforms} pagePart={params?.pagepart as AdminCatalogRoutes} />
			<div className={style.platformsAdminContent}>{getCurrentPlatformList()}</div>
		</div>
	);
}

export default PlatformsAdmin;
