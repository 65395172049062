import { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IFilterChecked, IToggle } from "../../../../types/pages/SolutionCatalog";
import { IPlatformChange } from "../../../../types/pages/Platform";
import { IUnsavedCurrentPlatformData } from "../../../../types/components/DescriptionPlatform";
import { Button } from "../../../../ui/Button/Button";
import FieldsToFill from "../../../../components/AdminLayoutComponents/CreationComponents/FieldsToFill/FieldsToFill";
import CheckBoxBlock from "../../../../components/AdminLayoutComponents/CreationComponents/CheckBoxBlock/CheckBoxBlock";
import ModalHandelClose from "../../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import ButtonsSubmit from "../../../../components/AdminLayoutComponents/CreationComponents/ButtonsSubmit/ButtonsSubmit";
import { createPlatforms } from "../../../../services/api/admin/createPlatform";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";
import { AdminPath } from "../../../../services/router/routes";
import { setPlatformData } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import PlatformFilterSelector from "../../../../services/redux/features/adminPart/platforms/platformsFilter/PlatformFilterSelector";
import { movePlatformToPublished } from "../../../../services/api/admin/platformsPageActions";
import {
	setSelectedFilter,
	setSelectedSolution,
} from "../../../../services/redux/features/adminPart/platforms/platformsFilter/PlatformFilterSlice";

import styles from "./PlatformCreation.module.css";

const PlatformCreation = ({ toggleModal, isOpen, setIsNameEmpty }: IToggle) => {
	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IPlatformChange>({
		defaultValues: {
			name: "",
			logo: "",
			description: "",
			shortDescription: "",
			cost: 0,
			website: "",
		},
		mode: "all",
		delayError: 200,
	});

	const [imageUrl, setImageUrl] = useState<string | undefined>();
	const [selectedFilters, setSelectedFilters] = useState<IFilterChecked[]>([]);
	const [selectedSolutions, setSelectedSolutions] = useState<number[]>([]);

	const activeElement = useAppSelector(switchSelector).activeElement;
	const selectedFilter = useAppSelector(PlatformFilterSelector).selectedFilters;
	const selectedSolution = useAppSelector(PlatformFilterSelector).selectedSolutions;
	const unsavedPlatform = useAppSelector(ResourceDataSelector).currentPlatformData;
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const maxFileSize: number = 1024;
	const minImageWidth: number = 808;
	const maxImageWidth: number = 1920;
	const minImageHight: number = 632;
	const maxImageHight: number = 1080;

	useEffect(() => {
		setIsNameEmpty(control._formValues?.name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [control._formValues?.name]);

	useEffect(() => {
		unsavedPlatform && setValue("shortDescription", unsavedPlatform?.shortDescription?.trim() || "");
		unsavedPlatform && setValue("name", unsavedPlatform.name?.trim() || "");
		unsavedPlatform && setValue("description", unsavedPlatform?.description?.trim() || "");
		unsavedPlatform && setValue("cost", unsavedPlatform?.cost || 0);
		unsavedPlatform && setValue("logo", unsavedPlatform?.logoUrl || "");
		unsavedPlatform && setValue("website", unsavedPlatform?.website || "");
		unsavedPlatform && setImageUrl(unsavedPlatform.logoUrl || "");
	}, [unsavedPlatform, setValue]);

	const setDefaultValues = () => {
		setValue("name", "");
		setValue("logo", "");
		setValue("description", "");
		setValue("shortDescription", "");
		setValue("cost", 0);
		setValue("website", "");
		setSelectedFilters([]);
		setSelectedSolutions([]);
		setImageUrl("");
		dispatch(setSelectedFilter([]));
		dispatch(setSelectedSolution([]));
		dispatch(setPlatformData({ name: "" }));
	};

	const unpublishedCreate: SubmitHandler<IPlatformChange> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");

		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};
		const updatePlatform = {
			...trimmedData,
			filters: selectedFilters,
			solutionIds: selectedSolutions,
		};

		const create = await createPlatforms(updatePlatform);
		if (create.ok) {
			setDefaultValues();
			navigate(`${AdminPath.Platforms}/drafted/1`);
		}
	};

	const setDataForPlatformPreviewPage = async (updatePlatform: IUnsavedCurrentPlatformData) => {
		return await dispatch(setPlatformData(updatePlatform));
	};

	const groupFilters = (filters: IFilterChecked[]): IFilterChecked[] => {
		const groupedFilters: Record<number, number[]> = {};

		filters.forEach((filter) => {
			if (!groupedFilters[filter.filterId]) {
				groupedFilters[filter.filterId] = [];
			}
			groupedFilters[filter.filterId] = [...[...groupedFilters[filter.filterId], ...filter.valueIds]];
		});

		return Object.entries(groupedFilters).map(([filterId, valueIds]) => ({
			filterId: parseInt(filterId),
			valueIds,
		}));
	};

	const handleUnsupportedType = () => {
		setValue("logo", "");
	};

	const setImageAndValue = (imageDataUrl: string) => {
		setImageUrl(imageDataUrl);
		setValue("logo", imageDataUrl);
	};

	const readImage = (file: File) => {
		const reader = new FileReader();
		reader.onload = () => {
			const imageDataUrl = reader.result as string;
			const img = new Image();
			img.src = imageDataUrl;
			img.onload = () => {
				if (isValidSize(img.width, img.height)) {
					setImageAndValue(imageDataUrl);
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const isValidSize = (width: number, height: number) => {
		return width >= minImageWidth && width <= maxImageWidth && height >= minImageHight && height <= maxImageHight;
	};

	const handlePreviewNavigate: SubmitHandler<IPlatformChange> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		if (activeElement === "Платформы") {
			data.name = data.name.replace(/\s{2,}/g, " ");
			const updatePlatform: IUnsavedCurrentPlatformData = {
				name: data.name.trim(),
			};
			if (data.logo) {
				updatePlatform.logoUrl = data.logo;
			}
			if (data.cost) {
				updatePlatform.cost = data.cost;
			}
			if (data.shortDescription) {
				updatePlatform.shortDescription = data.shortDescription.trim();
			}
			if (data.description) {
				updatePlatform.description = data.description.trim();
			}
			if (data.website) {
				updatePlatform.website = data.website;
			}
			dispatch(setSelectedFilter(groupFilters(selectedFilters)));
			dispatch(setSelectedSolution(selectedSolutions));
			await setDataForPlatformPreviewPage(updatePlatform);

			navigate(`${AdminPath.PlatformPreview}/0?from=platformCreation`);
		}
	};

	const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const file = e.target.files && e.target.files[0];

		if (!file) return;
		if (file) {
			const allowedImageTypes = ["image/jpeg", "image/png", "image/webp"];
			if (allowedImageTypes.includes(file.type)) {
				if (file.size <= maxFileSize * maxFileSize) {
					readImage(file);
				}
			} else {
				handleUnsupportedType();
			}
		}
	};

	const onSubmit: SubmitHandler<IPlatformChange> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");

		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};
		const updatePlatform = {
			...trimmedData,
			filters: selectedFilters,
			solutionIds: selectedSolutions,
		};

		await createPlatforms(updatePlatform).then(async (value) => {
			const jsonResponse = await value.json();
			const list = await movePlatformToPublished(jsonResponse);
			if (list.ok) {
				setDefaultValues();
				navigate(`${AdminPath.Platforms}/published/1`);
			}
		});
	};

	return (
		<form>
			<div className={styles.fields}>
				<FieldsToFill
					control={control}
					imageText="Загрузить изображение"
					handleImageChange={handleImageChange}
					imageUrl={imageUrl}
					showInput={true}
					errors={errors}
					height="700px"
					unsavedImg={unsavedPlatform.logoUrl}
				/>
				<CheckBoxBlock
					selectedFilters={selectedFilters}
					setSelectedFilters={setSelectedFilters}
					selectedSolution={selectedSolutions}
					setSelectedSolutionIds={setSelectedSolutions}
					checked={true}
					checkedCreationFilter={selectedFilter}
					checkedCreationSolutions={selectedSolution}
					type="Платформы"
				/>
			</div>
			<ButtonsSubmit
				showButton={false}
				unpublishedCreate={handleSubmit(unpublishedCreate)}
				preview={handleSubmit(handlePreviewNavigate)}
				onSubmit={handleSubmit(onSubmit)}
			/>
			<ModalHandelClose open={isOpen} toggle={toggleModal} title="Выйти из создания?">
				<Button
					content="Сохранить и выйти"
					styleName="saveAndExitButton"
					testId=""
					onClick={handleSubmit(unpublishedCreate)}
				/>
				<Button
					content="Выйти без сохранения"
					styleName="exitWithoutSavingButton"
					testId=""
					onClick={() => navigate(-1)}
				/>
			</ModalHandelClose>
		</form>
	);
};
export default PlatformCreation;
