import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames/dedupe";

import useAppDispatch from "../../../hooks/useAppDispatch";

import { ICatalogSwitch } from "../../../types/common/ComponentProps";
import { setIsActive } from "../../../services/redux/features/userPart/switch/SwitchSlice";
import { Path } from "../../../services/router/routes";
import { CatalogRoutes, CatalogTitles } from "../../../helpers/catalogHelpers";

import styles from "./CatalogSwitch.module.css";

function CatalogSwitch({ currentResource, isLogin }: ICatalogSwitch) {
	const navigator = useNavigate();
	const dispatch = useAppDispatch();

	const isPlatformPage: boolean = currentResource === CatalogRoutes.Platforms;

	useEffect(() => {
		dispatch(setIsActive(isPlatformPage ? CatalogTitles.Platforms : CatalogTitles.Solutions));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlatformPage]);

	function handleSolutionAction() {
		if (!isPlatformPage) {
			return null;
		}
		dispatch(setIsActive(CatalogTitles.Solutions));
		return navigator((isLogin ? Path.SolutionCatalogSignin : Path.SolutionCatalogUnSignin) + "/1");
	}

	function handlePlatformAction() {
		if (isPlatformPage) {
			return null;
		}
		dispatch(setIsActive(CatalogTitles.Platforms));
		return navigator((isLogin ? Path.PlatformCatalogSignin : Path.PlatformCatalogUnSignin) + "/1");
	}

	return (
		<div className={styles.catalogSwitchWrap}>
			<button
				className={cn(styles.inactiveButton, { [styles.activeButton]: !isPlatformPage })}
				onClick={handleSolutionAction}>
				Решения
			</button>
			<button
				className={cn(styles.inactiveButton, { [styles.activeButton]: isPlatformPage })}
				onClick={handlePlatformAction}>
				Платформы
			</button>
		</div>
	);
}

export default CatalogSwitch;
