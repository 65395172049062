import { createAsyncThunk } from "@reduxjs/toolkit";

import { IAdminPlatformsData } from "../../../../../../types/redux/storeTypes";
import { getCorrectBaseUrl } from "../../../../../../utils/baseUrlConverter";
import { platformsDeletedEndpoint } from "../../../../../api/admin/apiAdminConstants";

const baseURL = getCorrectBaseUrl("ps");

export const getDeletedPlatformsData = createAsyncThunk(
	"deletedPlatforms/getDeletedPlatformsData",
	async (params: number, thunkAPI) => {
		try {
			const response = await fetch(`${baseURL}/${platformsDeletedEndpoint}?offset=${params - 1}&limit=10`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			});
			return (await response.json()) as IAdminPlatformsData;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);
