import { ITitle } from "../../types/common/UiKitProps";
import { formClassesFromStyleName } from "../../utils/formClassesFromStyleName";

import styles from "./Title.module.css";

export const Title = ({ title, styleName, size }: ITitle) => {
	return (
		<h2 className={styleName ? formClassesFromStyleName(styleName, styles) : ""} style={size ? { fontSize: size } : {}}>
			{title}
		</h2>
	);
};
