import { BaseSyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NavLink, useLocation } from "react-router-dom";

import useModal from "../../../hooks/useModal";

import { ServerError, NewPasswordData, NewPasswordFullData } from "../../../types/api/AuthApiTypes";
import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { Title } from "../../../ui/Title/Title";
import { Form } from "../../../ui/Form/Form";
import ReturnBackArrow from "../../../components/PersonalAreaLayoutComponents/ReturnBackArrow/ReturnBackArrow";
import { AuthInput } from "../../../components/AuthLayoutComponents/AuthInput/AuthInput";
import ModalAutoHidden from "../../../components/PersonalAreaLayoutComponents/ModalAutoHidden/ModalAutoHidden";
import { sendNewPassword } from "../../../services/api/auth/sendNewPassword";
import { Path } from "../../../services/router/routes";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";
import { errorServerLoginPassword, passwordPattern, serverLoginErrors } from "../../../helpers/AuthConstants";
import { DELAY_IN_SECONDS } from "../../../helpers/delayConstants";

import logo from "../../../assets/layouts/Main/logo.webp";

import styles from "./NewPassword.module.css";

const NewPassword = () => {
	const location = useLocation();
	const {
		handleSubmit,
		control,
		setError,
		formState: { isValid, errors },
	} = useForm<NewPasswordData>({
		defaultValues: {
			newPassword: "",
		},
		mode: "all",
		delayError: 200,
	});

	const { isOpen, toggleModal, delayModal, setDelayModal } = useModal();

	const token = new URLSearchParams(location.search).get("token");

	useEffect(() => {
		setDelayModal(delayModal + DELAY_IN_SECONDS);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [modalTitle, setModalTitle] = useState("Отлично!");
	const [modalMessage, setModalMessage] = useState("Ваш пароль успешно восстановлен");

	const onSubmit: SubmitHandler<NewPasswordData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		const newPasswordFullData: NewPasswordFullData = {
			token: token ?? "",
			newPassword: data.newPassword ?? "",
		};
		sendNewPassword(newPasswordFullData)
			.then(() => toggleModal())
			.catch((error: ServerErrorResponse) => {
				const message = (error.response?.data as ServerError).message;

				if (message === errorServerLoginPassword) {
					setError(
						"newPassword",
						getDefaultServerErrorMessage(serverLoginErrors[errorServerLoginPassword]),
						shouldBeFocusedField,
					);
				}

				setModalTitle("Упс");
				setModalMessage(message);
				toggleModal();
			});
	};

	return (
		<>
			<ModalAutoHidden open={isOpen} toggle={toggleModal} title={modalTitle} message={modalMessage} />

			<div className={styles["logo-container"]}>
				<NavLink to={Path.Main}>
					<img src={logo} alt="Townsend logo" />
				</NavLink>
			</div>

			<div className={styles.newPasswordWrap}>
				<ReturnBackArrow />
				<div className={styles.newPasswordContainer}>
					<Title title={"Изменить пароль"} styleName={"title _left"} />
					<Form
						styleName={styles.newPasswordForm}
						buttonName="Сохранить пароль"
						onSubmit={handleSubmit(onSubmit)}
						isValid={isValid}
						noValidate>
						<AuthInput
							control={control}
							id={"current-password"}
							name={"newPassword"}
							label={"Новый пароль"}
							type={"password"}
							autoComplete={"current-password"}
							rules={{
								required: true,
								pattern: passwordPattern,
							}}
							error={formHelpers.getNewPasswordError(errors, control._formValues.newPassword)}
						/>
					</Form>
				</div>
			</div>
		</>
	);
};

export default NewPassword;
