import { createAsyncThunk } from "@reduxjs/toolkit";

import { IUserData } from "../../../../../types/redux/thunkTypes";
import { getCurrentUserEndpoint } from "../../../../api/auth/apiConstants";
import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";

const baseURL = getCorrectBaseUrl("as");

export const getUserData = createAsyncThunk("userData/getUserData", async (params, thunkAPI) => {
	try {
		const response = await fetch(`${baseURL}/${getCurrentUserEndpoint}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
		return (await response.json()) as IUserData;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
