export const signupEndpoint = "api/v1/auth/registration";
export const loginEndpoint = "api/v1/auth/login";
export const getCurrentUserEndpoint = "api/v1/account/current-user";
export const logoutEndpoint = "api/v1/auth/logout";
export const forgotPasswordEndpoint = "api/v1/auth/forgot-password";
export const resetPasswordEndpoint = "api/v1/auth/reset-password";
export const sendOrderEndpoint = "api/v1/orders/contact";
export const sendProposalEndpoint = "api/v1/orders/consulting";
export const updateUserInfoEndpoint = "api/v1/account";
export const changePasswordEndpoint = "api/v1/account/update-password";
