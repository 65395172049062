import { BaseSyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { SignupData, ServerError } from "../../../types/api/AuthApiTypes";
import { AuthInput } from "../AuthInput/AuthInput";
import { signupUser } from "../../../services/api/auth/signUpUser";
import { Form } from "../../../ui/Form/Form";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";

import {
	emailPattern,
	errorServerLoginEmail,
	firstnamePattern,
	lastnamePattern,
	passwordPattern,
	serverLoginErrors,
} from "../../../helpers/AuthConstants";

import useModal from "../../../hooks/useModal";

import { DELAY_IN_SECONDS } from "../../../helpers/delayConstants";

import ModalAutoHiden from "../../PersonalAreaLayoutComponents/ModalAutoHidden/ModalAutoHidden";

import styles from "./SignupForm.module.css";

export const SignupForm = () => {
	const navigate = useNavigate();
	const [isSignUpError, setIsSignUpError] = useState<boolean>(false);
	const { isOpen, toggleModal, delayModal, setDelayModal } = useModal();
	const {
		register,
		handleSubmit,
		control,
		setError,
		formState: { isValid, errors },
	} = useForm<SignupData>({
		defaultValues: {
			firstname: "",
			lastname: "",
			email: "",
			password: "",
		},
		mode: "all",
		delayError: 200,
	});

	useEffect(() => {
		setDelayModal(delayModal + DELAY_IN_SECONDS);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const timer = 4000;

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				handleNavigate();
			}, timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, isOpen]);

	const handleNavigate = () => {
		if (!isSignUpError) {
			navigate(-1);
			toggleModal();
		} else {
			toggleModal();
			toggleModal();
		}
	};

	const getMessageType = (message: string) => (message === errorServerLoginEmail ? "email" : "password");

	const getErrorSignUpStatus = async (signUpStatus: boolean) => await setIsSignUpError(signUpStatus);

	const personalDataLink = "http://www.kremlin.ru/acts/bank/24154";
	const minimalEmailLength: number = 8;
	const maximalEmailLength: number = 30;

	const onSubmit: SubmitHandler<SignupData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		const signupData = {
			firstname: data.firstname.trim(),
			lastname: data.lastname?.trim(),
			email: data.email.trim(),
			password: data.password.trim(),
			agreement: data.agreement,
		};
		signupUser(signupData)
			.then((response: Response) => {
				if (response.ok) {
					getErrorSignUpStatus(false).then(() => {
						toggleModal();
					});
				} else {
					getErrorSignUpStatus(true).then(() => {
						toggleModal();
						toggleModal();
					});
				}
			})
			.catch((e: ServerErrorResponse) => {
				const message = (e.response?.data as ServerError).message as keyof typeof serverLoginErrors;

				getErrorSignUpStatus(true).then(() => {
					toggleModal();
					toggleModal();
				});

				message &&
					setError(
						getMessageType(message),
						getDefaultServerErrorMessage(serverLoginErrors[message]),
						shouldBeFocusedField,
					);
			});
	};

	const getModalTitle = () => {
		return isSignUpError ? "Что-то пошло не так :(" : "Отлично!";
	};

	const getModalDescription = () => {
		return isSignUpError
			? "Проверьте правильность введеных данных"
			: "На вашу почту отправлено письмо для подтверждения регистрации";
	};

	return (
		<>
			<Form
				noValidate={true}
				buttonName={"Зарегистрироваться"}
				isValid={isValid}
				styleName={"authForm"}
				onSubmit={handleSubmit(onSubmit)}>
				<AuthInput
					control={control}
					id={"firstname"}
					name={"firstname"}
					label={"Имя"}
					type={"text"}
					rules={{
						required: true,
						minLength: 2,
						maxLength: 25,
						pattern: firstnamePattern,
					}}
					error={formHelpers.getNameError(errors)}
				/>
				<AuthInput
					control={control}
					id={"lastname"}
					name={"lastname"}
					label={"Фамилия"}
					type={"lastname"}
					rules={{
						pattern: lastnamePattern,
					}}
					error={formHelpers.getLastNameError(errors)}
				/>
				<AuthInput
					control={control}
					id={"email"}
					name={"email"}
					label={"Email"}
					type={"email"}
					autoComplete={"username"}
					rules={{
						required: true,
						pattern: emailPattern,
						minLength: minimalEmailLength,
						maxLength: maximalEmailLength,
					}}
					error={formHelpers.getEmailError(errors)}
				/>
				<AuthInput
					control={control}
					id={"current-password"}
					name={"password"}
					label={"Пароль"}
					type={"password"}
					autoComplete={"current-password"}
					rules={{
						required: true,
						pattern: passwordPattern,
					}}
					error={formHelpers.getCommonPasswordError(errors, control._formValues.password)}
				/>
				<div className={styles["agreement-container"]}>
					<input type="checkbox" {...register("agreement", { required: true })} />
					<span className={styles.agreementWrap}>
						<p>Я даю согласие на </p>
						<a href={personalDataLink} target="_blank" rel="noreferrer" className={styles.agreementWrap__resourceLink}>
							обработку персональных данных
						</a>
					</span>
				</div>
			</Form>
			<ModalAutoHiden open={isOpen} toggle={handleNavigate} title={getModalTitle()} message={getModalDescription()} />
		</>
	);
};
