export enum Path {
	Main = "/",
	Service = "/service",
	PlatformCatalogUnSignin = "/platformscatalog",
	SolutionCatalogUnSignin = "/solutionscatalog",
	CurrentPlatformUnSignin = "/platformpage",
	CurrentSolutionUnSignin = "/solutionpage",
	Rates = "rates",
	Contacts = "/contacts",
	Blog = "blog",
	Login = "/login",
	Signup = "/signup",
	PersonalArea = "/PersonalArea",
	PersonalAreaFavorites = "/PersonalAreaFavorites",
	PersonalAreaFAQ = "/personalAreaFAQ",
	PlatformCatalogSignin = "/personalArea/platformcatalog",
	SolutionCatalogSignin = "/personalArea/solutioncatalog",
	CurrentPlatformSignin = "/personalArea/platformpage",
	CurrentSolutionSignin = "/personalArea/solutionpage",
	PersonalAreaContacts = "/personalArea/contacts",
	PersonalAreaAcceptedPage = "/personalArea/accepted",
	AcceptedPage = "/accepted",
	EditProfile = "/personalArea/edit",
	ResetPassword = "/resetpassword",
	ChangeUserPassword = "/personalArea/changepassword",
	NewPassword = "/newpassword",
}

export enum AdminPath {
	Drafts = "/admin/drafts",
	Staff = "/admin/staff",
	Filters = "/admin/filters",
	Creation = "/admin/creation",
	Platforms = "/admin/platforms",
	Solutions = "/admin/solutions",
	SolutionsEdit = "/admin/solutions/edit",
	PlatformEdit = "/admin/platforms/edit",
	PlatformPreview = "/admin/platformPreview",
	SolutionPreview = "/admin/solutionPreview",
}
