import { useEffect } from "react";

import { PostList } from "../../../components/MainLayoutComponents/PostList/PostList";
import { Title } from "../../../ui/Title/Title";

import style from "./Blog.module.css";

export const Blog = () => {
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<div>
			<div className={style.title__container}>
				<Title title="Блог" styleName="titlePage" />
			</div>
			<PostList />;
		</div>
	);
};
