export const TelegramIcon = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_890_1121)">
				<path
					d="M27.5646 0.295505L0.985973 10.9034C-0.083597 11.3974 -0.445355 12.3867 0.727457
          12.9235L7.54601 15.1663L24.0324 4.62087C24.9326 3.95885 25.8541 4.13538 25.0611
          4.86364L10.9016 18.1327L10.4568 23.7481C10.8687 24.6152 11.6231 24.6192 12.1042
          24.1883L16.0217 20.3518L22.731 25.5516C24.2893 26.5065 25.1372 25.8903 25.4725
          24.1402L29.8731 2.57345C30.3301 0.419306 29.5509 -0.529833 27.5646 0.295505Z"
					fill="white"
					transform="scale(0.65) translate(16, 16)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_890_1121">
					<rect width="40" height="40" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
