import { createSlice } from "@reduxjs/toolkit";

import { IHistoryPlatformsState } from "../../../../../types/redux/storeTypes";

import { getPlatformHistory } from "./PlatformHistoryThunk";

const platformHistoryInitialState: IHistoryPlatformsState = {
	historyPlatforms: [],
	isLoading: false,
	error: null,
};

const PlatformHistorySlice = createSlice({
	name: "history/platforms",
	initialState: platformHistoryInitialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(getPlatformHistory.pending, (state) => {
			state.error = false;
			state.isLoading = true;
		});
		builder.addCase(getPlatformHistory.rejected, (state) => {
			state.isLoading = false;
			state.error = true;
		});
		builder.addCase(getPlatformHistory.fulfilled, (state, action) => {
			state.error = false;
			state.isLoading = false;
			state.historyPlatforms = action.payload;
		});
	},
});

export default PlatformHistorySlice.reducer;
