import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { IEditSolution } from "../../../types/pages/SolutionCatalog";

export async function changeEditSolution(inputData: IEditSolution, id: string | undefined) {
	const baseURL = getCorrectBaseUrl("ps");

	return fetch(`${baseURL}/solutions/${id}`, {
		method: "PUT",
		body: JSON.stringify(inputData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
