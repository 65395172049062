export const basePlatformsEndpoint = "platforms";

export const gettingPlatformsPaginationEndpoint = basePlatformsEndpoint + "/filtered";
export const gettingPlatformFiltersEndpoint = basePlatformsEndpoint + "/filters";
export const settingFavoritePlatformEndpoint = basePlatformsEndpoint + "/favorites";
export const settingPlatformHistoryEndpoint = "history/" + basePlatformsEndpoint;
export const gettingSearchedPlatformListEndpoint = basePlatformsEndpoint + "/search";

export const baseSolutionEndpoint = "solutions";

export const gettingSolutionPaginationEndpoint = baseSolutionEndpoint + "/filtered";
export const gettingSolutionFiltersEndpoint = baseSolutionEndpoint + "/filters";
export const settingFavoriteSolutionEndpoint = baseSolutionEndpoint + "/favorites";
export const settingSolutionHistoryEndpoint = "history/" + baseSolutionEndpoint;
export const gettingSearchedSolutionListEndpoint = baseSolutionEndpoint + "/search";
