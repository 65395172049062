import { BaseSyntheticEvent, useEffect } from "react";
import className from "classnames";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import { useScrollLock } from "../../../hooks/useScrollLock";

import { SendProposal } from "../../../types/api/AuthApiTypes";
import { FeedbackDataType } from "../../../types/components/ModalMainPage";
import { IModal } from "../../../types/common/ComponentProps";
import { AuthInput } from "../../AuthLayoutComponents/AuthInput/AuthInput";
import { Form } from "../../../ui/Form/Form";
import { formHelpers } from "../../../utils/formHelpers";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { sendProposalData } from "../../../services/api/auth/sendProposal";
import { phonePattern, emailPattern, firstnamePattern } from "../../../helpers/AuthConstants";
import { Path } from "../../../services/router/routes";

import { FormattedPhoneInput } from "./FormattedPhoneInput/FormattedPhoneInput";

import style from "./Modal.module.css";

export const Modal = ({ isOpened, setIsOpened }: IModal) => {
	const {
		handleSubmit,
		reset,
		control,
		register,
		setValue,
		formState: { isValid, errors },
	} = useForm<FeedbackDataType>({
		defaultValues: {
			firstname: "",
			email: "",
			phone: "",
		},
		mode: "all",
		delayError: 300,
	});

	const userData = useAppSelector(userDataSelector).userData;
	const navigate = useNavigate();
	const scrollLock = useScrollLock(isOpened);

	scrollLock();

	const openModal = () => {
		setIsOpened(false);
		if (!userData?.id) {
			reset();
		}
	};

	useEffect(() => {
		if (userData?.id) {
			setValue("firstname", userData?.firstname);
			setValue("email", userData?.email);
		}
	}, [setValue, userData]);

	const onSubmit: SubmitHandler<FeedbackDataType> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		const userData: SendProposal = {
			email: data.email.trim(),
			name: data.firstname.trim(),
			number: data.phone,
		};
		sendProposalData(userData).then(() => navigate(Path.AcceptedPage));
	};

	return (
		<div className={className(style.modal_window, { [style.modal_window__active]: isOpened })}>
			<Form
				buttonName="Отправить"
				isValid={isValid}
				onSubmit={handleSubmit(onSubmit)}
				noValidate={true}
				styleName={"modalForm"}>
				<div className={style.header}>
					<p className={style.modal_window__title}>Консультация</p>
					<span onClick={openModal} className={style.cross}></span>
				</div>
				<AuthInput
					control={control}
					id={"name"}
					name={"firstname"}
					label={"Имя"}
					rules={{
						required: true,
						minLength: 2,
						maxLength: 25,
						pattern: firstnamePattern,
					}}
					error={formHelpers.getNameError(errors)}
				/>
				<AuthInput
					control={control}
					id={"email"}
					name={"email"}
					label={"Email"}
					type={"email"}
					rules={{
						required: true,
						pattern: emailPattern,
						minLength: 8,
						maxLength: 30,
					}}
					error={formHelpers.getEmailError(errors)}
				/>
				<FormattedPhoneInput
					register={register("phone", { required: true, minLength: 5, pattern: phonePattern })}
					name={"phone"}
					id={"phone"}
					label={"Телефон"}
					maxLength={19}
					onChange={(event: BaseSyntheticEvent) => setValue("phone", formHelpers.formatPhone(event.target.value))}
					error={formHelpers.getPhoneError(errors)}
				/>
			</Form>
		</div>
	);
};
