import { CrudMethods, IActionPlatformsModal, IActionStaffModal } from "../../../types/api/AdminApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";
import { basePlatformsEndpoint } from "../resources/apiResourcesConstants";

import {
	platformUnpublishingEndpoint,
	platformsDeletedEndpoint,
	platformsPublishedEndpoint,
} from "./apiAdminConstants";

async function actionPlatformsModal({ endpoint, method }: IActionPlatformsModal) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${endpoint}`, {
		method: method,
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}

export async function movePlatformToPublished(platformId: number) {
	return await actionPlatformsModal({
		endpoint: `${platformsPublishedEndpoint}/${platformId}`,
		method: CrudMethods.put,
	} as IActionStaffModal);
}

export async function movePlatformToDrafts(platformId: number) {
	return await actionPlatformsModal({
		endpoint: `${platformUnpublishingEndpoint}/${platformId}`,
		method: CrudMethods.put,
	} as IActionStaffModal);
}

export async function removePlatformToDeleted(platformId: number) {
	return await actionPlatformsModal({
		endpoint: `${basePlatformsEndpoint}/${platformId}`,
		method: CrudMethods.delete,
	} as IActionStaffModal);
}

export async function restorePlatformFromDeleted(platformId: number) {
	return await actionPlatformsModal({
		endpoint: `${platformsDeletedEndpoint}/${platformId}`,
		method: CrudMethods.put,
	} as IActionStaffModal);
}

export async function removePlatformFromDeleted(platformId: number) {
	return await actionPlatformsModal({
		endpoint: `${platformsDeletedEndpoint}/${platformId}`,
		method: CrudMethods.delete,
	} as IActionStaffModal);
}
