import { Link, useLocation } from "react-router-dom";

import { IHistoryLink } from "../../../types/common/ComponentProps";

export const HistoryLink = ({ children, to, ...props }: IHistoryLink) => {
	const location = useLocation();

	return (
		<Link to={to} state={{ from: location.pathname }} {...props}>
			{children}
		</Link>
	);
};
