/* eslint-disable quotes */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";

import { Title } from "../../../ui/Title/Title";
import { TargetAudience } from "../../../components/MainLayoutComponents/TargetAudience/TargetAudience";
import { Button } from "../../../ui/Button/Button";
import { Accordion } from "../../../components/PersonalAreaLayoutComponents/Accordion/Accordion";
import { Opportunities } from "../../../components/MainLayoutComponents/Opportunities/Opportunities";
import { PartnersCard } from "../../../components/MainLayoutComponents/PartnersCard/PartnersCard";
import { Modal } from "../../../components/MainLayoutComponents/Modal/Modal";
import { Price } from "../../../components/MainLayoutComponents/Price/Price";
import SampleSlider from "../../../components/MainLayoutComponents/SampleSlider/SampleSlider";
import ReviewsSlider from "../../../components/MainLayoutComponents/ReviewsSlider/ReviewsSlider";
import { accordionArr } from "../../../mocks/accordionArr";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { solutionCatalogSelector } from "../../../services/redux/features/userPart/solutionCatalog/SolutionCatalogSelector";
import { getSolutionListData } from "../../../services/redux/features/userPart/solutionCatalog/SolutionCatalogThunk";
import { getCorrectCatalogLink } from "../../../utils/CatalogLink";
import { CatalogRoutes } from "../../../helpers/catalogHelpers";
import { opportunitiesArr, targetAudienceArr, partnersArr, priceArr } from "../../../mocks/servicePage";

import phoneService from "../../../assets/pages/Service/PhoneService.png";
import player from "../../../assets/pages/Service/Video.png";

import style from "./Service.module.css";

export const Service = () => {
	const [isOpened, setIsOpened] = useState<boolean>(false);
	const navigation = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const userData = useAppSelector(userDataSelector).userData;
	const { catalogSolutions, loading, error } = useAppSelector(solutionCatalogSelector);

	const solutionLimit: number = 15;
	const solutionListErrorContent: string = "Ошибка загрузки списка решений";

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
		window.localStorage.setItem("previousLoginPath", location.pathname);
	}, [location]);

	useEffect(() => {
		dispatch(
			getSolutionListData({
				limit: solutionLimit,
				pageNumber: 1,
				filterData: {
					filterList: [],
				},
			}),
		);
	}, [dispatch]);

	const handleNavigateSolutionCatalog = () => {
		navigation(getCorrectCatalogLink(CatalogRoutes.Solutions, Boolean(userData?.email)));
	};

	const renderSolutionList = () => {
		return !catalogSolutions.length || loading ? (
			<p className={style.emptyListMessage}>{error ? solutionListErrorContent : ""}</p>
		) : (
			<SampleSlider cardList={catalogSolutions} interval={30} solutionsPerSlide={3} />
		);
	};

	function renderOpportunitiesList() {
		return opportunitiesArr.map((item) => {
			return (
				<Opportunities key={crypto.randomUUID()} title={item.title} text={item.text} icon={item.icon} to={item.to} />
			);
		});
	}

	return (
		<div className={style.service}>
			<Modal isOpened={isOpened} setIsOpened={setIsOpened} />
			<div className={style.chat}>
				<div className={style.chatInfoWrap}>
					<Title title="Автоматизируйте бизнес и получайте больше продаж!" styleName="titleChat" />
					<p className={style.chatInfoWrap__subtitle}>
						Используйте проверенные шаблоны чат-ботов в мессенджерах. Это самый простой и эффективный способ получить
						нужные вам результаты в короткий срок.
					</p>
					<div className={style.chat__button_container}>
						<Button
							content={"Подобрать решение"}
							onClick={() => {
								navigation(getCorrectCatalogLink(CatalogRoutes.Solutions, Boolean(userData?.email)));
							}}
							styleName="commonMaroonButton introBlockSolutionAction"
						/>
						<Button
							content={"Подобрать платформу"}
							onClick={() => {
								navigation(getCorrectCatalogLink(CatalogRoutes.Platforms, Boolean(userData?.email)));
							}}
							styleName="commonWhiteMaroonButton introBlockPlatformAction"
						/>
					</div>
				</div>
				<img className={style.chatPhone} src={phoneService} alt="smartphone" />
			</div>
			<div className={style.serviceOpportunitiesWrapper}>
				<div className={style.serviceOpportunitiesContainer}>
					<Title title={"Наш сервис даёт  возможность развития вашего бизнеса"} styleName={"titleOpportunities"} />
					<p className={style.serviceOpportunitiesContainer__subtitle}>
						Закажите шаблон чат-бота под любую задачу вашего бизнеса. Здесь вы найдете только проверенные решения,
						созданные на основе нашего 5-летнего опыта. Мы собрали все, что вам нужно для:
					</p>
					<div className={style.opportunitiesListWrap}>{renderOpportunitiesList()}</div>
				</div>
			</div>

			<div className={style.howItWorks}>
				<Title title="Как это работает" styleName="titleService" />
				<div className={style.howItWorks__content}>
					<p className={style.description}>
						Чат-бот — это виртуальный онлайн-консультант в чате, который принимает обращения клиентов и отвечает им. Он
						может работать на сайте, в мессенджерах, в соцсетях. Боты берут на себя рутинные диалоги, освобождая время
						операторов для более интересных, сложных и творческих задач. Кроме того, чат-боты работают 24/7, без
						выходных и праздников{" "}
					</p>
					<img className={style.player} src={player} alt="player" />
				</div>
			</div>

			<div className={style.targetWrap}>
				<div className={style.targetTitlesWrap}>
					<div className={style.targetTitlesContainer}>
						<div className={style.infoWrap}>
							<Title title="Для кого подходит" styleName="targetTitle" />
							<p className={style.infoWrap__subtitle}>
								Для тех, кто не готов тратить время на исследования и самостоятельную настройку чат-бота в мессенджерах,
								а хочет:
							</p>
						</div>
					</div>
				</div>
				<div className={style.categoriesWrap}>
					{targetAudienceArr.map((item, index) => (
						<TargetAudience key={index} description={item.description} />
					))}
				</div>
			</div>
			<div className={style.sampleSlider}>
				<h3 className={style.sampleSlider__title}>Наши решения</h3>
				<div className={style.sampleSliderContainer}>{renderSolutionList()}</div>
				<Button content="Каталог" styleName="commonMaroonButton targetButton" onClick={handleNavigateSolutionCatalog} />
			</div>

			<div className={style.partners}>
				<Title title={"Наши партнёры"} styleName={"titleService"} />
				<div className={style.partners__container}>
					{partnersArr.map((item) => {
						return <PartnersCard partners={item.partners} key={crypto.randomUUID()} />;
					})}
				</div>
			</div>

			<section className={style.whyUsWrap}>
				<div className={style.whyUsContainer}>
					<div className={style.whyUsContent}>
						<Title title="Почему мы?" styleName="titleService" />
						<p className={style.whyUsContent__title}>
							Мы — агентство мессенджер-маркетинга Townsend. С 2018 года автоматизируем продажи, увеличиваем конверсию и
							эффективность бизнес-процессов, помогаем клиентам достичь нужных результатов с помощью чат-ботов в
							мессенджерах. Работаем {'"под ключ"'} на KPI в виде конверсий, заявок и продаж.
						</p>
						<p className={style.whyUsContent__title}>
							Предлагаем вам эффективные и гарантированно качественные шаблоны чат-ботов для быстрого и успешного
							решения задач бизнеса.
						</p>
						<p className={style.whyUsContent__title}>
							Главным преимуществом решений — является быстрый запуск. Вам не нужно тратить несколько недель или месяцев
							на разработку, тестирование, анализ, внесение правок. Мы даём вам готовые шаблоны, уже прошедшие все эти
							этапы и проверенные на наших клиентах в самых разных нишах. На сайте вы найдёте только те решения, которые
							уже не раз приносили результаты и гарантированно работают.
						</p>
						<p className={style.whyUsContent__title}>
							Определите, какие задачи вы хотите решить, какие возможности вам необходимы и выберите подходящий вариант.
							Большой выбор, удобная форма подбора с фильтром, разнообразие категорий помогут вам найти то, что нужно
							для вашего бизнеса.
						</p>
					</div>
				</div>
			</section>

			<div className={style.reviewsWrap}>
				<Title title="Истории наших клиентов" styleName="clientTitle" />
				<ReviewsSlider />
			</div>
			<div className={style.rate}>
				<Title title="Тарифы" styleName="titleService" />
				<div className={style.rate__card}>
					{priceArr.map((item) => (
						<Price
							key={crypto.randomUUID()}
							priceName={item.priceName}
							price={item.price}
							description={item.description}
						/>
					))}
				</div>
			</div>
			<div className={style.accordion__container}>
				<Title title="FAQ" styleName="titleService" />
				<Accordion accordionItems={accordionArr} />
			</div>
			<Button
				onClick={() => setIsOpened(true)}
				content="Оставить заявку"
				styleName="commonMaroonButton serviceModalAction"
			/>
		</div>
	);
};
