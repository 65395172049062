import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { basePlatformsEndpoint } from "./apiResourcesConstants";

export async function getCurrentPlatformData(platformId: number) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${basePlatformsEndpoint}/${platformId}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
