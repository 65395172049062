import { LoginData } from "../../../types/api/AuthApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { loginEndpoint } from "./apiConstants";

async function loginUser(loginData: LoginData) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${loginEndpoint}`, {
		method: "POST",
		body: JSON.stringify(loginData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}

export { loginUser };
