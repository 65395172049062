import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IDraftedPlatformsState, IAdminPlatformsData } from "../../../../../../types/redux/storeTypes";

import { getDraftedPlatformsData } from "./DraftedPlatformsThunk";

const draftedPlatformsInitialState: IDraftedPlatformsState = {
	draftedPlatforms: [],
	pageNumber: 0,
	platformsOnPage: 0,
	loading: false,
	error: null,
};

const DraftedPlatformsSlice = createSlice({
	name: "draftedPlatforms",
	initialState: draftedPlatformsInitialState,
	reducers: {
		clearDraftedPlatformsData: (state: IDraftedPlatformsState) => {
			state.draftedPlatforms = draftedPlatformsInitialState.draftedPlatforms;
		},
	},
	extraReducers(builder) {
		builder.addCase(
			getDraftedPlatformsData.fulfilled,
			(state: IDraftedPlatformsState, action: PayloadAction<IAdminPlatformsData>) => {
				state.draftedPlatforms = action.payload.content;
				state.pageNumber = action.payload.totalPages;
				state.platformsOnPage = action.payload.numberOfElements;
				state.loading = false;
			},
		);
		builder.addCase(
			getDraftedPlatformsData.rejected,
			(state: IDraftedPlatformsState, action: PayloadAction<string | unknown>) => {
				state.error = action.payload;
				state.loading = false;
			},
		);
		builder.addCase(getDraftedPlatformsData.pending, (state: IDraftedPlatformsState) => {
			state.loading = true;
		});
	},
});

export const { clearDraftedPlatformsData } = DraftedPlatformsSlice.actions;
export default DraftedPlatformsSlice.reducer;
