import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";

import SolutionFavoritesSlice from "./features/userPart/solutionFavorites/SolutionFavoritesSlice";
import PlatformFavoritesSlice from "./features/userPart/platformFavorites/PlatformFavoritesSlice";
import UserDataSlice from "./features/userPart/userData/UserDataSlice";
import SwitchSlice from "./features/userPart/switch/SwitchSlice";
import PublishedPlatformsSlice from "./features/adminPart/platforms/publishedPlatforms/PublishedPlatformsSlice";
import DraftedPlatformsSlice from "./features/adminPart/platforms/draftedPlatforms/DraftedPlatformsSlice";
import DeletedPlatformsSlice from "./features/adminPart/platforms/deletedPlatforms/DeletedPlatformsSlice";
import PublishedSolutionsSlice from "./features/adminPart/solutions/publishedSolutions/PublishedSolutionsSlice";
import DraftedSolutionsSlice from "./features/adminPart/solutions/draftedSolutions/DraftedSolutionsSlice";
import DeletedSolutionsSlice from "./features/adminPart/solutions/deletedSolutions/DeletedSolutionsSlice";
import PlatformCatalogSlice from "./features/userPart/platformCatalog/PlatformCatalogSlice";
import SolutionCatalogSlice from "./features/userPart/solutionCatalog/SolutionCatalogSlice";
import ResourceDataSlice from "./features/adminPart/resourceData/ResourceDataSlice";
import ComponentTogglerSlice from "./features/adminPart/componentToggler/ComponentTogglerSlice";
import SolutionHistorySlice from "./features/userPart/solutionHistory/SolutionHistorySlice";
import PlatformHistorySlice from "./features/userPart/platformHistory/PlatformHistorySlice";
import ResourceDataEditSlice from "./features/adminPart/resourceForEditPages/ResourceDataEditSlice";

import persistConfig from "./persist/persistConfig";
import PlatformFilterSlice from "./features/adminPart/platforms/platformsFilter/PlatformFilterSlice";

const RootReducer = combineReducers({
	SolutionFavoritesSlice,
	PlatformFavoritesSlice,
	UserDataSlice,
	SwitchSlice,
	PublishedPlatformsSlice,
	DraftedPlatformsSlice,
	DeletedPlatformsSlice,
	PublishedSolutionsSlice,
	DraftedSolutionsSlice,
	DeletedSolutionsSlice,
	PlatformCatalogSlice,
	SolutionCatalogSlice,
	ResourceDataSlice,
	ComponentTogglerSlice,
	SolutionHistorySlice,
	PlatformHistorySlice,
	PlatformFilterSlice,
	ResourceDataEditSlice,
});

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
		}),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export { store, persistor };
