import { useEffect } from "react";

import CatalogSolutions from "../../../../components/MainLayoutComponents/ResourceComponents/CatalogSolutions/CatalogSolutions";
import CatalogSwitch from "../../../../components/MainLayoutComponents/CatalogSwitch/CatalogSwitch";
import { CatalogRoutes } from "../../../../helpers/catalogHelpers";
import { Title } from "../../../../ui/Title/Title";

import styles from "./SolutionCatalogUnSignin.module.css";

function SolutionCatalogUnSignin() {
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<main className={styles.catalogUnsigninWrap}>
			<Title title="Каталог" styleName="titlePage" />
			<CatalogSwitch currentResource={CatalogRoutes.Solutions} isLogin={false} />
			<div className={styles.catalogUnsigninContent}>
				<CatalogSolutions />
			</div>
		</main>
	);
}

export default SolutionCatalogUnSignin;
