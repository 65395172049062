import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames/dedupe";

import useAppSelector from "../../../hooks/useAppSelector";

import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { adminNavIconArr } from "../../../helpers/adminNavIconArr";
import { Roles } from "../../../helpers/roles";

import exitNavArrow from "../../../assets/layouts/PersonalArea/NavIcon/exitNavArrow.svg";
import openArrow from "../../../assets/layouts/PersonalArea/NavIcon/openArrow.svg";

import styles from "./NavBarAdmin.module.css";

function NavBarAdmin() {
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [isSuperadmin, setIsSuperAdmin] = useState<boolean>(false);
	const [isModer, setIsModer] = useState<boolean>(false);
	const [shouldBeHidden, setShouldBeHidden] = useState<boolean>(false);

	const location = useLocation();
	const userData = useAppSelector(userDataSelector).userData;

	const filtersItemNumber: number = 3;
	const staffItemNumber: number = 4;
	const lastItemNumber: number = 5;

	useEffect(() => {
		const isPageForHiddenSidebar: boolean = ["platformPreview", "creation", "solutions/edit", "platforms/edit"].some(
			(substring) => location.pathname.includes(substring),
		);
		setShouldBeHidden(isPageForHiddenSidebar);
	}, [location.pathname]);

	useEffect(() => {
		if (userData?.roles) {
			setIsSuperAdmin(userData?.roles[0]?.name === Roles.SUPERADMIN);
			setIsModer(userData?.roles[0]?.name === Roles.MODERATOR);
		}
	}, [userData?.roles]);

	const handleChange = () => {
		setIsCheck(!isCheck);
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const getMenuItemList = () => {
		// eslint-disable-next-line sonarjs/cognitive-complexity
		return adminNavIconArr.map((item, index) => {
			if (index === lastItemNumber) {
				return (
					<Link style={{ textDecoration: "none" }} key={item.id} to={`${item.src}`}>
						<div
							className={cn(
								styles.creationMenuItem,
								{ [styles.menuItem__open]: isCheck },
								{ [styles.creationMenuItem__open]: isCheck },
							)}>
							<img src={item.icon} alt="img" className={styles.menuItem__imgElement} />
							{isCheck ? <p className={styles.menuItem__creationTitle}>{item.title}</p> : ""}
						</div>
					</Link>
				);
			}

			if (index === filtersItemNumber) {
				if (!isModer)
					return (
						<Link style={{ textDecoration: "none" }} key={item.id} to={`${item.src}`}>
							<div className={cn(styles.menuItem, { [styles.menuItem__open]: isCheck })}>
								<img src={location.pathname === item.src ? item.coloredIcon : item.icon} alt="img" />
								{isCheck ? (
									<p
										className={cn(styles.menuItem__title, {
											[styles.menuItem__title__visited]: location.pathname === item.src,
										})}>
										{item.title}
									</p>
								) : (
									""
								)}
							</div>
						</Link>
					);
				else return null;
			}

			if (index === staffItemNumber) {
				if (isSuperadmin)
					return (
						<Link style={{ textDecoration: "none" }} key={item.id} to={`${item.src}`}>
							<div className={cn(styles.menuItem, { [styles.menuItem__open]: isCheck })}>
								<img src={location.pathname === item.src ? item.coloredIcon : item.icon} alt="img" />
								{isCheck ? (
									<p
										className={cn(styles.menuItem__title, {
											[styles.menuItem__title__visited]: location.pathname === item.src,
										})}>
										{item.title}
									</p>
								) : (
									""
								)}
							</div>
						</Link>
					);
				else return null;
			}

			return (
				<Link style={{ textDecoration: "none" }} key={item.id} to={`${item.src}`}>
					<div className={cn(styles.menuItem, { [styles.menuItem__open]: isCheck })}>
						<img src={location.pathname === item.src ? item.coloredIcon : item.icon} alt="img" />
						{isCheck ? (
							<p
								className={cn(styles.menuItem__title, {
									[styles.menuItem__title__visited]: location.pathname === item.src,
								})}>
								{item.title}
							</p>
						) : (
							""
						)}
					</div>
				</Link>
			);
		});
	};

	return (
		<nav className={!shouldBeHidden ? styles.adminNavbarWrap : styles.adminNavbarWrap__hidden}>
			<div className={cn(styles.adminNavbarContainer, { [styles.adminNavbarContainer__open]: isCheck })}>
				<div className={styles.checkboxContainer} onClick={handleChange}>
					<div className={styles.pictureWrapper}>
						<img src={isCheck ? exitNavArrow : openArrow} className={styles.pictureWrapper__imgElement} alt="open" />
					</div>
					<input className={styles.checkboxElement} type="checkbox" />
				</div>
				{getMenuItemList()}
			</div>
		</nav>
	);
}

export default NavBarAdmin;
