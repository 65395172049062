import { TSolutionList, IPlatformResponse } from "../types/pages/Platform";
import {
	IEditSolutionFilters,
	IFilterChecked,
	ISolutionNames,
	ISolutionResponse,
} from "../types/pages/SolutionCatalog";

export const updateSelectedSolutions = (
	setSelectedSolutionIds: React.Dispatch<React.SetStateAction<number[]>> | undefined,
	solutionFilters: TSolutionList[] | undefined,
	checked?: boolean,
	checkedSolutionsCreation?: number[],
	checkedSolutions?: ISolutionNames[],
) => {
	if (checked) {
		setSelectedSolutionsChecked(setSelectedSolutionIds, checkedSolutionsCreation);
	} else {
		setSelectedSolutionsUnchecked(setSelectedSolutionIds, solutionFilters, checkedSolutions);
	}
};

const setSelectedSolutionsChecked = (
	setSelectedSolutionIds: React.Dispatch<React.SetStateAction<number[]>> | undefined,
	checkedSolutions?: number[],
) => {
	if (checkedSolutions && setSelectedSolutionIds) {
		setSelectedSolutionIds(checkedSolutions);
	}
};

const setSelectedSolutionsUnchecked = (
	setSelectedSolutionIds: React.Dispatch<React.SetStateAction<number[]>> | undefined,
	solutionFilters: TSolutionList[] | undefined,
	checkedSolutions?: ISolutionNames[],
) => {
	if (!checkedSolutions || checkedSolutions.length === 0) {
		setSelectedSolutionIds && setSelectedSolutionIds([]);
		return;
	}

	const filteredSolutions = solutionFilters?.filter((filter) =>
		checkedSolutions.find((checkedSolution) => checkedSolution.id === filter.id),
	);
	const updatedSelectedSolutions: number[] = filteredSolutions ? filteredSolutions.map((solution) => solution.id) : [];
	setSelectedSolutionIds && setSelectedSolutionIds(updatedSelectedSolutions);
};

export const updateSelectedFilters = (
	checkedFilters: IEditSolutionFilters[] | undefined,
	platformFilters: IPlatformResponse | undefined,
	solutionFilters: ISolutionResponse | undefined,
	setSelectedFilters: React.Dispatch<React.SetStateAction<IFilterChecked[]>>,
	checkedCreationFilter?: IFilterChecked[],
	checked?: boolean,
) => {
	if (checked) {
		checkedCreationFilter && setSelectedFilters(checkedCreationFilter);
	} else {
		if (!checkedFilters || checkedFilters.length === 0) {
			setSelectedFilters([]);
			return;
		}

		const updatedSelectedFilters: IFilterChecked[] = checkedFilters.reduce((acc, checkedFilter) => {
			const filter =
				platformFilters?.filters.find((filter) => filter.name === checkedFilter.name) ||
				solutionFilters?.filters.find((filter) => filter.name === checkedFilter.name);

			if (filter) {
				checkedFilter.values.forEach((value) => {
					const valueObj = filter.values.find((v) => v.value === value);
					if (valueObj) {
						acc.push({ filterId: filter.id, valueIds: [valueObj.id] });
					}
				});
			}
			return acc;
		}, [] as IFilterChecked[]);

		setSelectedFilters(updatedSelectedFilters);
	}
};

export const toggleFilterValue = (filters: IFilterChecked[], filterId: number, valueId: number): IFilterChecked[] => {
	const filterIndex = filters.findIndex((filter) => filter.filterId === filterId);

	if (filterIndex === -1) {
		return [...filters, { filterId, valueIds: [valueId] }];
	}

	const newFilters = [...filters];
	newFilters[filterIndex].valueIds = [...newFilters[filterIndex].valueIds, valueId];

	return newFilters;
};

export const removeFilterValue = (filters: IFilterChecked[], filterId: number, valueId: number): IFilterChecked[] => {
	return filters.map((filter) => {
		if (filter.filterId === filterId) {
			const newValueIds = filter.valueIds.filter((id) => id !== valueId);
			return { ...filter, valueIds: newValueIds };
		}
		return filter;
	});
};

export const handleRadioChange = (
	filterId: number,
	valueId: number,
	checked: boolean | undefined,
	setSelectedFilters: React.Dispatch<React.SetStateAction<IFilterChecked[]>>,
): void => {
	setSelectedFilters((prevFilters) => {
		if (checked) {
			const newFilters = prevFilters
				.filter((filter) => filter.filterId !== filterId)
				.map((filter) => {
					if (filter.filterId === filterId) {
						return { ...filter, valueIds: [] };
					}
					return filter;
				});

			return [...newFilters, { filterId, valueIds: [valueId] }];
		} else {
			return prevFilters.filter((filter) => filter.filterId !== filterId);
		}
	});
};

export const handleCheckBoxChange = (
	filterId: number,
	valueId: number,
	checked: boolean | undefined,
	setSelectedFilters: React.Dispatch<React.SetStateAction<IFilterChecked[]>>,
): void => {
	setSelectedFilters((prevFilters) => {
		if (checked) {
			return toggleFilterValue(prevFilters, filterId, valueId);
		} else {
			return removeFilterValue(prevFilters, filterId, valueId);
		}
	});
};
