import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { getCorrectCatalogLink } from "../../../utils/CatalogLink";
import { CatalogRoutes } from "../../../helpers/catalogHelpers";
import { Path } from "../../../services/router/routes";

import logo from "../../../assets/components/Footer/logoFooter.svg";
import { VkIcon } from "../../../assets/script/VkIcon";
import { PhoneIcon } from "../../../assets/script/PhoneIcon";
import { TelegramIcon } from "../../../assets/script/TelegramIcon";
import { MailIcon } from "../../../assets/script/MailIcon";
import { WhatsAppIcon } from "../../../assets/script/WhatsAppIcon";

import styles from "./Footer.module.css";

export const Footer = () => {
	const location = useLocation();
	const navigator = useNavigate();
	const userData = useAppSelector(userDataSelector).userData;
	const isLogin: boolean = !!userData?.email;

	const ratesPosition: number = 4920;

	async function linkToRates() {
		if (isLogin) {
			return null;
		}

		if (location.pathname !== Path.Main) {
			await navigator(Path.Main);
		}

		window.scrollTo({ top: ratesPosition, behavior: "smooth" });
	}

	function linkToAbout() {
		if (isLogin) {
			return null;
		}

		window.scrollTo({ top: 576, behavior: "smooth" });
	}

	return (
		<footer className={styles.footer}>
			<div className={styles.footeRow}>
				<div className={styles.footerColOne}>
					<p className={styles.footerCompanyName}>TownSend</p>
					<p className={styles.footerColOne__title}>
						Создавайте ботов любой сложности для популярных <br /> мессенджеров без программирования
					</p>
					<NavLink to={isLogin ? location.pathname : Path.Main}>
						<img src={logo} alt="logo" className={styles.logo} />
					</NavLink>
					<div className={styles.footerMedia}>
						<a className={styles.socialMediaLink} href="1">
							<VkIcon></VkIcon>
						</a>
						<a className={styles.socialMediaLink} href="2">
							<PhoneIcon></PhoneIcon>
						</a>
						<a className={styles.socialMediaLink} href="3">
							<TelegramIcon></TelegramIcon>
						</a>
						<a className={styles.socialMediaLink} href="4">
							<MailIcon></MailIcon>
						</a>
						<a className={styles.socialMediaLink} href="5">
							<WhatsAppIcon></WhatsAppIcon>
						</a>
					</div>
				</div>
				<div className={styles.footerColTwo}>
					<h2 className={styles.footerListBoldItem}>Продукт</h2>
					<ul className={styles.footerListColumnSide}>
						<li className={styles.footerListItem} data-testid="footerContacts">
							<Link to={!userData?.id ? Path.Contacts : Path.PersonalAreaContacts}>Связаться с нами</Link>
						</li>
						<li className={styles.footerListItem} data-testid="footerCatalog">
							<Link to={getCorrectCatalogLink(CatalogRoutes.Solutions, Boolean(userData?.email))}>
								{"Решения (боты)"}
							</Link>
						</li>
						<li className={styles.footerListItem} data-testid="footerPersonal">
							<Link to={getCorrectCatalogLink(CatalogRoutes.Platforms, Boolean(userData?.email))}>Платформы</Link>
						</li>
					</ul>
				</div>
				<div className={styles.footerColThree}>
					<h2 className={styles.footerListBoldItem}>Другое</h2>
					<ul className={styles.footerListColumnSide}>
						<li className={styles.footerListItem}>
							<NavLink to={isLogin ? location.pathname : Path.Main} onClick={linkToAbout}>
								О сервисе
							</NavLink>
						</li>
						<li className={styles.footerListItem} data-testid="footerBlog">
							<Link to={isLogin ? location.pathname : Path.Blog}>Блог</Link>
						</li>
						<button className={styles.footerListItem} onClick={linkToRates}>
							Тарифы
						</button>
					</ul>
				</div>
			</div>
			<ul className={styles.footerContacts}>
				<li>© 2022 Townsend Digital</li>
				<li>
					Санкт-Петербург, 196240, RU <span className={styles.footerContactsItem}>+79119547183</span>
				</li>
			</ul>
		</footer>
	);
};
