import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ISwitchInitialState } from "../../../../../types/redux/storeTypes";

const switchInitialState = {
	activeElement: "",
};

const SwitchSlice = createSlice({
	name: "favoriteSolutions",
	initialState: switchInitialState,
	reducers: {
		setIsActive: (state: ISwitchInitialState, action: PayloadAction<string>) => {
			state.activeElement = action.payload;
		},
	},
});

export const { setIsActive } = SwitchSlice.actions;
export default SwitchSlice.reducer;
