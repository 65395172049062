export const platformListErrorContent: string = "Ошибка загрузки списка платформ!";
export const solutionListErrorContent: string = "Ошибка загрузки списка решений!";

export enum CatalogTitles {
	Solutions = "Решения",
	Platforms = "Платформы",
}

export enum CatalogRoutes {
	Solutions = "solutions",
	Platforms = "platforms",
}

export enum PlatformCardTypes {
	CatalogType = 1,
	AdminType = 2,
}

export enum AdminCatalogRoutes {
	Published = "published",
	Drafted = "drafted",
	Deleted = "deleted",
}
