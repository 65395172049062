import { ISearchLineData } from "../../../types/api/AdminApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { gettingSearchedPlatformListEndpoint } from "./apiResourcesConstants";

export async function searchPlatforms(searchData: ISearchLineData) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${gettingSearchedPlatformListEndpoint}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(searchData),
		credentials: "include",
	});
}
