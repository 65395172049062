import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useModal from "../../../../hooks/useModal";

import { ISolutionsUserStatus } from "../../../../types/common/ComponentProps";
import { Button } from "../../../../ui/Button/Button";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { SolutionCard } from "../../../MainLayoutComponents/ResourceComponents/SolutionCard/SolutionCard";
import ModalHandelClose from "../../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { deletedSolutionsSelector } from "../../../../services/redux/features/adminPart/solutions/deletedSolutions/DeletedSolutionsSelector";
import { clearDeletedSolutions } from "../../../../services/redux/features/adminPart/solutions/deletedSolutions/DeletedSolutionsSlice";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath } from "../../../../services/router/routes";
import { getDeletedSolutions } from "../../../../services/redux/features/adminPart/solutions/deletedSolutions/DeletedSolutionsThunk";
import { MenuContent } from "../../../../helpers/ContextMenu";
import { AdminCatalogRoutes, solutionListErrorContent } from "../../../../helpers/catalogHelpers";
import {
	removeSolutionFromDeleted,
	restoreSolutionFromDeleted,
} from "../../../../services/api/admin/solutionsPageActions";

import style from "./DeletedSolutions.module.css";

export const DeletedSolutions = ({ isModerator }: ISolutionsUserStatus) => {
	const [toggleListUpdate, setToggleListUpdate] = useState(false);
	const resourceId = useAppSelector(ResourceDataSelector).currentResourceId;
	const userData = useAppSelector(userDataSelector).userData;
	const { deletedSolutions, deletedTotalPages, isLoading, error } = useAppSelector(deletedSolutionsSelector);
	const dispatch = useAppDispatch();
	const { isOpen, toggleModal } = useModal();
	const param = useParams();

	const activeSwitchElement: AdminCatalogRoutes = param?.pagepart as AdminCatalogRoutes;

	const requestParams = {
		offset: Number(param.page),
		limit: 9,
	};

	useEffect(() => {
		if (activeSwitchElement === AdminCatalogRoutes.Deleted) {
			dispatch(getDeletedSolutions(requestParams));
		}
		return () => {
			dispatch(clearDeletedSolutions());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, requestParams.offset, toggleListUpdate]);

	const deletedCategory = {
		adminList: [
			{ title: MenuContent.RECOVER, onClick: () => handleRestoreSolution() },
			{
				title: MenuContent.DELETE,
				onClick: () => toggleModal(),
			},
		],
		moderList: [],
	};

	const handleDeleteSolution = async () => {
		await removeSolutionFromDeleted(resourceId);
		toggleModal();
		setToggleListUpdate(!toggleListUpdate);
	};

	const handleRestoreSolution = async () => {
		await restoreSolutionFromDeleted(resourceId);
		setToggleListUpdate(!toggleListUpdate);
	};

	function renderSolutionList() {
		return !deletedSolutions || !deletedSolutions.length || isLoading ? (
			<h3>{error ? solutionListErrorContent : ""}</h3>
		) : (
			deletedSolutions.map((item) => (
				<SolutionCard
					key={item.id}
					id={item.id}
					title={item.name}
					shortDescription={item.shortDescription}
					logo={item.logo}
					role={userData.roles[0]?.name}
					menuContent={isModerator ? deletedCategory.moderList : deletedCategory.adminList}
				/>
			))
		);
	}

	return (
		<>
			<ModalHandelClose title="Удалить?" open={isOpen} toggle={toggleModal} isLeftAlignedTextHeader>
				<p className={style.modalDescription}>Удалённое из корзины невозможно восстановить</p>
				<Button styleName="commonMaroonButton solutionCatalogModal" content="Удалить" onClick={handleDeleteSolution} />
			</ModalHandelClose>
			<div className={style.solutionList}>{renderSolutionList()}</div>
			<PaginationElement
				currPage={Number(param.page) ?? 1}
				pageCount={deletedTotalPages}
				searchLine={`${AdminPath.Solutions}/${AdminCatalogRoutes.Deleted}`}
			/>
		</>
	);
};
