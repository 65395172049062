import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useModal from "../../../../hooks/useModal";

import { Title } from "../../../../ui/Title/Title";
import { Switch } from "../../../../ui/Switch/Switch";
import SolutionCreation from "../SolutionCreation/SolutionCreation";
import PlatformCreation from "../PlatformCreation/PlatformCreation";
import { setIsActive } from "../../../../services/redux/features/userPart/switch/SwitchSlice";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath, Path } from "../../../../services/router/routes";
import { Roles } from "../../../../helpers/roles";

import { ArrowBackIcon } from "../../../../assets/script/ArrowBack";

import styles from "./Creation.module.css";

const Creation = () => {
	const location = useLocation();
	const navigator = useNavigate();
	const activeElementFromUrl = location.search.includes("from=platformCreation") ? "Платформы" : "Решения";
	const activeElement = useAppSelector(switchSelector).activeElement || activeElementFromUrl;
	const userData = useAppSelector(userDataSelector).userData;
	const [isNameEmpty, setIsNameEmpty] = useState<string>("");
	const { toggleModal, isOpen } = useModal();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.Creation) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(setIsActive(activeElement));
	}, [activeElement, dispatch]);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	function renderActivePagePart() {
		return activeElement === "Решения" ? (
			<SolutionCreation toggleModal={toggleModal} isOpen={isOpen} setIsNameEmpty={setIsNameEmpty} />
		) : (
			<PlatformCreation toggleModal={toggleModal} isOpen={isOpen} setIsNameEmpty={setIsNameEmpty} />
		);
	}

	return (
		<div className={styles.creationPage}>
			<div className={styles.creationPageHead}>
				<button
					className={styles.backButton}
					onClick={isNameEmpty === "" ? () => navigator(-1) : toggleModal}
					type="button">
					<ArrowBackIcon />
				</button>
				<Title title="Создание" styleName="titlePage" />
				<span className={styles.invisible}></span>
			</div>
			<div className={styles.switcher}>
				<Switch switchContent={["Решения", "Платформы"]} />
			</div>
			{renderActivePagePart()}
		</div>
	);
};
export default Creation;
