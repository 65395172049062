import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCorrectBaseUrl } from "../../../../../../utils/baseUrlConverter";
import { IParams } from "../../../../../../types/api/AdminApiTypes";
import { solutionsPublishedEndpoint } from "../../../../../api/admin/apiAdminConstants";

const baseURL = getCorrectBaseUrl("ps");

export const getPublishedSolutions = createAsyncThunk("getPublishedSolutions", async (params: IParams, thunkAPI) => {
	try {
		const response = await fetch(
			`${baseURL}/${solutionsPublishedEndpoint}?offset=${params.offset - 1}&limit=${params.limit}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			},
		);
		return await response.json();
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
