import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import useAppSelector from "../../../hooks/useAppSelector";

import { Button } from "../../../ui/Button/Button";
import { HistoryLink } from "../HistoryLink/HistoryLink";
import ModalPersonalAreaHeader from "../../PersonalAreaLayoutComponents/ModalPersonalAreaHeader/ModalPersonalAreaHeader";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { getCorrectCatalogLink } from "../../../utils/CatalogLink";
import { Path } from "../../../services/router/routes";
import { CatalogRoutes } from "../../../helpers/catalogHelpers";

import headerArrow from "../../../assets/components/PersonalAreaHeader/ArrowHeader.svg";
import logo from "../../../assets/layouts/Main/logo.png";

import userModalStyles from "../../PersonalAreaLayoutComponents/PersonalAreaHeader/PersonalAreaHeader.module.css";

import styles from "./Header.module.css";

export const Header = () => {
	const [toggler, setToggler] = useState<boolean>(false);
	const [userInitials, setUserInitials] = useState<string>("");
	const navigator = useNavigate();
	const location = useLocation();
	const userData = useAppSelector(userDataSelector).userData;
	const [shouldBeActive, setShouldBeActive] = useState<boolean>(false);

	const ratesPosition: number = 4920;

	useEffect(() => {
		if (!userData?.lastname) {
			return setUserInitials(`${userData?.firstname.at(0)}`);
		}

		setUserInitials(`${userData?.firstname.at(0)}${userData?.lastname.at(0)}`);
	}, [userData]);

	useEffect(() => {
		const isPageForHiddenSidebar: boolean = [Path.SolutionCatalogUnSignin, Path.PlatformCatalogUnSignin].some(
			(substring) => location.pathname.includes(substring),
		);
		setShouldBeActive(isPageForHiddenSidebar);
	}, [location.pathname]);

	async function linkToRates() {
		if (location.pathname !== Path.Main) {
			await navigator(Path.Main);
		}

		window.scrollTo({ top: ratesPosition, behavior: "smooth" });
	}

	return (
		<header className={styles.header}>
			<NavLink to={Path.Main}>
				<img src={logo} alt="logo" className={styles.logo} />
			</NavLink>
			<nav className={styles.navigationWrap}>
				<ul className={styles.navigation}>
					<li>
						<NavLink to={Path.Main} className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}>
							Главная
						</NavLink>
					</li>
					<li>
						<NavLink
							to={Path.Main}
							onClick={() => window.scrollTo({ top: 576, behavior: "smooth" })}
							className={styles.link}>
							О сервисе
						</NavLink>
					</li>
					<li>
						<NavLink
							to={getCorrectCatalogLink(CatalogRoutes.Solutions, Boolean(userData?.email))}
							className={shouldBeActive ? styles.activeLink : styles.link}>
							Каталог
						</NavLink>
					</li>
					<li>
						<button className={styles.link} onClick={linkToRates}>
							Тарифы
						</button>
					</li>
					<li>
						<NavLink
							to={!userData?.id ? Path.Contacts : Path.PersonalAreaContacts}
							className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}>
							Связаться с нами
						</NavLink>
					</li>
					<li>
						<NavLink to={Path.Blog} className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}>
							Блог
						</NavLink>
					</li>
				</ul>
			</nav>
			<div className={!userData?.email ? styles.buttonSetWrap : styles.buttonSetWrap__hidden}>
				<HistoryLink to={Path.Login}>
					<Button content="Войти" styleName="commonWhiteMaroonButton signInHeaderAction" />
				</HistoryLink>
				<HistoryLink to={Path.Signup}>
					<Button content="Регистрация" styleName="commonMaroonButton signUpHeaderAction" />
				</HistoryLink>
			</div>
			<div className={userData?.email ? styles.userProfileWrap : styles.userProfileWrap__hidden}>
				<div onClick={() => setToggler(!toggler)} className={userModalStyles.user__container}>
					<div className={styles.userName}>{userInitials} </div>
					<img
						className={classNames(userModalStyles.headerArrow, { [userModalStyles.headerArrowOpen]: toggler })}
						src={headerArrow}
						alt="arrow user modal"
					/>
				</div>
				<div
					className={classNames(
						userModalStyles.modal__container,
						{ [userModalStyles.modal__container__open]: toggler },
						styles.modalWrap,
					)}>
					<ModalPersonalAreaHeader />
				</div>
			</div>
		</header>
	);
};
