import { IFilterTypesGroup } from "../../../../../types/common/ComponentProps";
import { IPlatformFilters } from "../../../../../types/pages/Platform";
import { ISolutionFilters } from "../../../../../types/pages/SolutionCatalog";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import Radio from "../../../../../ui/Radiator/Radio";
import { handleCheckBoxChange, handleRadioChange } from "../../../../../utils/CheckBoxBlockUtils";

import styles from "./FilterTypes.module.css";

const FilterTypes = ({ filters, selectedFilters, setSelectedFilters, type }: IFilterTypesGroup) => {
	const renderFilterItems = (item: ISolutionFilters | IPlatformFilters) => {
		return item.values.map((content) => (
			<div key={`${item.id}-${content.id}`} className={styles.checkBoxItem}>
				{item.multipleChoice ? (
					<CheckBox
						label={content.value}
						checked={selectedFilters.some(
							(filter) => filter.filterId === item.id && filter.valueIds.includes(content.id),
						)}
						key={`${item.id}-${content.id}`}
						onChange={(newState) => handleCheckBoxChange(item.id, content.id, newState, setSelectedFilters)}
					/>
				) : (
					<Radio
						label={content.value}
						checked={selectedFilters.some(
							(filter) => filter.filterId === item.id && filter.valueIds.includes(content.id),
						)}
						key={`${item.id}-${content.id}`}
						onChange={(newState) => handleRadioChange(item.id, content.id, newState, setSelectedFilters)}
					/>
				)}
			</div>
		));
	};

	return (
		<div className={type === "Решения" ? styles.filterBlockSolution : styles.filterBlockPlatform}>
			{filters?.filters.map((item) => (
				<div key={item.id} className={styles.filter}>
					<p className={styles.filterTitle}>{item.name}:</p>
					{renderFilterItems(item)}
					<hr className={styles.hr} />
				</div>
			))}
		</div>
	);
};

export default FilterTypes;
