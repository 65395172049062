import { BaseSyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";

import useModal from "../../../hooks/useModal";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

import { Button } from "../../../ui/Button/Button";
import ModalHandelClose from "../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { userLogout } from "../../../services/redux/features/userPart/userData/UserDataSlice";
import { logoutUser } from "../../../services/api/auth/logoutUser";
import { clearDeletedPlatformsData } from "../../../services/redux/features/adminPart/platforms/deletedPlatforms/DeletedPlatformsSlice";
import { clearDraftedPlatformsData } from "../../../services/redux/features/adminPart/platforms/draftedPlatforms/DraftedPlatformsSlice";
import { clearPublishedPlatformsData } from "../../../services/redux/features/adminPart/platforms/publishedPlatforms/PublishedPlatformsSlice";
import { Path } from "../../../services/router/routes";

import styles from "./ModalAdminHeader.module.css";

const ModalAdminHeader = () => {
	const navigator = useNavigate();
	const { isOpen, toggleModal } = useModal();
	const dispatch = useAppDispatch();
	const userData = useAppSelector(userDataSelector).userData;

	async function logOut(event?: BaseSyntheticEvent) {
		event?.preventDefault();
		await logoutUser();
		dispatch(userLogout());
		dispatch(clearDeletedPlatformsData());
		dispatch(clearDraftedPlatformsData());
		dispatch(clearPublishedPlatformsData());
		return navigator(Path.Main);
	}

	return (
		<div className={styles.modalHeaderWrap}>
			<ModalHandelClose open={isOpen} toggle={toggleModal} title="Покинуть админ. панель?">
				<div className={styles.buttonsWrapper}>
					<Button
						onClick={() => {
							logOut();
							toggleModal();
						}}
						content="Выйти"
						styleName="commonMaroonButton closeHeaderModalAction"
					/>
					<Button content="Отмена" onClick={toggleModal} styleName="commonWhiteMaroonButton cancelModalHandleClose" />
				</div>
			</ModalHandelClose>
			<div className={styles.modalHeaderWrap__userDataContainer}>
				<p className={styles.email}>{userData?.email}</p>
			</div>
			<div className={styles.toolbar}>
				<button className={styles.logoutAction} onClick={toggleModal}>
					Вернуться на сайт
				</button>
			</div>
		</div>
	);
};

export default ModalAdminHeader;
