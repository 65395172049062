import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IPlatformsUserStatus } from "../../../../types/common/ComponentProps";
import { IContextModalPositionState, IContextMenuItem } from "../../../../types/ui/ContextMenuModal";
import PlatformCatalogCard from "../../../MainLayoutComponents/ResourceComponents/PlatformCatalogCard/PlatformCatalogCard";
import ContextMenuModal from "../../../../ui/ContextMenuModal/ContextMenuModal";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { Button } from "../../../../ui/Button/Button";
import ModalHandelClose from "../../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { getDeletedPlatformsData } from "../../../../services/redux/features/adminPart/platforms/deletedPlatforms/DeletedPlatformsThunk";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import DeletedPlatformsSelector from "../../../../services/redux/features/adminPart/platforms/deletedPlatforms/DeletedPlatformsSelector";
import { clearDeletedPlatformsData } from "../../../../services/redux/features/adminPart/platforms/deletedPlatforms/DeletedPlatformsSlice";
import { setResourceId } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import { AdminCatalogRoutes, PlatformCardTypes, platformListErrorContent } from "../../../../helpers/catalogHelpers";
import { AdminPath } from "../../../../services/router/routes";
import { defaultContextMenuPosition } from "../../../../helpers/ContextMenu";
import {
	restorePlatformFromDeleted,
	removePlatformFromDeleted,
} from "../../../../services/api/admin/platformsPageActions";
import {
	PlatformAdminContextMenuItems,
	contextMenuDivideWidthValue,
	firstElementTopPosition,
	fullHdLeftPosition,
	hdLeftPosition,
	platformCardsDifferencePosition,
} from "../../../../helpers/platformAdmin";

import styles from "./DeletedPlatforms.module.css";

function DeletedPlatforms({ isModerator }: IPlatformsUserStatus) {
	const [toggleContextModal, setToggleContextModal] = useState<boolean>(false);
	const [toggleListUpdate, setToggleListUpdate] = useState<boolean>(false);
	const [contextModalPosition, setContextModalPosition] =
		useState<IContextModalPositionState>(defaultContextMenuPosition);
	const [currentModalName, setCurrentModalName] = useState<string | undefined>(undefined);
	const [isPaginationHidden, setIsPaginationHidden] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	const params = useParams();
	const navigator = useNavigate();
	const dispatch = useAppDispatch();
	const platformId = useAppSelector(ResourceDataSelector).currentResourceId;
	const { deletedPlatforms, pageNumber, loading, platformsOnPage, error } = useAppSelector(DeletedPlatformsSelector);

	const ModalName: string = "modalRemove";
	const activeSwitchElement = params?.pagepart as AdminCatalogRoutes;

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (activeSwitchElement === AdminCatalogRoutes.Deleted) {
			dispatch(getDeletedPlatformsData(Number(params.page)));
		}

		return () => {
			dispatch(clearDeletedPlatformsData());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, dispatch, params.page, toggleListUpdate]);

	const contextMenuItems: IContextMenuItem[] = [
		{
			title: PlatformAdminContextMenuItems.Recover,
			onClick: () => handleRestoringAction(),
		},
		{
			title: PlatformAdminContextMenuItems.Delete,
			onClick: () => openRemovePlatformModal(),
		},
	];

	function toggleContextMenuWindow(cardPosition: number) {
		if (isModerator) {
			return null;
		}
		const top: number = firstElementTopPosition + platformCardsDifferencePosition * cardPosition;
		const left: number =
			windowWidth * (windowWidth > contextMenuDivideWidthValue ? fullHdLeftPosition : hdLeftPosition);
		setContextModalPosition({ top, left });
		setToggleContextModal(!toggleContextModal);
	}

	const closeCurrentModal = () => {
		dispatch(setResourceId(0));
		return setCurrentModalName(undefined);
	};

	const closeModalAfterAction = async () => {
		dispatch(setResourceId(0));
		setToggleContextModal(false);
		await setCurrentModalName(undefined);
		if (platformsOnPage === 1) {
			if (Number(params?.page) === 1) {
				setToggleListUpdate(!toggleListUpdate);
				return setIsPaginationHidden(!isPaginationHidden);
			}
			return navigator(AdminPath.Platforms + `/${Number(params.page) - 1}`);
		}
		return setToggleListUpdate(!toggleListUpdate);
	};

	const openRemovePlatformModal = () => {
		return setCurrentModalName(ModalName);
	};

	const handleRestoringAction = async () => {
		await restorePlatformFromDeleted(platformId);
		return closeModalAfterAction();
	};

	const handleRemovingAction = async () => {
		await removePlatformFromDeleted(platformId);
		return closeModalAfterAction();
	};

	return (
		<section className={styles.deletedPlatformsWrap}>
			{!deletedPlatforms || !deletedPlatforms.length || loading ? (
				<h3>{error ? platformListErrorContent : ""}</h3>
			) : (
				deletedPlatforms.map((platformData, index) => (
					<PlatformCatalogCard
						type={PlatformCardTypes.AdminType}
						title={platformData.name}
						cardId={platformData.id}
						description={platformData.shortDescription}
						solutionNumber={platformData.totalSolution ?? 0}
						icon={platformData.logoUrl}
						key={platformData.id ?? index}
						toggleContextMenu={(event) => toggleContextMenuWindow(index)}
						isHiddenDropDownMenu={isModerator}
					/>
				))
			)}
			{!isPaginationHidden ? (
				<PaginationElement
					currPage={Number(params.page ?? 1)}
					pageCount={pageNumber}
					searchLine={`${AdminPath.Platforms}/${AdminCatalogRoutes.Deleted}`}
				/>
			) : (
				<></>
			)}
			{toggleContextModal && !isModerator && (
				<ContextMenuModal
					content={contextMenuItems}
					position={contextModalPosition}
					closeContextModal={() => setToggleContextModal(!toggleContextModal)}
				/>
			)}
			<ModalHandelClose
				title="Удалить?"
				open={currentModalName === ModalName}
				toggle={closeCurrentModal}
				isLeftAlignedTextHeader={true}>
				<p className={styles.modalTitle}>Удалённое из корзины невозможно восстановить</p>
				<Button content="Удалить" styleName="commonMaroonButton platformsAdminRemove" onClick={handleRemovingAction} />
			</ModalHandelClose>
		</section>
	);
}

export default DeletedPlatforms;
