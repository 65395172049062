import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAdminSolutionsData, IDeletedSolutionsState } from "../../../../../../types/redux/storeTypes";

import { getDeletedSolutions } from "./DeletedSolutionsThunk";

const deletedSolutionsInitialState: IDeletedSolutionsState = {
	deletedTotalPages: 0,
	deletedSolutions: [],
	isLoading: false,
	error: null,
};

const DeletedSolutions = createSlice({
	name: "deletedSolutions",
	initialState: deletedSolutionsInitialState,
	reducers: {
		clearDeletedSolutions: (state) => {
			state.deletedSolutions = [];
			state.deletedTotalPages = 0;
		},
	},
	extraReducers(builder) {
		builder.addCase(getDeletedSolutions.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getDeletedSolutions.fulfilled, (state, action: PayloadAction<IAdminSolutionsData>) => {
			state.deletedTotalPages = action.payload.totalPages;
			state.deletedSolutions = action.payload.content;
			state.isLoading = false;
		});
		builder.addCase(getDeletedSolutions.rejected, (state, action: PayloadAction<string | unknown>) => {
			state.error = action.payload;
			state.isLoading = false;
		});
	},
});

export const { clearDeletedSolutions } = DeletedSolutions.actions;
export default DeletedSolutions.reducer;
