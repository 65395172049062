import cn from "classnames/dedupe";

import { useScrollLock } from "../../../hooks/useScrollLock";

import { IModalHandelClose } from "../../../types/common/ComponentProps";

import styles from "./ModalHandelClose.module.css";

export default function ModalHandelClose({
	open,
	toggle,
	title,
	message,
	children,
	isLeftAlignedTextHeader,
}: IModalHandelClose) {
	const lockScroll = useScrollLock(open);

	lockScroll();

	return (
		<>
			<dialog open={open} className={styles.modalWrapper}>
				<div onClick={(e) => e.stopPropagation()} className={styles.modalContainer}>
					<div className={styles.cross} onClick={toggle}>
						{" "}
					</div>
					<h3 className={cn(styles.modalTitle, { [styles.modalTitle__leftAligned]: isLeftAlignedTextHeader })}>
						{title}
					</h3>
					<p className={styles.modalMessage}>{message}</p>
					<div className={styles.childrenElementsWrap}>{children}</div>
				</div>
			</dialog>
		</>
	);
}
