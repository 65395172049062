import { IEditSolution } from "../../../types/pages/SolutionCatalog";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { createSolution } from "./apiAdminConstants";

export async function createSolutions(inputData: IEditSolution) {
	const baseURL = getCorrectBaseUrl("ps");
	const data = {
		name: inputData.name,
		logo: inputData.logo,
		description: inputData.description,
		shortDescription: inputData.shortDescription,
		cost: inputData.cost,
		filters: inputData.filters,
		platformsNames: inputData.platformsNames,
	};

	return await fetch(`${baseURL}/${createSolution}`, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
		},
	});
}
