import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";

import { DataList } from "../../../types/common/ComponentProps";
import { Title } from "../../../ui/Title/Title";
import { Switch } from "../../../ui/Switch/Switch";
import SampleSlider from "../../../components/MainLayoutComponents/SampleSlider/SampleSlider";
import DashboardCard from "../../../components/PersonalAreaLayoutComponents/DashboardCard/DashboardCard";

import { setIsActive } from "../../../services/redux/features/userPart/switch/SwitchSlice";
import { getSolutionFavorites } from "../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesThunk";
import { getPlatformFavorites } from "../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesThunk";
import switchSelector from "../../../services/redux/features/userPart/switch/SwitchSelector";
import { solutionHistorySelector } from "../../../services/redux/features/userPart/solutionHistory/SolutionHistorySelector";
import { platformHistorySelector } from "../../../services/redux/features/userPart/platformHistory/PlatformHistorySelector";
import { platformFavoritesSelector } from "../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesSelector";
import { solutionFavoritesSelector } from "../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { getSolutionHistory } from "../../../services/redux/features/userPart/solutionHistory/SolutionHistoryThunk";
import { getPlatformHistory } from "../../../services/redux/features/userPart/platformHistory/PlatformHistoryThunk";
import { Path } from "../../../services/router/routes";

import { getCorrectCatalogLink } from "../../../utils/CatalogLink";
import { CatalogRoutes } from "../../../helpers/catalogHelpers";
import { Roles } from "../../../helpers/roles";

import bulb from "../../../assets/pages/PersonalAreaPage/light-bulb.svg";
import carbonPlatform from "../../../assets/pages/PersonalAreaPage/carbon_platform.svg";

import style from "./PersonalArea.module.css";

const PersonalArea = () => {
	const [favoriteList, setFavoriteList] = useState<DataList>([]);
	const [historyList, setHistoryList] = useState<DataList>([]);
	const [currentListName, setCurrentListName] = useState<string>("");

	const switchActiveElement = useAppSelector(switchSelector).activeElement;
	const isLogin = useAppSelector(userDataSelector).userData?.email;
	const solutionHistory = useAppSelector(solutionHistorySelector);
	const solutionFavorite = useAppSelector(solutionFavoritesSelector);
	const platformHistory = useAppSelector(platformHistorySelector);
	const platformFavorite = useAppSelector(platformFavoritesSelector);
	const navigator = useNavigate();
	const dispatch = useAppDispatch();

	const switchContent = ["Решения", "Платформы"];

	const favoriteErrorContent: string = "Ошибка загрузки списка избранного";
	const historyErrorContent: string = "Ошибка загрузки истории просмотра";

	const paramsForFavorites = {
		offset: 1,
		limit: 20,
	};

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (!isLogin) {
			navigator(Path.Login);
		}
		!localStorage.getItem("switchElement")
			? dispatch(setIsActive(switchContent[0]))
			: dispatch(setIsActive(localStorage.getItem("switchElement") || ""));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		localStorage.setItem("switchElement", switchActiveElement);
		if (switchActiveElement === switchContent[0]) getSolutions();
		if (switchActiveElement === switchContent[1]) getPlatforms();
		return () => {
			localStorage.setItem("switchElement", "");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [switchActiveElement]);

	useEffect(() => {
		setCurrentLists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentListName]);

	useEffect(() => {
		setCurrentLists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [solutionFavorite, platformFavorite]);

	const setCurrentLists = () => {
		switch (currentListName) {
			case switchContent[0]:
				setFavoriteList(solutionFavorite.favoriteSolutions);
				setHistoryList(solutionHistory.historySolutions);
				break;
			case switchContent[1]:
				setFavoriteList(platformFavorite.favoritePlatforms);
				setHistoryList(platformHistory.historyPlatforms);
				break;
		}
	};

	const getSolutions = async () => {
		await dispatch(getSolutionFavorites(paramsForFavorites));
		await dispatch(getSolutionHistory());
		setCurrentListName(switchContent[0]);
	};

	const getPlatforms = async () => {
		await dispatch(getPlatformFavorites(paramsForFavorites));
		await dispatch(getPlatformHistory());
		setCurrentListName(switchContent[1]);
	};

	const dashboardCard = [
		{ id: 0, icon: bulb, link: getCorrectCatalogLink(CatalogRoutes.Solutions, true), text: "Подобрать решение" },
		{
			id: 1,
			icon: carbonPlatform,
			link: getCorrectCatalogLink(CatalogRoutes.Platforms, true),
			text: "Подобрать платформу",
		},
	];

	function renderDashboardCard() {
		return dashboardCard.map((item) => {
			return <DashboardCard key={item.id} text={item.text} id={item.id} icon={item.icon} link={item.link} />;
		});
	}

	function renderCorrectFavoriteList() {
		const isPlatformFavorite = "totalSolution" in favoriteList;

		const getCorrectElementsPerSlideCount = () => {
			return isPlatformFavorite ? 1 : favoriteList.length > 1 ? 2 : 1;
		};

		return !favoriteList.length ? (
			<p className={style.chatListMessage}>
				{solutionFavorite.error || platformFavorite.error ? favoriteErrorContent : ""}
			</p>
		) : (
			<SampleSlider
				cardList={favoriteList}
				solutionsPerSlide={getCorrectElementsPerSlideCount()}
				interval={70}
				role={Roles.USER}
			/>
		);
	}

	function renderCorrectHistoryList() {
		return !historyList.length ? (
			<p className={style.chatListMessage}>
				{solutionHistory.error || platformHistory.error ? historyErrorContent : ""}
			</p>
		) : (
			<SampleSlider
				cardList={historyList}
				solutionsPerSlide={historyList.length > 1 ? 2 : 1}
				interval={70}
				role={Roles.USER}
			/>
		);
	}

	return (
		<div className={style.personalArea}>
			<div className={style.personalArea__container}>
				<Title title="Личный кабинет" styleName="titlePage" />
				<Switch switchContent={switchContent} />
				<div className={style.dashboardCard__container}>{renderDashboardCard()}</div>
				<div className={style.chatList}>
					<h3>Избранное:</h3>
					{renderCorrectFavoriteList()}
				</div>
				<div className={style.chatList}>
					<h3>История просмотра:</h3>
					{renderCorrectHistoryList()}
				</div>
			</div>
		</div>
	);
};

export default PersonalArea;
