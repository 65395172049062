import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { SendUserOrder } from "../../../types/api/AuthApiTypes";

import { sendOrderEndpoint } from "./apiConstants";

export async function sendUserOrder(orderData: SendUserOrder) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${sendOrderEndpoint}`, {
		method: "POST",
		body: JSON.stringify(orderData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
