import { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IEditSolution, IFilterChecked, IToggle } from "../../../../types/pages/SolutionCatalog";
import { IUnsavedSolution } from "../../../../types/components/DescriptionSolution";
import { Button } from "../../../../ui/Button/Button";
import FieldsToFill from "../../../../components/AdminLayoutComponents/CreationComponents/FieldsToFill/FieldsToFill";
import CheckBoxBlock from "../../../../components/AdminLayoutComponents/CreationComponents/CheckBoxBlock/CheckBoxBlock";
import ButtonsSubmit from "../../../../components/AdminLayoutComponents/CreationComponents/ButtonsSubmit/ButtonsSubmit";
import ModalHandelClose from "../../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { createSolutions } from "../../../../services/api/admin/createSolution";
import { AdminPath } from "../../../../services/router/routes";
import { setSolutionData } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import PlatformFilterSelector from "../../../../services/redux/features/adminPart/platforms/platformsFilter/PlatformFilterSelector";
import { setSelectedFilter } from "../../../../services/redux/features/adminPart/platforms/platformsFilter/PlatformFilterSlice";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import { moveSolutionToPublished } from "../../../../services/api/admin/solutionsPageActions";

import styles from "./SolutionCreation.module.css";

const SolutionCreation = ({ toggleModal, isOpen, setIsNameEmpty }: IToggle) => {
	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IEditSolution>({
		defaultValues: {
			name: "",
			logo: "",
			description: "",
			shortDescription: "",
			cost: 0,
		},
		mode: "all",
		delayError: 200,
	});

	const [imageUrl, setImageUrl] = useState<string | undefined>("");
	const [selectedFilters, setSelectedFilters] = useState<IFilterChecked[]>([]);
	const selectedFilter = useAppSelector(PlatformFilterSelector).selectedFilters;
	const unsavedSolution = useAppSelector(ResourceDataSelector).currentSolutionData;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const maxFileSize: number = 1024;
	const minImageWidth: number = 808;
	const maxImageWidth: number = 1920;
	const minImageHight: number = 632;
	const maxImageHight: number = 1080;

	useEffect(() => {
		setIsNameEmpty(control._formValues?.name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [control._formValues?.name]);

	useEffect(() => {
		unsavedSolution && setValue("shortDescription", unsavedSolution?.shortDescription?.trim() || "");
		unsavedSolution && setValue("name", unsavedSolution.name?.trim() || "");
		unsavedSolution && setValue("description", unsavedSolution?.description?.trim() || "");
		unsavedSolution && setValue("cost", unsavedSolution?.cost || 0);
		unsavedSolution && setValue("logo", unsavedSolution?.logo || "");
		unsavedSolution && setImageUrl(unsavedSolution.logo || "");
	}, [unsavedSolution, setValue]);

	const setDefaultValues = () => {
		setValue("name", "");
		setValue("logo", "");
		setValue("description", "");
		setValue("shortDescription", "");
		setValue("cost", 0);
		setValue("logo", "");
		setSelectedFilters([]);
		setImageUrl("");
		dispatch(setSelectedFilter([]));
		dispatch(setSolutionData({ name: "" }));
	};

	const unpublishedCreate: SubmitHandler<IEditSolution> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};

		const updateSolution = {
			...trimmedData,
			filters: selectedFilters,
		};
		const create = await createSolutions(updateSolution);
		if (create.ok) {
			setDefaultValues();
			navigate(`${AdminPath.Solutions}/drafted/1`);
		}
	};

	const groupFilters = (filters: IFilterChecked[]): IFilterChecked[] => {
		const groupedFilters: Record<number, number[]> = {};

		filters.forEach((filter) => {
			if (!groupedFilters[filter.filterId]) {
				groupedFilters[filter.filterId] = [];
			}
			groupedFilters[filter.filterId] = [...[...groupedFilters[filter.filterId], ...filter.valueIds]];
		});

		return Object.entries(groupedFilters).map(([filterId, valueIds]) => ({
			filterId: parseInt(filterId),
			valueIds,
		}));
	};

	const setDataForSolutionPreviewPage = async (updateSolution: IUnsavedSolution) => {
		return await dispatch(setSolutionData(updateSolution));
	};

	const readImage = (file: File) => {
		const reader = new FileReader();
		reader.onload = () => {
			const imageDataUrl = reader.result as string;
			const img = new Image();
			img.src = imageDataUrl;
			img.onload = () => {
				if (isValidSize(img.width, img.height)) {
					setImageAndValue(imageDataUrl);
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const isValidSize = (width: number, height: number) => {
		return width >= minImageWidth && width <= maxImageWidth && height >= minImageHight && height <= maxImageHight;
	};

	const setImageAndValue = (imageDataUrl: string) => {
		setImageUrl(imageDataUrl);
		setValue("logo", imageDataUrl);
	};

	const handleUnsupportedType = () => {
		setValue("logo", "");
	};

	const handlePreviewNavigate: SubmitHandler<IEditSolution> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");

		const updateSolution: IUnsavedSolution = {
			name: data.name.trim(),
		};

		if (data.logo) {
			updateSolution.logo = data.logo;
		}
		if (data.cost) {
			updateSolution.cost = Number(data.cost);
		}
		if (data.shortDescription) {
			updateSolution.shortDescription = data.shortDescription.trim();
		}
		if (data.description) {
			updateSolution.description = data.description.trim();
		}
		if (data.platformsNames) {
			updateSolution.platformsNames = data.platformsNames;
		}
		dispatch(setSelectedFilter(groupFilters(selectedFilters)));
		await setDataForSolutionPreviewPage(updateSolution);

		navigate(`${AdminPath.SolutionPreview}/0?from=solutionCreation`);
	};

	const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const file = e.target.files && e.target.files[0];

		if (!file) return;
		if (file) {
			const allowedImageTypes = ["image/jpeg", "image/png", "image/webp"];
			if (allowedImageTypes.includes(file.type)) {
				if (file.size <= maxFileSize * maxFileSize) {
					readImage(file);
				}
			} else {
				handleUnsupportedType();
			}
		}
	};

	const onSubmit: SubmitHandler<IEditSolution> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};

		const updateSolution = {
			...trimmedData,
			filters: selectedFilters,
		};
		await createSolutions(updateSolution).then(async (value) => {
			const jsonResponse = await value.json();
			const list = await moveSolutionToPublished(jsonResponse);
			if (list.ok) {
				setDefaultValues();
				navigate(`${AdminPath.Solutions}/published/1`);
			}
		});
	};

	return (
		<form>
			<div className={styles.fields}>
				<FieldsToFill
					control={control}
					imageText="Загрузить изображение"
					handleImageChange={handleImageChange}
					imageUrl={imageUrl}
					errors={errors}
					height="530px"
					unsavedImg={unsavedSolution.logo}
				/>
				<CheckBoxBlock
					selectedFilters={selectedFilters}
					setSelectedFilters={setSelectedFilters}
					checkedCreationFilter={selectedFilter}
					checked={true}
					type="Решения"
				/>
			</div>
			<ButtonsSubmit
				showButton={false}
				unpublishedCreate={handleSubmit(unpublishedCreate)}
				preview={handleSubmit(handlePreviewNavigate)}
				onSubmit={handleSubmit(onSubmit)}
			/>
			<ModalHandelClose open={isOpen} toggle={toggleModal} title="Выйти из создания?">
				<Button
					content="Сохранить и выйти"
					styleName="saveAndExitButton"
					testId=""
					onClick={handleSubmit(unpublishedCreate)}
				/>
				<Button
					content="Выйти без сохранения"
					styleName="exitWithoutSavingButton"
					testId=""
					onClick={() => navigate(-1)}
				/>
			</ModalHandelClose>
		</form>
	);
};

export default SolutionCreation;
