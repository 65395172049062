import { IMainButton } from "../../../types/common/ComponentProps";
import { formClassesFromStyleName } from "../../../utils/formClassesFromStyleName";

import styles from "./MainButton.module.css";

export const MainButton = ({ content, styleName, type, testId, size, disabled }: IMainButton) => {
	return (
		<button
			data-testid={testId}
			className={formClassesFromStyleName(styleName, styles)}
			type={type ? type : "button"}
			style={size ? { width: size } : {}}
			disabled={disabled}>
			{content}
		</button>
	);
};
