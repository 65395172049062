import { Outlet } from "react-router-dom";

import { Title } from "../../ui/Title/Title";

import phone from "../../assets/layouts/Authorization/phone.webp";

import styles from "./AuthLayout.module.css";

export const AuthLayout = () => {
	return (
		<div className={styles.layout}>
			<div className={styles["form-container"]}>
				<Outlet />
			</div>
			<div className={styles["ad-container"]}>
				<div className={styles["img-container"]}>
					<img src={phone} alt="Phone" />
				</div>
				<Title title={"Сервис для покупки чат-ботов"} styleName={"_white"} size={"2.25rem"} />
				<div>
					<div className={styles["ad-desc"]}>
						Создавайте ботов любой сложности для популярных мессенджеров без программирования.
					</div>
					<div className={styles["ad-desc"]}>
						Умные боты обзванивают клиентов, принимают заказы на сайте, работают во всех мессенджерах и голосовых
						ассистентах.
					</div>
				</div>
			</div>
		</div>
	);
};
