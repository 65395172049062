import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

import { IPlatformFilterData } from "../../../types/components/PlatformCatalog";
import { Title } from "../../../ui/Title/Title";
import { Switch } from "../../../ui/Switch/Switch";
import ArrowUp from "../../../components/AdminLayoutComponents/ArrowUp/ArrowUp";
import SolutionFilters from "../../../components/AdminLayoutComponents/Filters/SolutionFilters/SolutionFilters";
import PlatformFilters from "../../../components/AdminLayoutComponents/Filters/PlatformFilters/PlatformFilters";
import { setIsActive } from "../../../services/redux/features/userPart/switch/SwitchSlice";
import { setIsOpenComponent } from "../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSlice";
import switchSelector from "../../../services/redux/features/userPart/switch/SwitchSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import ComponentTogglerSelector from "../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSelector";
import { Roles } from "../../../helpers/roles";
import { AdminPath, Path } from "../../../services/router/routes";
import { CatalogTitles } from "../../../helpers/catalogHelpers";
import {
	setFilterData,
	setResourceType,
} from "../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";

import plusLogo from "../../../assets/pages/Filtering/whitePlusLogoFilters.svg";

import styles from "./Filters.module.css";

function Filters() {
	const dispatch = useAppDispatch();
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const isOpenCreationForm = useAppSelector(ComponentTogglerSelector);
	const userData = useAppSelector(userDataSelector).userData;
	const location = useLocation();
	const navigator = useNavigate();

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.Filters) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		if (userData?.roles[0]?.name === String(Roles.MODERATOR)) {
			navigator(AdminPath.Drafts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0 });
		dispatch(setIsActive(CatalogTitles.Solutions));

		return () => {
			dispatch(setResourceType(null));
			dispatch(
				setFilterData({
					id: 0,
					name: "",
					description: "",
					values: [],
					multipleChoice: true,
				} as IPlatformFilterData),
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	const switchContent: CatalogTitles[] = [CatalogTitles.Solutions, CatalogTitles.Platforms];

	function getCurrentFilterList(): JSX.Element {
		return activeSwitchElement === CatalogTitles.Solutions ? <SolutionFilters /> : <PlatformFilters />;
	}

	const openCreatingFormWrap = () => {
		return dispatch(setIsOpenComponent(!isOpenCreationForm));
	};

	return (
		<div className={styles.filtersWrap}>
			<div className={styles.actionAddingWrapper}>
				<button className={styles.actionAddingContainer} onClick={openCreatingFormWrap}>
					<img src={plusLogo} alt="plus logo" />
				</button>
			</div>
			<Title title="Фильтрация" styleName="titlePage" />
			<Switch switchContent={switchContent} />
			<div>{getCurrentFilterList()}</div>
			<ArrowUp />
		</div>
	);
}

export default Filters;
