import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";
import { settingFavoritePlatformEndpoint } from "../../../../api/resources/apiResourcesConstants";

import { IParams } from "../../../../../types/api/AdminApiTypes";
import { IPlatformCatalogData } from "../../../../../types/redux/thunkTypes";

const baseUrl = getCorrectBaseUrl("ps");

export const getPlatformFavorites = createAsyncThunk("platform/favorites", async (params: IParams, thunkAPI) => {
	try {
		const response = await fetch(`${baseUrl}/${settingFavoritePlatformEndpoint}?offset=${params.offset - 1}&limit=5`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
		return (await response.json()) as IPlatformCatalogData;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
