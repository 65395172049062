import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IFavoritePlatformsState } from "../../../../../types/redux/storeTypes";
import { IPlatformCatalogData } from "../../../../../types/redux/thunkTypes";

import { getPlatformFavorites } from "./PlatformFavoritesThunk";

const favoritePlatformInitialState: IFavoritePlatformsState = {
	favoritePlatforms: [],
	activePlatformTitle: "",
	isLoading: false,
	error: null,
	pageNumber: 0,
	totalPages: 1,
};

const PlatformFavoritesSlice = createSlice({
	name: "favoritePlatforms",
	initialState: favoritePlatformInitialState,
	reducers: {
		clearPlatformFavoriteData: (state) => {
			state.favoritePlatforms = [];
		},
		setActivePlatform: (state, action: PayloadAction<string>) => {
			state.activePlatformTitle = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(getPlatformFavorites.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPlatformFavorites.rejected, (state, action: PayloadAction<string | unknown>) => {
			state.isLoading = false;
			state.error = action.payload;
		});
		builder.addCase(getPlatformFavorites.fulfilled, (state, action: PayloadAction<IPlatformCatalogData>) => {
			state.favoritePlatforms = [...action.payload.content];
			state.totalPages = action.payload.totalPages;
			state.error = null;
		});
	},
});

export const { clearPlatformFavoriteData, setActivePlatform } = PlatformFavoritesSlice.actions;
export default PlatformFavoritesSlice.reducer;
