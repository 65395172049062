import { IClientReview } from "../../../types/common/ComponentProps";

import style from "./ClientReview.module.css";

function ClientReview({ clientsData }: IClientReview) {
	return (
		<section className={style.clientPairReviewWrap}>
			<div className={style.clientReviewWrap}>
				<img src={clientsData.firstIcon} alt="client" className={style.clientReviewWrap__photo} />
				<div className={style.titlesWrap}>
					<p className={style.clientReviewWrap__clientName}>{clientsData.firstName}</p>
					<p className={style.clientReviewWrap__clientProfession}>{clientsData.firstProfession}</p>
				</div>
				<p className={style.clientReviewTitle}>{clientsData.firstReview}</p>
			</div>
			<hr className={style.clientPairReviewWrap__line} />
			<div className={style.clientReviewWrap}>
				<img src={clientsData.secondIcon} alt="client" className={style.clientReviewWrap__photo} />
				<div className={style.titlesWrap}>
					<p className={style.clientReviewWrap__clientName}>{clientsData.secondName}</p>
					<p className={style.clientReviewWrap__clientProfession}>{clientsData.secondProfession}</p>
				</div>
				<p className={style.clientReviewTitle}>{clientsData.secondReview}</p>
			</div>
		</section>
	);
}

export default ClientReview;
