import { useState } from "react";

import { IAccordion } from "../../../types/common/ComponentProps";
import Arrow from "../../../assets/components/Arrow/arrow.svg";

import style from "./Accordion.module.css";

export const Accordion = ({ accordionItems }: IAccordion) => {
	const [isActiveIndex, setIsActiveIndex] = useState<number | null>(null);

	const toggle = (index: number) => {
		setIsActiveIndex(isActiveIndex === index ? null : index);
	};

	return (
		<ul>
			{accordionItems.map((item, index) => (
				<li key={index} className={style.accordion__item}>
					<div className={style.accordion__item_title} onClick={() => toggle(index)}>
						<div>{item.title}</div>
						<img className={isActiveIndex !== index ? style.arrow : style.arrow__active} src={Arrow} alt="arrow-icon" />
					</div>
					<div className={isActiveIndex !== index ? style.accordion__item_content : style.accordion__item_content_show}>
						{item.content}
					</div>
				</li>
			))}
		</ul>
	);
};
