import cn from "classnames";

import { IContextMenu } from "../../types/common/UiKitProps";

import style from "./ContextMenu.module.css";

export const ContextMenu = ({ content, isOpen, refObj }: IContextMenu) => {
	return (
		<ul ref={refObj} className={cn(style.contextMenuContainer, { [style.opened]: isOpen })}>
			{content.map((item) => (
				<li
					key={crypto.randomUUID()}
					onClick={() => {
						item.onClick();
					}}>
					{item.title}
				</li>
			))}
		</ul>
	);
};
