import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISolutionCatalogDataState } from "../../../../../types/redux/storeTypes";
import { ISolutionCatalogData, ISolutionCatalogContent } from "../../../../../types/redux/thunkTypes";

import { getSolutionListData } from "./SolutionCatalogThunk";

const solutionCatalogInitialState: ISolutionCatalogDataState = {
	catalogSolutions: [],
	pageNumber: 0,
	loading: false,
	error: null,
};

const SolutionCatalogSlice = createSlice({
	name: "solutions",
	initialState: solutionCatalogInitialState,
	reducers: {
		setSolutionsData: (state, action: PayloadAction<ISolutionCatalogContent[]>) => {
			state.catalogSolutions = action.payload;
			state.pageNumber = 1;
		},
		clearData: (state) => {
			state.catalogSolutions = [];
		},
	},
	extraReducers(builder) {
		builder.addCase(getSolutionListData.pending, (state) => {
			state.error = null;
			state.loading = true;
		});
		builder.addCase(getSolutionListData.rejected, (state, action: PayloadAction<string | unknown>) => {
			state.error = action.payload;
			state.loading = false;
		});
		builder.addCase(getSolutionListData.fulfilled, (state, action: PayloadAction<ISolutionCatalogData>) => {
			state.catalogSolutions = [...action.payload.content];
			state.pageNumber = action.payload.totalPages;
			state.error = null;
			state.loading = false;
		});
	},
});

export const { clearData, setSolutionsData } = SolutionCatalogSlice.actions;
export default SolutionCatalogSlice.reducer;
