import { useEffect, useRef } from "react";

import { IImageViewElement } from "../../../types/common/ComponentProps";

import styles from "./ImageViewElement.module.css";

function ImageViewElement({ imageTextLine }: IImageViewElement) {
	const imageContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (imageTextLine && imageContainerRef) {
			const image = new Image();
			image.src = imageTextLine;

			imageContainerRef.current && imageContainerRef.current.appendChild(image);
		}
	}, [imageTextLine]);

	return (
		<div className={styles.imageViewElementWrap}>
			<div className={styles.imageViewElementContainer} ref={imageContainerRef}></div>
		</div>
	);
}

export default ImageViewElement;
