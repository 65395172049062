import { Link } from "react-router-dom";
import cn from "classnames/dedupe";

import { IPaginationElement } from "../../types/common/UiKitProps";
import { getPreviousNextPages } from "../../utils/paginationElement";

import blackArrow from "../../assets/components/Pagination/paginationArrowBlack.svg";
import greyArrow from "../../assets/components/Pagination/paginationArrowGrey.svg";

import styles from "./PaginationElement.module.css";

function PaginationElement({ currPage, pageCount, searchLine }: IPaginationElement) {
	const { previousPageNumber, nextPageNumber } = getPreviousNextPages(pageCount, currPage);

	const startHiddenEllipsisPosition: number = 4;
	const finalHiddenEllipsisPosition: number = 3;

	const getStartEllipsisStyle = () => {
		return cn(styles.ellipsisLine, {
			[styles.ellipsisLine__hidden]:
				currPage === 2 || currPage === finalHiddenEllipsisPosition || currPage === startHiddenEllipsisPosition,
		});
	};

	const getFinalEllipsisStyle = () => {
		return cn(styles.ellipsisLine, {
			[styles.ellipsisLine__hidden]:
				currPage === pageCount - 1 ||
				currPage === pageCount - 2 ||
				currPage === pageCount - finalHiddenEllipsisPosition,
		});
	};

	const getPreviousPageTitleStyle = () => {
		return cn(styles.previousPageActionWrap__title, { [styles.previousPageActionWrap__title__grey]: currPage === 1 });
	};

	const getNextPageTitleStyle = () => {
		return cn(styles.nextPageActionWrap__title, { [styles.nextPageActionWrap__title__grey]: currPage === pageCount });
	};

	const getStartArrowStyle = () => {
		return cn(styles.previousPageActionWrap__arrow, { [styles.previousPageActionWrap__arrow__grey]: currPage === 1 });
	};

	const getFinalArrowStyle = () => {
		return cn(styles.nextPageActionWrap__arrow, { [styles.nextPageActionWrap__arrow__grey]: currPage === pageCount });
	};

	if (pageCount === 0) return <></>;

	return (
		<div className={styles.paginationElementWrap}>
			<div className={styles.paginationElementContainer}>
				<Link to={`${searchLine}/${previousPageNumber}`}>
					<button className={styles.previousPageActionWrap}>
						<img
							src={currPage === 1 ? greyArrow : blackArrow}
							alt="previous page arrow"
							className={getStartArrowStyle()}
						/>
						<span className={getPreviousPageTitleStyle()}>Назад</span>
					</button>
				</Link>
				<div className={styles.paginationLinksWrap}>
					{currPage > 1 && pageCount >= 2 && (
						<>
							<Link to={`${searchLine}/1`}>1</Link>
							<p className={getStartEllipsisStyle()}>...</p>
						</>
					)}

					{currPage > finalHiddenEllipsisPosition && <Link to={`${searchLine}/${currPage - 2}`}>{currPage - 2}</Link>}

					{currPage > 2 && <Link to={`${searchLine}/${currPage - 1}`}>{currPage - 1}</Link>}

					{
						<div className={styles.currentPageTextLine}>
							<Link to={`${searchLine}/${currPage}`}>{currPage}</Link>
						</div>
					}

					{currPage < pageCount && currPage + 1 !== pageCount && (
						<Link to={`${searchLine}/${currPage + 1}`}>{currPage + 1}</Link>
					)}

					{currPage < pageCount && currPage + 2 !== pageCount && currPage + 1 !== pageCount && (
						<Link to={`${searchLine}/${currPage + 2}`}>{currPage + 2}</Link>
					)}

					{currPage !== pageCount && (
						<>
							<p className={getFinalEllipsisStyle()}>...</p>
							<Link to={`${searchLine}/${pageCount}`}>{pageCount}</Link>
						</>
					)}
				</div>
				<Link to={`${searchLine}/${nextPageNumber}`}>
					<button className={styles.nextPageActionWrap}>
						<span className={getNextPageTitleStyle()}>Далее</span>
						<img
							src={currPage === pageCount ? greyArrow : blackArrow}
							alt="next arrow"
							className={getFinalArrowStyle()}
						/>
					</button>
				</Link>
			</div>
		</div>
	);
}

export default PaginationElement;
