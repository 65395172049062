import { InputHTMLAttributes } from "react";

import style from "./SearchInput.module.css";

export const SearchInput = ({ placeholder, onChange, value }: InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<div className={style.searchInputWrap}>
			<input value={value} onChange={onChange} type="text" placeholder={placeholder} />
		</div>
	);
};
