import { CatalogRoutes } from "../helpers/catalogHelpers";
import { Path } from "../services/router/routes";

export function getCorrectCatalogLink(resource: CatalogRoutes, isLogin: boolean): string {
	const CorrectUnSigninCatalogLink =
		resource === CatalogRoutes.Platforms ? Path.PlatformCatalogUnSignin : Path.SolutionCatalogUnSignin;
	const CorrectSigninCatalogLink =
		resource === CatalogRoutes.Platforms ? Path.PlatformCatalogSignin : Path.SolutionCatalogSignin;
	return (isLogin ? CorrectSigninCatalogLink : CorrectUnSigninCatalogLink) + "/1";
}
