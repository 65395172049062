import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import DescriptionPlatform from "../../../components/MainLayoutComponents/ResourceComponents/DescriptionPlatform/DescriptionPlatform";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { Path } from "../../../services/router/routes";
import { DescriptionPlatformTypes } from "../../../helpers/DescriptionPlatform";

import styles from "./CurrentPlatformSignIn.module.css";

function CurrentPlatformSignIn() {
	const navigator = useNavigate();
	const isLogin = useAppSelector(userDataSelector).userData?.email;

	useEffect(() => {
		if (!isLogin) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.fullPlatformDescriptionWrap}>
			<DescriptionPlatform pageType={DescriptionPlatformTypes.Common} isDisabledSolutionCard={false} />
		</div>
	);
}

export default CurrentPlatformSignIn;
