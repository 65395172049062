import { ISelectedFilterVariant } from "../../../types/common/ComponentProps";

import style from "./SelectedFilterVariant.module.css";

export const SelectedFilterVariant = ({ value, onClick }: ISelectedFilterVariant) => {
	return (
		<li className={style.filter__variant}>
			<p>{value}</p>
			<span onClick={() => onClick(value)} className={style.cross}></span>
		</li>
	);
};
