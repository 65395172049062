import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useModal from "../../../../hooks/useModal";

import { ISolutionsUserStatus } from "../../../../types/common/ComponentProps";
import { Button } from "../../../../ui/Button/Button";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { SolutionCard } from "../../../MainLayoutComponents/ResourceComponents/SolutionCard/SolutionCard";
import ModalHandelClose from "../../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { draftedSolutionsSelector } from "../../../../services/redux/features/adminPart/solutions/draftedSolutions/DraftedSolutionsSelector";
import { clearDraftedSolutions } from "../../../../services/redux/features/adminPart/solutions/draftedSolutions/DraftedSolutionsSlice";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { moveSolutionToPublished, removeSolutionToDeleted } from "../../../../services/api/admin/solutionsPageActions";
import { AdminPath } from "../../../../services/router/routes";
import { getDraftedSolutions } from "../../../../services/redux/features/adminPart/solutions/draftedSolutions/DraftedSolutionThunk";
import { MenuContent } from "../../../../helpers/ContextMenu";
import { AdminCatalogRoutes, solutionListErrorContent } from "../../../../helpers/catalogHelpers";

import style from "./DraftedSolutions.module.css";

export const DraftedSolutions = ({ isModerator }: ISolutionsUserStatus) => {
	const [toggleListUpdate, setToggleListUpdate] = useState(false);
	const [modalTextContent, setModalTextContent] = useState<{
		title: string;
		description: string;
		buttonName: string;
	}>();

	const dispatch = useAppDispatch();
	const param = useParams();
	const navigate = useNavigate();
	const { isOpen, toggleModal } = useModal();
	const { draftedSolutions, draftedTotalPages, isLoading, error } = useAppSelector(draftedSolutionsSelector);
	const resourceId = useAppSelector(ResourceDataSelector).currentResourceId;
	const userData = useAppSelector(userDataSelector).userData;

	const activeSwitchElement: AdminCatalogRoutes = param?.pagepart as AdminCatalogRoutes;

	const requestParams = {
		offset: Number(param.page),
		limit: 9,
	};

	useEffect(() => {
		if (activeSwitchElement === AdminCatalogRoutes.Drafted) {
			dispatch(getDraftedSolutions(requestParams));
		}
		return () => {
			dispatch(clearDraftedSolutions());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, requestParams.offset, toggleListUpdate]);

	const openModal = (title: string, description: string, buttonName: string) => {
		setModalTextContent({ title, description, buttonName });
		toggleModal();
	};

	const handlePublishSolution = async () => {
		await moveSolutionToPublished(resourceId);
		toggleModal();
		setToggleListUpdate(!toggleListUpdate);
	};

	const handleDeleteSolutions = async () => {
		await removeSolutionToDeleted(resourceId);
		toggleModal();
		setToggleListUpdate(!toggleListUpdate);
	};

	const draftedCategory = {
		adminList: [
			{ title: MenuContent.EDIT, onClick: () => navigate(`${AdminPath.SolutionsEdit}/${resourceId}`) },
			{ title: MenuContent.PREVIEW, onClick: () => navigate(`${AdminPath.SolutionPreview}/${resourceId}`) },
			{
				title: MenuContent.PUBLISH,
				onClick: () =>
					openModal(
						"Опубликовать?",
						"Опубликованное появится на сайте и будет храниться в опубликованном",
						MenuContent.PUBLISH,
					),
			},
			{
				title: MenuContent.DELETE,
				onClick: () => openModal("Удалить?", "Удалённое будет храниться 30 дней", MenuContent.DELETE),
			},
		],
		moderList: [
			{ title: MenuContent.EDIT, onClick: () => navigate(`${AdminPath.SolutionsEdit}/${resourceId}`) },
			{ title: MenuContent.PREVIEW, onClick: () => navigate(`${AdminPath.SolutionPreview}/${resourceId}`) },
			{
				title: MenuContent.PUBLISH,
				onClick: () =>
					openModal(
						"Опубликовать?",
						"Опубликованное появится на сайте и будет храниться в опубликованном",
						MenuContent.PUBLISH,
					),
			},
		],
	};

	function renderSolutionList() {
		return !draftedSolutions || !draftedSolutions.length || isLoading ? (
			<h3>{error ? solutionListErrorContent : ""}</h3>
		) : (
			draftedSolutions.map((item) => (
				<SolutionCard
					key={item.id}
					id={item.id}
					title={item.name}
					shortDescription={item.shortDescription}
					logo={item.logo}
					role={userData.roles[0]?.name}
					menuContent={isModerator ? draftedCategory.moderList : draftedCategory.adminList}
				/>
			))
		);
	}

	return (
		<>
			<ModalHandelClose
				title={modalTextContent?.title ?? ""}
				open={isOpen}
				toggle={toggleModal}
				isLeftAlignedTextHeader>
				<p className={style.modalDescription}>{modalTextContent?.description}</p>
				<Button
					styleName="commonMaroonButton solutionCatalogModal"
					content={modalTextContent?.buttonName ?? ""}
					onClick={modalTextContent?.buttonName === MenuContent.DELETE ? handleDeleteSolutions : handlePublishSolution}
				/>
			</ModalHandelClose>
			<div className={style.solutionList}>{renderSolutionList()}</div>
			<PaginationElement
				currPage={Number(param.page) ?? 1}
				pageCount={draftedTotalPages}
				searchLine={`${AdminPath.Solutions}/${AdminCatalogRoutes.Drafted}`}
			/>
		</>
	);
};
