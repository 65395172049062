import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAdminSolutionsData, IPublishedSolutionsState } from "../../../../../../types/redux/storeTypes";

import { getPublishedSolutions } from "./PublishedSolutionsThunk";

const publishedSolutionsInitialState: IPublishedSolutionsState = {
	publishedTotalPages: 0,
	publishedSolutions: [],
	isLoading: false,
	error: null,
};

const PublishedSolutions = createSlice({
	name: "publishedSolutions",
	initialState: publishedSolutionsInitialState,
	reducers: {
		clearPublishedSolutions: (state) => {
			state.publishedSolutions = [];
			state.publishedTotalPages = 0;
		},
	},
	extraReducers(builder) {
		builder.addCase(getPublishedSolutions.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPublishedSolutions.fulfilled, (state, action: PayloadAction<IAdminSolutionsData>) => {
			state.publishedTotalPages = action.payload.totalPages;
			state.publishedSolutions = action.payload.content;
			state.isLoading = false;
		});
		builder.addCase(getPublishedSolutions.rejected, (state, action: PayloadAction<string | unknown>) => {
			state.error = action.payload;
			state.isLoading = false;
		});
	},
});

export const { clearPublishedSolutions } = PublishedSolutions.actions;
export default PublishedSolutions.reducer;
