import { FieldErrors } from "react-hook-form";

import {
	errorRequiredField,
	errorDefault,
	errorEmailIncorrect,
	errorPasswordIncorrect,
	passwordPattern,
	errorLastnameIncorrect,
	errorPhoneIncorrect,
	errorFirstnameIncorrect,
} from "../helpers/AuthConstants";

enum ErrorTypes {
	REQUIRED = "required",
	PATTERN = "pattern",
	MIN_LENGTH = "minLength",
	MAX_LENGTH = "maxLength",
}

enum PasswordTypes {
	COMMON_PASSWORD = "password",
	OLD_PASSWORD = "oldPassword",
	NEW_PASSWORD = "newPassword",
}

class FormHelpers {
	formatPhone = (value: string) => {
		let newValue = value.replace(/[^0-9]/g, "");
		const maximalPhoneNumberLength: number = 18;

		if (newValue.trim().length === 0) {
			return "";
		} else if (newValue.charAt(0) !== "+") {
			newValue = "+" + value.replace(/[^0-9]/g, "").substring(0, maximalPhoneNumberLength);
		}
		return newValue;
	};
	getNameError = (errors: FieldErrors): string => {
		switch (errors.firstname?.type) {
			case ErrorTypes.REQUIRED:
				return errorRequiredField;
			case ErrorTypes.MIN_LENGTH:
				return "Не менее 2 символов";
			case ErrorTypes.MAX_LENGTH:
				return "Не более 25 символов";
			case ErrorTypes.PATTERN:
				return errorFirstnameIncorrect;
			default:
				return errorDefault;
		}
	};
	getLastNameError = (errors: FieldErrors) => {
		switch (errors.lastname?.type) {
			case ErrorTypes.REQUIRED:
				return errorRequiredField;
			case ErrorTypes.MIN_LENGTH:
				return "Не менее 8 символов";
			case ErrorTypes.MAX_LENGTH:
				return "Не более 30 символов";
			case ErrorTypes.PATTERN:
				return errorLastnameIncorrect;
			default:
				return errorDefault;
		}
	};
	getPhoneError = (errors: FieldErrors) => {
		switch (errors.phone?.type) {
			case ErrorTypes.REQUIRED:
				return errorRequiredField;
			case ErrorTypes.PATTERN:
				return errorPhoneIncorrect;
			case ErrorTypes.MIN_LENGTH:
				return "Не менее 4 цифр";
			default:
				return errorDefault;
		}
	};
	getEmailError = (errors: FieldErrors) => {
		switch (errors.email?.type) {
			case ErrorTypes.REQUIRED:
				return errorRequiredField;
			case ErrorTypes.MAX_LENGTH:
				return errorEmailIncorrect + ". Не более 30 символов";
			case ErrorTypes.MIN_LENGTH:
				return errorEmailIncorrect + ". Не менее 8 символов";
			case ErrorTypes.PATTERN:
				return errorEmailIncorrect;
			default:
				return errorDefault;
		}
	};
	getPasswordError = (errors: FieldErrors, password: string, fieldName: string) => {
		const minimalPasswordLength: number = 6;
		const maximalPasswordLength: number = 50;
		const { type } = errors[fieldName] || {};

		if (type === ErrorTypes.REQUIRED) {
			return errorRequiredField;
		} else if (type === ErrorTypes.PATTERN) {
			let message = `${errorPasswordIncorrect}. `;

			if (!passwordPattern.test(password)) {
				if (password.length < minimalPasswordLength) {
					message += "Минимум 6 символов";
				} else if (password.length > maximalPasswordLength) {
					message += "Не более 50 символов";
				} else if (/(?=.*[а-яА-Я])/.test(password)) {
					message += "Только латинские буквы";
				} else if (!/(?=.*[A-Z])/.test(password)) {
					message += "Хотя бы одна заглавная буква";
				} else if (!/(?=.*\d)/.test(password)) {
					message += "Хотя бы одна цифра";
				}
			}
			return message;
		} else {
			return errorDefault;
		}
	};

	getPasswordErrorMessage = (errors: FieldErrors, password: string, fieldName: string) => {
		const errorMessage = this.getPasswordError(errors, password, fieldName);
		return errorMessage !== errorDefault ? errorMessage : "";
	};

	getCommonPasswordError = (errors: FieldErrors, oldPassword: string) => {
		return this.getPasswordErrorMessage(errors, oldPassword, PasswordTypes.COMMON_PASSWORD);
	};

	getOldPasswordError = (errors: FieldErrors, oldPassword: string) => {
		return this.getPasswordErrorMessage(errors, oldPassword, PasswordTypes.OLD_PASSWORD);
	};

	getNewPasswordError = (errors: FieldErrors, newPassword: string) => {
		return this.getPasswordErrorMessage(errors, newPassword, PasswordTypes.NEW_PASSWORD);
	};
}

export const getDefaultServerErrorMessage = (errorMessage: string) => {
	return {
		type: "server",
		message: errorMessage,
	};
};

export const shouldBeFocusedField = {
	shouldFocus: true,
};

export const formHelpers = new FormHelpers();
