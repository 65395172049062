import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IContextMenuItem, IContextModalPositionState } from "../../../../types/ui/ContextMenuModal";
import { IPlatformsUserStatus } from "../../../../types/common/ComponentProps";
import ContextMenuModal from "../../../../ui/ContextMenuModal/ContextMenuModal";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { Button } from "../../../../ui/Button/Button";
import ModalHandelClose from "../../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import PlatformCatalogCard from "../../../MainLayoutComponents/ResourceComponents/PlatformCatalogCard/PlatformCatalogCard";
import { getPublishedPlatformsData } from "../../../../services/redux/features/adminPart/platforms/publishedPlatforms/PublishedPlatformsThunk";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import publishedPlatformsSelector from "../../../../services/redux/features/adminPart/platforms/publishedPlatforms/PublishedPlatformsSelector";
import { clearPublishedPlatformsData } from "../../../../services/redux/features/adminPart/platforms/publishedPlatforms/PublishedPlatformsSlice";
import { setResourceId } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import { movePlatformToDrafts, removePlatformToDeleted } from "../../../../services/api/admin/platformsPageActions";
import { AdminCatalogRoutes, PlatformCardTypes, platformListErrorContent } from "../../../../helpers/catalogHelpers";
import { AdminPath } from "../../../../services/router/routes";
import { defaultContextMenuPosition } from "../../../../helpers/ContextMenu";
import {
	PlatformAdminContextMenuItems,
	contextMenuDivideWidthValue,
	firstElementTopPosition,
	fullHdLeftPosition,
	hdLeftPosition,
	platformCardsDifferencePosition,
} from "../../../../helpers/platformAdmin";

import styles from "./PublishedPlatforms.module.css";

function PublishedPlatforms({ isModerator }: IPlatformsUserStatus) {
	const [toggleContextModal, setToggleContextModal] = useState<boolean>(false);
	const [toggleListUpdate, setToggleListUpdate] = useState<boolean>(false);
	const [contextModalPosition, setContextModalPosition] =
		useState<IContextModalPositionState>(defaultContextMenuPosition);
	const [currentModalName, setCurrentModalName] = useState<ModalsNameList | undefined>(undefined);
	const [isPaginationHidden, setIsPaginationHidden] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	const params = useParams();
	const navigator = useNavigate();
	const dispatch = useAppDispatch();
	const platformId = useAppSelector(ResourceDataSelector).currentResourceId;
	const { publishedPlatforms, pageNumber, loading, platformsOnPage, error } =
		useAppSelector(publishedPlatformsSelector);

	const activeSwitchElement: AdminCatalogRoutes = params?.pagepart as AdminCatalogRoutes;

	enum ModalsNameList {
		modalUnpublish = "modalUnpublish",
		modalRemove = "modalRemove",
	}

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (activeSwitchElement === AdminCatalogRoutes.Published) {
			dispatch(getPublishedPlatformsData(Number(params.page)));
		}

		return () => {
			dispatch(clearPublishedPlatformsData());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, dispatch, params.page, toggleListUpdate]);

	function toggleContextMenuWindow(cardPosition: number) {
		const top: number = firstElementTopPosition + platformCardsDifferencePosition * cardPosition;
		const left: number =
			windowWidth * (windowWidth > contextMenuDivideWidthValue ? fullHdLeftPosition : hdLeftPosition);
		setContextModalPosition({ top, left });
		setToggleContextModal(!toggleContextModal);
	}

	const contextMenuItems: IContextMenuItem[] = [
		{
			title: PlatformAdminContextMenuItems.Edit,
			onClick: () => navigator(`${AdminPath.PlatformEdit}/${platformId}`),
		},
		{
			title: PlatformAdminContextMenuItems.Preview,
			onClick: () => navigator(`${AdminPath.PlatformPreview}/${platformId}`),
		},
		{
			title: PlatformAdminContextMenuItems.Unpublish,
			onClick: () => openUnpublishPlatformModal(),
		},
		{
			title: PlatformAdminContextMenuItems.Delete,
			onClick: () => openRemovePlatformModal(),
		},
	];

	const shortContextMenuItemList: IContextMenuItem[] = [contextMenuItems[0], contextMenuItems[1]];

	const closeCurrentModal = () => {
		dispatch(setResourceId(0));
		return setCurrentModalName(undefined);
	};

	const closeModalAfterAction = async () => {
		dispatch(setResourceId(0));
		setToggleContextModal(false);
		await setCurrentModalName(undefined);
		if (platformsOnPage === 1) {
			if (Number(params?.page) === 1) {
				setToggleListUpdate(!toggleListUpdate);
				return setIsPaginationHidden(!isPaginationHidden);
			}
			return navigator(AdminPath.Platforms + `/${Number(params.page) - 1}`);
		}
		return setToggleListUpdate(!toggleListUpdate);
	};

	const openUnpublishPlatformModal = () => {
		return setCurrentModalName(ModalsNameList.modalUnpublish);
	};

	const openRemovePlatformModal = () => {
		return setCurrentModalName(ModalsNameList.modalRemove);
	};

	const handleUnpublishingAction = async () => {
		await movePlatformToDrafts(platformId);
		return closeModalAfterAction();
	};

	const handleRemovingAction = async () => {
		await removePlatformToDeleted(platformId);
		return closeModalAfterAction();
	};

	return (
		<section className={styles.publishedPlatformsWrap}>
			{!publishedPlatforms || !publishedPlatforms.length || loading ? (
				<h3>{error ? platformListErrorContent : ""}</h3>
			) : (
				publishedPlatforms.map((platformData, index) => (
					<PlatformCatalogCard
						type={PlatformCardTypes.AdminType}
						title={platformData.name}
						cardId={platformData.id}
						description={platformData.shortDescription}
						solutionNumber={platformData.totalSolution ?? 0}
						icon={platformData.logoUrl}
						key={platformData.id ?? index}
						toggleContextMenu={(event) => toggleContextMenuWindow(index)}
					/>
				))
			)}
			{!isPaginationHidden ? (
				<PaginationElement
					currPage={Number(params.page ?? 1)}
					pageCount={pageNumber}
					searchLine={`${AdminPath.Platforms}/${AdminCatalogRoutes.Published}`}
				/>
			) : (
				<></>
			)}
			{toggleContextModal && (
				<ContextMenuModal
					content={isModerator ? shortContextMenuItemList : contextMenuItems}
					position={contextModalPosition}
					closeContextModal={() => setToggleContextModal(!toggleContextModal)}
				/>
			)}
			<ModalHandelClose
				title="Снять с публикации?"
				open={currentModalName === ModalsNameList.modalUnpublish}
				isLeftAlignedTextHeader={true}
				toggle={closeCurrentModal}>
				<p className={styles.modalTitle}>Снятое с публикации будет храниться в черновиках</p>
				<Button
					content="Снять с публикации"
					styleName="commonMaroonButton platformsAdminUnpublish"
					onClick={handleUnpublishingAction}
				/>
			</ModalHandelClose>
			<ModalHandelClose
				title="Удалить?"
				open={currentModalName === ModalsNameList.modalRemove}
				isLeftAlignedTextHeader={true}
				toggle={closeCurrentModal}>
				<p className={styles.modalTitle}>Удалённое будет храниться 30 дней</p>
				<Button content="Удалить" styleName="commonMaroonButton platformsAdminRemove" onClick={handleRemovingAction} />
			</ModalHandelClose>
		</section>
	);
}

export default PublishedPlatforms;
