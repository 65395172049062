import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { platformsDraftsEndpoint, solutionsDraftsEndpoint } from "./apiAdminConstants";

export async function getPlatformDraftsList() {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${platformsDraftsEndpoint}?offset=0&limit=6`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}

export async function getSolutionDraftsList() {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${solutionsDraftsEndpoint}?offset=0&limit=6`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
