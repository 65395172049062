import classNames from "classnames";

import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import { ISwitch } from "../../types/common/UiKitProps";
import { setIsActive } from "../../services/redux/features/userPart/switch/SwitchSlice";
import switchSelector from "../../services/redux/features/userPart/switch/SwitchSelector";

import style from "./Switch.module.css";

export const Switch = ({ switchContent, onChange }: ISwitch) => {
	const dispatch = useAppDispatch();
	const activeElement = useAppSelector(switchSelector).activeElement;

	return (
		<div className={style.switchContainer}>
			{switchContent.map((item, index) => (
				<p
					key={index}
					onClick={() => {
						dispatch(setIsActive(item));
						onChange && onChange(item);
					}}
					className={classNames(style.switchItem, { [style.active]: activeElement === item })}>
					{item}
				</p>
			))}
		</div>
	);
};
