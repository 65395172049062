import { BaseSyntheticEvent, useEffect, useState } from "react";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useDebounce from "../../../../hooks/useDebounce";

import { IPlatformFilterData, IPlatformFilterListData } from "../../../../types/components/PlatformCatalog";
import FilterGroup from "../FilterGroup/FilterGroup";
import { getPlatformFilterList } from "../../../../services/api/resources/getPlatformFilterList";
import { setIsOpenComponent } from "../../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSlice";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";
import ComponentTogglerSelector from "../../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSelector";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import { searchFilterGroup } from "../../../../services/api/admin/filtersPageActions";
import { extractDigitsFromTextLine } from "../../../../utils/extractDigitsFromTextLine";
import { CatalogRoutes, CatalogTitles } from "../../../../helpers/catalogHelpers";
import { searchDelay } from "../../../../helpers/delayConstants";

import searchLogo from "../../../../assets/pages/Filtering/filtersSearchLogo.svg";

import styles from "./PlatformFilters.module.css";

function PlatformFilters() {
	const [platformFilterList, setPlatformFilterList] = useState<IPlatformFilterData[]>([]);
	const [searchLine, setSearchLine] = useState<string>();
	const dispatch = useAppDispatch();
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const isOpenCreationForm = useAppSelector(ComponentTogglerSelector);
	const { currentResourceId, currentFilterData, currentResourceType } = useAppSelector(ResourceDataSelector);
	const debouncedSearch = useDebounce(handleSearchFilters, searchDelay);

	useEffect(() => {
		dispatch(setIsOpenComponent(false));
	}, [dispatch]);

	useEffect(() => {
		(async () => {
			if (activeSwitchElement !== CatalogTitles.Platforms || searchLine || isOpenCreationForm) {
				return null;
			}
			const response: Response = await getPlatformFilterList();
			const filterResponse: Promise<IPlatformFilterListData> = await response.json();
			setPlatformFilterList((await filterResponse).filters);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, searchLine, isOpenCreationForm]);

	useEffect(() => {
		if (searchLine) {
			debouncedSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchLine]);

	useEffect(() => {
		if (currentResourceId) {
			removeDisplayingFilterGroup(currentResourceId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentResourceId]);

	useEffect(() => {
		if (currentResourceType && currentResourceType === CatalogRoutes.Platforms) {
			updateCurrentFilterData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFilterData]);

	function updateCurrentFilterData() {
		const updatedPlatformFilterList = platformFilterList.map((platformFilter) => {
			return currentFilterData?.id === platformFilter.id ? currentFilterData : platformFilter;
		});

		setPlatformFilterList(updatedPlatformFilterList);
	}

	function removeDisplayingFilterGroup(platformId: number) {
		const updatedPlatformFilterList = platformFilterList.filter((platformFilter) => platformFilter.id !== platformId);
		return setPlatformFilterList(updatedPlatformFilterList);
	}

	async function handleSearchFilters() {
		if (!searchLine) {
			return null;
		}

		const response = await searchFilterGroup({ value: searchLine }, CatalogRoutes.Platforms);
		const searchedFiltersData: Promise<IPlatformFilterListData> = await response.json();

		return setPlatformFilterList((await searchedFiltersData).filters);
	}

	function handleChange(event: BaseSyntheticEvent) {
		setSearchLine(event.target.value);
	}

	const renderCreatingFilterContainer = () => {
		return isOpenCreationForm ? (
			<div className={styles.creatingFilterFormWrap}>
				<FilterGroup
					id={0}
					name=""
					description=""
					filterValueList={[
						{
							id: extractDigitsFromTextLine(crypto.randomUUID()),
							value: "",
						},
					]}
					isMultipleChoice={true}
					isCreationCard={true}
					resourceType={CatalogRoutes.Platforms}
				/>
			</div>
		) : (
			<></>
		);
	};

	const renderFilterList = () => {
		return platformFilterList.map((platformFilter) => (
			<FilterGroup
				key={platformFilter.id}
				id={platformFilter.id}
				name={platformFilter.name}
				description={platformFilter.description ?? ""}
				filterValueList={platformFilter.values}
				isMultipleChoice={platformFilter.multipleChoice}
				isCreationCard={false}
				resourceType={CatalogRoutes.Platforms}
			/>
		));
	};

	return (
		<div className={styles.platformFiltersWrap}>
			<div className={styles.searchLineWrap}>
				<img src={searchLogo} alt="loupe" />
				<input
					type="text"
					placeholder="Введите название фильтра"
					className={styles.searchLineWrap__searchLineInput}
					onChange={handleChange}
				/>
			</div>
			<div className={styles.creatingFilterWrap}>{renderCreatingFilterContainer()}</div>
			<div className={styles.filterListWrap}>{renderFilterList()}</div>
		</div>
	);
}

export default PlatformFilters;
