import { ISolutionsFilter } from "../../../../../types/common/ComponentProps";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";

import searchIcon from "../../../../../assets/components/CheckBoxBlock/searchIcon.svg";

import styles from "./SolutionFilter.module.css";

const SolutionsFilter = ({
	solutions,
	searchText,
	setSearchText,
	selectedSolutionIds,
	setSelectedSolutionIds,
}: ISolutionsFilter) => {
	const handleCheckBoxChange = (solutionId: number, checked: boolean) => {
		setSelectedSolutionIds &&
			setSelectedSolutionIds((prevIds) =>
				checked ? [...prevIds, solutionId] : prevIds.filter((id) => id !== solutionId),
			);
	};
	const renderFilteredSolutions = () => {
		return (
			solutions &&
			solutions
				.filter((filter) => filter.name.toLowerCase().includes(searchText.toLowerCase()))
				.map((filteredSolution) => (
					<div key={filteredSolution.id} className={styles.checkBoxItem}>
						<CheckBox
							label={filteredSolution.name}
							checked={selectedSolutionIds.includes(filteredSolution.id)}
							onChange={(checked) => handleCheckBoxChange(filteredSolution.id, checked)}
						/>
					</div>
				))
		);
	};

	return (
		<div className={styles.solutionsFilter}>
			<div className={styles.filter}>
				<p className={styles.filterTitle}>Входящие в эту платформу решения:</p>
				<label className={styles.searchInputBlock}>
					<img src={searchIcon} alt="searchIcon" />
					<input
						type="text"
						placeholder="Введи название решения"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</label>
				{renderFilteredSolutions()}
			</div>
		</div>
	);
};

export default SolutionsFilter;
