import { Controller, UseControllerProps } from "react-hook-form";

import { IAuthTextArea } from "../../types/common/ComponentProps";

import styles from "./TextArea.module.css";

export const TextArea = ({
	id,
	rows,
	cols,
	placeholder,
	autoComplete,
	control,
	name,
	error,
	height,
	...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IAuthTextArea & UseControllerProps<any>) => {
	return (
		<div className={styles.textAreaWrapper}>
			<Controller
				name={name}
				control={control}
				defaultValue=""
				rules={props.rules}
				render={({ field }) => (
					<textarea
						{...field}
						rows={rows}
						cols={cols}
						placeholder={placeholder}
						className={error ? styles.textAreaError : styles.textArea}
						autoComplete={autoComplete}
						style={{ height: height }}
					/>
				)}
			/>
		</div>
	);
};
