import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { settingFavoritePlatformEndpoint } from "./apiResourcesConstants";

export async function setPlatformFavorite(platfromId: number, isFavoritePlatform: boolean) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${settingFavoritePlatformEndpoint}/${platfromId}`, {
		method: isFavoritePlatform ? "DELETE" : "POST",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
