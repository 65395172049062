import { useController, UseControllerProps } from "react-hook-form";
import { InputHTMLAttributes, useState } from "react";
import cn from "classnames/dedupe";

import { IGetInputElementClassName, IGetInputWrapClassName } from "../../types/components/Input";
import { IInputProperties } from "../../types/common/UiKitProps";
import PasswordEye from "../../components/AuthLayoutComponents/PasswordEye/PasswordEye";

import styles from "./Input.module.css";

export const Input = ({
	id,
	type,
	placeholder,
	autoComplete,
	inputStyleName,
	...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IInputProperties & InputHTMLAttributes<HTMLInputElement> & UseControllerProps<any>) => {
	const { field, fieldState } = useController(props);

	enum PasswordTypes {
		TEXT = "text",
		PASSWORD = "password",
	}

	const [passwordType, setPasswordType] = useState<PasswordTypes>(PasswordTypes.PASSWORD);

	const togglePassword = (e: Event) => {
		e.preventDefault();
		setPasswordType(passwordType === PasswordTypes.PASSWORD ? PasswordTypes.TEXT : PasswordTypes.PASSWORD);
	};

	const getInputWrapClassName: IGetInputWrapClassName = (type, fieldState, styles) => {
		return type === PasswordTypes.PASSWORD
			? `${styles.passwordInputElementWrap} ${fieldState.invalid && styles._error}`
			: "";
	};

	const getInputElementClassName: IGetInputElementClassName = (type, fieldState, styles) => {
		switch (inputStyleName) {
			case "filterGroupTitleInput":
				return cn(styles.filterGroupTitleInput, { [styles.filterError]: fieldState.invalid });
			case "filterGroupHintInput":
				return cn(styles.filterGroupHintInput, { [styles.filterError]: fieldState.invalid });
		}
		return type === PasswordTypes.PASSWORD ? styles.input : `${styles.input} ${fieldState.invalid && styles._error}`;
	};

	return (
		<div className={getInputWrapClassName(type, fieldState, styles)}>
			<input
				{...field}
				id={id}
				placeholder={placeholder}
				autoComplete={autoComplete}
				type={type ? (type === PasswordTypes.PASSWORD ? passwordType : type) : PasswordTypes.TEXT}
				className={getInputElementClassName(type, fieldState, styles)}
				value={field.value || ""}
			/>
			{type === PasswordTypes.PASSWORD && (
				<PasswordEye onClick={togglePassword} className={field.value ? "visiblePasswordEye" : "invisiblePasswordEye"} />
			)}
		</div>
	);
};
