import { BaseSyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import useModal from "../../../hooks/useModal";

import { IUserRoles } from "../../../types/redux/thunkTypes";
import { ServerErrorResponse } from "../../../types/components/SignUpForm";
import { LoginData, ServerError } from "../../../types/api/AuthApiTypes";
import { AuthInput } from "../AuthInput/AuthInput";
import { AuthButton } from "../AuthButton/AuthButton";
import { Form } from "../../../ui/Form/Form";
import { getUserData } from "../../../services/redux/features/userPart/userData/UserDataThunk";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { formHelpers, getDefaultServerErrorMessage, shouldBeFocusedField } from "../../../utils/formHelpers";
import { loginUser } from "../../../services/api/auth/loginUser";
import {
	emailPattern,
	errorServerLoginEmail,
	passwordPattern,
	serverLoginErrors,
} from "../../../helpers/AuthConstants";
import { Roles } from "../../../helpers/roles";
import { AdminPath, Path } from "../../../services/router/routes";

import ModalAutoHiden from "../../PersonalAreaLayoutComponents/ModalAutoHidden/ModalAutoHidden";

import { DELAY_IN_SECONDS } from "../../../helpers/delayConstants";

import styles from "./LoginForm.module.css";

export const LoginForm = () => {
	const [rememberMeState, setRememberMeState] = useState<boolean>(false);
	const [isLoginError, setIsLoginError] = useState<boolean>(false);
	const { isOpen, toggleModal, delayModal, setDelayModal } = useModal();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const userData = useAppSelector(userDataSelector).userData;

	const {
		handleSubmit,
		control,
		setError,
		formState: { isValid, errors },
	} = useForm<LoginData>({
		defaultValues: {
			email: "",
			password: "",
		},
		mode: "all",
		delayError: 200,
	});

	useEffect(() => {
		setDelayModal(delayModal + DELAY_IN_SECONDS);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const timer: number = 4000;

	useEffect(() => {
		if (userData?.email && isOpen) {
			const userRole = userData?.roles;
			setTimeout(() => {
				handleNavigate(userRole);
			}, timer);
		}
		if (userData?.email && !isOpen) {
			handleNavigate(userData?.roles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, userData, isOpen]);

	useEffect(() => {
		window.localStorage.setItem("previousLoginPath", location.pathname);
	}, [location]);

	const previousPath: string | null = window.localStorage.getItem("previousPath");

	const handleNavigate = (userRole: IUserRoles[]) => {
		if (userRole[0].name === Roles.USER) {
			if (previousPath?.includes(Path.Contacts)) {
				return navigate(Path.PersonalAreaContacts);
			}

			const previousPathArray: string[] = previousPath?.split("/") ?? [];

			if (previousPath?.includes(Path.PlatformCatalogUnSignin)) {
				return navigate(`${Path.PlatformCatalogSignin}/${previousPathArray[2]}`);
			}
			if (previousPath?.includes(Path.SolutionCatalogUnSignin)) {
				return navigate(`${Path.SolutionCatalogSignin}/${previousPathArray[2]}`);
			}
			if (previousPath?.includes(Path.CurrentPlatformUnSignin)) {
				return navigate(`${Path.CurrentPlatformSignin}/${previousPathArray[2]}`);
			}
			if (previousPath?.includes(Path.CurrentSolutionUnSignin)) {
				return navigate(`${Path.CurrentSolutionSignin}/${previousPathArray[2]}`);
			}
			return navigate(Path.PersonalArea);
		} else {
			return navigate(AdminPath.Drafts);
		}
	};

	const handleClickNavigate = () => {
		toggleModal();
		if (userData?.email) {
			const userRole = userData?.roles;
			handleNavigate(userRole);
		}
	};

	const getMessageType = (message: string) => (message === errorServerLoginEmail ? "email" : "password");

	const getErrorLoginStatus = async (loginStatus: boolean) => await setIsLoginError(loginStatus);

	const onSubmit: SubmitHandler<LoginData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		const loginData = {
			email: data.email.trim(),
			password: data.password.trim(),
		};
		loginUser(loginData)
			.then((response: Response) => {
				if (response.ok) {
					getErrorLoginStatus(false).then(() => {
						dispatch(getUserData());
					});
				} else {
					getErrorLoginStatus(true).then(() => {
						toggleModal();
						toggleModal();
					});
				}
			})
			.catch((error: ServerErrorResponse) => {
				const message = (error.response?.data as ServerError).message as keyof typeof serverLoginErrors;

				getErrorLoginStatus(true).then(() => {
					toggleModal();
					toggleModal();
				});

				message &&
					setError(
						getMessageType(message),
						getDefaultServerErrorMessage(serverLoginErrors[message]),
						shouldBeFocusedField,
					);
			});
	};

	const handleCheckboxAction = () => {
		setRememberMeState(!rememberMeState);
	};

	const getModalTitle = () => {
		return isLoginError ? "Что-то пошло не так :(" : "";
	};

	const getModalDescription = () => {
		return isLoginError ? "Проверьте правильность введеных данных" : "";
	};

	return (
		<>
			<Form
				noValidate={true}
				styleName={"authForm"}
				buttonName="Войти"
				isValid={isValid}
				onSubmit={handleSubmit(onSubmit)}>
				<AuthInput
					control={control}
					id={"email"}
					name={"email"}
					label={"Email"}
					type={"email"}
					autoComplete={"username"}
					rules={{
						required: true,
						pattern: emailPattern,
					}}
					error={formHelpers.getEmailError(errors)}
				/>
				<AuthInput
					control={control}
					id={"current-password"}
					name={"password"}
					label={"Пароль"}
					type={"password"}
					autoComplete={"current-password"}
					rules={{
						required: true,
						pattern: passwordPattern,
					}}
					error={formHelpers.getCommonPasswordError(errors, control._formValues.password)}
				/>
				<div className={styles.beforeButtonContainer}>
					<div className={styles.rememberMeWrapper}>
						<input
							type="checkbox"
							name="rememberme"
							id="remembermeactionelement"
							className={styles.rememberMeWrapper__actionElement}
						/>
						<label
							htmlFor="remembermeactionelement"
							className={styles.rememberMeWrapper__title}
							onClick={handleCheckboxAction}>
							Запомнить меня
						</label>
					</div>
					<NavLink to={Path.ResetPassword} className={styles.forgotPasswordLine}>
						<AuthButton content={"Забыли пароль?"} />
					</NavLink>
				</div>
			</Form>
			<ModalAutoHiden
				open={isOpen}
				toggle={handleClickNavigate}
				title={getModalTitle()}
				message={getModalDescription()}
			/>
		</>
	);
};
