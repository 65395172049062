import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../../hooks/useAppSelector";

import CatalogSwitch from "../../../../components/MainLayoutComponents/CatalogSwitch/CatalogSwitch";
import CatalogPlatforms from "../../../../components/MainLayoutComponents/ResourceComponents/CatalogPlatforms/CatalogPlatforms";
import { Title } from "../../../../ui/Title/Title";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { CatalogRoutes } from "../../../../helpers/catalogHelpers";
import { Path } from "../../../../services/router/routes";

import styles from "./PlatformCatalogSignin.module.css";

function PlatformCatalogSignin() {
	const navigator = useNavigate();
	const isLogin = useAppSelector(userDataSelector).userData?.email;

	useEffect(() => {
		if (!isLogin) {
			navigator(Path.Login);
		}
		window.scrollTo({ top: 0 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main className={styles.catalogSigninWrap}>
			<Title title="Каталог" styleName="titlePage" />
			<CatalogSwitch currentResource={CatalogRoutes.Platforms} isLogin={true} />
			<div className={styles.catalogSigninContent}>
				<CatalogPlatforms />
			</div>
		</main>
	);
}

export default PlatformCatalogSignin;
