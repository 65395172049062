import { createAsyncThunk } from "@reduxjs/toolkit";

import { IPlatformCatalogData, IGetPlatformListThunkData } from "../../../../../types/redux/thunkTypes";
import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";
import { gettingPlatformsPaginationEndpoint } from "../../../../api/resources/apiResourcesConstants";

const baseURL = getCorrectBaseUrl("ps");

export const getPlatformListData = createAsyncThunk(
	"platform/getPlatforms",
	async (params: IGetPlatformListThunkData, thunkAPI) => {
		try {
			const response = await fetch(
				`${baseURL}/${gettingPlatformsPaginationEndpoint}?offset=${params.pageNumber - 1}&limit=10`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(params.filterData),
					credentials: "include",
				},
			);
			return (await response.json()) as IPlatformCatalogData;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);
