import { IContextMenuModal } from "../../types/common/UiKitProps";

import styles from "./ContextMenuModal.module.css";

const ContextMenuModal = ({ content, position, closeContextModal }: IContextMenuModal) => {
	return (
		<div className={styles.contextMenuWrap} onClick={closeContextModal}>
			<div
				className={styles.contextMenuContainer}
				style={{ top: position.top, left: position.left }}
				onClick={(e) => e.stopPropagation()}>
				{content.map((item, index) => (
					<button key={index} onClick={() => item.onClick()}>
						{item.title}
					</button>
				))}
			</div>
		</div>
	);
};

export default ContextMenuModal;
