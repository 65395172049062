import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";
import { settingPlatformHistoryEndpoint } from "../../../../api/resources/apiResourcesConstants";
import { IPlatformCatalogContent } from "../../../../../types/redux/thunkTypes";

const baseUrl = getCorrectBaseUrl("ps");

export const getPlatformHistory = createAsyncThunk("history/platforms", async (params, thunkAPI) => {
	try {
		const response = await fetch(`${baseUrl}/${settingPlatformHistoryEndpoint}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
		return (await response.json()) as IPlatformCatalogContent[];
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
