import { BaseSyntheticEvent } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import useModal from "../../../hooks/useModal";

import { RouteMappings } from "../../../types/components/ModalPersonalAreaHeader";
import { Button } from "../../../ui/Button/Button";
import ModalHandelClose from "../ModalHandelClose/ModalHandelClose";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { logoutUser } from "../../../services/api/auth/logoutUser";
import { userLogout } from "../../../services/redux/features/userPart/userData/UserDataSlice";
import { Path } from "../../../services/router/routes";

import styles from "./ModalPersonalAreaHeader.module.css";

const ModalPersonalAreaHeader = () => {
	const navigator = useNavigate();
	const dispatch = useAppDispatch();
	const userData = useAppSelector(userDataSelector).userData;
	const { isOpen, toggleModal } = useModal();
	const location = useLocation();
	const currentPage = Number(useParams().page);
	const { platformId, solutionId } = useParams();

	const faqPosition: number = 5571;

	async function linkToFAQElement() {
		if (location.pathname !== Path.Main) {
			await navigator(Path.Main);
		}
		setTimeout(() => {
			window.scrollTo({ top: faqPosition, behavior: "smooth" });
		}, 0);
	}

	const routeMappings: RouteMappings = {
		[Path.SolutionCatalogSignin + `/${currentPage}`]: Path.SolutionCatalogUnSignin + `/${currentPage}`,
		[Path.PlatformCatalogSignin + `/${currentPage}`]: Path.PlatformCatalogUnSignin + `/${currentPage}`,
		[Path.CurrentPlatformSignin + `/${platformId}`]: Path.CurrentPlatformUnSignin + `/${platformId}`,
		[Path.CurrentSolutionSignin + `/${solutionId}`]: Path.CurrentSolutionUnSignin + `/${solutionId}`,
		[Path.PersonalAreaContacts]: Path.Contacts,
		[Path.PersonalAreaFAQ]: () => linkToFAQElement(),
	};

	async function logOut(event?: BaseSyntheticEvent) {
		event?.preventDefault();
		await logoutUser();
		dispatch(userLogout());

		const targetRoute = routeMappings[location.pathname];
		if (targetRoute) {
			if (typeof targetRoute === "function") {
				return targetRoute();
			} else {
				await navigator(targetRoute);
			}
		} else {
			await navigator(Path.Main);
		}
	}

	return (
		<div className={styles.modal}>
			<ModalHandelClose open={isOpen} toggle={toggleModal} title="Покинуть личный кабинет?">
				<div className={styles.buttonsWrapper}>
					<Button
						onClick={() => {
							logOut();
							toggleModal();
						}}
						content="Выйти"
						styleName="commonMaroonButton closeHeaderModalAction"
					/>
					<Button content="Отмена" onClick={toggleModal} styleName="commonWhiteMaroonButton cancelModalHandleClose" />
				</div>
			</ModalHandelClose>
			<div className={styles.modal__userData}>
				<p className={styles.name}>{`${userData?.firstname} ${userData?.lastname || ""}`}</p>
				<p className={styles.email}>{userData?.email}</p>
			</div>
			<div className={styles.modal__editData}>
				<NavLink to={Path.EditProfile}>
					<p className={styles.edit__profile}>Редактировать профиль</p>
				</NavLink>
				<NavLink to={Path.ChangeUserPassword}>
					<p className={styles.password}>Изменить пароль</p>
				</NavLink>
				<button className={styles.logoutAction} onClick={toggleModal}>
					Выход
				</button>
			</div>
		</div>
	);
};

export default ModalPersonalAreaHeader;
