import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import useAppSelector from "../../../../hooks/useAppSelector";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";

import DownloadImgComponent from "../DownloadImgComponent/DownloadImgComponent";
import { AuthInput } from "../../../AuthLayoutComponents/AuthInput/AuthInput";
import { TextArea } from "../../../../ui/TextArea/TextArea";
import required from "../../../../assets/components/FieldsToFill/RequiredField.svg";
import { pricePattern, domainPattern, resourceName } from "../../../../helpers/AuthConstants";
import { IFieldsToFill } from "../../../../types/common/ComponentProps";

import styles from "./FieldsToFill.module.css";

const FieldsToFill = ({
	control,
	handleImageChange,
	imageUrl,
	solutionNames,
	imageText,
	showInput,
	height,
	errors,
	platform,
	unsavedImg,
}: IFieldsToFill) => {
	const activeElement = useAppSelector(switchSelector).activeElement;
	const [helperFields, setHelperFields] = useState(false);
	const location = useLocation();
	useEffect(() => {
		const isPageForHelperFields = location.pathname.includes("creation");
		setHelperFields(isPageForHelperFields);
	}, [location.pathname]);

	function renderActiveElement() {
		return activeElement === "Платформы" || showInput ? (
			<AuthInput
				control={control}
				id="website"
				name="website"
				label=""
				type="url"
				placeholder="Ссылка на сайт платформы"
				rules={{
					pattern: domainPattern,
				}}
			/>
		) : (
			""
		);
	}

	function renderHelperFields() {
		return (
			<ul className={styles.helperFields}>
				<li className={styles.helperText}>Поле должно быть заполнено</li>
				<li className={styles.helperText}>
					Название не должно совпадать с уже существующими названиями решений и платформ
				</li>
			</ul>
		);
	}

	return (
		<div>
			<DownloadImgComponent
				name="logo"
				onImageSelected={handleImageChange}
				imageUrl={imageUrl ? imageUrl : solutionNames?.logo || platform?.logoUrl || unsavedImg || ""}
				text={imageText}
			/>
			<div className={styles.fieldName}>
				<AuthInput
					control={control}
					id="name"
					name="name"
					label=""
					type="text"
					placeholder="Название"
					rules={{
						required: true,
						pattern: resourceName,
						maxLength: 50,
						minLength: 2,
					}}
				/>
				<img src={required} alt="" className={styles.requiredImg} />
			</div>
			{helperFields && renderHelperFields()}
			<AuthInput
				control={control}
				id="shortDescription"
				name="shortDescription"
				label=""
				type="text"
				placeholder="Краткое описание"
				rules={{
					pattern: resourceName,
					maxLength: 150,
				}}
			/>
			<AuthInput
				control={control}
				id="cost"
				name="cost"
				label=""
				type="text"
				placeholder="Цена"
				rules={{
					pattern: pricePattern,
				}}
			/>
			{renderActiveElement()}
			<TextArea
				control={control}
				id="description"
				name="description"
				placeholder="Полное описание"
				rules={{
					pattern: resourceName,
					maxLength: 1000,
				}}
				label=""
				height={height}
				error={!!errors?.description}
			/>
		</div>
	);
};

export default FieldsToFill;
