import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import { DescriptionSolution } from "../../../components/MainLayoutComponents/ResourceComponents/DescriptionSolution/DescriptionSolution";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { Path } from "../../../services/router/routes";
import { PageTypes } from "../../../helpers/DescriptionSolution";

export const CurrentSolutionSignIn = () => {
	const navigator = useNavigate();
	const isLogin = useAppSelector(userDataSelector).userData?.email;

	useEffect(() => {
		if (!isLogin) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <DescriptionSolution pageType={PageTypes.ADMIN_PANEL} />;
};
