import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IFilterChecked } from "../../../../../../types/pages/SolutionCatalog";
import { IPlatformFilterState } from "../../../../../../types/redux/storeTypes";

const initialState: IPlatformFilterState = {
	selectedFilters: [],
	selectedSolutions: [],
};

const PlatformFilterSlice = createSlice({
	name: "platformFilter",
	initialState,
	reducers: {
		setSelectedFilter: (state, action: PayloadAction<IFilterChecked[]>) => {
			state.selectedFilters = action.payload;
		},
		setSelectedSolution: (state, action: PayloadAction<number[]>) => {
			state.selectedSolutions = action.payload;
		},
	},
});

export const { setSelectedFilter, setSelectedSolution } = PlatformFilterSlice.actions;
export default PlatformFilterSlice.reducer;
