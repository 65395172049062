import { IButtonsSubmit } from "../../../../types/common/ComponentProps";
import { Button } from "../../../../ui/Button/Button";

import deleteImg from "../../../../assets/pages/EditSolutions/delete.svg";
import previewImg from "../../../../assets/pages/EditSolutions/preview.svg";
import saveImg from "../../../../assets/pages/EditSolutions/save.svg";

import styles from "./ButtonsSubmit.module.css";

function ButtonsSubmit({ showButton, unpublished, deleted, preview, unpublishedCreate, onSubmit }: IButtonsSubmit) {
	return (
		<div className={styles.buttonsWrapper}>
			<div className={showButton ? styles.buttons : styles.buttonsCreation}>
				{showButton ? (
					<Button
						styleName="editSolutionDeleteButton"
						content={<img src={deleteImg} alt="delete" />}
						onClick={deleted}
					/>
				) : (
					<></>
				)}
				<Button styleName="editSolutionButton" content={<img src={previewImg} alt="preview" />} onClick={preview} />
				<Button
					styleName="editSolutionButton"
					content={<img src={saveImg} alt="save" />}
					onClick={unpublished || unpublishedCreate}
				/>
				<Button content="Опубликовать" styleName="customButton" onClick={onSubmit} />
			</div>
		</div>
	);
}

export default ButtonsSubmit;
