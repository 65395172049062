import { NavLink, useNavigate } from "react-router-dom";

import { Title } from "../../../ui/Title/Title";
import { AuthButton } from "../../../components/AuthLayoutComponents/AuthButton/AuthButton";
import { LoginForm } from "../../../components/AuthLayoutComponents/LoginForm/LoginForm";
import { Path } from "../../../services/router/routes";

import logo from "../../../assets/layouts/Main/logo.webp";
import { ArrowBackIcon } from "../../../assets/script/ArrowBack";

import styles from "./LoginPage.module.css";

export const LoginPage = () => {
	const navigator = useNavigate();

	const previousPath = window.localStorage.getItem("previousPath");

	const returnBackPage = () => {
		navigator(-1);
		if (previousPath?.includes(Path.PlatformCatalogUnSignin)) {
			const previousPathArray: string[] = previousPath.split("/");
			return navigator(`${Path.PlatformCatalogUnSignin}/${previousPathArray[2]}`);
		}
		if (previousPath?.includes(`${Path.SolutionCatalogUnSignin}`)) {
			const previousPathArray: string[] = previousPath.split("/");
			return navigator(`${Path.SolutionCatalogUnSignin}/${previousPathArray[2]}`);
		}
		if (previousPath !== Path.Main) {
			navigator(-1);
		}
	};

	return (
		<>
			<div className={styles["logo-container"]}>
				<NavLink to={Path.Main}>
					<img src={logo} alt="Townsend logo" />
				</NavLink>
			</div>

			<div className={styles.loginPageWrap}>
				<div className={styles.arrowBackWrap}>
					<button onClick={returnBackPage} className={styles.arrowBackWrap__arrowBackContainer}>
						<ArrowBackIcon />
					</button>
				</div>
				<div className={styles["form-container"]}>
					<Title title={"Войти"} styleName={"title _left"} />
					<LoginForm />
					<div className={styles["toggle-container"]}>
						Нет аккаунта?&nbsp;
						<NavLink to={Path.Signup}>
							<AuthButton content={"Зарегистрироваться"} />
						</NavLink>
					</div>
				</div>
			</div>
			<div></div>
		</>
	);
};
