import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { ISolutionResponse } from "../../../../types/pages/SolutionCatalog";
import { IPlatformResponse, TSolutionList } from "../../../../types/pages/Platform";
import { ICheckBoxBlock } from "../../../../types/common/ComponentProps";
import { CheckBoxFetchData } from "../../../../types/components/CheckBoxBlock";
import FilterTypes from "../FiltersCheckBoxBlock/FilterTypes/FilterTypes";
import SolutionsFilter from "../FiltersCheckBoxBlock/SolutionFilter/SolutionFilter";
import { getPlatformFilterList } from "../../../../services/api/resources/getPlatformFilterList";
import { getSolutionFilterList } from "../../../../services/api/resources/getSolutionFilterList";
import { getSolutionsNames } from "../../../../services/api/admin/getSolutionsNames";
import { updateSelectedFilters, updateSelectedSolutions } from "../../../../utils/CheckBoxBlockUtils";
import { CatalogTitles } from "../../../../helpers/catalogHelpers";

import { AdminPath } from "../../../../services/router/routes";

import styles from "./CheckBoxBlock.module.css";

const CheckBoxBlock = ({
	type,
	selectedFilters,
	setSelectedFilters,
	checkedFilters,
	selectedSolution,
	setSelectedSolutionIds,
	checked,
	checkedCreationFilter,
	checkedCreationSolutions,
	checkedSolutions,
}: ICheckBoxBlock) => {
	const [solutionFilters, setSolutionFilters] = useState<ISolutionResponse | undefined>();
	const [platformFilters, setPlatformFilters] = useState<IPlatformResponse | undefined>();
	const [solutions, setSolutions] = useState<TSolutionList[] | undefined>();
	const [searchText, setSearchText] = useState("");
	const location = useLocation();

	const fetchData: CheckBoxFetchData = async (setSolutionFilters, setPlatformFilters, setSolutions) => {
		if (location.pathname.includes(AdminPath.PlatformEdit) || type === CatalogTitles.Platforms) {
			const platformListResponse = await getPlatformFilterList();
			setPlatformFilters(await platformListResponse.json());
			const solutionsNamesResponse = await getSolutionsNames();
			setSolutions(await solutionsNamesResponse.json());
		}
		if (location.pathname.includes(AdminPath.SolutionsEdit) || type === CatalogTitles.Solutions) {
			const filterListResponse = await getSolutionFilterList();
			setSolutionFilters(await filterListResponse.json());
		}
	};

	useEffect(() => {
		fetchData(setSolutionFilters, setPlatformFilters, setSolutions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, location.pathname]);

	useEffect(() => {
		updateSelectedFilters(
			checkedFilters,
			platformFilters,
			solutionFilters,
			setSelectedFilters,
			checkedCreationFilter,
			checked,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkedFilters, setSelectedFilters, solutionFilters, platformFilters, checkedCreationFilter]);

	useEffect(() => {
		updateSelectedSolutions(setSelectedSolutionIds, solutions, checked, checkedCreationSolutions, checkedSolutions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked, checkedCreationSolutions, setSelectedSolutionIds, solutions]);

	const renderFilters = () => {
		if (type === CatalogTitles.Solutions || location.pathname.includes(AdminPath.SolutionsEdit)) {
			return (
				<FilterTypes
					filters={solutionFilters}
					selectedFilters={selectedFilters}
					setSelectedFilters={setSelectedFilters}
					type={CatalogTitles.Solutions}
				/>
			);
		} else {
			return (
				<>
					<FilterTypes
						filters={platformFilters}
						selectedFilters={selectedFilters}
						setSelectedFilters={setSelectedFilters}
						type={CatalogTitles.Platforms}
					/>
					<SolutionsFilter
						solutions={solutions}
						searchText={searchText}
						setSearchText={setSearchText}
						selectedSolutionIds={selectedSolution || []}
						setSelectedSolutionIds={setSelectedSolutionIds}
					/>
				</>
			);
		}
	};

	return <div className={styles.filterSection}>{renderFilters()}</div>;
};

export default CheckBoxBlock;
