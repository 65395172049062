import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import DescriptionPlatform from "../../../components/MainLayoutComponents/ResourceComponents/DescriptionPlatform/DescriptionPlatform";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath, Path } from "../../../services/router/routes";
import { DescriptionPlatformTypes } from "../../../helpers/DescriptionPlatform";
import { Roles } from "../../../helpers/roles";

function PlatformPreview() {
	const params = useParams();
	const navigator = useNavigate();
	const location = useLocation();
	const userData = useAppSelector(userDataSelector).userData;

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.PlatformPreview) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const platformPreviewPageType: DescriptionPlatformTypes =
		String(params.platformId ?? 0) === "0" ? DescriptionPlatformTypes.UnsavedData : DescriptionPlatformTypes.AdminPanel;

	return (
		<div>
			<DescriptionPlatform pageType={platformPreviewPageType} isDisabledSolutionCard={true} />
		</div>
	);
}

export default PlatformPreview;
