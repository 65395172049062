export const WhatsAppIcon = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12,0.5C5.7,0.5,0.5,5.7,0.5,12c0,2.4,0.7,4.6,2,6.5l-1.3,3.9c-0.1,0.2,0,0.4,0.1,0.5C1.5,23,1.7,23.1,1.9,23l4-1.3   c1.8,1.2,4,1.8,6.1,1.8c6.3,0,11.5-5.2,11.5-11.5C23.5,5.7,18.3,0.5,12,0.5z M18.9,16.7c-0.3,0.9-1.5,1.7-2.5,1.9   c-0.2,0-0.4,0.1-0.7,0.1c-0.8,0-1.8-0.3-3.4-0.9c-2-0.8-4-2.6-5.6-4.9c0,0-0.1-0.1-0.1-0.1C6,12,5.1,10.6,5.1,9.1   c0-1.7,0.9-2.6,1.2-3c0.4-0.4,0.9-0.6,1.5-0.6c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.5,0,0.8,0.2,1.1,0.8l0.2,0.4   c0.3,0.7,0.7,1.8,0.8,2c0.2,0.4,0.2,0.7,0,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3   C9.4,11,9.4,11,9.4,11c0.3,0.5,0.9,1.4,1.7,2.1c1.1,1,1.9,1.3,2.3,1.5l0.1,0c0.1,0,0.2,0.1,0.2,0c0.2-0.2,0.4-0.6,0.7-0.9l0.1-0.1   c0.3-0.4,0.7-0.5,0.9-0.5c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,2.2,1.1l0.2,0.1c0.3,0.1,0.5,0.2,0.6,0.5C19.2,15.1,19.1,16,18.9,16.7   z"
				fill="white"
				transform="scale(0.85) translate(11, 11)"
			/>
		</svg>
	);
};
