export const posts = [
	{
		id: 0,
		title: "Статья",
		// eslint-disable-next-line sonarjs/no-duplicate-string
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
	{
		id: 1,
		title: "Статья",
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
	{
		id: 2,
		title: "Статья",
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
	{
		id: 3,
		title: "Статья",
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
	{
		id: 4,
		title: "Статья",
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
	{
		id: 5,
		title: "Статья",
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
	{
		id: 6,
		title: "Статья",
		text: "Lorem ipsum dolor sit amet consectetur. Non venenatis cras ultrices eu etiam purus.",
	},
];
