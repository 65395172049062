import { CrudMethods, IActionFilterGroup, IActionStaffModal, ISearchLineData } from "../../../types/api/AdminApiTypes";
import { ICreateResult, IEditResult } from "../../../types/components/FilterGroup";
import { CatalogRoutes } from "../../../helpers/catalogHelpers";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

async function actionFilterGroup({ endpoint, method, filterData }: IActionFilterGroup) {
	const baseURL = getCorrectBaseUrl("ps");

	if (filterData) {
		return await fetch(`${baseURL}/${endpoint}`, {
			method: method,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(filterData),
			credentials: "include",
		});
	}

	return await fetch(`${baseURL}/${endpoint}`, {
		method: method,
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}

export async function updateFilterGroup(resourceId: number, resourceType: CatalogRoutes, filterData: IEditResult) {
	const correctBaseEndpoint =
		resourceType === CatalogRoutes.Platforms ? CatalogRoutes.Platforms : CatalogRoutes.Solutions;
	return await actionFilterGroup({
		endpoint: `${correctBaseEndpoint}/filters/${resourceId}`,
		method: CrudMethods.put,
		filterData: filterData,
	} as IActionStaffModal);
}

export async function removeFilterGroup(resourceId: number, resourceType: CatalogRoutes) {
	const correctBaseEndpoint =
		resourceType === CatalogRoutes.Platforms ? CatalogRoutes.Platforms : CatalogRoutes.Solutions;
	return await actionFilterGroup({
		endpoint: `${correctBaseEndpoint}/filters/${resourceId}`,
		method: CrudMethods.delete,
	} as IActionStaffModal);
}

export async function createFilterGroup(resourceType: CatalogRoutes, filterData: ICreateResult) {
	const correctBaseEndpoint =
		resourceType === CatalogRoutes.Platforms ? CatalogRoutes.Platforms : CatalogRoutes.Solutions;
	return await actionFilterGroup({
		endpoint: `${correctBaseEndpoint}/filters`,
		method: CrudMethods.post,
		filterData: filterData,
	} as IActionStaffModal);
}

export async function searchFilterGroup(searchLineData: ISearchLineData, resourceType: CatalogRoutes) {
	const correctBaseEndpoint =
		resourceType === CatalogRoutes.Platforms ? CatalogRoutes.Platforms : CatalogRoutes.Solutions;
	return await actionFilterGroup({
		endpoint: `${correctBaseEndpoint}/filters/search`,
		method: CrudMethods.post,
		filterData: searchLineData,
	} as IActionStaffModal);
}
