import { IPlatformHistoryData } from "../../../types/api/ResourcesApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { settingPlatformHistoryEndpoint } from "./apiResourcesConstants";

export async function setPlatformHistory(platformHistoryData: IPlatformHistoryData) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${settingPlatformHistoryEndpoint}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(platformHistoryData),
		credentials: "include",
	});
}
