import { useState, useRef, useEffect, BaseSyntheticEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { HandleImageErrorType } from "../../../../types/components/PlatformCatalog";
import { ExternalMouseEvent } from "../../../../types/pages/DraftsPreview";
import { setActiveSolution } from "../../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSlice";
import { setResourceId } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";

import { ContextMenu } from "../../../../ui/ContextMenu/ContextMenu";
import verticalPoints from "../../../../assets/components/SolutionAdmin/verticalEllipsis.svg";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { ISolutionCard } from "../../../../types/common/ComponentProps";
import { Roles } from "../../../../helpers/roles";
import arrow from "../../../../assets/components/PlatformCatalogCard/arrowRightCatalog.svg";
import { AdminPath, Path } from "../../../../services/router/routes";
import FavoriteAction from "../../../AuthLayoutComponents/FavoriteAction/FavoriteAction";
import { getCorrectResourceLogo } from "../../../../utils/getCorrectResourceLogo";
import { SwitchContentTitles } from "../../../../helpers/platformAdmin";

import mockSolutionLogo from "../../../../assets/pages/SolutionCatalog/mocksCardIcon.png";

import style from "./SolutionCard.module.css";

export const SolutionCard = ({
	id,
	title,
	shortDescription,
	logo,
	role,
	isFavorite,
	menuContent,
	toggleContextMenu,
	setFavorite,
	isDisabledCard,
}: ISolutionCard) => {
	const [imageError, setImageError] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const switchActiveElement = useAppSelector(switchSelector).activeElement;
	const userData = useAppSelector(userDataSelector).userData;
	const isUserPart = !([Roles.ADMIN, Roles.SUPERADMIN, Roles.MODERATOR] as string[]).includes(role);
	const isDraftsPreviewPage: boolean = Boolean(location.pathname.includes(AdminPath.Drafts));
	const modalContainer = useRef<HTMLUListElement>(null);
	const menuContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.addEventListener("mousedown", (e) => {
			if (menuContainer.current && !menuContainer.current.contains(e.target as Node)) setIsOpenMenu(false);
		});
	}, [modalContainer]);

	const handleClickBuy = () => {
		dispatch(setActiveSolution(title));
		navigate(userData?.id ? Path.PersonalAreaContacts : Path.Contacts);
	};

	const handleImageError: HandleImageErrorType = () => {
		return (event: BaseSyntheticEvent) => {
			return setImageError(true);
		};
	};

	const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (isDisabledCard) {
			return null;
		}

		dispatch(setResourceId(id));
		if (isDraftsPreviewPage && toggleContextMenu !== undefined && toggleContextMenu !== void 0)
			return toggleContextMenu(event as unknown as ExternalMouseEvent);
		setIsOpenMenu(!isOpenMenu);
	};

	const getCorrectCurrentSolutionLink = (): string => {
		return (userData?.email ? Path.CurrentSolutionSignin : Path.CurrentSolutionUnSignin) + `/${id}`;
	};

	const renderActionButton = () => {
		if (role === Roles.USER) {
			return (
				<span className={style.favoriteAction}>
					<FavoriteAction resourceId={id ?? 0} isFavoriteContent={isFavorite ?? false} setFavorite={setFavorite} />
				</span>
			);
		}

		if (role === Roles.MODERATOR && switchActiveElement === SwitchContentTitles.Deleted) {
			return null;
		}

		return (
			<span className={style.ellipsisAction} onClick={openMenu}>
				<img src={verticalPoints} alt="action" />{" "}
			</span>
		);
	};

	function renderCardInteraction() {
		return (
			isUserPart && (
				<div className={style.cardInteraction}>
					<button className={style.buyButton} onClick={handleClickBuy}>
						Купить
					</button>
					<Link to={getCorrectCurrentSolutionLink()} className={style.cardLink}>
						<p className={style.linkName}>Подробнее</p>
						<img src={arrow} alt="" />
					</Link>
				</div>
			)
		);
	}

	return (
		<div className={style.cardWrapper}>
			<div ref={menuContainer} className={style.top}>
				<div className=""></div>
				<ContextMenu refObj={modalContainer} content={menuContent ?? []} isOpen={isOpenMenu} />
				<img
					className={style.solutionLogo}
					src={getCorrectResourceLogo(logo, mockSolutionLogo, imageError)}
					alt="logo"
					onError={handleImageError()}
				/>
				{renderActionButton()}
			</div>
			<div className={style.cardInfo}>
				<p className={style.cardTitle}>{title}</p>
				<p className={style.cardDescription}>{shortDescription}</p>
				{renderCardInteraction()}
			</div>
		</div>
	);
};
