import { useLocation, useNavigate } from "react-router-dom";

import { IReturnBackArrow } from "../../types/common/UiKitProps";
import { ReturnBackArrowTypes } from "../../helpers/ReturnBack";
import { Path } from "../../services/router/routes";

import { ArrowBackIcon } from "../../assets/script/ArrowBack";
import returnBackWhite from "../../assets/components/ReturnBackArrow/returnBackWhite1.svg";

import styles from "./ReturnBackArrow.module.css";

const ReturnBackArrow = ({ type }: IReturnBackArrow) => {
	const navigator = useNavigate();
	const location = useLocation();

	const previousLoginPath: string | null = window.localStorage.getItem("previousLoginPath");

	function returnPreviousPage() {
		if (previousLoginPath?.includes(Path.Login) && location.pathname.includes(Path.CurrentPlatformSignin)) {
			return navigator(`${Path.PlatformCatalogSignin}/1`);
		}
		return navigator(-1);
	}

	return (
		<button
			className={
				type === ReturnBackArrowTypes.EditDataPagesType ? styles.editDataPagesStyle : styles.descriptionPageStyle
			}
			onClick={returnPreviousPage}>
			{type === ReturnBackArrowTypes.EditDataPagesType ? (
				<ArrowBackIcon />
			) : (
				<img src={returnBackWhite} alt="arrow back" />
			)}
		</button>
	);
};

export default ReturnBackArrow;
