import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAdminPlatformsData, IDeletedPlatformsState } from "../../../../../../types/redux/storeTypes";

import { getDeletedPlatformsData } from "./DeletedPlatformsThunk";

const deletedPlatformsInitialState: IDeletedPlatformsState = {
	deletedPlatforms: [],
	pageNumber: 0,
	platformsOnPage: 0,
	loading: false,
	error: null,
};

const DeletedPlatformsSlice = createSlice({
	name: "deletedPlatforms",
	initialState: deletedPlatformsInitialState,
	reducers: {
		clearDeletedPlatformsData: (state: IDeletedPlatformsState) => {
			state.deletedPlatforms = deletedPlatformsInitialState.deletedPlatforms;
		},
	},
	extraReducers(builder) {
		builder.addCase(
			getDeletedPlatformsData.fulfilled,
			(state: IDeletedPlatformsState, action: PayloadAction<IAdminPlatformsData>) => {
				state.deletedPlatforms = action.payload.content;
				state.pageNumber = action.payload.totalPages;
				state.platformsOnPage = action.payload.numberOfElements;
				state.loading = false;
			},
		);
		builder.addCase(
			getDeletedPlatformsData.rejected,
			(state: IDeletedPlatformsState, action: PayloadAction<string | unknown>) => {
				state.error = action.payload;
				state.loading = false;
			},
		);
		builder.addCase(getDeletedPlatformsData.pending, (state: IDeletedPlatformsState) => {
			state.loading = true;
		});
	},
});

export const { clearDeletedPlatformsData } = DeletedPlatformsSlice.actions;
export default DeletedPlatformsSlice.reducer;
