import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../../hooks/useAppSelector";
import useAppDispatch from "../../../../hooks/useAppDispatch";

import { IParams } from "../../../../types/api/AdminApiTypes";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import PlatformCatalogCard from "../../../MainLayoutComponents/ResourceComponents/PlatformCatalogCard/PlatformCatalogCard";
import { platformFavoritesSelector } from "../../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesSelector";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";
import { getPlatformFavorites } from "../../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesThunk";
import { Path } from "../../../../services/router/routes";
import { CatalogRoutes, CatalogTitles, PlatformCardTypes } from "../../../../helpers/catalogHelpers";

import styles from "./FavoritePlatforms.module.css";

function FavoritePlatforms() {
	const [favorite, setFavorite] = useState<boolean>(true);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const { favoritePlatforms, totalPages, error } = useAppSelector(platformFavoritesSelector);
	const params = useParams();

	const favoritePlatformListErrorContent: string = "Ошибка загрузки списка избранных платформ!";

	useEffect(() => {
		window.scrollTo({ top: 0 });
		(async () => {
			if (activeSwitchElement !== CatalogTitles.Platforms) {
				return null;
			}
			const thunkParams: IParams = {
				offset: Number(params.page ?? 1),
			};

			return dispatch(getPlatformFavorites(thunkParams));
		})();
	}, [dispatch, activeSwitchElement, params.page]);

	useEffect(() => {
		(async () => {
			if (activeSwitchElement === CatalogTitles.Platforms) {
				const thunkParams: IParams = {
					offset: Number(params.page ?? 1),
				};
				await dispatch(getPlatformFavorites(thunkParams));
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favorite]);

	useEffect(() => {
		if (Number(params.page) !== 1 && favoritePlatforms.length === 0) {
			const previousPage = Number(params.page) - 1;
			navigate(`${Path.PersonalAreaFavorites}/${CatalogRoutes.Platforms}/${previousPage}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favoritePlatforms.length, navigate]);

	function getCorrectPaginationSearchLine(): string {
		return `${Path.PersonalAreaFavorites}/${CatalogRoutes.Platforms}`;
	}

	function renderFavoritePlatforms() {
		return favoritePlatforms.map((item) => (
			<PlatformCatalogCard
				key={item.id}
				type={PlatformCardTypes.CatalogType}
				solutionNumber={item.totalSolution ?? 0}
				title={item.name}
				description={item.shortDescription}
				icon={item.logoUrl}
				cardId={item.id}
				isFavoritePlatform={item.favorite === true}
				setFavorite={() => setFavorite(!favorite)}
			/>
		));
	}

	return !favoritePlatforms.length ? (
		<p className={styles.emptyListMessage}>{error ? favoritePlatformListErrorContent : ""}</p>
	) : (
		<div className={styles.listPlatform}>
			{renderFavoritePlatforms()}
			<PaginationElement
				currPage={Number(params.page ?? 1)}
				pageCount={totalPages}
				searchLine={getCorrectPaginationSearchLine()}
			/>
		</div>
	);
}

export default FavoritePlatforms;
