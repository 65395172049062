import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import cn from "classnames/dedupe";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useDebounce from "../../../../hooks/useDebounce";

import { IPlatformFilterData, IPlatformFilterListData } from "../../../../types/components/PlatformCatalog";
import {
	IFilterRequestData,
	IGetPlatformListThunkData,
	IPlatformCatalogContent,
} from "../../../../types/redux/thunkTypes";
import { SearchInput } from "../../../../ui/SearchInput/SearchInput";
import { Button } from "../../../../ui/Button/Button";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import PlatformCatalogCard from "../PlatformCatalogCard/PlatformCatalogCard";
import { Filter } from "../../Filter/Filter";
import { SelectedFilterVariant } from "../../SelectedFilterVariant/SelectedFilterVariant";
import { getPlatformListData } from "../../../../services/redux/features/userPart/platformCatalog/PlatformCatalogThunk";
import PlatformCatalogSelector from "../../../../services/redux/features/userPart/platformCatalog/PlatformCatalogSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { getPlatformFilterList } from "../../../../services/api/resources/getPlatformFilterList";
import { searchPlatforms } from "../../../../services/api/resources/searchPlatforms";
import { setPlatformsData } from "../../../../services/redux/features/userPart/platformCatalog/PlatformCatalogSlice";
import { PlatformCardTypes, platformListErrorContent } from "../../../../helpers/catalogHelpers";
import { Path } from "../../../../services/router/routes";
import { searchDelay } from "../../../../helpers/delayConstants";

import allFiltersArrow from "../../../../assets/components/PlatformCatalog/allFiltersArrow.svg";

import style from "./CatalogPlatforms.module.css";

const CatalogPlatforms = () => {
	const [filterList, setFilterList] = useState<IPlatformFilterData[]>([]);
	const [filterGroups, setFilterGroups] = useState<IFilterRequestData[]>([]);
	const [selectedFilterGroupTitles, setSelectedFilterGroupTitles] = useState<string[]>([]);
	const [toggleAdditionalFilters, setToggleAdditionalFilters] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>();
	const [isFiltersError, setIsFiltersError] = useState<boolean>(false);
	const [isSearchPlatformError, setIsSearchPlatformError] = useState<boolean>(false);

	const { catalogPlatforms, pageNumber, loading, error } = useAppSelector(PlatformCatalogSelector);
	const userData = useAppSelector(userDataSelector).userData;
	const debouncedSearch = useDebounce(handleSearchPlatforms, searchDelay);
	const params = useParams();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const displayedFiltersDefaultNumber: number = 9;
	const filterListErrorContent: string = "Ошибка загрузки списка фильтров!";
	const searchPlatformErrorContent: string = "Ошибка поиска платформ!";

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
		window.localStorage.setItem("previousLoginPath", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		(async () => {
			if (filterGroups.length || searchValue) {
				return null;
			}
			window.scrollTo({ top: 0 });
			getCorrectPlatformData(Number(params?.page) ?? 1, []);
			await getPlatformFilterList().then(async (response: Response) => {
				if (response.ok) {
					const filterListResponse: Promise<IPlatformFilterListData> = await response.json();
					setFilterList((await filterListResponse).filters);
					setIsFiltersError(false);
				} else {
					setIsFiltersError(true);
				}
			});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, params.page, filterGroups, searchValue]);

	useEffect(() => {
		const updatedFilterGroupTitles: string[] = [];

		filterGroups.forEach((filterGroup) => {
			updatedFilterGroupTitles.push(filterGroup.name);
		});

		setSelectedFilterGroupTitles(updatedFilterGroupTitles);
	}, [filterGroups]);

	useEffect(() => {
		if (searchValue) {
			debouncedSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	async function handleSearchPlatforms() {
		if (!searchValue || [1, 2].includes(searchValue.length)) {
			return null;
		}

		await searchPlatforms({ value: searchValue }).then(async (response: Response) => {
			if (response.ok) {
				const searchedPlatformsData: Promise<IPlatformCatalogContent[]> = await response.json();
				dispatch(setPlatformsData(await searchedPlatformsData));
				setIsSearchPlatformError(false);
			} else {
				setIsSearchPlatformError(true);
			}
		});
	}

	const getCorrectPlatformData = (pageNumber: number, filterGroups: IFilterRequestData[]) => {
		const platformData: IGetPlatformListThunkData = {
			pageNumber: pageNumber,
			filterData: {
				filterList: filterGroups,
			},
		};
		return dispatch(getPlatformListData(platformData));
	};

	const addIncomingFilter = (filterGroupTitle: string, incomingFilterTitle: string) => {
		const incomingFilterGroup: IFilterRequestData = {
			name: filterGroupTitle,
			values: [incomingFilterTitle],
		};

		const filterData = filterList.find((currFilter) => currFilter.name === filterGroupTitle);

		if (!filterGroups.length) {
			return [incomingFilterGroup];
		}

		const isFilterGroupExists = filterGroups.find((filterGroup) => filterGroup.name === filterGroupTitle);

		if (isFilterGroupExists) {
			return filterGroups.map((filterGroupData) => {
				if (filterGroupData.name === filterGroupTitle) {
					if (filterData && !filterData.multipleChoice) {
						filterGroupData.values = incomingFilterGroup.values;
					} else {
						filterGroupData.values = [...filterGroupData.values, incomingFilterTitle];
					}
				}
				return filterGroupData;
			});
		} else {
			return [...filterGroups, incomingFilterGroup];
		}
	};

	const removeIncomingFilter = (filterGroupTitle: string, incomingFilterTitle: string) => {
		const updatedFilterGroups: IFilterRequestData[] = filterGroups.map((filterGroup) => {
			if (filterGroupTitle === filterGroup.name && filterGroup.values.includes(incomingFilterTitle)) {
				return {
					name: filterGroup.name,
					values: filterGroup.values.filter((filterValue) => filterValue !== incomingFilterTitle),
				};
			}
			return filterGroup;
		});
		return updatedFilterGroups.filter((filterGroup) => filterGroup.values.length);
	};

	const fillCurrentFilter = (checked: boolean, value: string, title: string) => {
		if (checked) {
			setFilterGroups(addIncomingFilter(title, value));
		} else {
			setFilterGroups(removeIncomingFilter(title, value));
		}
	};

	const deleteFromCurrentFilter = (filterTitle: string, filterValue: string) => {
		setFilterGroups(removeIncomingFilter(filterTitle, filterValue));
	};

	const activeAdditionalFilters = () => {
		setToggleAdditionalFilters(!toggleAdditionalFilters);
	};

	function getCorrectPaginationSearchLine(): string {
		return userData?.email ? Path.PlatformCatalogSignin : Path.PlatformCatalogUnSignin;
	}

	const clearAllSelectedFilters = () => {
		return setFilterGroups([]);
	};

	function filterPlatformCatalog() {
		if (!filterGroups.length) return null;
		window.scrollTo({ top: 0 });
		return getCorrectPlatformData(1, filterGroups);
	}

	const renderSelectedFilterValues = () => {
		return filterGroups.map((filterGroup) => {
			return filterGroup.values.map((filterGroupValue) => (
				<SelectedFilterVariant
					onClick={() => deleteFromCurrentFilter(filterGroup.name, filterGroupValue)}
					key={crypto.randomUUID()}
					value={filterGroupValue}
				/>
			));
		});
	};

	const displayPlatformFilters = () => {
		return !filterList || !filterList.length ? (
			<h3 className={cn(style.emptyFiltersListLine, style.emptyListTextLine)}>
				{isFiltersError ? filterListErrorContent : ""}
			</h3>
		) : (
			<>
				{filterList.map((item, index) => {
					if (!toggleAdditionalFilters && index > displayedFiltersDefaultNumber) {
						return null;
					}
					return (
						<Filter
							onChange={fillCurrentFilter}
							key={item.id}
							id={item.id}
							title={item.name}
							content={item.values}
							isSingleChoice={!item.multipleChoice}
							selectedFiltersData={filterGroups}
							selectedFilterGroupTitles={selectedFilterGroupTitles}
							tooltipText={item.description ?? ""}
						/>
					);
				})}
				<Button
					content="Применить фильтры"
					styleName="commonWhiteMaroonButton platformCatalogFilters"
					onClick={filterPlatformCatalog}
				/>
				<button className={style.clearAllSelectedFiltersAction} onClick={clearAllSelectedFilters}>
					Сбросить фильтры
				</button>
				<button className={style.allFiltersActionWrap} onClick={activeAdditionalFilters}>
					<span>Ещё фильтры</span>
					<img
						src={allFiltersArrow}
						alt="filter arrow"
						className={cn(style.allFiltersActionWrap__arrow, {
							[style.allFiltersActionWrap__arrow__show]: toggleAdditionalFilters,
						})}
					/>
				</button>
			</>
		);
	};

	const displayPlatformContent = () => {
		return (
			<div className={style.catalogWrap}>
				<SearchInput
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="Введите название платформы"
				/>
				<ul className={style.selectedFiltersWrap}>{renderSelectedFilterValues()}</ul>
				{!catalogPlatforms || !catalogPlatforms.length || loading ? (
					<h3 className={cn(style.emptyPlatformListLine, style.emptyListTextLine)}>
						{error ? platformListErrorContent : ""}
						{isSearchPlatformError && searchValue ? searchPlatformErrorContent : ""}
					</h3>
				) : (
					<>
						<section className={style.catalogContentWrap}>
							<div className={style.cardsWrap}>
								{catalogPlatforms.map((item) => (
									<PlatformCatalogCard
										type={PlatformCardTypes.CatalogType}
										key={item.id}
										cardId={item.id}
										title={item.name}
										description={item.shortDescription}
										solutionNumber={item.totalSolution}
										icon={item.logoUrl}
										isFavoritePlatform={item.favorite}
									/>
								))}
							</div>
							<PaginationElement
								currPage={Number(params.page ?? 1)}
								pageCount={pageNumber ?? 1}
								searchLine={getCorrectPaginationSearchLine()}
							/>
						</section>
					</>
				)}
			</div>
		);
	};

	return (
		<main className={style.platformCatalogWrap}>
			<div className={style.catalogContainer}>
				<aside className={style.filtersWrap}>
					<ul className={style.filter}>{displayPlatformFilters()}</ul>
				</aside>
				{displayPlatformContent()}
			</div>
		</main>
	);
};

export default CatalogPlatforms;
