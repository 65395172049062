import { useState } from "react";

import ClientReview from "../../../components/MainLayoutComponents/ClientReview/ClientReview";

import reviewArrow from "../../../assets/pages/Service/reviewArrow.svg";

import { clientReviewData } from "../../../mocks/servicePage";

import styles from "./ReviewsSlider.module.css";

function ReviewsSlider() {
	const [count, setCount] = useState<number>(0);

	const sliderCardWidth = 1000;
	const reviewsLength: number = Math.ceil(clientReviewData.length);

	const getStyleTransformValue = () => {
		return `translateX(${count !== 0 ? -count * sliderCardWidth * count + "px" : -count * sliderCardWidth + "px"})`;
	};

	const handleClickLeft = () => {
		setCount((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : reviewsLength - 1));
	};

	const handleClickRight = () => {
		setCount((prevIndex) => (prevIndex < reviewsLength - count ? prevIndex + 1 : 0));
	};

	return (
		<div className={styles.reviewsSliderWrap}>
			<button onClick={handleClickLeft}>
				<img src={reviewArrow} alt="leftArrow" className={styles.reviewsSliderWrap__leftArrow} />
			</button>
			<div className={styles.reviewContentWrap}>
				<div className={styles.reviewContent} style={{ transform: getStyleTransformValue() }}>
					{clientReviewData.map((reviewPair) => (
						<ClientReview key={crypto.randomUUID()} clientsData={reviewPair} />
					))}
				</div>
			</div>
			<button onClick={handleClickRight}>
				<img src={reviewArrow} alt="rightArrow" className={styles.reviewsSliderWrap__rightArrow} />
			</button>
		</div>
	);
}

export default ReviewsSlider;
