import { Path } from "../services/router/routes";

// eslint-disable-next-line import/order
import { CatalogRoutes } from "./catalogHelpers";

import carbon from "../assets/layouts/PersonalArea/NavIcon/carbon.svg";
import dashboard from "../assets/layouts/PersonalArea/NavIcon/dashboard.svg";
import FAQ from "../assets/layouts/PersonalArea/NavIcon/FAQ.svg";
import heart from "../assets/layouts/PersonalArea/NavIcon/heart.svg";
import tPhone from "../assets/layouts/PersonalArea/NavIcon/tPhone.svg";
import lamp from "../assets/layouts/PersonalArea/NavIcon/lamp.svg";

export const navIconArr = [
	{ icon: dashboard, title: "Дашборд", id: 0, src: "personalArea" },
	{ icon: lamp, title: "Подобрать решение", id: 1, src: Path.SolutionCatalogSignin + "/1" },
	{ icon: carbon, title: "Подобрать платформу", id: 2, src: Path.PlatformCatalogSignin + "/1" },
	{ icon: tPhone, title: "Связь с нами", id: 3, src: "personalArea/contacts" },
	{ icon: heart, title: "Избранное", id: 4, src: Path.PersonalAreaFavorites + `/${CatalogRoutes.Solutions}/1` },
	{ icon: FAQ, title: "FAQ", id: 5, src: "personalAreaFAQ" },
];
