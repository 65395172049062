import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { logoutEndpoint } from "./apiConstants";

export async function logoutUser(): Promise<Response> {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${logoutEndpoint}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
