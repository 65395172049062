import { IAuthButton } from "../../../types/common/ComponentProps";

import styles from "./AuthButton.module.css";

export const AuthButton = ({ content, onClick }: IAuthButton) => {
	return (
		<button className={styles["auth-button"]} onClick={onClick} type={"button"}>
			{content}
		</button>
	);
};
