import { NavLink, useLocation } from "react-router-dom";

import { Title } from "../../../ui/Title/Title";
import { AuthButton } from "../../../components/AuthLayoutComponents/AuthButton/AuthButton";
import { SignupForm } from "../../../components/AuthLayoutComponents/SignupForm/SignupForm";
import { Path } from "../../../services/router/routes";

import logo from "../../../assets/layouts/Main/logo.webp";
import { ArrowBackIcon } from "../../../assets/script/ArrowBack";

import styles from "./SignupPage.module.css";

export const SignupPage = () => {
	const location = useLocation();
	const fromPage = location.state?.from || Path.Main;

	return (
		<>
			<div className={styles["logo-container"]}>
				<NavLink to={Path.Main}>
					<img src={logo} alt="Townsend logo" />
				</NavLink>
			</div>

			<div className={styles.signupPageWrap}>
				<div className={styles.arrowBackWrap}>
					<NavLink to={fromPage}>
						<ArrowBackIcon />
					</NavLink>
				</div>
				<div className={styles["form-container"]}>
					<Title title={"Зарегистрироваться"} styleName={"title"} />
					<SignupForm />
					<div className={styles["toggle-container"]}>
						Уже есть аккаунт?&nbsp;
						<NavLink to={Path.Login}>
							<AuthButton content={"Войти"} />
						</NavLink>
					</div>
				</div>
			</div>

			<div></div>
		</>
	);
};
