import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import { Title } from "../../../ui/Title/Title";
import AdminCatalogSwitch from "../../../components/AdminLayoutComponents/AdminCatalogSwitch/AdminCatalogSwitch";
import { PublishedSolutions } from "../../../components/AdminLayoutComponents/Solutions/PublishedSolutions/PublishedSolutions";
import { DraftedSolutions } from "../../../components/AdminLayoutComponents/Solutions/DraftedSolutions/DraftedSolutions";
import { DeletedSolutions } from "../../../components/AdminLayoutComponents/Solutions/DeletedSolutions/DeletedSolutions";
import { AdminCatalogRoutes } from "../../../helpers/catalogHelpers";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath, Path } from "../../../services/router/routes";
import { Roles } from "../../../helpers/roles";

import style from "./SolutionsAdmin.module.css";

export const SolutionAdmin = () => {
	const [isModerator, setIsModerator] = useState<boolean>(false);
	const userData = useAppSelector(userDataSelector).userData;
	const params = useParams();
	const location = useLocation();
	const navigator = useNavigate();

	const activeSwitchElement: AdminCatalogRoutes = params?.pagepart as AdminCatalogRoutes;

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (
			location.pathname.includes(AdminPath.Solutions) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsModerator(userData?.roles[0]?.name === Roles.MODERATOR);
	}, [userData?.roles]);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	const getCurrentSolutionList = (): JSX.Element => {
		if (activeSwitchElement === AdminCatalogRoutes.Published) {
			return <PublishedSolutions isModerator={isModerator} />;
		} else {
			if (activeSwitchElement === AdminCatalogRoutes.Drafted) {
				return <DraftedSolutions isModerator={isModerator} />;
			} else {
				return <DeletedSolutions isModerator={isModerator} />;
			}
		}
	};

	return (
		<div className={style.solutionAdminWrap}>
			<Title title="Решения" styleName="titlePage" />
			<AdminCatalogSwitch currentResource={AdminPath.Solutions} pagePart={params?.pagepart as AdminCatalogRoutes} />
			<div className={style.solutionAdminContent}>{getCurrentSolutionList()}</div>
		</div>
	);
};
