import { CheckIcon } from "../../assets/script/CheckIcon";
import { ICheckBoxItem } from "../../types/common/UiKitProps";

import styles from "./CheckBox.module.css";

const CheckBox = ({ label, checked, onChange }: ICheckBoxItem) => {
	const handleInputChange = () => {
		if (onChange) {
			onChange(!checked);
		}
	};
	return (
		<div className={styles.checkboxContainer} onClick={handleInputChange}>
			<div className={`${styles.customCheckbox} ${checked ? styles.checked : ""}`}>
				<input className={styles.checkboxInput} type="checkbox" checked={checked} />
				{checked ? <CheckIcon /> : ""}
			</div>
			<label className={styles.checkboxLabel}>{label}</label>
		</div>
	);
};

export default CheckBox;
