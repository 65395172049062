import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames/dedupe";

import { navIconArr } from "../../../helpers/navIconArr";
import { Path } from "../../../services/router/routes";

import exitNavArrow from "../../../assets/layouts/PersonalArea/NavIcon/exitNavArrow.svg";
import openArrow from "../../../assets/layouts/PersonalArea/NavIcon/openArrow.svg";

import style from "./NavBar.module.css";

const NavBar = () => {
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [shouldBeHidden, setShouldBeHidden] = useState<boolean>(false);
	const location = useLocation();

	useEffect(() => {
		const isPageForHiddenSidebar: boolean = (
			[Path.ChangeUserPassword, Path.EditProfile, Path.CurrentSolutionSignin, Path.CurrentPlatformSignin] as string[]
		).some((substring) => location.pathname.includes(substring));
		setShouldBeHidden(isPageForHiddenSidebar);
	}, [location.pathname]);

	const handleChange = () => {
		setIsCheck(!isCheck);
	};

	return (
		<nav className={!shouldBeHidden ? style.navContainer : style.navContainer__hidden}>
			<div className={cn(style.navBar, { [style.navBar__open]: isCheck })}>
				<div onClick={handleChange} className={style.checkBox__container}>
					<div className={style.img__container}>
						<img className={style.img} src={isCheck ? exitNavArrow : openArrow} alt="open" />
					</div>
					<input className={style.checkBox} type="checkbox" />
				</div>
				{navIconArr.map((item) => {
					return (
						<Link style={{ textDecoration: "none" }} key={item.id} to={`${item.src}`}>
							<div className={cn(style.navBar__item, { [style.navBar__item_open]: isCheck })}>
								<img src={item.icon} alt="img" />
								{isCheck ? <p className={style.navBar__title}>{item.title}</p> : ""}
							</div>
						</Link>
					);
				})}
			</div>
		</nav>
	);
};

export default NavBar;
