import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCorrectBaseUrl } from "../../../../../utils/baseUrlConverter";
import { settingFavoriteSolutionEndpoint } from "../../../../api/resources/apiResourcesConstants";

import { IParams } from "../../../../../types/api/AdminApiTypes";
import { IAdminSolutionsData } from "../../../../../types/redux/storeTypes";

const baseUrl = getCorrectBaseUrl("ps");

export const getSolutionFavorites = createAsyncThunk("solution/favorites", async (params: IParams, thunkAPI) => {
	try {
		const response = await fetch(`${baseUrl}/${settingFavoriteSolutionEndpoint}?offset=${params.offset - 1}&limit=9`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
		return (await response.json()) as IAdminSolutionsData;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
