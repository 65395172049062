import { useNavigate } from "react-router-dom";
import cn from "classnames/dedupe";

import { IAdminCatalogSwitch } from "../../../types/common/ComponentProps";
import { AdminCatalogRoutes } from "../../../helpers/catalogHelpers";

import styles from "./AdminCatalogSwitch.module.css";

function AdminCatalogSwitch({ currentResource, pagePart }: IAdminCatalogSwitch) {
	const navigator = useNavigate();

	const isPublishedPart: boolean = pagePart === AdminCatalogRoutes.Published;
	const isDeletedPart: boolean = pagePart === AdminCatalogRoutes.Deleted;

	function handleAction(route: AdminCatalogRoutes) {
		return navigator(`${currentResource}/${route}/1`);
	}

	return (
		<div className={styles.adminCatalogSwitchWrap}>
			<button
				onClick={() => handleAction(AdminCatalogRoutes.Published)}
				className={cn(styles.inactiveButton, { [styles.activeButton]: isPublishedPart })}>
				Опубликовано
			</button>
			<button
				onClick={() => handleAction(AdminCatalogRoutes.Drafted)}
				className={cn(styles.inactiveButton, { [styles.activeButton]: !isPublishedPart && !isDeletedPart })}>
				Черновики
			</button>
			<button
				onClick={() => handleAction(AdminCatalogRoutes.Deleted)}
				className={cn(styles.inactiveButton, { [styles.activeButton]: isDeletedPart })}>
				Удалено
			</button>
		</div>
	);
}

export default AdminCatalogSwitch;
