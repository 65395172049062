import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";

import { IAdminPlatformsData, IAdminSolutionsData } from "../../../types/redux/storeTypes";
import { ExternalMouseEvent, IsErrorDraftsResponseState } from "../../../types/pages/DraftsPreview";
import { IContextMenuItem, IContextModalPositionState } from "../../../types/ui/ContextMenuModal";
import { IAdminPlatformsContent } from "../../../types/components/PlatformsAdmin";
import { ISolutionAdminContent } from "../../../types/redux/thunkTypes";
import { Button } from "../../../ui/Button/Button";
import { Title } from "../../../ui/Title/Title";
import ContextMenuModal from "../../../ui/ContextMenuModal/ContextMenuModal";
import ModalHandelClose from "../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import SampleSlider from "../../../components/MainLayoutComponents/SampleSlider/SampleSlider";
import { getPlatformDraftsList, getSolutionDraftsList } from "../../../services/api/admin/draftsPageActions";
import { movePlatformToPublished, removePlatformToDeleted } from "../../../services/api/admin/platformsPageActions";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import ResourceDataSelector from "../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import { moveSolutionToPublished, removeSolutionToDeleted } from "../../../services/api/admin/solutionsPageActions";
import { AdminPath, Path } from "../../../services/router/routes";
import { PlatformAdminContextMenuItems } from "../../../helpers/platformAdmin";
import { isErrorDraftsResponseState } from "../../../helpers/AdminDrafts";
import { CatalogRoutes } from "../../../helpers/catalogHelpers";
import { Roles } from "../../../helpers/roles";
import { defaultContextMenuPosition } from "../../../helpers/ContextMenu";
import {
	setResourceId,
	setResourceType,
} from "../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";

import createNew from "../../../assets/components/NavbarAdmin/plusIcon.svg";

import styles from "./Drafts.module.css";

function Drafts() {
	const [platformList, setPlatformList] = useState<IAdminPlatformsContent[] | undefined>(undefined);
	const [solutionList, setSolutionList] = useState<ISolutionAdminContent[] | undefined>(undefined);
	const [isErrorDraftsResponse, setIsErrorDraftsResponse] =
		useState<IsErrorDraftsResponseState>(isErrorDraftsResponseState);
	const [isModerator, setIsModerator] = useState<boolean>(false);
	const [toggleContextModal, setToggleContextModal] = useState<boolean>(false);
	const [currentModalName, setCurrentModalName] = useState<ModalsNameList>();
	const [toggleListsUpdate, setToggleListsUpdate] = useState<boolean>(false);
	const [contextModalPosition, setContextModalPosition] =
		useState<IContextModalPositionState>(defaultContextMenuPosition);

	const userData = useAppSelector(userDataSelector).userData;
	const platformId = useAppSelector(ResourceDataSelector).currentResourceId;
	const { currentResourceId, currentResourceType } = useAppSelector(ResourceDataSelector);
	const dispatch = useAppDispatch();
	const navigator = useNavigate();
	const location = useLocation();

	enum ModalsNameList {
		modalPublish = "modalPublish",
		modalRemove = "modalRemove",
	}

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.Drafts) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		window.scrollTo({ top: 0 });
		(async () => {
			await getPlatformDraftsList()
				.then(async (responsePlatforms: Response) => {
					if (responsePlatforms.ok) {
						const platformsData: IAdminPlatformsData = await responsePlatforms.json();
						setPlatformList(platformsData.content.length ? platformsData.content : undefined);
						if (!platformsData.content.length) {
							setCorrectErrorField(CatalogRoutes.Platforms);
						}
					} else {
						setCorrectErrorField(CatalogRoutes.Platforms);
					}
				})
				.catch((e: Error) => {
					setCorrectErrorField(CatalogRoutes.Platforms);
				});
			await getSolutionDraftsList()
				.then(async (responseSolutions: Response) => {
					if (responseSolutions.ok) {
						const solutionsData: IAdminSolutionsData = await responseSolutions.json();
						setSolutionList(solutionsData.content.length ? solutionsData.content : undefined);
						if (!solutionsData.content.length) {
							setCorrectErrorField(CatalogRoutes.Solutions);
						}
					} else {
						setCorrectErrorField(CatalogRoutes.Solutions);
					}
				})
				.catch((e: Error) => {
					setCorrectErrorField(CatalogRoutes.Solutions);
				});
		})();
	}, [toggleListsUpdate]);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (userData?.roles) {
			setIsModerator(userData?.roles[0]?.name === Roles.MODERATOR);
		}
	}, [userData?.roles]);

	function setCorrectErrorField(resourceType: CatalogRoutes) {
		if (resourceType === CatalogRoutes.Platforms) {
			return setIsErrorDraftsResponse((prevState) => ({
				...prevState,
				isErrorPlatforms: true,
			}));
		}
		return setIsErrorDraftsResponse((prevState) => ({
			...prevState,
			isErrorSolutions: true,
		}));
	}

	const closeCurrentModal = () => {
		dispatch(setResourceId(0));
		dispatch(setResourceType(null));
		return setCurrentModalName(undefined);
	};

	const contextMenuItems: IContextMenuItem[] = [
		{
			title: PlatformAdminContextMenuItems.Edit,

			onClick: () =>
				currentResourceType === "solutions"
					? navigator(`${AdminPath.SolutionsEdit}/${currentResourceId}`)
					: navigator(`${AdminPath.PlatformEdit}/${platformId}`),
		},
		{
			title: PlatformAdminContextMenuItems.Preview,
			onClick: () => navigator(`${AdminPath.PlatformPreview}/${platformId}`),
		},
		{
			title: PlatformAdminContextMenuItems.Publish,
			onClick: () => openPublishModal(),
		},
		{
			title: PlatformAdminContextMenuItems.Delete,
			onClick: () => openRemoveModal(),
		},
	];

	const shortContextMenuItemList: IContextMenuItem[] = [contextMenuItems[0], contextMenuItems[1], contextMenuItems[2]];

	const paramsForContextMenu = {
		platformTopPosition: 190,
		platformContextMenuLeftPosition: 750,
		solutionTopPosition: 610,
		solutionContextMenuFirstLeftPosition: 340,
		solutionContextMenuSecondLeftPosition: 730,
		fiftyPercent: 50,
		oneHundredPercent: 100,
	};

	const handleCreatingPlatformTransition = () => {
		return navigator(AdminPath.Platforms);
	};

	const handleCreatingSolutionTransition = () => {
		return navigator(AdminPath.Solutions);
	};

	function togglePlatformContextMenuWindow() {
		const contextModalPosition: IContextModalPositionState = {
			top: paramsForContextMenu.platformTopPosition,
			left: paramsForContextMenu.platformContextMenuLeftPosition,
		};
		setContextModalPosition(contextModalPosition);
		dispatch(setResourceType(CatalogRoutes.Platforms));
		setToggleContextModal(true);
	}

	function toggleSolutionContextMenuWindow(event: ExternalMouseEvent) {
		const ratioOfCursorXPositionToScreenWidth: number = Math.round(
			(event.clientX / window.innerWidth) * paramsForContextMenu.oneHundredPercent,
		);
		const contextModalPosition: IContextModalPositionState = {
			top: paramsForContextMenu.solutionTopPosition,
			left:
				ratioOfCursorXPositionToScreenWidth < paramsForContextMenu.fiftyPercent
					? paramsForContextMenu.solutionContextMenuFirstLeftPosition
					: paramsForContextMenu.solutionContextMenuSecondLeftPosition,
		};
		dispatch(setResourceType(CatalogRoutes.Solutions));
		setContextModalPosition(contextModalPosition);
		setToggleContextModal(true);
	}

	const closeModalAfterAction = async () => {
		dispatch(setResourceId(0));
		dispatch(setResourceType(null));
		setToggleContextModal(false);
		await setCurrentModalName(undefined);
		return setToggleListsUpdate(!toggleListsUpdate);
	};

	const openPublishModal = () => {
		return setCurrentModalName(ModalsNameList.modalPublish);
	};

	const openRemoveModal = () => {
		return setCurrentModalName(ModalsNameList.modalRemove);
	};

	const handlePublishingAction = async () => {
		if (currentResourceType === CatalogRoutes.Platforms) movePlatformToPublished(currentResourceId);
		if (currentResourceType === CatalogRoutes.Solutions) moveSolutionToPublished(currentResourceId);
		return closeModalAfterAction();
	};

	const handleRemovingAction = async () => {
		if (currentResourceType === CatalogRoutes.Platforms) removePlatformToDeleted(currentResourceId);
		if (currentResourceType === CatalogRoutes.Solutions) removeSolutionToDeleted(currentResourceId);
		return closeModalAfterAction();
	};

	function renderPlatformSampleSlider() {
		return (
			platformList !== undefined && (
				<SampleSlider
					cardList={platformList}
					interval={30}
					solutionsPerSlide={1}
					isAdminPanel={true}
					role="role"
					toggleMenuForPlatform={togglePlatformContextMenuWindow}
				/>
			)
		);
	}

	function renderSolutionSampleSlider() {
		return (
			solutionList !== undefined && (
				<SampleSlider
					cardList={solutionList}
					interval={70}
					solutionsPerSlide={solutionList.length === 1 ? 1 : 2}
					isAdminPanel={true}
					role="role"
					toggleMenuForSolution={toggleSolutionContextMenuWindow}
				/>
			)
		);
	}

	function renderNewPlatformCreationBlock() {
		return (
			isErrorDraftsResponse.isErrorPlatforms && (
				<div className={styles.newPlatformWrap}>
					<div className={styles.newPlatformContainer}>
						<p className={styles.newPlatformWrap__title}>
							У вас пока нет черновиков платформ, создайте первый прямо сейчас
						</p>
						<button className={styles.newPlatformWrap__actionWrap} onClick={handleCreatingPlatformTransition}>
							<span>Создать</span>
							<img src={createNew} alt="plus action" />
						</button>
					</div>
				</div>
			)
		);
	}

	function renderNewSolutionCreationBlock() {
		return (
			isErrorDraftsResponse.isErrorSolutions && (
				<div className={styles.newSolutionWrap}>
					<div className={styles.newSolutionContainer}>
						<p className={styles.newSolutionWrap__title}>
							У вас пока нет черновиков шаблонов, создайте первый прямо сейчас
						</p>
						<button className={styles.newSolutionWrap__actionWrap} onClick={handleCreatingSolutionTransition}>
							<span>Создать</span>
							<img src={createNew} alt="plus action" />
						</button>
					</div>
				</div>
			)
		);
	}

	return (
		<main className={styles.draftsWrapper}>
			<Title title="Последние черновики платформ:" styleName="draftsTitle" />
			<section className={styles.platformsSliderWrap}>
				{renderPlatformSampleSlider()}
				{renderNewPlatformCreationBlock()}
			</section>

			<Title title="Последние черновики решений:" styleName="draftsTitle" />
			<section className={styles.solutionsSliderWrap}>
				{renderSolutionSampleSlider()}
				{renderNewSolutionCreationBlock()}
			</section>
			{toggleContextModal && (
				<ContextMenuModal
					content={isModerator ? shortContextMenuItemList : contextMenuItems}
					position={contextModalPosition}
					closeContextModal={() => setToggleContextModal(false)}
				/>
			)}

			<ModalHandelClose
				title="Опубликовать?"
				open={currentModalName === ModalsNameList.modalPublish}
				isLeftAlignedTextHeader={true}
				toggle={closeCurrentModal}>
				<p className={styles.modalTitle}>Опубликованное появится на сайте и будет храниться в опубликованном</p>
				<Button
					content="Опубликовать"
					styleName="commonMaroonButton platformsAdminPublish"
					onClick={handlePublishingAction}
				/>
			</ModalHandelClose>
			<ModalHandelClose
				title="Удалить?"
				open={currentModalName === ModalsNameList.modalRemove}
				isLeftAlignedTextHeader={true}
				toggle={closeCurrentModal}>
				<p className={styles.modalTitle}>Удалённое будет храниться 30 дней</p>
				<Button content="Удалить" styleName="commonMaroonButton platformsAdminRemove" onClick={handleRemovingAction} />
			</ModalHandelClose>
		</main>
	);
}

export default Drafts;
