import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { settingFavoriteSolutionEndpoint } from "./apiResourcesConstants";

export async function setSolutionFavorite(solutionId: number | undefined, isFavoriteSolution: boolean | undefined) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${settingFavoriteSolutionEndpoint}/${solutionId}`, {
		method: isFavoriteSolution ? "DELETE" : "POST",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
