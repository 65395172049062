import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { baseSolutionEndpoint } from "./apiResourcesConstants";

export async function getCurrentSolutionData(solutionId: number) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${baseSolutionEndpoint}/${solutionId}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
