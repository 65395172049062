import { useState } from "react";

export default function useModal() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [delayModal, setDelayModal] = useState<number>(0);

	const millisecondsInSecondNumber: number = 1000;

	const toggleModal = () => {
		setIsOpen(!isOpen);

		if (!isOpen && delayModal !== 0) {
			setTimeout(() => setIsOpen(false), delayModal * millisecondsInSecondNumber);
		}
	};

	return {
		isOpen,
		toggleModal,
		setDelayModal,
		delayModal,
	};
}
