import { IContactsLink } from "../../../types/common/ComponentProps";

import styles from "./ContactsLink.module.css";

export const ContactsLink = ({ type, content }: IContactsLink) => {
	enum LinkTypes {
		PHONE = "phone",
		EMAIL = "email",
	}

	const generateHref = () => (LinkTypes.PHONE === type ? `tel:${content}` : `mailto:${content}`);

	return (
		<div>
			<p className={styles.linkTitle}>
				{type === LinkTypes.PHONE && "Звоните"}
				{type === LinkTypes.EMAIL && "Пишите"}
			</p>
			<a className={styles.link} href={generateHref()}>
				{content}
			</a>
		</div>
	);
};
