import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { ResetPasswordData } from "../../../types/api/AuthApiTypes";

import { forgotPasswordEndpoint } from "./apiConstants";

export async function resetUserPassword(emailData: ResetPasswordData) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${forgotPasswordEndpoint}`, {
		method: "POST",
		body: JSON.stringify(emailData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
