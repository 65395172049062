import { BaseSyntheticEvent, useEffect, useState } from "react";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useDebounce from "../../../../hooks/useDebounce";

import { ISolutionFilterData, ISolutionFilterListData } from "../../../../types/components/SolutionCatalog";
import FilterGroup from "../FilterGroup/FilterGroup";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import ComponentTogglerSelector from "../../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSelector";
import { setIsOpenComponent } from "../../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSlice";
import { searchFilterGroup } from "../../../../services/api/admin/filtersPageActions";
import { getSolutionFilterList } from "../../../../services/api/resources/getSolutionFilterList";
import { extractDigitsFromTextLine } from "../../../../utils/extractDigitsFromTextLine";
import { CatalogRoutes, CatalogTitles } from "../../../../helpers/catalogHelpers";
import { searchDelay } from "../../../../helpers/delayConstants";

import searchLogo from "../../../../assets/pages/Filtering/filtersSearchLogo.svg";

import styles from "./SolutionFilters.module.css";

function SolutionFilters() {
	const [solutionFilterList, setSolutionFilterList] = useState<ISolutionFilterData[]>([]);
	const [searchLine, setSearchLine] = useState<string>();
	const dispatch = useAppDispatch();
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const isOpenCreationForm = useAppSelector(ComponentTogglerSelector);
	const { currentResourceId, currentFilterData, currentResourceType } = useAppSelector(ResourceDataSelector);
	const debouncedSearch = useDebounce(handleSearchFilters, searchDelay);

	useEffect(() => {
		dispatch(setIsOpenComponent(false));
	}, [dispatch]);

	useEffect(() => {
		(async () => {
			if (activeSwitchElement !== CatalogTitles.Solutions || searchLine || isOpenCreationForm) {
				return null;
			}
			const response: Response = await getSolutionFilterList();
			const filters: Promise<ISolutionFilterListData> = await response.json();
			setSolutionFilterList((await filters).filters);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, searchLine, isOpenCreationForm]);

	useEffect(() => {
		if (searchLine) {
			debouncedSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchLine]);

	useEffect(() => {
		if (currentResourceId) {
			removeDisplayingFilterGroup(currentResourceId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentResourceId]);

	useEffect(() => {
		if (currentResourceType && currentResourceType === CatalogRoutes.Solutions) {
			updateCurrentFilterData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFilterData]);

	function updateCurrentFilterData() {
		const updatedSolutionFilterList = solutionFilterList.map((solutionFilter) => {
			return currentFilterData?.id === solutionFilter.id ? currentFilterData : solutionFilter;
		});

		setSolutionFilterList(updatedSolutionFilterList);
	}

	function removeDisplayingFilterGroup(solutionId: number) {
		const updatedSolutionFilterList = solutionFilterList.filter((solutionFilter) => solutionFilter.id !== solutionId);
		return setSolutionFilterList(updatedSolutionFilterList);
	}

	async function handleSearchFilters() {
		if (!searchLine) {
			return null;
		}

		const response = await searchFilterGroup({ value: searchLine }, CatalogRoutes.Solutions);
		const searchedFiltersData: Promise<ISolutionFilterListData> = await response.json();

		return setSolutionFilterList((await searchedFiltersData).filters);
	}

	function handleChange(event: BaseSyntheticEvent) {
		setSearchLine(event.target.value);
	}

	const renderCreatingFilterContainer = () => {
		return isOpenCreationForm ? (
			<div className={styles.creatingFilterFormWrap}>
				<FilterGroup
					id={0}
					name=""
					description=""
					filterValueList={[
						{
							id: extractDigitsFromTextLine(crypto.randomUUID()),
							value: "",
						},
					]}
					isMultipleChoice={true}
					isCreationCard={true}
					resourceType={CatalogRoutes.Solutions}
				/>
			</div>
		) : (
			<></>
		);
	};

	const renderFilterList = () => {
		return solutionFilterList.map((solutionFilter) => (
			<FilterGroup
				key={solutionFilter.id}
				id={solutionFilter.id}
				name={solutionFilter.name}
				description={solutionFilter.description ?? ""}
				filterValueList={solutionFilter.values}
				isMultipleChoice={solutionFilter.multipleChoice}
				isCreationCard={false}
				resourceType={CatalogRoutes.Solutions}
			/>
		));
	};

	return (
		<div className={styles.solutionFiltersWrap}>
			<div className={styles.searchLineWrap}>
				<img src={searchLogo} alt="loupe" />
				<input
					type="text"
					placeholder="Введите название фильтра"
					className={styles.searchLineWrap__searchLineInput}
					onChange={handleChange}
				/>
			</div>
			<div className={styles.creatingFilterWrap}>{renderCreatingFilterContainer()}</div>
			<div className={styles.filterListWrap}>{renderFilterList()}</div>
		</div>
	);
}

export default SolutionFilters;
