import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IFavoriteSolutionsState, IAdminSolutionsData } from "../../../../../types/redux/storeTypes";

import { getSolutionFavorites } from "./SolutionFavoritesThunk";

const favoriteSolutionInitialState: IFavoriteSolutionsState = {
	favoriteSolutions: [],
	activeSolutionTitle: "",
	isLoading: false,
	pageNumber: 1,
	totalPages: 1,
	error: null,
};

const SolutionFavoritesSlice = createSlice({
	name: "favoriteSolutions",
	initialState: favoriteSolutionInitialState,
	reducers: {
		clearSolutionFavoriteData: (state) => {
			state.favoriteSolutions = [];
		},
		setActiveSolution: (state, action: PayloadAction<string>) => {
			state.activeSolutionTitle = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(getSolutionFavorites.pending, (state) => {
			state.error = null;
			state.isLoading = true;
		});
		builder.addCase(getSolutionFavorites.rejected, (state, action: PayloadAction<string | unknown>) => {
			state.isLoading = false;
			state.error = action.payload;
		});
		builder.addCase(getSolutionFavorites.fulfilled, (state, action: PayloadAction<IAdminSolutionsData>) => {
			state.favoriteSolutions = [...action.payload.content];
			state.error = null;
			state.isLoading = false;
			state.pageNumber = action.payload.totalPages;
		});
	},
});

export const { clearSolutionFavoriteData, setActiveSolution } = SolutionFavoritesSlice.actions;
export default SolutionFavoritesSlice.reducer;
