import { IPost } from "../../../types/common/ComponentProps";
import { Path } from "../../../services/router/routes";

import PostImg from "../../../assets/components/PostCard/PostImg.png";

import style from "./PostCard.module.css";

export const PostCard = ({ title, text }: IPost) => {
	return (
		<div className={style.post}>
			<img className={style.post__img} src={PostImg} alt={PostImg} />
			<div className={style.post__info}>
				<h3 className={style.post__info_title}>{title}</h3>
				<p className={style.post__info_text}>{text}</p>
				<div className={style.post__info_link}>
					<a className={style.link} href={Path.Main}>
						Читать далее
					</a>
					<div className={style.arrow}>
						<div className={style.arrow__top}></div>
						<div className={style.arrow__bottom}></div>
					</div>
				</div>
			</div>
		</div>
	);
};
