import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { UpdateUserInfo } from "../../../types/api/AuthApiTypes";

import { updateUserInfoEndpoint } from "./apiConstants";

export async function updateUserInfo(userData: UpdateUserInfo) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${updateUserInfoEndpoint}`, {
		method: "PUT",
		body: JSON.stringify(userData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
