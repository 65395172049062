import { useEffect } from "react";
import { Link } from "react-router-dom";

import { MainButton } from "../../../components/AuthLayoutComponents/MainButton/MainButton";
import { Title } from "../../../ui/Title/Title";
import { Path } from "../../../services/router/routes";

import useAppSelector from "../../../hooks/useAppSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";

import style from "./AcceptedPage.module.css";

export const AcceptedPage = () => {
	const userData = useAppSelector(userDataSelector).userData;
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<div className={style.acceptedContainer}>
			<div className={style.acceptedContent}>
				<Title title="Заявка принята" styleName="titlePage" />
				<p className={style.acceptedDescription}>
					Lorem ipsum dolor sit amet consectetur. Auctor sit morbi vulputate scelerisque ipsum tempus aliquam ut nunc.
					Lorem felis senectus viverra at sed. Consequat tincidunt augue id et.
				</p>
				<Link to={userData?.id ? Path.PersonalArea : Path.Main}>
					<MainButton content="Вернуться на главную" testId="" styleName="button" size="384px" />
				</Link>
			</div>
		</div>
	);
};
