import { UseControllerProps } from "react-hook-form";
import className from "classnames";

import { IFormattedPhoneInput } from "../../../../types/common/ComponentProps";

import styles from "./FormattedPhoneInput.module.css";

export const FormattedPhoneInput = ({
	id,
	label,
	register,
	error,
	...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IFormattedPhoneInput & UseControllerProps<any>) => {
	return (
		<div className={styles["input-container"]}>
			<div className={styles["label-container"]}>
				<label htmlFor={id} className={styles.label}>
					{label}
				</label>
			</div>
			<input
				type="tel"
				className={error ? className(styles.phone, styles._error) : styles.phone}
				{...register}
				onChange={props.onChange}
				{...props}
			/>
			{error && <p className={styles.error}>{error}</p>}
		</div>
	);
};
