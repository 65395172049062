import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";

import { Switch } from "../../../ui/Switch/Switch";
import { Title } from "../../../ui/Title/Title";
import FavoritePlatforms from "../../../components/PersonalAreaLayoutComponents/FavoriteComponents/FavoritePlatforms/FavoritePlatforms";
import FavoriteSolutions from "../../../components/PersonalAreaLayoutComponents/FavoriteComponents/FavoriteSolutions/FavoriteSolutions";
import switchSelector from "../../../services/redux/features/userPart/switch/SwitchSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { setIsActive } from "../../../services/redux/features/userPart/switch/SwitchSlice";
import { Path } from "../../../services/router/routes";
import { CatalogRoutes, CatalogTitles } from "../../../helpers/catalogHelpers";

import styles from "./PersonalAreaFavorites.module.css";

function PersonalAreaFavorites() {
	const isLogin = useAppSelector(userDataSelector).userData?.email;
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const params = useParams();
	const dispatch = useAppDispatch();
	const navigator = useNavigate();

	const resourcesList: string[] = [CatalogTitles.Solutions, CatalogTitles.Platforms];

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (!isLogin) {
			navigator(Path.Login);
		}
		dispatch(
			setIsActive(params.resource === CatalogRoutes.Solutions ? CatalogTitles.Solutions : CatalogTitles.Platforms),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	function getCurrentResourcesList() {
		return activeSwitchElement === CatalogTitles.Solutions ? <FavoriteSolutions /> : <FavoritePlatforms />;
	}

	return (
		<div className={styles.personalAreaFavoritesWrap}>
			<Title title="Избранное" styleName="titlePage" />
			<Switch switchContent={resourcesList} />
			<div className={styles.catalogUnsigninContent}>{getCurrentResourcesList()}</div>
		</div>
	);
}

export default PersonalAreaFavorites;
