import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames/dedupe";

import useAppSelector from "../../../hooks/useAppSelector";

import { ISlider } from "../../../types/common/ComponentProps";
import PlatformCatalogCard from "../ResourceComponents/PlatformCatalogCard/PlatformCatalogCard";
import { SolutionCard } from "../ResourceComponents/SolutionCard/SolutionCard";
import switchSelector from "../../../services/redux/features/userPart/switch/SwitchSelector";
import { AdminPath, Path } from "../../../services/router/routes";
import { PlatformCardTypes } from "../../../helpers/catalogHelpers";
import { Roles } from "../../../helpers/roles";

import sliderArrow from "../../../assets/components/Slider/sliderArrow.svg";
import authSliderArrow from "../../../assets/components/Slider/authSliderArrow.svg";

import style from "./SampleSlider.module.css";

const SampleSlider = ({
	cardList,
	interval,
	solutionsPerSlide,
	role,
	isDisabledCard,
	isAdminPanel,
	toggleMenuForPlatform,
	toggleMenuForSolution,
}: ISlider) => {
	const [cardWidth, setCardWidth] = useState<number>(0);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [countItemPerSlide, setCountItemPerSlide] = useState<number>(0);
	const [isPlatformSlider, setIsPlatformSlider] = useState<boolean>(false);
	const [isLessFourSolutions, setIsLessFourSolutions] = useState<boolean>(false);

	const switchActiveElement = useAppSelector(switchSelector).activeElement;
	const location = useLocation();

	const sliderModificationLengthNumber = 4;

	const sliderParameters = {
		defaultCountSolutionsPerSlide: 2,
		defaultCountPlatformsPerSlide: 1,
		solutionCardWidth: 320,
		platformCardWidth: 820,
	};

	useEffect(() => {
		(() => {
			if (
				location.pathname.includes(Path.CurrentPlatformSignin) ||
				location.pathname.includes(Path.CurrentPlatformUnSignin) ||
				location.pathname.includes(AdminPath.PlatformPreview)
			) {
				if ("totalSolution" in cardList[0]) {
					setIsPlatformSlider(true);
				} else {
					setIsLessFourSolutions(cardList.length < sliderModificationLengthNumber);
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardList]);

	useEffect(() => {
		setCurrentIndex(0);
		setCountItemPerSlide(
			"totalSolution" in cardList[0]
				? sliderParameters.defaultCountPlatformsPerSlide
				: solutionsPerSlide || sliderParameters.defaultCountSolutionsPerSlide,
		);
		setCardWidth(
			"totalSolution" in cardList[0] ? sliderParameters.platformCardWidth : sliderParameters.solutionCardWidth,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardList, switchActiveElement]);

	const handlePrevSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cardList.length - countItemPerSlide));
	};

	const handleNextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex < cardList.length - countItemPerSlide ? prevIndex + 1 : 0));
	};

	const getStyleTransformValue = () => {
		return `translateX(${currentIndex === 0 ? 0 : currentIndex * (-cardWidth - interval)}px)`;
	};

	const getStyleWidthList = (solutions: number = sliderParameters.defaultCountSolutionsPerSlide) => {
		if (isLessFourSolutions && !isPlatformSlider) {
			return "1280px";
		}
		if ("totalSolution" in cardList[0]) {
			return `${sliderParameters.platformCardWidth}px`;
		} else {
			const solutionListWidth = sliderParameters.solutionCardWidth * solutions + (solutions - 1) * interval;
			return `${solutionListWidth}px`;
		}
	};

	const showSampleList = () => {
		return cardList.map((item) => {
			return (
				<li
					style={{ transform: getStyleTransformValue() }}
					id="card"
					className={"totalSolution" in item ? style.sliderItemPlatform : style.sliderItemSolution}
					key={item.id}>
					{"totalSolution" in item ? (
						<PlatformCatalogCard
							type={isAdminPanel ? PlatformCardTypes.AdminType : PlatformCardTypes.CatalogType}
							title={item.name}
							description={item.shortDescription}
							solutionNumber={item.totalSolution}
							icon={item.logoUrl}
							cardId={item.id}
							toggleContextMenu={toggleMenuForPlatform}
							isFavoritePlatform={item.favorite}
						/>
					) : (
						<SolutionCard
							id={item.id}
							title={item.name}
							logo={item.logo}
							shortDescription={item.shortDescription}
							role={isDisabledCard || isAdminPanel ? Roles.ADMIN : Roles.USER}
							isFavorite={item.favorite}
							isDisabledCard={isDisabledCard}
							toggleContextMenu={toggleMenuForSolution}
						/>
					)}
				</li>
			);
		});
	};

	return (
		<div className={style.sliderContainer}>
			<div className={style.slider}>
				<img
					src={role ? authSliderArrow : sliderArrow}
					alt="arrow"
					className={isLessFourSolutions && !isPlatformSlider ? style.hiddenSliderArrow : style.sliderLeftArrow}
					onClick={handlePrevSlide}
				/>
				<ul
					style={{ maxWidth: getStyleWidthList(solutionsPerSlide), gap: `${interval}px` }}
					className={cn(style.sliderList, { [style.modifiedSliderList]: isLessFourSolutions && !isPlatformSlider })}>
					{showSampleList()}
				</ul>
				<img
					src={role ? authSliderArrow : sliderArrow}
					alt="arrow"
					className={isLessFourSolutions && !isPlatformSlider ? style.hiddenSliderArrow : style.sliderRightArrow}
					onClick={handleNextSlide}
				/>
			</div>
		</div>
	);
};

export default SampleSlider;
