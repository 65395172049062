import { useScrollLock } from "../../../hooks/useScrollLock";

import { IModalAutoHidden } from "../../../types/common/ComponentProps";

import crossModal from "../../../assets/components/auth/LoginForm/CrossModal.svg";

import styles from "./ModalAutoHidden.module.css";

function ModalAutoHidden({ open, toggle, title, message }: IModalAutoHidden) {
	const lockScroll = useScrollLock(open);

	lockScroll();

	return (
		<>
			<dialog open={open} className={styles.modalWrapper}>
				<div onClick={(e) => e.stopPropagation()} className={styles.modalContainer}>
					<div className={styles.modalCross}>
						<button onClick={toggle}>
							<img src={crossModal} alt="crossModal" className={styles.modalCrossImg} />
						</button>
					</div>
					<div className={styles.modalTitle}>{title}</div>
					<div className={styles.modalMessage}>{message}</div>
				</div>
			</dialog>
		</>
	);
}

export default ModalAutoHidden;
