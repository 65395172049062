import { MouseEventHandler, useState } from "react";

import { IPasswordEye } from "../../../types/common/ComponentProps";
import closeEye from "../../../assets/components/PasswordEye/closeEye.svg";
import openEye from "../../../assets/components/PasswordEye/openEye.svg";

import "../../../App.css";
import styles from "./PasswordEye.module.css";

const PasswordEye = (props: IPasswordEye) => {
	const [toggleEyeState, setToggleEyeState] = useState<boolean>(false);

	const changeEyeLogo = () => {
		setToggleEyeState(!toggleEyeState);
	};

	return (
		<div className={props.className} onClick={props.onClick as MouseEventHandler<HTMLDivElement>}>
			<div className={styles.passwordEyeWrap} onClick={changeEyeLogo}>
				<img src={toggleEyeState ? openEye : closeEye} alt="password eye" />
			</div>
		</div>
	);
};

export default PasswordEye;
