import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IParams } from "../../../../types/api/AdminApiTypes";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { SolutionCard } from "../../../MainLayoutComponents/ResourceComponents/SolutionCard/SolutionCard";
import { getSolutionFavorites } from "../../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesThunk";
import switchSelector from "../../../../services/redux/features/userPart/switch/SwitchSelector";
import { solutionFavoritesSelector } from "../../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSelector";
import { Path } from "../../../../services/router/routes";
import { CatalogRoutes, CatalogTitles } from "../../../../helpers/catalogHelpers";
import { Roles } from "../../../../helpers/roles";

import styles from "./FavoriteSolutions.module.css";

function FavoriteSolutions() {
	const [favorite, setFavorite] = useState(true);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const { favoriteSolutions, pageNumber, error } = useAppSelector(solutionFavoritesSelector);

	const favoritePlatformListErrorContent: string = "Ошибка загрузки списка избранных решений!";

	useEffect(() => {
		window.scrollTo({ top: 0 });
		(async () => {
			if (activeSwitchElement !== CatalogTitles.Solutions) {
				return null;
			}
			const thunkParams: IParams = {
				offset: Number(params.page ?? 1),
			};

			return dispatch(getSolutionFavorites(thunkParams));
		})();
	}, [dispatch, activeSwitchElement, params.page]);

	useEffect(() => {
		(async () => {
			if (activeSwitchElement === CatalogTitles.Solutions) {
				const thunkParams: IParams = {
					offset: Number(params.page ?? 1),
				};
				await dispatch(getSolutionFavorites(thunkParams));
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favorite]);

	useEffect(() => {
		if (Number(params.page) !== 1 && favoriteSolutions.length === 0) {
			const previousPage = Number(params.page) - 1;
			navigate(`${Path.PersonalAreaFavorites}/${CatalogRoutes.Solutions}/${previousPage}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favoriteSolutions.length, navigate]);

	function getCorrectPaginationSearchLine(): string {
		return `${Path.PersonalAreaFavorites}/${CatalogRoutes.Solutions}`;
	}

	function renderFavoriteSolutions() {
		return favoriteSolutions.map((item) => (
			<SolutionCard
				key={item.id}
				role={Roles.USER}
				title={item.name}
				shortDescription={item.shortDescription}
				logo={item.logo}
				id={item.id}
				isFavorite={item.favorite === true}
				setFavorite={() => setFavorite(!favorite)}
			/>
		));
	}

	return !favoriteSolutions.length ? (
		<p className={styles.emptyListMessage}>{error ? favoritePlatformListErrorContent : ""}</p>
	) : (
		<div className={styles.listSolution}>
			{renderFavoriteSolutions()}
			<PaginationElement
				currPage={Number(params.page ?? 1)}
				pageCount={pageNumber}
				searchLine={getCorrectPaginationSearchLine()}
			/>
		</div>
	);
}

export default FavoriteSolutions;
