import { useEffect, useRef, useState } from "react";
import classNames from "classnames/dedupe";
import { Link, useLocation } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import ModalPersonalAreaHeader from "../ModalPersonalAreaHeader/ModalPersonalAreaHeader";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";

import headerArrow from "../../../assets/components/PersonalAreaHeader/ArrowHeader.svg";
import logo from "../../../assets/layouts/Main/logo.png";

import style from "./PersonalAreaHeader.module.css";

const PersonalAreaHeader = () => {
	const [toggler, setToggler] = useState<boolean>(false);
	const [userInitials, setUserInitials] = useState<string>("");

	const location = useLocation();
	const userData = useAppSelector(userDataSelector).userData;
	const userContainerRef = useRef<HTMLDivElement>(null);
	const modalContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
				userContainerRef.current &&
				!userContainerRef.current.contains(event.target as Node) &&
				modalContainerRef.current &&
				!modalContainerRef.current.contains(event.target as Node)
			) {
				setToggler(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (!userData?.lastname) {
			return setUserInitials(`${userData?.firstname.at(0)}`);
		}
		setUserInitials(`${userData?.firstname.at(0)}${userData?.lastname.at(0)}`);
	}, [userData]);

	return (
		<header className={style.personalHeader}>
			<div className={style.personalHeader__container}>
				<Link to={location.pathname}>
					<img className={style.logo} src={logo} alt="logo" />
				</Link>
				<div onClick={() => setToggler(!toggler)} className={style.user__container} ref={userContainerRef}>
					<div className={style.user}>{userInitials}</div>
					<img
						className={classNames(style.headerArrow, { [style.headerArrowOpen]: toggler })}
						src={headerArrow}
						alt="headerArrow"
					/>
				</div>
				<div
					className={classNames(style.modal__container, { [style.modal__container__open]: toggler })}
					ref={modalContainerRef}>
					<ModalPersonalAreaHeader />
				</div>
			</div>
		</header>
	);
};

export default PersonalAreaHeader;
