import { NavLink } from "react-router-dom";

import { IOpportunities } from "../../../types/common/ComponentProps";

import { Path } from "../../../services/router/routes";

import style from "./Opportunities.module.css";

export const Opportunities = ({ icon, text, title }: IOpportunities) => {
	return (
		<div className={style.opportunities__frame}>
			<div className={style.opportunities__icon}>
				<img className={style.img} src={icon} alt="icon" />
			</div>
			<div>
				<h4 className={style.title}>{title}</h4>
				<p className={style.text}>{text}</p>
				<div className={style.flex__link}>
					<NavLink to={Path.Blog} className={style.link}>
						Подробнее
					</NavLink>
					<span className={style.triangle}></span>
				</div>
			</div>
		</div>
	);
};
