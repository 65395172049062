import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";

import { IFavoriteAction } from "../../../types/common/ComponentProps";
import { setPlatformFavorite } from "../../../services/api/resources/setPlatformFavorite";
import { setSolutionFavorite } from "../../../services/api/resources/setSolutionFavorite";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import switchSelector from "../../../services/redux/features/userPart/switch/SwitchSelector";
import { Path } from "../../../services/router/routes";
import { CatalogTitles } from "../../../helpers/catalogHelpers";
import { getPlatformFavorites } from "../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesThunk";
import { getSolutionFavorites } from "../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesThunk";

import pureHeart from "../../../assets/components/FavoritesAction/heart.svg";
import filledHeart from "../../../assets/components/FavoritesAction/Vector.svg";

import style from "./FavoriteAction.module.css";

const FavoriteAction = ({ resourceId, isFavoriteContent, setFavorite }: IFavoriteAction) => {
	const [isLogin, setIsLogin] = useState<boolean>(false);
	const [isFavoriteResource, setIsFavoriteResource] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const activeSwitchElement = useAppSelector(switchSelector).activeElement;
	const userData = useAppSelector(userDataSelector).userData;

	useEffect(() => {
		setIsFavoriteResource(isFavoriteContent);
	}, [isFavoriteContent]);

	useEffect(() => {
		setIsLogin(userData?.email !== "");
	}, [userData?.email]);

	const handleFavoritePlatform = async () => {
		if (!isLogin) return navigate(Path.Login);

		if (activeSwitchElement === CatalogTitles.Platforms) {
			await setPlatformFavorite(resourceId, isFavoriteResource)
				.then(() => setIsFavoriteResource(!isFavoriteResource))
				.then(() =>
					location.pathname === Path.PersonalArea ? dispatch(getPlatformFavorites({ offset: 1, limit: 20 })) : null,
				);
		} else {
			await setSolutionFavorite(resourceId, isFavoriteResource)
				.then(() => setIsFavoriteResource(!isFavoriteResource))
				.then(() =>
					location.pathname === Path.PersonalArea ? dispatch(getSolutionFavorites({ offset: 1, limit: 20 })) : null,
				);
		}
		setFavorite && setFavorite();
	};

	return (
		<div className={style.favoriteActionWrap} data-favorite={isFavoriteContent}>
			<button className={style.addingFavoritesButton} onClick={handleFavoritePlatform}>
				<img
					src={isFavoriteResource && userData?.email ? filledHeart : pureHeart}
					alt="heart logo"
					className={style.addingFavoritesLogo}
				/>
			</button>
		</div>
	);
};

export default FavoriteAction;
