import { Link } from "react-router-dom";

import { IDashboardCard } from "../../../types/common/ComponentProps";

import style from "./DashboardCard.module.css";

const DashboardCard = ({ id, icon, link, text }: IDashboardCard) => {
	return (
		<div className={style.dashboardCard} key={id}>
			<img className={style.dashboardCard__img} src={icon} alt="dashboardCard" />
			<Link className={style.dashboardCard__link} to={link}>
				{text}
			</Link>
		</div>
	);
};

export default DashboardCard;
