import { basePlatformsEndpoint, baseSolutionEndpoint } from "../resources/apiResourcesConstants";

export const staffPageEndpoint = "api/v1/employees";
export const staffLockProfileEndpoint = staffPageEndpoint + "/block";
export const staffUnLockProfileEndpoint = staffPageEndpoint + "/unblock";
export const staffChangeUserRoleEndpoint = staffPageEndpoint + "/role";
export const createSolution = "solutions";
export const createPlatform = "platforms";
export const solutionNamesForPlatforms = "solutions/names";
export const platformsDeletedEndpoint = basePlatformsEndpoint + "/deleted";
export const platformsDraftsEndpoint = basePlatformsEndpoint + "/drafts";
export const platformsPublishedEndpoint = basePlatformsEndpoint + "/published";
export const platformUnpublishingEndpoint = basePlatformsEndpoint + "/unpublished";
export const solutionsPublishedEndpoint = baseSolutionEndpoint + "/published";
export const solutionsDraftsEndpoint = baseSolutionEndpoint + "/drafts";
export const solutionsDeletedEndpoint = baseSolutionEndpoint + "/deleted";
export const solutionsUnpublishedEndpoint = baseSolutionEndpoint + "/unpublished";
