import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IPublishedPlatformsState, IAdminPlatformsData } from "../../../../../../types/redux/storeTypes";

import { getPublishedPlatformsData } from "./PublishedPlatformsThunk";

const publishedPlatformsInitialState: IPublishedPlatformsState = {
	publishedPlatforms: [],
	pageNumber: 0,
	platformsOnPage: 0,
	loading: false,
	error: null,
};

const PublishedPlatformsSlice = createSlice({
	name: "publishedPlatforms",
	initialState: publishedPlatformsInitialState,
	reducers: {
		clearPublishedPlatformsData: (state: IPublishedPlatformsState) => {
			state.publishedPlatforms = publishedPlatformsInitialState.publishedPlatforms;
		},
	},
	extraReducers(builder) {
		builder.addCase(
			getPublishedPlatformsData.fulfilled,
			(state: IPublishedPlatformsState, action: PayloadAction<IAdminPlatformsData>) => {
				state.publishedPlatforms = action.payload.content;
				state.pageNumber = action.payload.totalPages;
				state.platformsOnPage = action.payload.numberOfElements;
				state.loading = false;
			},
		);
		builder.addCase(
			getPublishedPlatformsData.rejected,
			(state: IPublishedPlatformsState, action: PayloadAction<string | unknown>) => {
				state.error = action.payload;
				state.loading = false;
			},
		);
		builder.addCase(getPublishedPlatformsData.pending, (state: IPublishedPlatformsState) => {
			state.loading = true;
		});
	},
});

export const { clearPublishedPlatformsData } = PublishedPlatformsSlice.actions;
export default PublishedPlatformsSlice.reducer;
