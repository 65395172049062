import { SubmitHandler, useForm } from "react-hook-form";
import { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useModal from "../../../hooks/useModal";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";

import { IFilterChecked } from "../../../types/pages/SolutionCatalog";
import { IUnsavedCurrentPlatformData } from "../../../types/components/DescriptionPlatform";
import { IGetPlatform, IPlatformChange } from "../../../types/pages/Platform";
import { Title } from "../../../ui/Title/Title";
import { Button } from "../../../ui/Button/Button";
import CheckBoxBlock from "../../../components/AdminLayoutComponents/CreationComponents/CheckBoxBlock/CheckBoxBlock";
import FieldsToFill from "../../../components/AdminLayoutComponents/CreationComponents/FieldsToFill/FieldsToFill";
import ButtonsSubmit from "../../../components/AdminLayoutComponents/CreationComponents/ButtonsSubmit/ButtonsSubmit";
import ModalHandelClose from "../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { movePlatformToPublished, removePlatformToDeleted } from "../../../services/api/admin/platformsPageActions";
import { getCurrentPlatformData } from "../../../services/api/resources/getCurrentPlatformData";
import { changeEditPlatform } from "../../../services/api/admin/changeEditPlatform";
import ResourceDataEditSelector from "../../../services/redux/features/adminPart/resourceForEditPages/ResourceDataEditSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath, Path } from "../../../services/router/routes";
import { Roles } from "../../../helpers/roles";
import {
	setEditPlatformData,
	setSelectedFilterForEdit,
	setSelectedSolutionForEdit,
} from "../../../services/redux/features/adminPart/resourceForEditPages/ResourceDataEditSlice";

import { ArrowBackIcon } from "../../../assets/script/ArrowBack";

import styles from "./EditPlatform.module.css";

function EditPlatform() {
	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IPlatformChange>({
		defaultValues: {
			id: 0,
			name: "",
			logo: "",
			description: "",
			shortDescription: "",
			filters: [],
			solutionIds: [],
			cost: 0,
			website: "",
		},
		mode: "all",
		delayError: 200,
	});

	const [platform, setPlatform] = useState<IGetPlatform>();
	const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
	const [selectedFilters, setSelectedFilters] = useState<IFilterChecked[]>([]);
	const [selectedSolutions, setSelectedSolutions] = useState<number[]>([]);

	const unsavedPlatform = useAppSelector(ResourceDataEditSelector).currentEditPlatformData;
	const selectedFilter = useAppSelector(ResourceDataEditSelector).selectedFilters;
	const selectedSolution = useAppSelector(ResourceDataEditSelector).selectedSolutions;
	const userData = useAppSelector(userDataSelector).userData;
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { isOpen, toggleModal } = useModal();

	const maxFileSize: number = 1024;
	const minImageWidth: number = 808;
	const maxImageWidth: number = 1920;
	const minImageHight: number = 632;
	const maxImageHight: number = 1080;

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.PlatformEdit) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigate(Path.Login);
		}
		if (userData?.roles[0]?.name === String(Roles.MODERATOR)) {
			navigate(AdminPath.Drafts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (unsavedPlatform.name === "") {
			(async () => {
				const platformResponse: Response = await getCurrentPlatformData(Number(id));
				const platformData: Promise<IGetPlatform> = await platformResponse.json();
				setPlatform(await platformData);
				setImageUrl((await platformData).logoUrl);

				setValue("name", (await platformData).name.trim());
				setValue("shortDescription", (await platformData).shortDescription);
				setValue("description", (await platformData).description);
				setValue("website", (await platformData).website);
				setValue("cost", (await platformData).cost);
				setValue("logo", (await platformData).logoUrl);
			})();
		}
	}, [id, setValue, unsavedPlatform]);

	useEffect(() => {
		if (unsavedPlatform.name !== "") {
			setValue("shortDescription", unsavedPlatform?.shortDescription?.trim() || "");
			setValue("name", unsavedPlatform.name?.trim() || "");
			setValue("description", unsavedPlatform?.description?.trim() || "");
			setValue("cost", unsavedPlatform?.cost || 0);
			setValue("logo", unsavedPlatform?.logoUrl || "");
			setValue("website", unsavedPlatform?.website || "");
		}
	}, [unsavedPlatform, setValue]);

	useEffect(() => {
		const previousPath = window.localStorage.getItem("previousPath");
		if (!previousPath?.includes(`${AdminPath.PlatformPreview}/0`)) {
			dispatch(setSelectedFilterForEdit([]));
			dispatch(setSelectedSolutionForEdit([]));
			dispatch(setEditPlatformData({ name: "" }));
		}
	}, [dispatch]);

	const groupFilters = (filters: IFilterChecked[]): IFilterChecked[] => {
		const groupedFilters: Record<number, number[]> = {};

		filters.forEach((filter) => {
			if (!groupedFilters[filter.filterId]) {
				groupedFilters[filter.filterId] = [];
			}
			groupedFilters[filter.filterId] = [...[...groupedFilters[filter.filterId], ...filter.valueIds]];
		});

		return Object.entries(groupedFilters).map(([filterId, valueIds]) => ({
			filterId: parseInt(filterId),
			valueIds,
		}));
	};

	const unpublishedPlatform: SubmitHandler<IPlatformChange> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};

		const updatedData = {
			...trimmedData,
			filters: groupFilters(selectedFilters),
			solutionIds: selectedSolutions,
		};
		const change = await changeEditPlatform(updatedData, id);
		if (change.ok) {
			navigate(-1);
		}
	};

	const setDataForPlatformPreviewPage = async (updatePlatform: IUnsavedCurrentPlatformData) => {
		return await dispatch(setEditPlatformData(updatePlatform));
	};

	const deletePlatform = async () => {
		const remove = await removePlatformToDeleted(Number(id));
		if (remove.ok) {
			navigate(-1);
		}
	};

	const handleUnsupportedType = () => {
		setValue("logo", platform?.logoUrl || "");
	};

	const setImageAndValue = (imageDataUrl: string) => {
		setImageUrl(imageDataUrl);
		setValue("logo", imageDataUrl);
	};

	const readImage = (file: File) => {
		const reader = new FileReader();
		reader.onload = () => {
			const imageDataUrl = reader.result as string;
			const img = new Image();
			img.src = imageDataUrl;
			img.onload = () => {
				if (isValidSize(img.width, img.height)) {
					setImageAndValue(imageDataUrl);
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const isValidSize = (width: number, height: number) => {
		return width >= minImageWidth && width <= maxImageWidth && height >= minImageHight && height <= maxImageHight;
	};

	const handlePreviewNavigate: SubmitHandler<IPlatformChange> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const updatePlatform: IUnsavedCurrentPlatformData = {
			name: data.name.trim(),
		};
		if (data.logo) {
			updatePlatform.logoUrl = data.logo;
		}
		if (data.cost) {
			updatePlatform.cost = data.cost;
		}
		if (data.shortDescription) {
			updatePlatform.shortDescription = data.shortDescription.trim();
		}
		if (data.description) {
			updatePlatform.description = data.description.trim();
		}
		if (data.website) {
			updatePlatform.website = data.website;
		}
		dispatch(setSelectedFilterForEdit(groupFilters(selectedFilters)));
		dispatch(setSelectedSolutionForEdit(selectedSolutions));
		await setDataForPlatformPreviewPage(updatePlatform);

		navigate(`${AdminPath.PlatformPreview}/0`);
	};

	const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (!file) return;
		if (file) {
			const allowedImageTypes = ["image/jpeg", "image/png", "image/webp"];
			if (allowedImageTypes.includes(file.type)) {
				if (file.size <= maxFileSize * maxFileSize) {
					readImage(file);
				}
			} else {
				handleUnsupportedType();
			}
		}
	};

	const onSubmit: SubmitHandler<IPlatformChange> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};

		const updatedData = {
			...trimmedData,
			filters: groupFilters(selectedFilters),
			solutionIds: selectedSolutions,
			name: data.name.trim(),
		};

		const change = await changeEditPlatform(updatedData, id);
		if (change.ok) {
			if (!platform?.published) {
				movePlatformToPublished(Number(id));
				navigate(`${AdminPath.Platforms}/published/1`);
			} else {
				navigate(-1);
			}
		}
	};

	function renderCorrectCheckbox() {
		return unsavedPlatform.name !== "" ? (
			<CheckBoxBlock
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
				selectedSolution={selectedSolutions}
				setSelectedSolutionIds={setSelectedSolutions}
				checked={true}
				checkedCreationFilter={selectedFilter}
				checkedCreationSolutions={selectedSolution}
				type="Платформы"
			/>
		) : (
			<CheckBoxBlock
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
				selectedSolution={selectedSolutions}
				setSelectedSolutionIds={setSelectedSolutions}
				checkedFilters={platform?.filters}
				checkedSolutions={platform?.solutionList}
				type="Платформы"
			/>
		);
	}

	return (
		<div className={styles.editPlatformWrapper}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.editPlatformHead}>
					<button className={styles.editPlatformBackButton} onClick={toggleModal} type="button">
						<ArrowBackIcon />
					</button>
					<Title title="Редактирование" styleName="titlePage" />
					<span className={styles.invisible}></span>
				</div>
				<div className={styles.editPlatformParams}>
					<FieldsToFill
						control={control}
						handleImageChange={handleImageChange}
						imageUrl={imageUrl}
						platform={platform}
						imageText={"Изменить изображение"}
						height="700px"
						errors={errors}
						showInput={true}
						unsavedImg={unsavedPlatform.logoUrl}
					/>
					{renderCorrectCheckbox()}
				</div>
				<ButtonsSubmit
					showButton={true}
					deleted={deletePlatform}
					preview={handleSubmit(handlePreviewNavigate)}
					unpublished={handleSubmit(unpublishedPlatform)}
					onSubmit={handleSubmit(onSubmit)}
				/>
				<ModalHandelClose open={isOpen} toggle={toggleModal} title="Выйти из редактирования?">
					<Button
						content="Сохранить и выйти"
						styleName="saveAndExitButton"
						testId=""
						onClick={handleSubmit(unpublishedPlatform)}
					/>
					<Button
						content="Выйти без сохранения"
						styleName="exitWithoutSavingButton"
						testId=""
						onClick={() => navigate(-1)}
					/>
				</ModalHandelClose>
			</form>
		</div>
	);
}

export default EditPlatform;
