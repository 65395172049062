import { CrudMethods, IActionPlatformsModal, IActionStaffModal } from "../../../types/api/AdminApiTypes";
import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";
import { baseSolutionEndpoint } from "../resources/apiResourcesConstants";

import {
	solutionsDeletedEndpoint,
	solutionsUnpublishedEndpoint,
	solutionsPublishedEndpoint,
} from "./apiAdminConstants";

async function actionSolutionsModal({ endpoint, method }: IActionPlatformsModal) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${endpoint}`, {
		method: method,
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}

export async function moveSolutionToPublished(solutionId: number) {
	return await actionSolutionsModal({
		endpoint: `${solutionsPublishedEndpoint}/${solutionId}`,
		method: CrudMethods.put,
	} as IActionStaffModal);
}

export async function moveSolutionToDrafts(solutionId: number) {
	return await actionSolutionsModal({
		endpoint: `${solutionsUnpublishedEndpoint}/${solutionId}`,
		method: CrudMethods.put,
	} as IActionStaffModal);
}

export async function removeSolutionToDeleted(solutionId: number) {
	return await actionSolutionsModal({
		endpoint: `${baseSolutionEndpoint}/${solutionId}`,
		method: CrudMethods.delete,
	} as IActionStaffModal);
}

export async function restoreSolutionFromDeleted(solutionId: number) {
	return await actionSolutionsModal({
		endpoint: `${solutionsDeletedEndpoint}/${solutionId}`,
		method: CrudMethods.put,
	} as IActionStaffModal);
}

export async function removeSolutionFromDeleted(solutionId: number) {
	return await actionSolutionsModal({
		endpoint: `${solutionsDeletedEndpoint}/${solutionId}`,
		method: CrudMethods.delete,
	} as IActionStaffModal);
}
