import { useEffect, useRef, useState } from "react";
import classNames from "classnames/dedupe";

import useAppSelector from "../../../hooks/useAppSelector";

import ModalAdminHeader from "../ModalAdminHeader/ModalAdminHeader";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";

import logo from "../../../assets/layouts/Main/logo.png";

import styles from "./HeaderAdmin.module.css";

function HeaderAdmin() {
	const [toggler, setToggler] = useState<boolean>(false);
	const [userInitials, setUserInitials] = useState<string>("");
	const userData = useAppSelector(userDataSelector).userData;
	const adminContainerRef = useRef<HTMLDivElement>(null);
	const modalContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
				adminContainerRef.current &&
				!adminContainerRef.current.contains(event.target as Node) &&
				modalContainerRef.current &&
				!modalContainerRef.current.contains(event.target as Node)
			) {
				setToggler(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (userData?.firstname) {
			setUserInitials(
				!userData?.lastname
					? `${userData?.firstname.at(0)}`
					: `${userData?.firstname.at(0)}${userData?.lastname.at(0)}`,
			);
		}
	}, [userData]);

	return (
		<header className={styles.adminHeaderWrap}>
			<div className={styles.headerContainer}>
				<img className={styles.headerContainer__logo} src={logo} alt="logo" />
				<div className={styles.adminDataWrap} onClick={() => setToggler(!toggler)} ref={adminContainerRef}>
					<div className={styles.adminDataWrap__adminInitials}>{userInitials}</div>
				</div>
				<div
					className={classNames(styles.headerContainer__modalContainer, {
						[styles.headerContainer__modalContainerOpen]: toggler,
					})}
					ref={modalContainerRef}>
					<ModalAdminHeader />
				</div>
			</div>
		</header>
	);
}

export default HeaderAdmin;
