import { BaseSyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames/dedupe";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IPlatformCatalogCard } from "../../../../types/common/ComponentProps";
import { HandleImageErrorType } from "../../../../types/components/PlatformCatalog";
import FavoriteAction from "../../../AuthLayoutComponents/FavoriteAction/FavoriteAction";
import { setResourceId } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { getCorrectResourceLogo } from "../../../../utils/getCorrectResourceLogo";
import { PlatformCardTypes } from "../../../../helpers/catalogHelpers";
import { Path } from "../../../../services/router/routes";

import { mockPlatformLogo } from "../../../../mocks/linksArr";
import verticalPoints from "../../../../assets/components/PlatformsAdmin/verticalPoints.svg";
import arrowRight from "../../../../assets/components/PlatformCatalogCard/arrowRightCatalog.svg";

import style from "./PlatformCatalogCard.module.css";

const PlatformCatalogCard = ({
	type,
	title,
	description,
	solutionNumber,
	icon,
	isFavoritePlatform,
	isHiddenDropDownMenu,
	cardId,
	toggleContextMenu,
	setFavorite,
}: IPlatformCatalogCard) => {
	const [imageError, setImageError] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const isLogin = useAppSelector(userDataSelector).userData?.email;

	const handleImageError: HandleImageErrorType = () => {
		return (event: BaseSyntheticEvent) => {
			return setImageError(true);
		};
	};

	function handleDropDownMenuAction() {
		return dispatch(setResourceId(cardId));
	}

	function getCorrectCurrentPlatformLink(): string {
		return (isLogin ? Path.CurrentPlatformSignin : Path.CurrentPlatformUnSignin) + `/${cardId}`;
	}

	function renderCorrectCornerButton() {
		return type === PlatformCardTypes.CatalogType ? (
			<FavoriteAction
				resourceId={cardId ?? 0}
				isFavoriteContent={isFavoritePlatform ?? false}
				setFavorite={setFavorite}
			/>
		) : (
			<div onClick={handleDropDownMenuAction}>
				<button
					className={cn(style.description__dropDownMenuAction, {
						[style.description__dropDownMenuAction__hidden]: isHiddenDropDownMenu,
					})}
					onClick={toggleContextMenu}>
					<img src={verticalPoints} alt="drops" />
				</button>
			</div>
		);
	}

	return (
		<div className={style.catalogCardWrap}>
			<div className={style.catalogCardWrap__logoWrap}>
				<img
					className={style.catalogCardWrap__logo}
					src={getCorrectResourceLogo(icon, mockPlatformLogo, imageError)}
					alt="platform logo"
					onError={handleImageError()}
				/>
			</div>
			<div className={style.descriptionWrap}>
				<div className={style.headerDescriptionWrap}>
					<h3 className={style.headerDescriptionWrap__title}>{title}</h3>
					{renderCorrectCornerButton()}
				</div>
				<p className={style.descriptionWrap__text}>{description}</p>
				<p className={style.descriptionWrap__solutionNumberLine}>
					Количество решений, входящих в платформу:
					<span className={style.descriptionWrap__solutionNumberLine__normalFontWeight}>{" " + solutionNumber}</span>
				</p>
				<div className={style.fullDescriptionWrap}>
					<Link
						to={getCorrectCurrentPlatformLink()}
						className={
							type === PlatformCardTypes.CatalogType ? style.fullDescriptionLink : style.fullDescriptionLinkHidden
						}>
						<span className={style.fullDescriptionLink__title}>Подробнее</span>
						<img src={arrowRight} alt="full description arrow" className={style.fullDescriptionLink__arrowRight}></img>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default PlatformCatalogCard;
