import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Accordion } from "../../../components/PersonalAreaLayoutComponents/Accordion/Accordion";
import { Button } from "../../../ui/Button/Button";
import { Title } from "../../../ui/Title/Title";
import { accordionArr } from "../../../mocks/accordionArr";

import useAppSelector from "../../../hooks/useAppSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { Path } from "../../../services/router/routes";

import style from "./PersonalAreaFAQ.module.css";

const PersonalAreaFAQ = () => {
	const navigate = useNavigate();
	const userData = useAppSelector(userDataSelector).userData;

	useEffect(() => {
		if (!userData?.email) {
			navigate(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNavigateContacts = () => {
		navigate(userData?.id ? Path.PersonalAreaContacts : Path.Contacts);
	};

	return (
		<div className={style.faq__container}>
			<Title title={"FAQ"} styleName={"titleService"} />
			<div className={style.accordion__container}>
				<Accordion accordionItems={accordionArr} />
				<div className={style.accordion__containerBlock}>
					<p className={style.accordion__container_question}>
						{" "}
						Не нашли ответа на свой вопрос? Закажите консультацию специалиста
					</p>
					<Button onClick={handleNavigateContacts} content="Оставить заявку" styleName="commonMaroonButton FAQButton" />
				</div>
			</div>
		</div>
	);
};

export default PersonalAreaFAQ;
