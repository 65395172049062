import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames/dedupe";

import useAppSelector from "../../../hooks/useAppSelector";

import {
	ICheckedCurrentRoleButton,
	IStaffLineUserData,
	IStaffResponse,
	ISetCurrentUserDataState,
} from "../../../types/pages/Staff";
import { EmailDataType } from "../../../types/api/AdminApiTypes";
import ModalHandelClose from "../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { Title } from "../../../ui/Title/Title";
import { Button } from "../../../ui/Button/Button";
import CreateAdminProfileForm from "../../../components/AdminLayoutComponents/CreateProfileForm/CreateProfileForm";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath, Path } from "../../../services/router/routes";
import { Roles } from "../../../helpers/roles";
import {
	removeAdminProfile,
	getStaffList,
	blockProfile,
	unBlockProfile,
	changeUserRoleAction,
} from "../../../services/api/admin/staffPageActions";

import plusElement from "../../../assets/pages/Staff/plusElement.svg";

import styles from "./Staff.module.css";

function Staff() {
	const [staffList, setStaffList] = useState<IStaffResponse>();
	const [isLockedCurrentProfile, setIsLockedCurrentProfile] = useState<boolean>(false);
	const [currentModalName, setCurrentModalName] = useState<ModalsNameList>();
	const [isStaffListError, setIsStaffListError] = useState<boolean>(false);
	const [currentUserData, setCurrentUserData] = useState<ISetCurrentUserDataState>({
		currentUserName: "",
		currentProfileEmail: "",
		currentUserRole: "",
	});
	const [isCheckedCurrentRoleButton, setIsCheckedCurrentRoleButton] = useState<ICheckedCurrentRoleButton>({
		isCheckedAdminButton: false,
		isCheckedModerButton: false,
	});

	const location = useLocation();
	const navigator = useNavigate();
	const userData = useAppSelector(userDataSelector).userData;

	const activeStatus: string = "STATUS_WORK";
	const staffErrorContent: string = "Ошибка загрузки списка персонала!";

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.Staff) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		if (([Roles.MODERATOR, Roles.ADMIN] as string[]).includes(userData?.roles[0]?.name)) {
			navigator(AdminPath.Drafts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	enum ModalsNameList {
		modalCreateName = "modalCreate",
		modalChangeName = "modalChange",
		modalLockedName = "modalLocked",
		modalRemoveName = "modalRemove",
	}

	enum StatusTitle {
		locked = "разблокировать",
		unLocked = "заблокировать",
	}

	useEffect(() => {
		if (!currentModalName) {
			(async () => {
				await getStaffList().then(async (response: Response) => {
					if (response.ok) {
						const staffListResponse: Promise<IStaffResponse> = await response.json();
						setStaffList(await staffListResponse);
						setIsStaffListError(false);
					} else {
						setIsStaffListError(true);
					}
				});
			})();
		}
	}, [currentModalName]);

	const closeCurrentModal = () => {
		setCurrentUserData((prevState) => ({
			...prevState,
			currentUserRole: " ",
		}));
		return setCurrentModalName(undefined);
	};

	function getUserRoleTitle(userRole: string): string {
		return userRole === Roles.ADMIN ? "Администратор" : "Модератор";
	}

	function getLockedProfileModalStatusTitle(): string {
		return isLockedCurrentProfile ? StatusTitle.locked : StatusTitle.unLocked;
	}

	function getStyleChangeRoleButton(userRole: string) {
		const checkedButtonStyleList: string[] = [
			styles.switchRoleContainer__activeRoleButton,
			styles.switchRoleContainer__checkedButton,
		];
		const isCheckedEmployeeButtons = [
			isCheckedCurrentRoleButton.isCheckedAdminButton,
			isCheckedCurrentRoleButton.isCheckedModerButton,
		];
		const isRoleEqual: number = Number(userRole !== currentUserData.currentUserRole);

		const getCurrentStyleLine = (activeRoleCheckedButton: string, isCheckedEmployeeButton: boolean) => {
			return cn(styles.switchRoleContainer__uncheckedButton, {
				[activeRoleCheckedButton]: isCheckedEmployeeButton,
			});
		};

		return userRole === Roles.MODERATOR
			? getCurrentStyleLine(checkedButtonStyleList[isRoleEqual], isCheckedEmployeeButtons[1])
			: getCurrentStyleLine(checkedButtonStyleList[isRoleEqual], isCheckedEmployeeButtons[0]);
	}

	function setActiveStyleRoleButton(employee: boolean) {
		setIsCheckedCurrentRoleButton({
			isCheckedAdminButton: Boolean(employee),
			isCheckedModerButton: Boolean(!employee),
		});
		return null;
	}

	const displayStaffList = () => {
		if (staffList) {
			return staffList.content.map((personInfo, index) => {
				const userInitials: string = `${personInfo.firstname.charAt(0)}${
					personInfo.lastname && personInfo.lastname.charAt(0)
				}`;
				const employeesData: IStaffLineUserData = {
					userName: `${personInfo.firstname} ${personInfo.lastname || ""}`,
					email: personInfo.email,
					userRole: personInfo.roles[0].name,
					isUserLocked: !(personInfo.statuses[0].name === activeStatus),
				};

				return (
					<div className={styles.personInfoWrap} key={index}>
						<div className={styles.personInfoWrap__userInitialsContainer}>{userInitials}</div>
						<p className={styles.personInfoWrap__userName}>{employeesData.userName}</p>
						<p className={styles.personInfoWrap__roleLine}>{getUserRoleTitle(employeesData.userRole)}</p>
						<Button
							content="Сменить должность"
							styleName="commonMaroonButton staffChangeJobTitle"
							onClick={() => openChangeRoleModal(employeesData)}
						/>
						<Button
							content={employeesData.isUserLocked ? "Разблокировать" : "Заблокировать"}
							styleName={
								employeesData.isUserLocked ? "commonMaroonButton staffChangeJobTitle" : "commonMaroonButton setLockUser"
							}
							onClick={() => openLockedProfileModal(employeesData.isUserLocked, employeesData.email)}
						/>
						<Button
							content="Удалить"
							styleName="commonMaroonButton staffRemoveUserProfile"
							onClick={() => openRemoveProfileModal(employeesData.email)}
						/>
					</div>
				);
			});
		}
		return <h2 className={styles.emptyListTitle}>{isStaffListError ? staffErrorContent : ""}</h2>;
	};

	const openRemoveProfileModal = (email: string) => {
		setCurrentUserData((prevState) => ({
			...prevState,
			currentProfileEmail: email,
		}));
		return setCurrentModalName(ModalsNameList.modalRemoveName);
	};

	const openLockedProfileModal = (isLocked: boolean, email: string) => {
		setIsLockedCurrentProfile(isLocked);
		setCurrentUserData((prevState) => ({
			...prevState,
			currentProfileEmail: email,
		}));
		return setCurrentModalName(ModalsNameList.modalLockedName);
	};

	const openChangeRoleModal = (userData: IStaffLineUserData) => {
		setCurrentUserData({
			currentUserName: userData.userName,
			currentProfileEmail: userData.email,
			currentUserRole: userData.userRole,
		});
		setIsCheckedCurrentRoleButton({
			isCheckedAdminButton: userData.userRole === Roles.ADMIN,
			isCheckedModerButton: userData.userRole === Roles.MODERATOR,
		});
		return setCurrentModalName(ModalsNameList.modalChangeName);
	};

	const openCreateProfileModal = (userRole: string) => {
		setCurrentUserData((prevState) => ({
			...prevState,
			currentUserRole: userRole,
		}));

		return setCurrentModalName(ModalsNameList.modalCreateName);
	};

	const handleRemoveProfile = async () => {
		const emailData: EmailDataType = {
			email: currentUserData.currentProfileEmail,
		};
		await removeAdminProfile(emailData);
		return closeCurrentModal();
	};

	const handleSetLockProfile = async () => {
		const emailData: EmailDataType = {
			email: currentUserData.currentProfileEmail,
		};
		await (isLockedCurrentProfile ? unBlockProfile(emailData) : blockProfile(emailData));
		return closeCurrentModal();
	};

	const handleChangeUserRole = async () => {
		const emailData: EmailDataType = {
			email: currentUserData.currentProfileEmail,
		};
		const isAdmin = currentUserData.currentUserRole === Roles.ADMIN;
		const isAdminButtonChecked = isCheckedCurrentRoleButton.isCheckedAdminButton;

		if ((isAdmin && !isAdminButtonChecked) || (!isAdmin && isAdminButtonChecked)) {
			await changeUserRoleAction(emailData);
			return closeCurrentModal();
		}
		return null;
	};

	return (
		<main className={styles.staffPageWrap}>
			<Title title="Персонал" styleName="titlePage" />
			<div className={styles.toolsWrap}>
				<button className={styles.addAdminWrap} onClick={() => openCreateProfileModal("admin")}>
					<p className={styles.addAdminWrap__title}>Добавить Админа</p>
					<img src={plusElement} alt="plus" className={styles.addAdminWrap__imageElement} />
				</button>
				<button className={styles.addModerWrap} onClick={() => openCreateProfileModal("moderator")}>
					<p className={styles.addModerWrap__title}>Добавить Модератора</p>
					<img src={plusElement} alt="plus" className={styles.addModerWrap__imageElement} />
				</button>
			</div>
			<div className={styles.staffListWrap}>
				<div className={styles.staffListContainer}>{displayStaffList()}</div>
			</div>
			<ModalHandelClose
				title="Вы уверены, что хотите удалить этого сотрудника?"
				open={currentModalName === ModalsNameList.modalRemoveName}
				toggle={closeCurrentModal}>
				<Button
					content="Удалить"
					styleName="commonMaroonButton staffRemoveUserProfileModal"
					onClick={handleRemoveProfile}
				/>
			</ModalHandelClose>
			<ModalHandelClose
				title={`Вы уверены, что хотите ${getLockedProfileModalStatusTitle()} этого сотрудника?`}
				open={currentModalName === ModalsNameList.modalLockedName}
				toggle={closeCurrentModal}>
				<Button
					content={getLockedProfileModalStatusTitle()}
					styleName="commonMaroonButton staffLockedProfileModal"
					onClick={handleSetLockProfile}
				/>
			</ModalHandelClose>
			<ModalHandelClose
				title="Смена должности"
				open={currentModalName === ModalsNameList.modalChangeName}
				toggle={closeCurrentModal}>
				<p className={styles.currentUserNameTitle}>{currentUserData.currentUserName}</p>
				<div className={styles.switchRoleWrap}>
					<p className={styles.switchRoleWrap__title}>Должность</p>
					<div className={styles.switchRoleContainer}>
						<button
							className={getStyleChangeRoleButton(Roles.MODERATOR)}
							onClick={() => setActiveStyleRoleButton(false)}>
							Модератор
						</button>
						<button className={getStyleChangeRoleButton(Roles.ADMIN)} onClick={() => setActiveStyleRoleButton(true)}>
							Администратор
						</button>
					</div>
				</div>
				<Button
					content="Принять изменение"
					styleName="commonMaroonButton staffChangeRoleModal"
					onClick={handleChangeUserRole}
				/>
			</ModalHandelClose>
			<ModalHandelClose
				title="Добавление персонала"
				open={currentModalName === ModalsNameList.modalCreateName}
				toggle={closeCurrentModal}>
				<CreateAdminProfileForm userRole={currentUserData.currentUserRole} toggleModal={closeCurrentModal} />
			</ModalHandelClose>
		</main>
	);
}

export default Staff;
