import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import cn from "classnames/dedupe";

import useAppDispatch from "../../../../hooks/useAppDispatch";

import { IFilterGroup } from "../../../../types/common/ComponentProps";
import { IPlatformFilterData } from "../../../../types/components/PlatformCatalog";
import {
	ICreateResult,
	ICurrentFilterValueState,
	IEditResult,
	IFilterGroupFormData,
	IValueForUpdating,
	PickedCurrentFilterValueState,
} from "../../../../types/components/FilterGroup";
import { Input } from "../../../../ui/Input/Input";
import {
	createFilterGroup,
	removeFilterGroup,
	updateFilterGroup,
} from "../../../../services/api/admin/filtersPageActions";
import {
	setFilterData,
	setResourceId,
	setResourceType,
} from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import { setIsOpenComponent } from "../../../../services/redux/features/adminPart/componentToggler/ComponentTogglerSlice";
import { extractDigitsFromTextLine } from "../../../../utils/extractDigitsFromTextLine";
import { filterHintPattern, filterTitlePattern } from "../../../../helpers/AuthConstants";
import { CatalogRoutes } from "../../../../helpers/catalogHelpers";

import saveFilterLogo from "../../../../assets/pages/Filtering/basil_save_filter.svg";
import removeFilterLogo from "../../../../assets/pages/Filtering/removeFilter.svg";
import removeFilterTitleLogo from "../../../../assets/pages/Filtering/removeFilterValueLogo.svg";
import addFilterTitleLogo from "../../../../assets/pages/Filtering/addFilterValueLogo.svg";

import styles from "./FilterGroup.module.css";

function FilterGroup(filterGroupData: IFilterGroup) {
	const [filterValueList, setFilterValueList] = useState<ICurrentFilterValueState[]>([]);
	const [isMultipleChoice, setIsMultipleChoice] = useState<boolean>();
	const [deletedFieldIds, setDeletedFieldIds] = useState<number[]>([]);
	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		reset,
		unregister,
		formState: { isValid },
	} = useForm<IFilterGroupFormData>({
		defaultValues: {
			filterGroupTitle: filterGroupData.name,
			filterGroupHint: filterGroupData.description,
		},
		mode: "all",
	});
	const dispatch = useAppDispatch();

	useEffect(() => {
		(() => {
			if (!filterGroupData.filterValueList) {
				return null;
			}
			const updatedFilterValueList: ICurrentFilterValueState[] = filterGroupData.filterValueList.map(
				(filterValue, index) => {
					return {
						id: filterValue.id,
						value: filterValue.value,
						formFieldTitle: `filterListValue${index}`,
						isCreatedFieldValue: filterGroupData.isCreationCard,
					};
				},
			);
			setFilterValueList(updatedFilterValueList);
			setIsMultipleChoice(filterGroupData.isMultipleChoice);
		})();
	}, [filterGroupData]);

	function getCorrectFieldTitleList(isCreatedFieldValue: boolean): string[] {
		return filterValueList.reduce((fieldTitleArray, currentFieldData) => {
			if (isCreatedFieldValue ? currentFieldData.isCreatedFieldValue : !currentFieldData.isCreatedFieldValue)
				return fieldTitleArray.concat(currentFieldData.formFieldTitle);
			return fieldTitleArray;
		}, [] as string[]);
	}

	function getCreatedFieldValues(updatedFilterData: IFilterGroupFormData): string[] {
		const fieldTitles = getCorrectFieldTitleList(true);

		return fieldTitles.reduce((resultCreatedFieldValues, fieldTitle) => {
			return resultCreatedFieldValues.concat(String(updatedFilterData[fieldTitle as keyof IFilterGroupFormData]));
		}, [] as string[]);
	}

	function getUpdatedFieldValues(updatedFilterData: IFilterGroupFormData): IValueForUpdating[] {
		const resultUpdatedFieldValues: IValueForUpdating[] = [];
		const fieldTitles = getCorrectFieldTitleList(false);

		filterValueList.forEach((filterValue, index) => {
			if (filterValue.isCreatedFieldValue) {
				return filterValue;
			}

			const updatedFieldValue = String(updatedFilterData[fieldTitles[index] as keyof IFilterGroupFormData]);

			if (updatedFieldValue !== filterValue.value) {
				resultUpdatedFieldValues.push({
					valueId: filterValue.id,
					value: updatedFieldValue,
				} as IValueForUpdating);
			} else return filterValue;
		});
		return resultUpdatedFieldValues;
	}

	const onSubmit: SubmitHandler<IFilterGroupFormData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();

		if (filterGroupData.isCreationCard) {
			const createResult: ICreateResult = {
				name: data.filterGroupTitle,
				description: data.filterGroupHint,
				values: getCreatedFieldValues(data),
				multipleChoice: isMultipleChoice ?? true,
			};
			const resourceFilterData: Promise<Response> = createFilterGroup(filterGroupData.resourceType, createResult);

			resourceFilterData
				.then((response: Response) => {
					if (response.ok) dispatch(setIsOpenComponent(false));
				})
				.catch((e: Error) => {
					dispatch(setIsOpenComponent(false));
				});
		}
		const editResult: IEditResult = {
			name: filterGroupData.name,
			description: filterGroupData.description,
			valueIdsForDeleting: deletedFieldIds,
			valuesForUpdating: getUpdatedFieldValues(data),
			valuesForCreating: getCreatedFieldValues(data),
		};

		if (editResult.name !== data.filterGroupTitle) {
			editResult.name = data.filterGroupTitle;
		}
		if (editResult.description !== data.filterGroupHint) {
			editResult.description = data.filterGroupHint;
		}

		(async () => {
			const updateFilterGroupResponse = await updateFilterGroup(
				filterGroupData.id,
				filterGroupData.resourceType,
				editResult,
			);
			if (updateFilterGroupResponse.ok) {
				const updatedFilterGroupData: IPlatformFilterData = await updateFilterGroupResponse.json();

				dispatch(setFilterData(updatedFilterGroupData));
				dispatch(
					setResourceType(
						filterGroupData.resourceType === CatalogRoutes.Solutions
							? CatalogRoutes.Solutions
							: CatalogRoutes.Platforms,
					),
				);
			}
		})();
	};

	const handleAddFilterValue = (formFieldTitle: string) => {
		const newFilterValue: ICurrentFilterValueState = {
			id: extractDigitsFromTextLine(crypto.randomUUID()),
			value: "",
			formFieldTitle: formFieldTitle,
			isCreatedFieldValue: true,
		};
		return setFilterValueList([...filterValueList, newFilterValue]);
	};

	function getUpdatedFields(isCreatedFieldValue: boolean, deletedStateFieldValue: ICurrentFilterValueState) {
		const controlValues: IFilterGroupFormData = getValues();
		const skippingKeysInLoop: string[] = ["filterGroupTitle", "filterGroupHint"];
		const updatedFieldValues: string[] = [];
		let deletedValueIndex: number = 0;

		const correctFieldTitleList: string[] = isCreatedFieldValue
			? getCorrectFieldTitleList(true)
			: [...getCorrectFieldTitleList(false), ...getCorrectFieldTitleList(true)];

		for (const controlValuesKey in controlValues) {
			if (
				skippingKeysInLoop.includes(controlValuesKey) ||
				(!correctFieldTitleList.includes(controlValuesKey) && isCreatedFieldValue)
			) {
				continue;
			}
			updatedFieldValues.push(controlValues[controlValuesKey as keyof IFilterGroupFormData] as string);
		}

		const resultUpdatedFieldValues: string[] = updatedFieldValues.filter((updatedFieldValue, index) => {
			if (updatedFieldValue === controlValues[deletedStateFieldValue?.formFieldTitle as keyof IFilterGroupFormData])
				deletedValueIndex = index;
			return updatedFieldValue !== controlValues[deletedStateFieldValue?.formFieldTitle as keyof IFilterGroupFormData];
		});

		correctFieldTitleList.forEach((fieldTitle, index) => {
			if (index < deletedValueIndex) {
				return fieldTitle;
			}
			return setValue(
				fieldTitle as `filterValueList.${number}`,
				correctFieldTitleList.length - 1 !== index ? resultUpdatedFieldValues[index] : "",
			);
		});

		unregister(correctFieldTitleList[correctFieldTitleList.length - 1] as `filterValueList.${number}`);
		return filterValueList.filter((filterValue, index) => index !== filterValueList.length - 1);
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const handleRemoveFilterValue = (filterId: number) => {
		const deletedStateFieldValue = filterValueList.find((filterValue) => filterValue.id === filterId);

		if (!deletedStateFieldValue?.isCreatedFieldValue) {
			setDeletedFieldIds([...deletedFieldIds, filterId]);
			const updatedFieldsData = getUpdatedFields(false, deletedStateFieldValue ?? filterValueList[0]);
			const updatedFieldsStateData: PickedCurrentFilterValueState[] = [];
			let firstCreatedFieldValueId = 0;

			filterValueList.find((filterValue, index) => {
				if (filterValue.id !== filterId && !filterValue.isCreatedFieldValue) {
					updatedFieldsStateData.push({
						id: filterValue.id,
						value: filterValue.value,
					});
				}
				if (filterValue.isCreatedFieldValue) firstCreatedFieldValueId = index;
				return !!filterValue.isCreatedFieldValue;
			});

			const updatedCorrectedFilterValueList = updatedFieldsData.map((filterValue, index) => {
				if (!filterValue.isCreatedFieldValue && index !== firstCreatedFieldValueId - 1) {
					return {
						id: updatedFieldsStateData[index].id,
						value: updatedFieldsStateData[index].value,
						formFieldTitle: filterValue.formFieldTitle,
						isCreatedFieldValue: filterValue.isCreatedFieldValue,
					} as ICurrentFilterValueState;
				}
				if (index === firstCreatedFieldValueId - 1) {
					return {
						id: extractDigitsFromTextLine(crypto.randomUUID()),
						value: "",
						formFieldTitle: filterValue.formFieldTitle,
						isCreatedFieldValue: true,
					} as ICurrentFilterValueState;
				}
				return filterValue;
			});

			return setFilterValueList(updatedCorrectedFilterValueList);
		}
		if (deletedStateFieldValue?.isCreatedFieldValue) {
			const updatedFieldsData = getUpdatedFields(true, deletedStateFieldValue);

			return setFilterValueList(updatedFieldsData);
		}
	};

	const handleRemoveFilterGroup = async () => {
		if (filterGroupData.isCreationCard) {
			return reset();
		}

		return await removeFilterGroup(filterGroupData.id, filterGroupData.resourceType).then((response: Response) => {
			if (response.ok) {
				dispatch(setResourceId(filterGroupData.id));
			}
		});
	};

	const handleSwitchAction = () => {
		if (!filterGroupData.isCreationCard) {
			return null;
		}
		return setIsMultipleChoice(!isMultipleChoice);
	};

	const showDynamicFilterList = () => {
		return filterValueList.map((filterValue, index) => {
			return (
				<div className={styles.filterItemWrap} key={crypto.randomUUID()}>
					<p className={styles.filterItemWrap__title}>Значение фильтра</p>
					<div className={styles.filterValueWrapper}>
						<div className={styles.filterValueWrapper__filterValueContainer}>
							<Input
								control={control}
								id={`filterListValue${index}`}
								name={`filterListValue${index}`}
								type="text"
								defaultValue={filterValue.value}
								rules={{
									required: index === 0,
									pattern: filterTitlePattern,
								}}
							/>
						</div>
						<button type="button" onClick={() => handleRemoveFilterValue(filterValue.id)}>
							<img src={removeFilterTitleLogo} alt="remove filter" />
						</button>
						{index === filterValueList.length - 1 && (
							<button type="button" onClick={() => handleAddFilterValue(`filterListValue${filterValueList.length}`)}>
								<img src={addFilterTitleLogo} alt="add filter" />
							</button>
						)}
					</div>
				</div>
			);
		});
	};

	return (
		<form noValidate={true} className={styles.filterGroupWrap} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.initialActionBarWrap}>
				<button className={styles.initialActionBarWrap__savingActionWrap} type="submit" disabled={!isValid}>
					<img src={saveFilterLogo} alt="save filter" />
				</button>
				<button
					className={styles.initialActionBarWrap__removingActionWrap}
					type="button"
					onClick={handleRemoveFilterGroup}>
					<img src={removeFilterLogo} alt="remove filter" />
				</button>
			</div>
			<h4 className={styles.filterGroupWrap__filterGroupTitle}>Название фильтра</h4>
			<Input
				control={control}
				id="filterGroupTitle"
				name="filterGroupTitle"
				type="text"
				inputStyleName="filterGroupTitleInput"
				rules={{
					required: true,
					pattern: filterTitlePattern,
				}}
			/>
			<h4 className={styles.filterGroupWrap__filterGroupHint}>Подсказка для фильтра</h4>
			<Input
				control={control}
				id="filterGroupHint"
				name="filterGroupHint"
				type="text"
				inputStyleName="filterGroupHintInput"
				rules={{
					pattern: filterHintPattern,
				}}
			/>
			<div className={styles.switchWrap}>
				<div className={styles.switchContainer}>
					<button
						type="button"
						className={cn(styles.disabledSwitchButton, {
							[styles.activeSwitchButton]: !isMultipleChoice,
						})}
						onClick={handleSwitchAction}>
						Одинарный
					</button>
					<button
						type="button"
						className={cn(styles.disabledSwitchButton, {
							[styles.activeSwitchButton]: isMultipleChoice,
						})}
						onClick={handleSwitchAction}>
						Множественный
					</button>
				</div>
			</div>
			<hr className={styles.filterGroupWrap__dividingLine} />
			<div>{showDynamicFilterList()}</div>
		</form>
	);
}

export default FilterGroup;
