enum PortTypes {
	JAVA_ENV_AS = "",
	JAVA_ENV_PS = "/api/v1",
}

export function getCorrectBaseUrl(serviceName: string): string {
	const envDomainName = window.location.hostname.split(".")[0];
	const port = serviceName === "as" ? PortTypes.JAVA_ENV_AS : PortTypes.JAVA_ENV_PS;
	if (envDomainName === "java-dev1") {
		return `https://java-dev.devlaba.online${port}`;
	}
	return `https://${envDomainName}.devlaba.online${port}`;
}
