import { useEffect } from "react";

import CatalogSwitch from "../../../../components/MainLayoutComponents/CatalogSwitch/CatalogSwitch";
import CatalogPlatforms from "../../../../components/MainLayoutComponents/ResourceComponents/CatalogPlatforms/CatalogPlatforms";
import { CatalogRoutes } from "../../../../helpers/catalogHelpers";
import { Title } from "../../../../ui/Title/Title";

import styles from "./PlatformCatalogUnSignin.module.css";

function PlatformCatalogUnSignin() {
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<main className={styles.catalogUnsigninWrap}>
			<Title title="Каталог" styleName="titlePage" />
			<CatalogSwitch currentResource={CatalogRoutes.Platforms} isLogin={false} />
			<div className={styles.catalogUnsigninContent}>
				<CatalogPlatforms />
			</div>
		</main>
	);
}

export default PlatformCatalogUnSignin;
