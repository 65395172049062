export const CheckIcon = () => {
	return (
		<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.6666 1L5.49992 10.1667L1.33325 6"
				stroke="#B72A62"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
