import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import { publishedSolutionsSelector } from "../../../../services/redux/features/adminPart/solutions/publishedSolutions/PublishedSolutionsSelector";
import { clearPublishedSolutions } from "../../../../services/redux/features/adminPart/solutions/publishedSolutions/PublishedSolutionsSlice";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";

import { SolutionCard } from "../../../MainLayoutComponents/ResourceComponents/SolutionCard/SolutionCard";
import ModalHandelClose from "../../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import useModal from "../../../../hooks/useModal";
import { ISolutionsUserStatus } from "../../../../types/common/ComponentProps";
import { Button } from "../../../../ui/Button/Button";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { moveSolutionToDrafts, removeSolutionToDeleted } from "../../../../services/api/admin/solutionsPageActions";
import { getPublishedSolutions } from "../../../../services/redux/features/adminPart/solutions/publishedSolutions/PublishedSolutionsThunk";
import { MenuContent } from "../../../../helpers/ContextMenu";
import { AdminCatalogRoutes, solutionListErrorContent } from "../../../../helpers/catalogHelpers";
import { AdminPath } from "../../../../services/router/routes";

import style from "./PublishedSolutions.module.css";

export const PublishedSolutions = ({ isModerator }: ISolutionsUserStatus) => {
	const [toggleListUpdate, setToggleListUpdate] = useState(false);
	const [modalTextContent, setModalTextContent] = useState<{
		title: string;
		description: string;
		buttonName: string;
	}>();

	const dispatch = useAppDispatch();
	const param = useParams();
	const navigate = useNavigate();
	const { isOpen, toggleModal } = useModal();
	const { publishedSolutions, publishedTotalPages, isLoading, error } = useAppSelector(publishedSolutionsSelector);
	const resourceId = useAppSelector(ResourceDataSelector).currentResourceId;
	const userData = useAppSelector(userDataSelector).userData;

	const activeSwitchElement: AdminCatalogRoutes = param?.pagepart as AdminCatalogRoutes;

	const requestParams = {
		offset: Number(param.page),
		limit: 9,
	};

	useEffect(() => {
		if (AdminCatalogRoutes.Published === activeSwitchElement) {
			dispatch(getPublishedSolutions(requestParams));
		}
		return () => {
			dispatch(clearPublishedSolutions());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, requestParams.offset, toggleListUpdate]);

	const openModal = (title: string, description: string, buttonName: string) => {
		setModalTextContent({ title, description, buttonName });
		toggleModal();
	};

	const publishedCategory = {
		adminList: [
			{ title: MenuContent.EDIT, onClick: () => navigate(`${AdminPath.SolutionsEdit}/${resourceId}`) },
			{ title: MenuContent.PREVIEW, onClick: () => navigate(`${AdminPath.SolutionPreview}/${resourceId}`) },
			{
				title: MenuContent.UNPUBLISH,
				onClick: () =>
					openModal("Снять с публикации?", "Снятое с публикации будет храниться в черновиках", MenuContent.UNPUBLISH),
			},
			{
				title: MenuContent.DELETE,
				onClick: () => openModal("Удалить?", "Удалённое будет храниться 30 дней", MenuContent.DELETE),
			},
		],
		moderList: [
			{ title: MenuContent.EDIT, onClick: () => navigate(`${AdminPath.Solutions}/${resourceId}`) },
			{ title: MenuContent.PREVIEW, onClick: () => navigate(`${AdminPath.SolutionPreview}/${resourceId}`) },
		],
	};

	const handleSolutionUnpublish = async () => {
		await moveSolutionToDrafts(resourceId);
		toggleModal();
		setToggleListUpdate(!toggleListUpdate);
	};

	const handleSolutionDelete = async () => {
		await removeSolutionToDeleted(resourceId);
		toggleModal();
		setToggleListUpdate(!toggleListUpdate);
	};

	function renderSolutionList() {
		return !publishedSolutions || !publishedSolutions.length || isLoading ? (
			<h3>{error ? solutionListErrorContent : ""}</h3>
		) : (
			publishedSolutions.map((item) => (
				<SolutionCard
					key={item.id}
					id={item.id}
					title={item.name}
					shortDescription={item.shortDescription}
					logo={item.logo}
					role={userData.roles[0]?.name}
					menuContent={isModerator ? publishedCategory.moderList : publishedCategory.adminList}
				/>
			))
		);
	}

	return (
		<>
			<ModalHandelClose
				title={modalTextContent?.title ?? ""}
				open={isOpen}
				toggle={toggleModal}
				isLeftAlignedTextHeader>
				<p className={style.modalDescription}>{modalTextContent?.description}</p>
				<Button
					styleName="commonMaroonButton solutionCatalogModal"
					content={modalTextContent?.buttonName ?? ""}
					onClick={modalTextContent?.buttonName === MenuContent.DELETE ? handleSolutionDelete : handleSolutionUnpublish}
				/>
			</ModalHandelClose>
			<div className={style.solutionList}>{renderSolutionList()}</div>
			<PaginationElement
				currPage={Number(param.page) ?? 1}
				pageCount={publishedTotalPages}
				searchLine={`${AdminPath.Solutions}/${AdminCatalogRoutes.Published}`}
			/>
		</>
	);
};
