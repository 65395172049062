import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import { DescriptionSolution } from "../../../components/MainLayoutComponents/ResourceComponents/DescriptionSolution/DescriptionSolution";
import { AdminPath, Path } from "../../../services/router/routes";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { PageTypes } from "../../../helpers/DescriptionSolution";
import { Roles } from "../../../helpers/roles";

export const SolutionPreview = () => {
	const params = useParams();
	const location = useLocation();
	const navigator = useNavigate();
	const userData = useAppSelector(userDataSelector).userData;

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (
			location.pathname.includes(AdminPath.SolutionPreview) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigator(Path.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const solutionPreviewPageType: PageTypes =
		String(params.solutionId ?? 0) === "0" ? PageTypes.UNSAVED_DATA : PageTypes.ADMIN_PANEL;

	return <DescriptionSolution pageType={solutionPreviewPageType} />;
};
