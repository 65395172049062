import { IEditResult } from "../components/FilterGroup";

export enum CrudMethods {
	post = "POST",
	get = "GET",
	put = "PUT",
	delete = "DELETE",
}

export type EmailDataType = {
	email: string;
};

export interface IActionPlatformsModal {
	endpoint: string;
	method: CrudMethods;
}

export interface IActionStaffModal extends IActionPlatformsModal {
	emailData?: EmailDataType;
	employeeProfileData?: IEmployeeProfileData;
}

export interface IActionFilterGroup extends IActionPlatformsModal {
	filterData?: IEditResult;
}

export interface IEmployeeProfileData {
	firstname: string;
	lastname?: string;
	email: string;
	password: string;
	role: string;
}

export interface IParams {
	offset: number;
	limit?: number;
}

export interface ISearchLineData {
	value: string;
}

export type SearchFilterGroupType = () => Promise<null | undefined | void>;
