import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAdminSolutionsData, IDraftedSolutionsState } from "../../../../../../types/redux/storeTypes";

import { getDraftedSolutions } from "./DraftedSolutionThunk";

const draftedSolutionsInitialState: IDraftedSolutionsState = {
	draftedTotalPages: 0,
	draftedSolutions: [],
	isLoading: false,
	error: null,
};

const DraftedSolutions = createSlice({
	name: "draftedSolutions",
	initialState: draftedSolutionsInitialState,
	reducers: {
		clearDraftedSolutions: (state) => {
			state.draftedSolutions = [];
			state.draftedTotalPages = 0;
		},
	},
	extraReducers(builder) {
		builder.addCase(getDraftedSolutions.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getDraftedSolutions.fulfilled, (state, action: PayloadAction<IAdminSolutionsData>) => {
			state.draftedTotalPages = action.payload.totalPages;
			state.draftedSolutions = action.payload.content;
			state.isLoading = false;
		});
		builder.addCase(getDraftedSolutions.rejected, (state, action: PayloadAction<string | unknown>) => {
			state.error = action.payload;
			state.isLoading = false;
		});
	},
});

export const { clearDraftedSolutions } = DraftedSolutions.actions;
export default DraftedSolutions.reducer;
