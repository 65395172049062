import { ICheckBoxItem } from "../../types/common/UiKitProps";

import styles from "./Radio.module.css";

const Radio = ({ label, checked, onChange }: ICheckBoxItem) => {
	const handleInputChange = () => {
		if (onChange) {
			onChange(!checked);
		}
	};

	return (
		<div className={styles.circleContainer} onClick={handleInputChange}>
			<div className={`${styles.customCircle} ${checked ? styles.checked : ""}`}>
				{checked && <div className={styles.dot} />}
			</div>
			<label className={styles.circleLabel}>{label}</label>
		</div>
	);
};

export default Radio;
