import { BaseSyntheticEvent, ChangeEvent, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useAppSelector from "../../../hooks/useAppSelector";

import { ContactFormData } from "../../../types/components/ContactsForm";
import { SendExtendedUserOrder } from "../../../types/api/AuthApiTypes";
import { Form } from "../../../ui/Form/Form";
import { FormattedPhoneInput } from "../Modal/FormattedPhoneInput/FormattedPhoneInput";
import { AuthInput } from "../../AuthLayoutComponents/AuthInput/AuthInput";
import { Path } from "../../../services/router/routes";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { platformFavoritesSelector } from "../../../services/redux/features/userPart/platformFavorites/PlatformFavoritesSelector";
import { solutionFavoritesSelector } from "../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSelector";
import { sendUserOrder } from "../../../services/api/auth/sendOrder";
import { phonePattern, emailPattern, firstnamePattern } from "../../../helpers/AuthConstants";
import { formHelpers } from "../../../utils/formHelpers";

import styles from "./ContactsForm.module.css";

export const ContactsForm = () => {
	const defaultFormValues = {
		firstname: "",
		email: "",
		phone: "",
		description: "",
	};
	const {
		handleSubmit,
		control,
		register,
		setValue,
		formState: { errors, isValid },
	} = useForm<ContactFormData>({
		defaultValues: defaultFormValues,
		mode: "all",
		delayError: 1000,
	});

	const navigate = useNavigate();
	const userData = useAppSelector(userDataSelector).userData;
	const activeSolutionTitle = useAppSelector(solutionFavoritesSelector).activeSolutionTitle;
	const activePlatformTitle = useAppSelector(platformFavoritesSelector).activePlatformTitle;

	useEffect(() => {
		if (userData?.id) {
			setValue("firstname", userData?.firstname);
			setValue("email", userData?.email);
		}
	}, [setValue, userData]);

	function getVisitedFromValue(isLogin: boolean): string {
		if (!activeSolutionTitle && !activePlatformTitle) {
			return "";
		}

		return isLogin
			? `${activeSolutionTitle ? "Решение" : "Платформа"} - ${
					activeSolutionTitle ? activeSolutionTitle : activePlatformTitle
			  }`
			: "";
	}

	const onSubmit: SubmitHandler<ContactFormData> = (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		const userInfo: SendExtendedUserOrder = {
			email: data.email.trim(),
			name: data.firstname.trim(),
			number: data.phone,
			text: data.description,
			visitedFrom: getVisitedFromValue(!!userData?.email),
		};
		sendUserOrder(userInfo).then(() => navigate(userData?.id ? Path.PersonalAreaAcceptedPage : Path.AcceptedPage));
	};
	return (
		<Form
			noValidate={true}
			styleName="contactForm"
			buttonName="Отправить"
			isValid={isValid}
			onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.box}>
				<AuthInput
					placeholder="Имя"
					label=""
					name="firstname"
					control={control}
					rules={{ required: true, minLength: 2, maxLength: 25, pattern: firstnamePattern }}
					error={formHelpers.getNameError(errors)}
				/>
				<AuthInput
					placeholder="Email"
					label=""
					name="email"
					control={control}
					rules={{
						required: true,
						pattern: emailPattern,
						minLength: 8,
						maxLength: 30,
					}}
					error={formHelpers.getEmailError(errors)}
				/>
				<FormattedPhoneInput
					placeholder="Телефон"
					register={register("phone", { required: true, minLength: 5, pattern: phonePattern })}
					name={"phone"}
					id={"phone"}
					label={""}
					maxLength={19}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setValue("phone", formHelpers.formatPhone(e.target.value))}
					error={formHelpers.getPhoneError(errors)}
				/>
			</div>
			<textarea
				className={styles.description}
				id="description"
				placeholder="Расскажите немного о вашей задаче"
				{...register("description")}></textarea>
		</Form>
	);
};
