import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { ICurrentSolution } from "../../../../types/common/PageProps";
import { HandleImageErrorType } from "../../../../types/components/PlatformCatalog";
import { IUnsavedSolution } from "../../../../types/components/DescriptionSolution";
import { IDescriptionSolution } from "../../../../types/common/ComponentProps";
import ReturnBackArrow from "../../../../ui/ReturnBackArrow/ReturnBackArrow";
import { getCurrentSolutionData } from "../../../../services/api/resources/getCurrentSolutionData";
import { setSolutionFavorite } from "../../../../services/api/resources/setSolutionFavorite";
import ImageViewElement from "../../../AdminLayoutComponents/ImageViewElement/ImageViewElement";
import { getCorrectResourceLogo } from "../../../../utils/getCorrectResourceLogo";
import { setSolutionHistory } from "../../../../services/api/resources/setSolutionHistory";
import userDataSelector from "../../../../services/redux/features/userPart/userData/UserDataSelector";
import { setActiveSolution } from "../../../../services/redux/features/userPart/solutionFavorites/SolutionFavoritesSlice";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import ResourceDataEditSelector from "../../../../services/redux/features/adminPart/resourceForEditPages/ResourceDataEditSelector";
import { Path } from "../../../../services/router/routes";
import { ReturnBackArrowTypes } from "../../../../helpers/ReturnBack";
import { PageTypes } from "../../../../helpers/DescriptionSolution";

import { mockPlatformLogo } from "../../../../mocks/linksArr";

import style from "./DescriptionSolution.module.css";

export const DescriptionSolution = ({ pageType }: IDescriptionSolution) => {
	const [currentSolution, setCurrentSolution] = useState<ICurrentSolution | IUnsavedSolution>();
	const [isFavorite, setIsFavorite] = useState<boolean | undefined>(false);
	const [imageError, setImageError] = useState<boolean>(false);

	const isLogin = useAppSelector(userDataSelector).userData?.email;
	const unsavedSolutionData = useAppSelector(ResourceDataSelector).currentSolutionData;
	const unsavedEditSolutionData = useAppSelector(ResourceDataEditSelector).currentEditSolutionData;
	const solutionId = Number(useParams().solutionId);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (currentSolution && isLogin && pageType === PageTypes.ADMIN_PANEL) setSolutionHistory(currentSolution?.name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSolution?.name]);

	useEffect(() => {
		window.localStorage.setItem("previousPath", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (pageType === PageTypes.UNSAVED_DATA) {
			if (location.search.includes("from=solutionCreation")) {
				setCurrentSolution(unsavedSolutionData);
			} else {
				setCurrentSolution(unsavedEditSolutionData);
			}
		} else {
			(async () => {
				const response = await getCurrentSolutionData(solutionId);
				const solution = await response.json();
				setCurrentSolution(solution);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [solutionId, navigate]);

	useEffect(() => {
		if (currentSolution && pageType === PageTypes.ADMIN_PANEL) setIsFavorite(currentSolution?.favorite);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [solutionId, currentSolution]);

	const handleSetFavoriteClick = () => {
		if (!currentSolution || pageType === PageTypes.PREVIEW) {
			return null;
		}

		!isLogin
			? navigate(Path.Login)
			: setSolutionFavorite(currentSolution?.id, isFavorite).then(() => setIsFavorite(currentSolution.favorite));
	};

	const renderActionFavoriteButton = () => {
		return (
			<button onClick={handleSetFavoriteClick}>{isFavorite ? "Убрать из избранного" : "Добавить в избранное"}</button>
		);
	};

	const renderPlatformNames = () => {
		return (
			<div className={style.platformNames}>
				{currentSolution?.platformsNames && !currentSolution?.platformsNames.length ? (
					<span>Список платформ пуст</span>
				) : (
					<>
						<span>Платформа: </span>
						<ul className={style.solutionDescriptionFilters__values}>
							{currentSolution?.platformsNames &&
								currentSolution?.platformsNames.map((name) => (
									<li key={crypto.randomUUID()} className={style.solutionDescriptionFilters__value}>
										<p>{name}</p>
									</li>
								))}
						</ul>
					</>
				)}
			</div>
		);
	};

	const renderFilterList = () => {
		return !currentSolution?.filters || !currentSolution?.filters.length || !currentSolution ? (
			<p>Список фильтров пуст</p>
		) : (
			<ul className={style.solutionDescriptionFilters}>
				{currentSolution?.filters?.map((filter) => (
					<li key={crypto.randomUUID()}>
						<span>{filter.name}: </span>
						{
							<ul className={style.solutionDescriptionFilters__values}>
								{filter.values.map((value) => (
									<li className={style.solutionDescriptionFilters__value} key={crypto.randomUUID()}>
										<p>{value}</p>
									</li>
								))}
							</ul>
						}
					</li>
				))}
			</ul>
		);
	};

	const handleImageError: HandleImageErrorType = () => {
		return () => setImageError(true);
	};

	const getCorrectSolutionImage = () => {
		if (pageType === PageTypes.UNSAVED_DATA) {
			return unsavedSolutionData.logo ? (
				<ImageViewElement imageTextLine={unsavedSolutionData.logo} />
			) : (
				<img
					src={getCorrectResourceLogo(currentSolution?.logo, mockPlatformLogo, imageError)}
					alt="solution logo"
					onError={handleImageError()}
				/>
			);
		}

		return (
			<img
				src={getCorrectResourceLogo(currentSolution?.logo, mockPlatformLogo, imageError)}
				alt="solution logo"
				onError={handleImageError()}
			/>
		);
	};

	const getCorrectContactLink = () => {
		if (pageType === PageTypes.PREVIEW) {
			return null;
		}

		if (isLogin && currentSolution?.name) {
			dispatch(setActiveSolution(currentSolution?.name));
			navigate(Path.PersonalAreaContacts);
		} else navigate(Path.Contacts);
	};

	return (
		<div className={style.solutionDescriptionWrap}>
			<ReturnBackArrow type={ReturnBackArrowTypes.FullResourceDescriptionType} />
			<main className={style.solutionDescriptionContainer}>
				<div className={style.solutionDescriptionHeader}>
					<div className={style.solutionDescriptionLogo}>{getCorrectSolutionImage()}</div>
					<div className={style.solutionDescriptionHeader__content}>
						<h3 className={style.solutionDescriptionHeader__title}>{currentSolution?.name}</h3>
						<p className={style.solutionDescriptionHeader__text}>{currentSolution?.shortDescription}</p>
						<p className={style.solutionDescriptionCost}>{`${currentSolution?.cost ?? 0}  ₽`}</p>
						<div className={style.buttonGroups}>
							{renderActionFavoriteButton()}
							<button onClick={getCorrectContactLink}>Купить</button>
						</div>
					</div>
				</div>
				<div className={style.solutionDescriptionMain}>
					<h3 className={style.solutionDescriptionMain__title}>О чат-боте</h3>
					<p className={style.solutionDescriptionMain__text}>{currentSolution?.description}</p>
				</div>
				<div className={style.solutionDescriptionDetail}>
					<h3 className={style.solutionDescriptionDetail__title}>Детали</h3>
					<div className={style.solutionDescriptionDetail__content}>
						<div>
							{renderPlatformNames()}
							{renderFilterList()}
						</div>
						<div className={style.solutionDescriptionTooltip}>
							<p className={style.solutionDescriptionTooltip_title}>Не хватает функционала?</p>
							<p className={style.solutionDescriptionTooltip__content}>
								Закажите это решение с персональной доработкой от нашего агентства
							</p>
							<button onClick={getCorrectContactLink}>Купить с возможностью доработки</button>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};
