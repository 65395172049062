import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { NewPasswordFullData } from "../../../types/api/AuthApiTypes";

import { resetPasswordEndpoint } from "./apiConstants";

export async function sendNewPassword(newPasswordData: NewPasswordFullData) {
	const baseURL = getCorrectBaseUrl("as");

	return await fetch(`${baseURL}/${resetPasswordEndpoint}`, {
		method: "POST",
		body: JSON.stringify(newPasswordData),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
