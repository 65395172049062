import { IPartners } from "../../../types/common/ComponentProps";

import style from "./PartnersCard.module.css";

export const PartnersCard = ({ partners }: IPartners) => {
	return (
		<div className={style.partners__frame}>
			<img src={partners} alt="partners" />
		</div>
	);
};
