import { useNavigate } from "react-router-dom";

import { ArrowBackIcon } from "../../../assets/script/ArrowBack";

const ReturnBackArrow = () => {
	const navigator = useNavigate();

	return (
		<button onClick={() => navigator(-1)}>
			<ArrowBackIcon />
		</button>
	);
};

export default ReturnBackArrow;
