import { useCallback, useRef } from "react";

import { SearchFilterGroupType } from "../types/api/AdminApiTypes";

function useDebounce(callback: SearchFilterGroupType, delay: number) {
	const timer = useRef<NodeJS.Timeout | undefined>();

	return useCallback(() => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(() => {
			callback();
		}, delay);
	}, [callback, delay]);
}

export default useDebounce;
