import { useEffect, useState } from "react";
import cn from "classnames/dedupe";

import arrowUp from "../../../assets/components/ArrowUp/arrow-up.svg";

import styles from "./ArrowUp.module.css";

function ArrowUp() {
	const [hideScrollButton, setHideScrollButton] = useState<boolean>(true);

	const firstBlockPosition: number = 900;

	useEffect(() => {
		const handleScroll = () => {
			const isOnTopPosition = document.documentElement.scrollTop > firstBlockPosition;
			setHideScrollButton(!isOnTopPosition);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	function goToTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	return (
		<button className={cn(styles.arrowUpWrap, { [styles.arrowUpWrap__hidden]: hideScrollButton })} onClick={goToTop}>
			<img src={arrowUp} alt="arrow up" />
		</button>
	);
}

export default ArrowUp;
