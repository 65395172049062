import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { settingSolutionHistoryEndpoint } from "./apiResourcesConstants";

export async function setSolutionHistory(solutionHistoryData: string) {
	const baseURL = getCorrectBaseUrl("ps");

	return await fetch(`${baseURL}/${settingSolutionHistoryEndpoint}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(solutionHistoryData),
		credentials: "include",
	});
}
