import { Outlet } from "react-router-dom";

import HeaderAdmin from "../../components/AdminLayoutComponents/HeaderAdmin/HeaderAdmin";
import NavBarAdmin from "../../components/AdminLayoutComponents/NavBarAdmin/NavBarAdmin";

import styles from "./AdminLayout.module.css";

export const AdminLayout = () => {
	return (
		<div className={styles.adminLayoutWrap}>
			<HeaderAdmin />
			<div className={styles.adminLayoutWrap__flexContainer}>
				<NavBarAdmin />
				<div className={styles.layoutContainer}>
					<Outlet />
				</div>
			</div>
		</div>
	);
};
