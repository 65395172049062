import { getCorrectBaseUrl } from "../../../utils/baseUrlConverter";

import { solutionNamesForPlatforms } from "./apiAdminConstants";

export async function getSolutionsNames() {
	const baseURL = getCorrectBaseUrl("ps");

	return fetch(`${baseURL}/${solutionNamesForPlatforms}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
}
