import jivosite from "../assets/pages/Service/JivositePartner.svg";
import manyChat from "../assets/pages/Service/ManyChatPartner.svg";
import fasttrack from "../assets/pages/Service/fasttrackPartner.svg";
import onlizer from "../assets/pages/Service/onlizerParner.svg";
import rpvbot from "../assets/pages/Service/rpvbotPartner.svg";
import socialsend from "../assets/pages/Service/socialsendPartner.svg";
import sparkSystems from "../assets/pages/Service/spark_systems_partner.svg";
import zapier from "../assets/pages/Service/zapierPartner.svg";
import arrow from "../assets/pages/Service/arrow.svg";
import phone from "../assets/pages/Service/phonIcon1.svg";
import lamp from "../assets/pages/Service/lamp.svg";
import currency from "../assets/pages/Service/currency.svg";
import firstClient from "../assets/pages/Service/Client_1.png";
import secondClient from "../assets/pages/Service/Client_2.png";
import thirdClient from "../assets/pages/Service/Client_3.png";
import fourthClient from "../assets/pages/Service/Client_4.png";

export const clientReviewData = [
	{
		firstIcon: firstClient,
		secondIcon: secondClient,
		firstName: "Иван",
		secondName: "Ксения",
		firstProfession: "Директор кампании «Альфа»",
		secondProfession: "Менеджер по цифровому маркетингу в кампании «Energo»",
		firstReview:
			"Обеспечили рост среднего чека на 10%, сократили расходы на содержание колл-центра на 20%, обеспечили доходимость лидов до покупки в воронке продаж до 50%",
		secondReview:
			"Я уже долгое время занимаюсь своим бизнесом в сфере розничной торговли, и постоянно ищу новые способы улучшения своей деятельности. Когда я узнала о возможности использования чат-бота для взаимодействия с клиентами и автоматизации многих процессов, я решила попробовать это решение.",
	},
	{
		firstIcon: fourthClient,
		secondIcon: thirdClient,
		firstName: "Владислав",
		secondName: "Екатерина",
		firstProfession: "Менеджер франшизы «Грошык",
		secondProfession: "Руководитель отдела продаж в “Приор”",
		firstReview:
			"Я очень доволен своим опытом использования чат-бота для улучшения моего бизнеса. Я уверен, что это решение помогло мне увеличить прибыль и улучшить уровень обслуживания клиентов. Я рекомендую использовать чат-боты для всех предпринимателей, которые хотят улучшить свой бизнес и упростить свою работу.",
		secondReview:
			"С помощью чат-бота я также смогла улучшить свои маркетинговые стратегии. Чат-бот помог мне собирать данные о клиентах и анализировать их поведение, чтобы я могла создавать более эффективные рекламные кампании и предлагать клиентам более подходящие товары и услуги.",
	},
];

export const opportunitiesArr = [
	{
		icon: arrow,
		title: "Увеличение конверсий",
		text: "Рост микро и макро конверсии для вашего бизнеса",
		to: "blog",
	},
	{
		icon: lamp,
		title: "Снижения затрат на реализацию идей",
		text: "Чат-бот экономит деньги, предоставляя инфо и ресурсы, а также давая советы для оптимизации",
		to: "blog",
	},
	{
		icon: phone,
		title: "Автоматизации обработки заявок",
		text: "Снижение потребляемого времени и человеческих ресурсов на обработку заявок",
		to: "blog",
	},
	{
		icon: currency,
		title: "Приема оплат в мессенджере",
		text: "Процесс приёма оплат от ваших клиентов наш бот-мессенджер полностью возьмёт на себя",
		to: "blog",
	},
];

export const partnersArr = [
	{ partners: zapier },
	{ partners: manyChat },
	{ partners: jivosite },
	{ partners: sparkSystems },
	{ partners: onlizer },
	{ partners: rpvbot },
	{ partners: fasttrack },
	{ partners: socialsend },
];

export const priceArr = [
	{
		priceName: "Стандартный",
		price: "917 ",
		description: [
			"Быстрая настройка чат-бота",
			"Сегментация аудитории",
			"Аpi для разработчиков",
			"Неограниченное количество чат-ботов",
			"Встроенная crm-система",
		],
	},
	{
		priceName: "Продвинутый",
		price: "1 667 ",
		description: [
			"Неограниченное количество операторов",
			"Настройка виджета",
			"Подключение чат - бота к виджету",
			"Простая интеграция на сайт",
			"Инструменты аналитики",
		],
	},
	{
		priceName: "Профессиональный",
		price: "2 416 ",
		description: [
			"Детальный анализ проекта",
			"Индивидуальный функционал",
			"Безлимит подписчиков",
			"Разработка api под проект",
			"Подключение любых мессенджеров",
		],
	},
];

export const targetAudienceArr = [
	{ description: "Внедрить проверенное решение по автоматизации" },
	{ description: "Протестировать проверенные инструменты мессенджер-маркетинга" },
	{ description: "Выбрать сервисы под ваши задачи" },
	{ description: "Получать новых клиентов и увеличить продажи в мессенджерах" },
	{ description: "Готовое и простое решение для достижения бизнес-целей" },
];
