export const firstElementTopPosition: number = 356;
export const platformCardsDifferencePosition: number = 300;
export const contextMenuDivideWidthValue: number = 1500;
export const fullHdLeftPosition: number = 0.62;
export const hdLeftPosition: number = 0.68;

export enum SwitchContentTitles {
	Published = "Опубликовано",
	Drafted = "Черновики",
	Deleted = "Удалено",
}

export enum PlatformAdminContextMenuItems {
	Edit = "Редактировать",
	Preview = "Предпросмотр",
	Publish = "Опубликовать",
	Unpublish = "Снять с публикации",
	Recover = "Восстановить",
	Delete = "Удалить",
}
