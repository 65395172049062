import { SubmitHandler, useForm } from "react-hook-form";
import { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useModal from "../../../hooks/useModal";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

import { IEditSolution, IFilterChecked, IGetSolution } from "../../../types/pages/SolutionCatalog";
import { IUnsavedSolution } from "../../../types/components/DescriptionSolution";
import { Button } from "../../../ui/Button/Button";
import { Title } from "../../../ui/Title/Title";
import CheckBoxBlock from "../../../components/AdminLayoutComponents/CreationComponents/CheckBoxBlock/CheckBoxBlock";
import FieldsToFill from "../../../components/AdminLayoutComponents/CreationComponents/FieldsToFill/FieldsToFill";
import ButtonsSubmit from "../../../components/AdminLayoutComponents/CreationComponents/ButtonsSubmit/ButtonsSubmit";
import ModalHandelClose from "../../../components/PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { changeEditSolution } from "../../../services/api/admin/changeEditSolution";
import { moveSolutionToPublished, removeSolutionToDeleted } from "../../../services/api/admin/solutionsPageActions";
import { getCurrentSolutionData } from "../../../services/api/resources/getCurrentSolutionData";
import ResourceDataEditSelector from "../../../services/redux/features/adminPart/resourceForEditPages/ResourceDataEditSelector";
import userDataSelector from "../../../services/redux/features/userPart/userData/UserDataSelector";
import { AdminPath, Path } from "../../../services/router/routes";
import { Roles } from "../../../helpers/roles";
import {
	setEditSolutionData,
	setSelectedFilterForEdit,
} from "../../../services/redux/features/adminPart/resourceForEditPages/ResourceDataEditSlice";

import { ArrowBackIcon } from "../../../assets/script/ArrowBack";

import styles from "./EditSolution.module.css";

function EditSolution() {
	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IEditSolution>({
		defaultValues: {
			id: 0,
			name: "",
			logo: "",
			description: "",
			shortDescription: "",
			filters: [],
			platformsNames: [],
		},
		mode: "all",
		delayError: 200,
	});

	const [solutionNames, setSolutionName] = useState<IGetSolution>();
	const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
	const [selectedFilters, setSelectedFilters] = useState<IFilterChecked[]>([]);

	const unsavedSolution = useAppSelector(ResourceDataEditSelector).currentEditSolutionData;
	const selectedFilter = useAppSelector(ResourceDataEditSelector).selectedFilters;
	const userData = useAppSelector(userDataSelector).userData;
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { isOpen, toggleModal } = useModal();
	const dispatch = useAppDispatch();

	const maxFileSize: number = 1024;
	const minImageWidth: number = 808;
	const maxImageWidth: number = 1920;
	const minImageHight: number = 632;
	const maxImageHight: number = 1080;

	useEffect(() => {
		if (
			location.pathname.includes(AdminPath.SolutionsEdit) &&
			(!userData?.email || userData?.roles[0]?.name === String(Roles.USER))
		) {
			navigate(Path.Login);
		}
		if (userData?.roles[0]?.name === String(Roles.MODERATOR)) {
			navigate(AdminPath.Drafts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (unsavedSolution.name === "") {
			(async () => {
				const solutionResponse: Response = await getCurrentSolutionData(Number(id));
				const solutionData: Promise<IGetSolution> = await solutionResponse.json();
				setSolutionName(await solutionData);
				setImageUrl((await solutionData).logo);
				setValue("name", (await solutionData).name.trim());
				setValue("shortDescription", (await solutionData).shortDescription);
				setValue("description", (await solutionData).description);
				setValue("cost", String((await solutionData).cost));
				setValue("logo", (await solutionData).logo);
			})();
		}
	}, [id, setValue, unsavedSolution]);

	useEffect(() => {
		if (unsavedSolution.name !== "") {
			setValue("name", unsavedSolution.name?.trim() || "");
			setValue("shortDescription", unsavedSolution.shortDescription?.trim() || "");
			setValue("description", unsavedSolution.description?.trim() || "");
			setValue("cost", unsavedSolution.cost || "");
			setValue("logo", unsavedSolution.logo || "");
		}
	}, [unsavedSolution, setValue]);

	useEffect(() => {
		const previousPath = window.localStorage.getItem("previousPath");
		if (!previousPath?.includes(`${AdminPath.SolutionPreview}/0`)) {
			dispatch(setSelectedFilterForEdit([]));
			dispatch(setEditSolutionData({ name: "" }));
		}
	}, [dispatch]);

	const groupFilters = (filters: IFilterChecked[]): IFilterChecked[] => {
		const groupedFilters: Record<number, number[]> = {};

		filters.forEach((filter) => {
			if (!groupedFilters[filter.filterId]) {
				groupedFilters[filter.filterId] = [];
			}
			groupedFilters[filter.filterId] = [...[...groupedFilters[filter.filterId], ...filter.valueIds]];
		});

		return Object.entries(groupedFilters).map(([filterId, valueIds]) => ({
			filterId: parseInt(filterId),
			valueIds,
		}));
	};

	const unpublishedSolution: SubmitHandler<IEditSolution> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};

		const updatedData = {
			...trimmedData,
			filters: groupFilters(selectedFilters),
		};
		const change = await changeEditSolution(updatedData, id);
		if (change.ok) {
			navigate(-1);
		}
	};

	const setDataForSolutionPreviewPage = async (updateSolution: IUnsavedSolution) => {
		return await dispatch(setEditSolutionData(updateSolution));
	};

	const deleteSolution = async () => {
		const remove = await removeSolutionToDeleted(Number(id));
		if (remove.ok) {
			navigate(-1);
		}
	};

	const readImage = (file: File) => {
		const reader = new FileReader();
		reader.onload = () => {
			const imageDataUrl = reader.result as string;
			const img = new Image();
			img.src = imageDataUrl;
			img.onload = () => {
				if (isValidSize(img.width, img.height)) {
					setImageAndValue(imageDataUrl);
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const isValidSize = (width: number, height: number) => {
		return width >= minImageWidth && width <= maxImageWidth && height >= minImageHight && height <= maxImageHight;
	};

	const setImageAndValue = (imageDataUrl: string) => {
		setImageUrl(imageDataUrl);
		setValue("logo", imageDataUrl);
	};

	const handleUnsupportedType = () => {
		setValue("logo", solutionNames?.logo || "");
	};

	const handlePreviewNavigate: SubmitHandler<IEditSolution> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const currentImageUrl = imageUrl;
		const updateSolution: IUnsavedSolution = {
			name: data.name.trim(),
		};
		if (data.logo) {
			updateSolution.logo = data.logo;
		}
		if (data.cost) {
			updateSolution.cost = Number(data.cost);
		}
		if (data.shortDescription) {
			updateSolution.shortDescription = data.shortDescription.trim();
		}
		if (data.description) {
			updateSolution.description = data.description.trim();
		}
		if (data.platformsNames) {
			updateSolution.platformsNames = data.platformsNames;
		}
		setImageUrl(currentImageUrl);
		dispatch(setSelectedFilterForEdit(groupFilters(selectedFilters)));
		await setDataForSolutionPreviewPage(updateSolution);

		navigate(`${AdminPath.SolutionPreview}/0`);
	};

	const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (!file) return;
		if (file) {
			const allowedImageTypes = ["image/jpeg", "image/png", "image/webp"];
			if (allowedImageTypes.includes(file.type)) {
				if (file.size <= maxFileSize * maxFileSize) {
					readImage(file);
				}
			} else {
				handleUnsupportedType();
			}
		}
	};

	const onSubmit: SubmitHandler<IEditSolution> = async (data, event?: BaseSyntheticEvent) => {
		event?.preventDefault();
		data.name = data.name.replace(/\s{2,}/g, " ");
		const trimmedData = {
			...data,
			name: data.name.trim(),
			shortDescription: data.shortDescription.trim(),
			description: data.description.trim(),
		};

		const updatedData = {
			...trimmedData,
			filters: groupFilters(selectedFilters),
			name: data.name.trim(),
		};

		const change = await changeEditSolution(updatedData, id);
		if (change.ok) {
			if (!solutionNames?.published) {
				moveSolutionToPublished(Number(id));
				navigate(`${AdminPath.Solutions}/published/1`);
			} else {
				navigate(-1);
			}
		}
	};

	function renderCOrrectCheckbox() {
		return unsavedSolution.name !== "" ? (
			<CheckBoxBlock
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
				checkedCreationFilter={selectedFilter}
				checked={true}
				type="Решения"
			/>
		) : (
			<CheckBoxBlock
				type="Решения"
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
				checkedFilters={solutionNames?.filters}
			/>
		);
	}

	return (
		<div className={styles.editSolutionWrapper}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.editSolutionHead}>
					<button className={styles.editSolutionBackButton} onClick={toggleModal} type="button">
						<ArrowBackIcon />
					</button>
					<Title title="Редактирование" styleName="titlePage" />
					<span className={styles.invisible}></span>
				</div>
				<div className={styles.editSolutionParams}>
					<FieldsToFill
						control={control}
						handleImageChange={handleImageChange}
						imageUrl={imageUrl}
						solutionNames={solutionNames}
						imageText={"Изменить изображение"}
						height="530px"
						errors={errors}
						unsavedImg={unsavedSolution.logo}
					/>
					{renderCOrrectCheckbox()}
				</div>
				<ButtonsSubmit
					showButton={true}
					deleted={deleteSolution}
					preview={handleSubmit(handlePreviewNavigate)}
					unpublished={handleSubmit(unpublishedSolution)}
					onSubmit={handleSubmit(onSubmit)}
				/>
				<ModalHandelClose open={isOpen} toggle={toggleModal} title="Выйти из редактирования?">
					<Button
						content="Сохранить и выйти"
						styleName="saveAndExitButton"
						testId=""
						onClick={handleSubmit(unpublishedSolution)}
					/>
					<Button
						content="Выйти без сохранения"
						styleName="exitWithoutSavingButton"
						testId=""
						onClick={() => navigate(-1)}
					/>
				</ModalHandelClose>
			</form>
		</div>
	);
}

export default EditSolution;
