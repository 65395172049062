import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";

import { IPlatformsUserStatus } from "../../../../types/common/ComponentProps";
import { IContextMenuItem, IContextModalPositionState } from "../../../../types/ui/ContextMenuModal";
import PlatformCatalogCard from "../../../MainLayoutComponents/ResourceComponents/PlatformCatalogCard/PlatformCatalogCard";
import ContextMenuModal from "../../../../ui/ContextMenuModal/ContextMenuModal";
import ModalHandelClose from "../../../PersonalAreaLayoutComponents/ModalHandelClose/ModalHandelClose";
import { Button } from "../../../../ui/Button/Button";
import PaginationElement from "../../../../ui/PaginationElement/PaginationElement";
import { getDraftedPlatformsData } from "../../../../services/redux/features/adminPart/platforms/draftedPlatforms/DraftedPlatformsThunk";
import DraftedPlatformsSelector from "../../../../services/redux/features/adminPart/platforms/draftedPlatforms/DraftedPlatformsSelector";
import ResourceDataSelector from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSelector";
import { clearDraftedPlatformsData } from "../../../../services/redux/features/adminPart/platforms/draftedPlatforms/DraftedPlatformsSlice";
import { setResourceId } from "../../../../services/redux/features/adminPart/resourceData/ResourceDataSlice";
import { movePlatformToPublished, removePlatformToDeleted } from "../../../../services/api/admin/platformsPageActions";
import { AdminCatalogRoutes, PlatformCardTypes, platformListErrorContent } from "../../../../helpers/catalogHelpers";
import { AdminPath } from "../../../../services/router/routes";
import { defaultContextMenuPosition } from "../../../../helpers/ContextMenu";
import {
	PlatformAdminContextMenuItems,
	contextMenuDivideWidthValue,
	firstElementTopPosition,
	fullHdLeftPosition,
	hdLeftPosition,
	platformCardsDifferencePosition,
} from "../../../../helpers/platformAdmin";

import styles from "./DraftedPlatforms.module.css";

function DraftedPlatforms({ isModerator }: IPlatformsUserStatus) {
	const [toggleContextModal, setToggleContextModal] = useState<boolean>(false);
	const [toggleListUpdate, setToggleListUpdate] = useState<boolean>(false);
	const [contextModalPosition, setContextModalPosition] =
		useState<IContextModalPositionState>(defaultContextMenuPosition);
	const [currentModalName, setCurrentModalName] = useState<ModalsNameList | undefined>(undefined);
	const [isPaginationHidden, setIsPaginationHidden] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	const dispatch = useAppDispatch();
	const params = useParams();
	const navigator = useNavigate();
	const platformId = useAppSelector(ResourceDataSelector).currentResourceId;
	const { draftedPlatforms, pageNumber, loading, platformsOnPage, error } = useAppSelector(DraftedPlatformsSelector);

	const activeSwitchElement: AdminCatalogRoutes = params?.pagepart as AdminCatalogRoutes;

	enum ModalsNameList {
		modalPublish = "modalPublish",
		modalRemove = "modalRemove",
	}

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (activeSwitchElement === AdminCatalogRoutes.Drafted) {
			dispatch(getDraftedPlatformsData(Number(params.page)));
		}

		return () => {
			dispatch(clearDraftedPlatformsData());
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSwitchElement, dispatch, params.page, toggleListUpdate]);

	const contextMenuItems: IContextMenuItem[] = [
		{
			title: PlatformAdminContextMenuItems.Edit,
			onClick: () => navigator(`${AdminPath.PlatformEdit}/${platformId}`),
		},
		{
			title: PlatformAdminContextMenuItems.Preview,
			onClick: () => navigator(`${AdminPath.PlatformPreview}/${platformId}`),
		},
		{
			title: PlatformAdminContextMenuItems.Publish,
			onClick: () => openPublishPlatformModal(),
		},
		{
			title: PlatformAdminContextMenuItems.Delete,
			onClick: () => openRemovePlatformModal(),
		},
	];

	const shortContextMenuItemList: IContextMenuItem[] = [contextMenuItems[0], contextMenuItems[1], contextMenuItems[2]];

	function toggleContextMenuWindow(cardPosition: number) {
		const top: number = firstElementTopPosition + platformCardsDifferencePosition * cardPosition;
		const left: number =
			windowWidth * (windowWidth > contextMenuDivideWidthValue ? fullHdLeftPosition : hdLeftPosition);
		setContextModalPosition({ top, left });
		setToggleContextModal(!toggleContextModal);
	}

	const closeCurrentModal = () => {
		dispatch(setResourceId(0));
		return setCurrentModalName(undefined);
	};

	const closeModalAfterAction = async () => {
		dispatch(setResourceId(0));
		setToggleContextModal(false);
		await setCurrentModalName(undefined);
		if (platformsOnPage === 1) {
			if (Number(params?.page) === 1) {
				setToggleListUpdate(!toggleListUpdate);
				return setIsPaginationHidden(!isPaginationHidden);
			}
			return navigator(AdminPath.Platforms + `/${Number(params.page) - 1}`);
		}
		return setToggleListUpdate(!toggleListUpdate);
	};

	const openPublishPlatformModal = () => {
		return setCurrentModalName(ModalsNameList.modalPublish);
	};

	const openRemovePlatformModal = () => {
		return setCurrentModalName(ModalsNameList.modalRemove);
	};

	const handlePublishingAction = async () => {
		await movePlatformToPublished(platformId);
		return closeModalAfterAction();
	};

	const handleRemovingAction = async () => {
		await removePlatformToDeleted(platformId);
		return closeModalAfterAction();
	};

	return (
		<section className={styles.draftedPlatformsWrap}>
			{!draftedPlatforms || !draftedPlatforms.length || loading ? (
				<h3>{error ? platformListErrorContent : ""}</h3>
			) : (
				draftedPlatforms.map((platformData, index) => (
					<PlatformCatalogCard
						type={PlatformCardTypes.AdminType}
						title={platformData.name}
						description={platformData.shortDescription}
						solutionNumber={platformData.totalSolution ?? 0}
						icon={platformData.logoUrl}
						cardId={platformData.id}
						key={platformData.id ?? index}
						toggleContextMenu={(event) => toggleContextMenuWindow(index)}
					/>
				))
			)}
			{!isPaginationHidden ? (
				<PaginationElement
					currPage={Number(params?.page) ?? 1}
					pageCount={pageNumber}
					searchLine={`${AdminPath.Platforms}/${AdminCatalogRoutes.Drafted}`}
				/>
			) : (
				<></>
			)}
			{toggleContextModal && (
				<ContextMenuModal
					content={isModerator ? shortContextMenuItemList : contextMenuItems}
					position={contextModalPosition}
					closeContextModal={() => setToggleContextModal(!toggleContextModal)}
				/>
			)}
			<ModalHandelClose
				title="Опубликовать?"
				open={currentModalName === ModalsNameList.modalPublish}
				isLeftAlignedTextHeader={true}
				toggle={closeCurrentModal}>
				<p className={styles.modalTitle}>Опубликованное появится на сайте и будет храниться в опубликованном</p>
				<Button
					content="Опубликовать"
					styleName="commonMaroonButton platformsAdminPublish"
					onClick={handlePublishingAction}
				/>
			</ModalHandelClose>
			<ModalHandelClose
				title="Удалить?"
				open={currentModalName === ModalsNameList.modalRemove}
				isLeftAlignedTextHeader={true}
				toggle={closeCurrentModal}>
				<p className={styles.modalTitle}>Удалённое будет храниться 30 дней</p>
				<Button content="Удалить" styleName="commonMaroonButton platformsAdminRemove" onClick={handleRemovingAction} />
			</ModalHandelClose>
		</section>
	);
}

export default DraftedPlatforms;
