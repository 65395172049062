import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IComponentTogglerDataState } from "../../../../../types/redux/storeTypes";

const componentTogglerDataState: IComponentTogglerDataState = {
	isOpenComponent: false,
};

const ComponentTogglerSlice = createSlice({
	name: "componentToggler",
	initialState: componentTogglerDataState,
	reducers: {
		setIsOpenComponent: (state: IComponentTogglerDataState, action: PayloadAction<boolean>) => {
			state.isOpenComponent = action.payload;
		},
	},
});

export const { setIsOpenComponent } = ComponentTogglerSlice.actions;
export default ComponentTogglerSlice.reducer;
