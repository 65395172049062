import { Outlet } from "react-router-dom";

import { Footer } from "../../components/MainLayoutComponents/Footer/Footer";
import NavBar from "../../components/PersonalAreaLayoutComponents/NavBar/NavBar";
import PersonalAreaHeader from "../../components/PersonalAreaLayoutComponents/PersonalAreaHeader/PersonalAreaHeader";

import widgetBotLogo from "../../assets/layouts/Main/widgetBotTabledMessage.svg";

import styles from "./LayoutPersonalArea.module.css";

export const LayoutPersonalArea = () => {
	return (
		<div className={styles.layout}>
			<PersonalAreaHeader />
			<div className={styles.flex}>
				<NavBar />
				<div className={styles.layoutContainer}>
					<Outlet />
				</div>
				<button className={styles.widgetBotWrap}>
					<img src={widgetBotLogo} alt="widgetBot" />
				</button>
			</div>
			<Footer />
		</div>
	);
};
